import React, { useEffect, useState } from "react";
import { AccountType, UserTypeName } from "../constants/securspace-constants";
import MultiSelect from "../multiSelect/MultiSelect";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import SolidButton from "../form/SolidButton";
import OutlinedButton from "../form/OutlinedButton";

import "./style/manage-user-item.css";
import { ADMIN_EDIT_ROLES, isRoleApproved } from "../../util/user/UserUtil";

const displayMap = new Map([
  ["ROLE_USERTYPE_GATECLERK", UserTypeName.GATE_CLERK],
  ["ROLE_USERTYPE_GATEMANAGER", UserTypeName.GATE_MANAGER],
  ["ROLE_USERTYPE_DISPATCHER", UserTypeName.DISPATCHER],
  ["ROLE_USERTYPE_READ_ONLY_ADMIN", UserTypeName.READ_ONLY_ADMIN],
  ["ROLE_USERTYPE_OWNER", UserTypeName.ADMIN],
]);

const ManageUserItem = (props) => {
  const {
    user,
    locationOptions,
    onRemoveUser,
    onUpdateLocations,
    accountType,
    account,
  } = props;

  const { role } = user;

  const isSupplierAccount = accountType === AccountType.SUPPLIER;
  const disableEditButton = isSupplierAccount && role === "ROLE_USERTYPE_OWNER";

  const [editLocationsOpen, setEditLocationsOpen] = useState(false);
  const [updatedLocations, setUpdatedLocations] = useState(user.locationIds);
  const [isValidLocationSelected, setIsValidLocationSelected] = useState(
    updatedLocations && updatedLocations.length > 0
  );

  const toggleLocationVisibility = () => {
    setEditLocationsOpen(!editLocationsOpen);

    if (!editLocationsOpen) {
      setUpdatedLocations(user.locationIds);
    }
  };

  const internalSubmit = () => {
    onUpdateLocations(user, updatedLocations);
  };

  useEffect(() => {
    setEditLocationsOpen(false);
    setUpdatedLocations(user.locationIds);
    setIsValidLocationSelected(updatedLocations && updatedLocations.length > 0);
  }, [user.locationIds]);

  return (
    <div>
      <div className="manage-user-item-info-container">
        <div className="manage-user-info-item">{user.username}</div>
        <div className="manage-user-info-item">{displayMap.get(user.role)}</div>
        {isRoleApproved(ADMIN_EDIT_ROLES, account?.userType) && (
          <div className="manage-user-info-action-item-container">
            {isSupplierAccount && (
              <OutlinedButton
                label="Edit"
                onClick={toggleLocationVisibility}
                className="edit-locations-button"
                disabled={disableEditButton}
                title={
                  disableEditButton
                    ? "Cannot Edit Administrator Accounts"
                    : null
                }
              />
            )}
            <SolidButton label="Remove" onClick={() => onRemoveUser(user)} />
          </div>
        )}

        {!isRoleApproved(ADMIN_EDIT_ROLES, account?.userType) && (
          <div className="manage-user-info-action-item-container">
            {isSupplierAccount && (
              <OutlinedButton
                label="Edit"
                onClick={toggleLocationVisibility}
                className="edit-locations-button"
                disabled={!isRoleApproved(ADMIN_EDIT_ROLES, account?.userType)}
                title={
                  disableEditButton
                    ? "Cannot Edit Administrator Accounts"
                    : null
                }
              />
            )}
            <SolidButton
              disabled={!isRoleApproved(ADMIN_EDIT_ROLES, account?.userType)}
              label="Remove"
              onClick={() => onRemoveUser(user)}
            />
          </div>
        )}
      </div>
      <div>
        {editLocationsOpen && (
          <div className="edit-locations-form-container">
            <form className="ss-form ss-block">
              <MultiSelect
                value={updatedLocations || []}
                options={locationOptions}
                label="LOCATIONS"
                editWidth="editWidth"
                onChange={(selectedLocations) => {
                  setUpdatedLocations(selectedLocations);
                  setIsValidLocationSelected(selectedLocations.length > 0);
                }}
              />
            </form>
            <div className="edit-locations-form-btn-container">
              <div className="edit-locations-cancel-btn">
                <OutlinedButton
                  label="Cancel"
                  onClick={toggleLocationVisibility}
                />
              </div>
              <div>
                <SolidButton
                  label="Save"
                  onClick={internalSubmit}
                  disabled={!isValidLocationSelected}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

ManageUserItem.propTypes = {
  user: PropTypes.object.isRequired,
  onUpdateLocations: PropTypes.func.isRequired,
  onRemoveUser: PropTypes.func.isRequired,
  locationOptions: PropTypes.array.isRequired,
  accountType: PropTypes.string.isRequired,
};

export default ManageUserItem;
