import React from 'react'

const internalContext = React.createContext({
    showLoginModal: (stack: number) : void => {},
    closeLoginModal: () : void => {},
    showSignUpModal: (stack: number) : void => {},
    closeSignUpModal: () : void => {},
})

internalContext.displayName = "SecurSpaceGlobalModalContext"

export const GlobalModalContext = internalContext
