import React, { useEffect, useContext, useState } from "react";
import { useHistory } from "react-router";
import { requestLogout } from "../services/session/session-requests";
import { AppContext } from "../context/app-context";
import { toast } from "react-toastify";
import Busy from "./Busy";
import ConfirmDialogBlock from "./ConfirmDialogBlock";

const Logout = () => {
  const appContext = useContext(AppContext);
  const { user, updateUser, loadingUserDetails } = appContext;
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(true);

  const history = useHistory();
  const handleClose = () => {
    if (!loadingUserDetails) {
      history.push("/");
    }
    setShowCancelConfirmation(false);
  };

  const handleProceed = () => {
    if (user && user.id) {
      Busy.set(true);
      requestLogout()
        .then(() => {
          toast.success("Logged Out", { position: toast.POSITION.TOP_RIGHT });
          localStorage.setItem("activeMenuItem", "");
          Busy.set(false);
          updateUser(null, history);
        })
        .catch(() => {
          Busy.set(false);
          history.push("/login?loggedOut=true");
        });
    }
    setShowCancelConfirmation(false);
  };

  return (
    <ConfirmDialogBlock
      showAlert={showCancelConfirmation}
      title="Confirmation"
      comp="logout"
      children="Are you sure you want to logout ?"
      onClose={() => handleClose()}
      proceedEventHandler={() => handleProceed()}
    />
  );
};

export default Logout;
