import Option from "./Option";

class DriverOption extends Option {

  constructor(driver) {
    super(driver.id);
    this.displayValue = driver.firstName + ' ' + driver.lastName + ' - ' + driver.carrier;
  }

  getDisplayValue() {
    return this.displayValue;
  }
}

export { DriverOption };
