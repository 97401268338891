import request from "../../../util/SuperagentUtils";

export const requestGetFailedTransactionsForBooking = (
  bookingId: String,
  onSuccess?: (failedTransactions: Array<Object>) => void,
  onFail?: (err: Object) => void
): Promise<Object> | void => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  const result = request
    .get(`${baseUrl}/api/booking/${bookingId}/transactions/failed`)
    .withCredentials();

  if (onSuccess && onFail) {
    result.then((resp) => onSuccess(resp.body)).catch((err) => onFail(err));
  } else {
    return result;
  }
};

export const requestCompleteBooking = (
  data: {
    id: String,
    buyerAccountId: String,
    tosDocUrl: String,
    paymentMethodId: String,
  },
  onSuccess?: (booking: Object) => void,
  onFail?: (err: Object) => void
): Promise<Object> | void => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const result = request
    .post(`${baseUrl}/api/booking/complete-booking`)
    .withCredentials()
    .send(data);

  if (onSuccess && onFail) {
    result.then((resp) => onSuccess(resp.body)).catch((err) => onFail(err));
  } else {
    return result;
  }
};

export const requestRetryFailedTransactionsForBooking = (
  bookingId: String,
  onSuccess?: () => void,
  onFail?: (err: Object) => void
): Promise<Object> | void => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  const result = request
    .post(`${baseUrl}/api/booking/${bookingId}/transactions/failed/retry`)
    .withCredentials();

  if (onSuccess && onFail) {
    result.then(onSuccess).catch(onFail);
  } else {
    return result;
  }
};

export const requestReadSupplierPendingBooking = (
  id: String,
  onSuccess?: () => void,
  onFail?: (err: Object) => void
): Promise | void => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  const result = request
    .get(
      `${baseUrl}/api/booking/count-bookings?supplierAccountId=${id}&approvalsOnly=true`
    )
    .withCredentials();

  if (onSuccess && onFail) {
    result.then(onSuccess).catch(onFail);
  } else {
    return result;
  }
};
