import { withGroupAndSort } from "../groupAndSort/withGroupAndSort";
import { withExportButton } from "../export/withExportButton";
import { withGroupings } from "../groupAndSort/withGroupings";

export const withGroupAndSortExportAndGroupings = (
  Component,
  dataProp = "data"
) =>
  withGroupAndSort(
    withExportButton(withGroupings(Component, dataProp), dataProp),
    dataProp
  );
