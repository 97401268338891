import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  fineDelete,
  fineRequest,
  fineUpdate,
  saveFine,
} from "../../checkin/request/fine-request";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import { getErrorMessageForStandardResponse } from "../../../util/NetworkErrorUtil";
import Busy from "../../Busy";

const useFineAddLogic = () => {
  const [fines, setFines] = useState(null);
  const [fine, setFine] = useState(null);
  const [updatedFines, setUpdatedFines] = useState(null); // Just using this as a means to trigger useEffect refresh
  const [editMode, setEditMode] = useState(false);
  const [show, setShow] = useState(false);

  let history = useHistory();
  var toastId = null;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [setFines]);

  useEffect(() => {
    Busy.set(true);
    fineRequest()
      .then(
        (promise) => {
          Busy.set(false);
          setFines(promise.body);
          setShow(true);
        },
        (error) => {
          Busy.set(false);
          notify(getErrorMessageForStandardResponse(error));
          setShow(true);
        }
      )
      .catch((error) => {
        Busy.set(false);
        notify(getErrorMessageForStandardResponse(error));
        setShow(true);
      });
  }, [updatedFines, setFines]);

  const notify = (errorMessage) => {
    if (!toast.isActive(toastId)) {
      toastId = toast.error(errorMessage, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const editFine = (fine) => {
    setFine(fine);
    setUpdatedFines(fine);
    setEditMode((prevState) => !prevState.editMode);
  };

  const deleteFine = async (fine) => {
    await fineDelete(fine);
    setUpdatedFines(fine);
  };

  const toggleEditMode = () => {
    setEditMode(!editMode);
    setUpdatedFines(null);
  };

  const setFineType = (fineType) => {
    setFine((prevState) => ({
      ...prevState,
      fineType: {
        name: fineType,
      },
    }));
  };

  const addFineInfractions = (fineInfractions) => {
    setFine((prevState) => ({
      ...prevState,
      fineInfractions: fineInfractions,
    }));
  };

  const addPeakPricingWindows = (peakPricingWindows) => {
    setFine((prevState) => ({
      ...prevState,
      peakPricingWindows: peakPricingWindows,
    }));
  };

  const addPeakPricingAdditionalCharge = (peakPricingAdditionalCharge) => {
    setFine((prevState) => ({
      ...prevState,
      peakPricingAdditionalCharge: peakPricingAdditionalCharge,
    }));
  };

  const addStartDate = (startDate) => {
    setFine((prevState) => ({
      ...prevState,
      startDate: startDate,
    }));
  };

  const addEndDate = (endDate) => {
    setFine((prevState) => ({
      ...prevState,
      endDate: endDate,
    }));
  };

  const saveFineToApi = () => {
    performValidations();
    if (!editMode) {
      saveFine(fine)
        .then(
          () => {
            history.push("/fines");
            toast.success("Fine added successfully!");
          },
          (error) => {
            notify(getErrorMessageForStandardResponse(error));
          }
        )
        .catch((error) => {
          notify(getErrorMessageForStandardResponse(error));
        });
    } else {
      fineUpdate(fine)
        .then(
          () => {
            toggleEditMode();
          },
          (error) => {
            notify(getErrorMessageForStandardResponse(error));
          }
        )
        .catch((error) => {
          notify(getErrorMessageForStandardResponse(error));
        });
    }
  };

  const performValidations = () => {
    if (!fine.fineType) {
      toast.error("Please select a fine type", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    if (fine.fineInfractions.length === 0) {
      toast.error("Please add a fine infraction", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const cancel = () => {
    localStorage.setItem("activeMenuItem", "account");
    localStorage.setItem("activeSubmenu", "locations-profile");
    history.push("/locations-profile");
    window.location.reload();
  };

  return {
    setFineType,
    addPeakPricingAdditionalCharge,
    addFineInfractions,
    addPeakPricingWindows,
    cancel,
    saveFineToApi,
    addStartDate,
    addEndDate,
    editFine,
    deleteFine,
    toggleEditMode,
    editMode,
    fines,
    fine,
    setFine,
    show,
  };
};

useFineAddLogic.propTypes = {
  fine: PropTypes.shape({
    id: PropTypes.string,
    fineType: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
    fineInfractions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        fineId: PropTypes.string,
        infractionOffense: PropTypes.number,
        infractionAmount: PropTypes.number,
      })
    ),
    peakPricingAdditionalCharge: PropTypes.number,
    peakPricingWindows: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        fineId: PropTypes.string,
        daysOfWeek: PropTypes.arrayOf(PropTypes.string),
        startTime: PropTypes.string,
        endTime: PropTypes.string,
      })
    ),
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }),
};

export default useFineAddLogic;
