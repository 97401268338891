import React from "react";
import ReportDataRow from "../report/ReportDataRow";
import ReportData from "../report/ReportData";
import moment from "moment";

const SecurityDepositCard = (props) => {
  const { securityDepositValue } = props;

  return (
    <>
      {securityDepositValue && (
        <ReportDataRow>
          <ReportData label={"CUSTOMER"}>
            {securityDepositValue?.accountId?.firstName
              ? securityDepositValue?.accountId?.firstName +
                " " +
                securityDepositValue?.accountId?.lastName
              : "-"}
          </ReportData>

          <ReportData label={"AMOUNT"}>
            $ {securityDepositValue?.securityDepositAmount || "-"}
          </ReportData>

          <ReportData label={"PAYMENT INITIATED"}>
            {securityDepositValue?.dateOfDepositProcessed
              ? moment(securityDepositValue?.dateOfDepositProcessed).format(
                  "MM/DD/YYYY"
                )
              : "-"}
          </ReportData>
          <ReportData label={"DATE CHARGED"}>
            {securityDepositValue?.dateOfDepositCharged
              ? moment(securityDepositValue?.dateOfDepositCharged).format(
                  "MM/DD/YYYY"
                )
              : "-"}
          </ReportData>
          <ReportData label={"PAYMENT TYPE"}>
            {securityDepositValue?.paymentType || "-"}
          </ReportData>
          <ReportData label={"TYPE"}>SECURITY DEPOSIT CHARGE</ReportData>
          <ReportData label={"INVOICE NUMBER"}>
            {securityDepositValue?.invoiceNumber || "-"}
          </ReportData>
          <ReportData label={"REFUND STATUS"}>
            {securityDepositValue?.refundStatus || "-"}
          </ReportData>
          <ReportData label={"LAST UPDATED BY"}>
            {securityDepositValue?.lastModifiedBy || "-"}
          </ReportData>
          <ReportData label={"LAST UPDATED ON"}>
            {securityDepositValue?.lastModifiedDate
              ? moment(securityDepositValue?.lastModifiedDate).format(
                  "MM/DD/YYYY"
                )
              : "-"}
          </ReportData>
          <ReportData label={"DATE OF DEPOSIT CHARGED"}>
            {securityDepositValue?.dateOfDepositCharged
              ? moment(securityDepositValue?.dateOfDepositCharged).format(
                  "MM/DD/YYYY"
                )
              : "-"}
          </ReportData>
        </ReportDataRow>
      )}
    </>
  );
};

export default SecurityDepositCard;
