import React from "react";
import "../../css/components/report/report-form.css";
import ReportForm from "./ReportForm";
import ReportFormFieldset from "./ReportFormFieldset";

const ReportFormContainer = (props) => {
  const { children } = props;
  return (
    <ReportForm>
      <ReportFormFieldset>{children}</ReportFormFieldset>
    </ReportForm>
  );
};

export default ReportFormContainer;
