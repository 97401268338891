import React from "react";
import PeakPricingWeekdaySelect from "./PeakPricingWeekdaySelect";

const PeakPricingWeekdayForm = (props) => {
  const { handleChangeWeekday } = props;

  const weekdays = [
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
    "SUNDAY",
  ];

  return (
    <form className="sps-fine-weekday-select">
      <label htmlFor="daysOfWeek">Day of the Week:</label>
      {weekdays.map((day) => {
        return (
          <PeakPricingWeekdaySelect
            key={day}
            day={day}
            handleChangeWeekday={handleChangeWeekday}
          />
        );
      })}
    </form>
  );
};

export default PeakPricingWeekdayForm;
