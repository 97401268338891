import _ from "underscore";
import Busy from "../components/Busy";
import { toast } from "react-toastify";

export const getErrorMessageForStandardResponse = (
  err: Object,
  defaultErrorMsg: String = "Request Failed",
  onlyShowAPIError
) => {
  if (err.status) {
    if (
      err.response &&
      err.response.body &&
      !_.isEmpty(err.response.body.message)
    ) {
      return !!err.response.body.message?.trim()
        ? err.response.body.message
        : "Internal Server Error";
    } else {
      return "request failed";
    }
  }
  if (!!err) return "Internal Server Error";
  if (onlyShowAPIError) return "Internal Server Error";
  return "Unable to complete the request potentially due to network connectivity issue";
};

export const toastErrorMessage = (error, ...rest) => {
  const { stopBusy = true } = rest;
  if (stopBusy) Busy.set(false);
  toast.error(getErrorMessageForStandardResponse(error));
};
