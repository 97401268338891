import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import SecurSpaceContent from "./SecurSpaceContent";
import GlobalModalManager from "../components/modals/GlobalModalManager";

const SecurSpaceRouter = (props) => {
  return (
    <Router>
      <GlobalModalManager>
        <SecurSpaceContent />
      </GlobalModalManager>
    </Router>
  );
};

export default SecurSpaceRouter;
