import React from "react";
import AdminInvoiceListItem from "./AdminInvoiceListItem";
import ReportTitleGroupCounts from "../../report/ReportTitleGroupCounts";
import { withGroupAndSortExportAndGroupings } from "../../report/hocs/withGroupAndSortExportAndGroupings";
import ReportTotal from "../../report/ReportTotal";
import ReportTotalContainer from "../../report/ReportTotalContainer";
import ReportTitleContainer from "../../report/ReportTitleContainer";
import { formatCurrencyValue } from "../../../util/PaymentUtils";
import {
  PAYMENT_FAILED,
  PAYOUT_SUCCEEDED,
} from "../../SupplierTransactionStatus";

const AdminInvoiceList = (props) => {
  const { invoices, ...rest } = props;

  const formatTotals = (value) => formatCurrencyValue(value, true);

  return (
    <>
      <ReportTitleContainer>
        <ReportTitleGroupCounts dataLength={invoices?.length} {...rest} />
        <ReportTotalContainer>
          <ReportTotal
            data={invoices}
            totalFieldLabel={"Expected Revenue"}
            totalFieldName={"supplierAmount"}
            totalFieldFormat={formatTotals}
          />
          <ReportTotal
            data={invoices}
            totalFieldLabel={"Collected Revenue"}
            totalFieldName={"paymentProcessorFees"}
            totalFieldFormat={formatTotals}
            totalFieldFilter={(x) => PAYOUT_SUCCEEDED.includes(x.status)}
          />
          <ReportTotal
            data={invoices}
            totalFieldLabel={"Failed Payments"}
            totalFieldName={"supplierAmount"}
            totalFieldFormat={formatTotals}
            totalFieldFilter={(x) => PAYMENT_FAILED.includes(x.status)}
          />
        </ReportTotalContainer>
      </ReportTitleContainer>
      {invoices &&
        invoices.map((invoice) => (
          <AdminInvoiceListItem key={invoice.transactionId} invoice={invoice} />
        ))}
    </>
  );
};

export default AdminInvoiceList;
export const AdminInvoiceListWithGroupAndSort =
  withGroupAndSortExportAndGroupings(AdminInvoiceList, "invoices");
