import React, { useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import _ from "underscore";
import { GoogleMap, withGoogleMap } from "react-google-maps";
import MapStyle from "../MapStyle";
import ZoneMapItem from "./ZoneMapItem";

const ZoneMap = (props) => {
  const { zones } = props;
  const [displayZones, setDisplayZones] = useState([]);
  const [bounds, setBounds] = useState(new google.maps.LatLngBounds());
  const [activeZone, setActiveZone] = useState(null);

  const defaultMapOptions = {
    mapTypeId: "satellite", // Set default to satellite view
  };

  useEffect(() => {
    const getBounds = (displayZones) => {
      const bounds = new google.maps.LatLngBounds();
      _.each(displayZones, (displayZone) => {
        _.each(displayZone.coordinates, (coordinate) =>
          bounds.extend(coordinate)
        );
      });
      return bounds;
    };

    const toGoogleMapLatLng = (coordinates) => {
      return _.map(coordinates, (coordinate) => {
        return new google.maps.LatLng(
          coordinate.latitude,
          coordinate.longitude
        );
      });
    };

    if (zones && zones.length) {
      const reformattedZones = _.map(zones, (zone) => {
        const coordinates = _.sortBy(zone.coordinates, "order");
        return {
          ...zone,
          coordinates: toGoogleMapLatLng(coordinates),
        };
      });
      setDisplayZones(reformattedZones);
      setBounds(getBounds(reformattedZones));
    }
  }, [zones, setDisplayZones, setBounds]);

  return (
    <GoogleMap
      ref={(map) => map && map.fitBounds(bounds)}
      defaultOptions={{
        styles: MapStyle,
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        zoomControl: true,
        mapTypeId: "satellite",
        zoomControlOptions: {
          position: google.maps.ControlPosition.TOP_RIGHT,
        },
      }}
    >
      {_.map(displayZones, (zone, idx) => {
        return zone.coordinates.length > 0 ? (
          <ZoneMapItem
            key={idx}
            zone={zone}
            activeZone={activeZone}
            setActiveZone={setActiveZone}
          />
        ) : null;
      })}
    </GoogleMap>
  );
};

ZoneMap.propTypes = {
  zones: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      totalSpaces: PropTypes.number,
      equipmentLength: PropTypes.string,
      activationDate: PropTypes.string,
      deactivationDate: PropTypes.string,
      active: PropTypes.bool,
      coordinates: PropTypes.arrayOf(
        PropTypes.shape({
          latitude: PropTypes.number,
          longitude: PropTypes.number,
          order: PropTypes.number,
        })
      ),
    })
  ),
};

export default withGoogleMap(ZoneMap);
