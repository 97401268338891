import React from "react";
import ReportData from "../report/ReportData";
import ReportDataRow from "../report/ReportDataRow";
import { formatDate } from "../../util/booking/bookingUtil";

const TransactionItem = (props) => {
  const { transaction } = props;

  return (
    <ReportDataRow>
      <ReportData label="Transaction Number">
        {transaction?.transactionNumber || "-"}
      </ReportData>
      <ReportData label={"Status"}>{transaction?.status || "-"}</ReportData>
      <ReportData label="Booking Number">
        {transaction?.bookingNumber || "-"}
      </ReportData>
      <ReportData label="Company Name">
        {transaction?.buyerCompanyName || "-"}
      </ReportData>
      <ReportData label="Transaction Type">
        {transaction?.transactionType || "-"}
      </ReportData>
      <ReportData label="Payment Type">
        {transaction?.paymentType || "-"}
      </ReportData>
      <ReportData label="Number of Spaces">
        {transaction?.numberOfSpaces || "-"}
      </ReportData>
      <ReportData label="Start Date">
        {transaction?.startDate ? formatDate(transaction.startDate) : "-"}
      </ReportData>
      <ReportData label="End Date">
        {transaction?.endDate ? formatDate(transaction.endDate) : "-"}
      </ReportData>
      <ReportData label="Gross Amount">
        {transaction?.grossAmount ? `${transaction.grossAmount / 100}` : "-"}
      </ReportData>
      <ReportData label="Zone Name">{transaction?.zoneName || "-"}</ReportData>
      <ReportData label="Notes">{transaction?.notes || "-"}</ReportData>
      <ReportData label={"Created Date"}>
        {transaction?.createdOn || "-"}
      </ReportData>
    </ReportDataRow>
  );
};

export default TransactionItem;
