import React from "react";
import ZoneAvailabilityListItem from "./ZoneAvailabilityListItem";
import { withGroupAndSortExportAndGroupings } from "../report/hocs/withGroupAndSortExportAndGroupings";

const ZoneAvailabilityContent = (props) => {
  const { account, availableZones } = props;
  return (
    <>
      <br />
      {availableZones?.map((zone) => (
        <ZoneAvailabilityListItem key={zone.id} zone={zone} account={account} />
      ))}
    </>
  );
};

export default ZoneAvailabilityContent;
export const ZoneAvailabilityWithGroupAndSort =
  withGroupAndSortExportAndGroupings(ZoneAvailabilityContent, "availableZones");
