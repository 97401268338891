import React, { useEffect, useState } from "react";
import { getReservationChargeAmount } from "./requests/charge-amount-requests";
import { getErrorMessageForStandardResponse } from "../../util/NetworkErrorUtil";
import { ErrorOutlineRounded } from "@material-ui/icons";
import ReportDataRow from "../report/ReportDataRow";
import ReportData from "../report/ReportData";
import MoneyDisplay from "../money/MoneyDisplay";
import Busy from "../Busy";

const DisplayChargeAmount = (props) => {
  const { reservationId, paymentMethodId } = props;
  const [chargeAmount, setChargeAmount] = useState({});
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (reservationId && paymentMethodId) {
      Busy.set(true);
      getReservationChargeAmount(reservationId, paymentMethodId)
        .then((response) => {
          setChargeAmount(response.body);
          Busy.set(false);
        })
        .catch((error) => {
          setErrorMessage(getErrorMessageForStandardResponse(error));
          Busy.set(false);
        });
    }
  }, [reservationId, paymentMethodId, setChargeAmount, setErrorMessage]);

  return (
    <>
      {chargeAmount ? (
        <ReportDataRow>
          <ReportData label={"Initial Amount"}>
            {chargeAmount && chargeAmount.initialBookingChargeAmount && (
              <MoneyDisplay
                dollarsAsLong={chargeAmount.initialBookingChargeAmount}
              />
            )}
          </ReportData>
          <ReportData label={"Initial Payment Amount"}>
            {chargeAmount && chargeAmount.initialBookingPaymentAmount && (
              <MoneyDisplay
                dollarsAsLong={chargeAmount.initialBookingPaymentAmount}
              />
            )}
          </ReportData>
          <ReportData label={"Recurring Charge Amount"}>
            {chargeAmount && chargeAmount.recurringBookingChargeAmount && (
              <MoneyDisplay
                dollarsAsLong={chargeAmount.recurringBookingChargeAmount}
              />
            )}
          </ReportData>
          <ReportData label={"Recurring Payment Amount"}>
            {chargeAmount && chargeAmount.recurringBookingPaymentAmount && (
              <MoneyDisplay
                dollarsAsLong={chargeAmount.recurringBookingPaymentAmount}
              />
            )}
          </ReportData>
        </ReportDataRow>
      ) : (
        errorMessage && (
          <div>
            <ErrorOutlineRounded />
            {errorMessage}
          </div>
        )
      )}
    </>
  );
};

export default DisplayChargeAmount;
