import {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import Busy from "../../components/Busy";
import {requestBookingById} from "../../views/bookings/requests/edit-booking-requests";
import {toastErrorMessage} from "../../util/NetworkErrorUtil";

const useBookingParam = () => {
  const { bookingId } = useParams();
  const [ booking, setBooking] = useState({});

  useEffect(() => {
    Busy.set(true);
    requestBookingById(bookingId).then((response) => {
      setBooking(response.body);
      Busy.set(false);
    }, toastErrorMessage).catch(toastErrorMessage);
  }, [bookingId]);

  return { booking };
}

export default useBookingParam;
