import React from "react";
import { withRouter } from "react-router";
import ZoneMap from "../../zone/ZoneMap";
import Steps from "../../steps/Steps";
import ZoneCard from "./ZoneCard";
import ReportBackgroundContainer from "../../report/ReportBackgroundContainer";
import SolidButton from "../../form/SolidButton";

const ViewZone = (props) => {
  const { zone, stepNumber, saveToApi } = props;

  return (
    <div>
      <ReportBackgroundContainer>
        <div style={{ height: "50vh", width: "100%" }}>
          {zone ? (
            <ZoneMap
              zones={[zone]}
              containerElement={<div style={{ height: `100%` }} />}
              mapElement={<div style={{ height: `100%` }} />}
            />
          ) : (
            ""
          )}
        </div>
        <hr />
        <ZoneCard zone={zone} />
        <hr />
        <div style={{ width: "35%", marginLeft: "33%" }}>
          <SolidButton label={"SAVE"} onClick={saveToApi} />
        </div>

        <Steps step={stepNumber} {...props} />
      </ReportBackgroundContainer>
    </div>
  );
};

export default withRouter(ViewZone);
