import { UserType } from "../../components/constants/securspace-constants";

export const ADMIN_EDIT_ROLES = [
  UserType.OWNER,
  UserType.ADMIN,
];

export const ALL_EDIT_ROLES = [
  UserType.GATE_CLERK,
  UserType.GATE_MANAGER,
  UserType.ADMIN,
  UserType.OWNER
];

export const MANAGER_EDIT_ROLES = [
  UserType.OWNER,
  UserType.ADMIN,
  UserType.GATE_MANAGER
];

export const MANAGER_EDIT_ROLES2 = [
  UserType.OWNER,
  UserType.ADMIN,
  UserType.GATE_MANAGER,
  UserType.READ_ONLY_ADMIN
];

export const ALL_MANAGER_ROLES = [
  UserType.OWNER,
  UserType.ADMIN,
  UserType.READ_ONLY_ADMIN,
  UserType.GATE_MANAGER
];

export const isRoleApproved = (approvedUserTypes: Array, loggedInUserType: String) => {
  return approvedUserTypes.includes(loggedInUserType);
}
