import React, { useEffect, useState } from "react";
import moment from "moment";
import { DateFormats } from "../constants/securspace-constants";
import ReportBackgroundContainer from "../report/ReportBackgroundContainer";
import ReportHeader from "../report/ReportHeader";
import { LocationRevenueContentWithGroupAndSort } from "./LocationRevenueContent";
import { getLocations } from "../checkin/request/fine-request";
import {
  getRevenueByLocation,
  getRevenueForAllLocations,
} from "./locationReportRequests";
import { toast } from "react-toastify";
import { getErrorMessageForStandardResponse } from "../../util/NetworkErrorUtil";
import LocationSelect from "./LocationSelect";
import Filter from "../../views/bookings/Filter";
import { searchRecordsByKeyword } from "../../util/SearchUtils";
import ReportFormContainer from "../report/ReportFormContainer";
import ReportFormDateInput from "../report/ReportFormDateInput";
import ReportEmpty from "../report/ReportEmpty";
import { extractDay } from "../constants/securspace-constants";
import Busy from "../Busy";

const LocationRevenueReport = (props) => {
  const { account } = props;

  const reportFields = [
    { label: "Company Name", name: "buyerCompanyName" },
    { label: "Transaction Number", name: "transactionNumber" },
    { label: "Booking Number", name: "bookingNumber" },
    { label: "Transaction Type", name: "transactionType" },
    { label: "Payment Type", name: "paymentType" },
    { label: "Number of Spaces", name: "numberOfSpaces" },
    { label: "Gross Amount", name: "grossAmount" },
    { label: "Zone Name", name: "zoneName" },
    { label: "Status", name: "status" },
  ];

  const defaultStartDate = moment()
    .subtract(30, "days")
    .format(DateFormats.DAY);
  const defaultEndDate = moment().format(DateFormats.DAY);

  const [locations, setLocations] = useState([
    { id: "allLocations", locationName: "All Locations" },
  ]);
  const [selectedLocationId, setSelectedLocationIdId] = useState();
  const [transactions, setTransactions] = useState();
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);
  const [searchBox, setSearchBox] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    let requestDates = {
      startDate: startDate,
      endDate: endDate,
    };

    if (selectedLocationId && selectedLocationId !== "allLocations") {
      Busy.set(true);
      getRevenueByLocation(account.id, selectedLocationId, requestDates)
        .then(
          (response) => {
            const data = response.body;
            setTransactions(data);
            Busy.set(false);
          },
          (error) => {
            toast(getErrorMessageForStandardResponse(error));
            Busy.set(false);
          }
        )
        .catch((error) => {
          toast(getErrorMessageForStandardResponse(error));
          Busy.set(false);
        });
    } else {
      Busy.set(true);

      getRevenueForAllLocations(account.id, requestDates)
        .then(
          (response) => {
            const data = response.body;
            setTransactions(data);
            Busy.set(false);
          },
          (error) => {
            toast(getErrorMessageForStandardResponse(error));
            Busy.set(false);
          }
        )
        .catch((error) => {
          toast(getErrorMessageForStandardResponse(error));
          Busy.set(false);
        });
    }
  }, [account.id, endDate, selectedLocationId, startDate]);

  useEffect(() => {
    getLocations()
      .then(
        (response) => {
          const data = response.body;
          const newLocations = [...locations, ...data];
          setLocations(newLocations);
        },
        (error) => {
          toast(getErrorMessageForStandardResponse(error));
        }
      )
      .catch((error) => {
        toast(getErrorMessageForStandardResponse(error));
      });
    // This only needs to run once
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (transactions) {
      setFilteredData(searchRecordsByKeyword(searchBox, transactions));
    }
  }, [searchBox, transactions, setTransactions]);

  const handleStartDate = (date) => {
    let day1 = extractDay(date);
    let day2 = extractDay(startDate);

    if (!(day1 === day2)) {
      setStartDate(date);
    }
  };

  const handleEndDate = (date) => {
    let day1 = extractDay(date);
    let day2 = extractDay(endDate);

    if (!(day1 === day2)) {
      setEndDate(date);
    }
  };

  const onLocationChange = (event) => {
    setSelectedLocationIdId(event.target.value);
  };

  const handleSearchBox = (event) => {
    setSearchBox(event.target.value);
  };

  return (
    <ReportBackgroundContainer>
      <ReportHeader>Revenue Report</ReportHeader>

      <ReportFormContainer>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <LocationSelect
            locations={locations}
            onChange={onLocationChange}
            value={selectedLocationId}
          />
          <div>
            <ReportFormDateInput
              label={"START DATE:"}
              date={startDate}
              onDateChange={handleStartDate}
            />
            <ReportFormDateInput
              label={"END DATE:"}
              date={endDate}
              onDateChange={handleEndDate}
            />
          </div>
        </div>
      </ReportFormContainer>

      {!!transactions && transactions?.length > 0 ? (
        <>
          <Filter
            searchBox={searchBox}
            searchChangeHandler={handleSearchBox}
            placeHolder="Type any field to filter location revenue result"
          />
          {filteredData?.length > 0 && (
            <LocationRevenueContentWithGroupAndSort
              account={account}
              transactions={filteredData}
              reportFields={reportFields}
              reportName="Revenue Report"
            />
          )}
          {filteredData?.length > 0 && (
            <div style={{ textAlign: "center" }}>
              {" "}
              You have reached end of the list
            </div>
          )}
          {filteredData?.length === 0 && <ReportEmpty />}

          <div className="ss-supplier-active-bookings-endlist">
            <h6>{!!filteredData && filteredData.length > 0 ? " " : " "}</h6>
          </div>
        </>
      ) : (
        <ReportEmpty />
      )}
    </ReportBackgroundContainer>
  );
};

export default LocationRevenueReport;
