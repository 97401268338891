import React, { useContext, useState } from "react";
import ReportKebabButton from "../../components/report/ReportKebabButton";
import { MenuItem } from "@material-ui/core";
import ReportFlexRightContainer from "../../components/report/ReportFlexRightContainer";
import { AppContext } from "../../context/app-context";

const SecurityDepositKebabButton = ({
  setShowModal,
  setSecurityDepositParticularRecordDetails,
  ele,
}) => {
  const { user } = useContext(AppContext);
  const handleClick = () => {
    setSecurityDepositParticularRecordDetails(ele);
    setShowModal(true);
  };

  return (
    <ReportFlexRightContainer>
      <ReportKebabButton>
        <MenuItem onClick={handleClick}>Edit Refund Status</MenuItem>
      </ReportKebabButton>
    </ReportFlexRightContainer>
  );
};

export default SecurityDepositKebabButton;
