import React, { useState } from "react";
import { withRouter } from "react-router";
import checkIcon from "../../img/check-mark.png";
import { useLocation } from "react-router-dom";
import Busy from "../../components/Busy";
import { truncateStringPaymentSuccessful } from "./checkout/checkoutConstants";
import "./paymentSuccessful.css";

const PaymentSuccessful = () => {
  const location = useLocation();
  const data = location.state;

  const handleClick = (e) => {
    Busy.set(false);
    e.preventDefault();
    Busy.set(true);
    window.location.href = "https://oaklandportalstage.portofoakland.com/#/";
  };

  const formattedDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDateNew = `${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}/${date
      .getDate()
      .toString()
      .padStart(2, "0")}/${date.getFullYear()}`;
    return formattedDateNew;
  };

  return (
    <div>
      <div className="grey-bg">
        <div>
          <div className="container paymentContainer">
            <img src={checkIcon} alt="checkMark" className="imageCheckMark" />

            <h1 style={{ fontWeight: "bold" }}>Payment Successful</h1>
            <p style={{ marginTop: "3px" }}>
              Your payment has been completed! Details of transactions are
              included below:
            </p>
          </div>

          <div className="container">
            <hr style={{ borderTop: "1px solid #ccc" }} />
            <table className="tableStyle">
              <tr className="tableRow">
                <th className="tableH">Amount : </th>
                <th className="tableHContent">
                  {" "}
                  $ {(data?.amount / 100).toFixed(2)}
                </th>
              </tr>
              <tr className="tableRow">
                <td className="tableH">Invoice Number : </td>
                <td className="tableHContent">{data?.invoiceNumber}</td>
              </tr>
              <tr className="tableRow">
                <td className="tableH">Payment Type :</td>
                <td className="tableHContent">Booking Payment</td>
              </tr>
              <tr className="tableRow">
                <td className="tableH">Transfer Type :</td>
                <td className="tableHContent">{data?.transferType}</td>
              </tr>
              <tr className="tableRow">
                <td className="tableH">Source :</td>
                <td className="tableHContent">
                  {!!data?.nickName
                    ? truncateStringPaymentSuccessful(data?.nickName)
                    : data?.source}
                </td>
              </tr>
              <tr className="tableRow">
                <td className="tableH">Recipient :</td>
                <td className="tableHContent">
                  Oakland Portal Smart Parking System on behalf of Port of
                  Oakland
                </td>
              </tr>
              <tr className="tableRow">
                <td className="tableH">Date Initiated :</td>
                <td className="tableHContent">
                  {formattedDate(data?.dateInitiated)}
                </td>
              </tr>
            </table>

            <button
              className="orange-button ss-action-button ss-login-submit paymentSuccessfulButton"
              onClick={(e) => handleClick(e)}
            >
              Back to Home
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(PaymentSuccessful);
