import React, { useState, useEffect, useRef } from "react";
import "../css/components/reportRowAction.css";
import menuIcon from "../img/menu.png";

const ReportRowAction = ({ actions, item }) => {
  const [show, setShow] = useState(false);
  const nodeRef = useRef();

  useEffect(() => {
    const handleBodyClick = (e) => {
      if (nodeRef.current && !nodeRef.current.contains(e.target)) {
        setShow(false);
      }
    };

    if (show) {
      document.addEventListener("click", handleBodyClick, true);
    } else {
      document.removeEventListener("click", handleBodyClick, true);
    }

    // Cleanup function to remove the event listener
    return () => {
      document.removeEventListener("click", handleBodyClick, true);
    };
  }, [show]);

  const showOptions = () => {
    setShow((prevShow) => !prevShow);
  };

  const selectAction = (option, item) => {
    if (option && typeof option.action === "function") {
      option.action(item);
    }
  };

  const visibleActions = actions.filter((action) => {
    return (
      !action.hasOwnProperty("shouldShowAction") ||
      action.shouldShowAction(item)
    );
  });

  return (
    <div>
      {visibleActions.length > 0 && (
        <div className="ss-row-action" ref={nodeRef}>
          <img
            className="ss-row-action-icon"
            onClick={showOptions}
            src={menuIcon}
            alt="row-action"
          />

          <div
            className={
              show ? "ss-action-options-visible" : "ss-action-options-hidden"
            }
          >
            <ul className="ss-action-list">
              {visibleActions.map((action, index) => (
                <li
                  key={index}
                  className={
                    !action.hasOwnProperty("shouldShowAction") ||
                    action.shouldShowAction(item)
                      ? "ss-action-item"
                      : "hidden"
                  }
                  onClick={() => selectAction(action, item)}
                >
                  {action.displayValue}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReportRowAction;
