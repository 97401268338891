import React from 'react';
import ReportTitle from "./ReportTitle";

const ReportTitleGroupCounts = (props) => {
  const {reportTitleCount = 'ALL', reportTitle, dataLength} = props;
  return <ReportTitle subtext={`(SHOWING ${reportTitleCount} RECORDS IN GROUP)`}>
    {
      reportTitle || <>{dataLength} record{dataLength > 1 ? 's' : ''}</>
    }
  </ReportTitle>
}

export default ReportTitleGroupCounts;
