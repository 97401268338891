import React from "react";
import { CSVLink } from "react-csv";

const ExportCSVButton = (props) => {
  const data = props.data || [];
  const reportName = props.reportName || "Report";

  const formatDate = (date) => {
    if (!date) return "";

    const formattedDate = new Date(date);

    if (
      !isNaN(formattedDate) &&
      Object.prototype.toString.call(formattedDate) === "[object Date]"
    ) {
      const month = String(formattedDate.getMonth() + 1).padStart(2, "0");
      const day = String(formattedDate.getDate()).padStart(2, "0");
      const year = formattedDate.getFullYear();

      return `${day}/${month}/${year}`;
    }

    return "";
  };

  const formatKeyName = (key) => {
    return key
      .replace(/([A-Z])/g, " $1")
      .replace(/_/g, " ")
      .replace(
        /\w\S*/g,
        (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      );
  };

  const transformedData = data.map((item) => {
    const transformedItem = {};

    for (const [key, value] of Object.entries(item)) {
      const mappedKey = formatKeyName(key);
      if (
        key === "dob" ||
        key === "expirationDate" ||
        key === "twicExpirationDate"
      ) {
        transformedItem[mappedKey] = formatDate(value);
      } else {
        transformedItem[mappedKey] = value;
      }
    }

    return transformedItem;
  });

  return (
    <div className="csvLink-container">
      <CSVLink filename={reportName} data={transformedData} separator=",">
        <button type="button" className="ss-button-primary ss-excel-icon">
          DOWNLOAD REPORT
        </button>
      </CSVLink>
    </div>
  );
};

export default ExportCSVButton;
