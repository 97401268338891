import request from "../../../util/SuperagentUtils";
const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const requestBookingById = (bookingId) => {
  return request.get(`${baseUrl}/api/booking/${bookingId}`).withCredentials();
};

export const requestZonesByBookingId = (bookingId) => {
  return request
    .get(`${baseUrl}/api/zone`)
    .query({ bookingId })
    .withCredentials();
};

export const requestUpdateLocationZone = (bookingId, locationZoneId) => {
  return request
    .put(`${baseUrl}/api/booking/${bookingId}/locationZone`)
    .withCredentials()
    .send({ locationZoneId });
};
