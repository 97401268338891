import React, { useContext } from "react";
import ManageUserItem from "./ManageUserItem";
import { AppContext } from "../../context/app-context";
import { useState, useEffect, useRef } from "react";
import { AccountType, UserTypeName } from "../constants/securspace-constants";
import classNames from "classnames";
import PropTypes from "prop-types";
import _ from "underscore";
import { searchRecordsByKeywordUserName } from "../../util/SearchUtils";
import ReactPaginate from "react-paginate";
import filterIcon from "../../img/filterIcon.png";
import useOnClickOutside from "./useOnClickOutside";

import "./style/manage-existing-users.css";

const ManageExistingUsers = (props) => {
  const [filteredData, setFilteredData] = useState([]);
  const [show, setShow] = useState(false);
  const ref = useRef();

  const {
    users,
    locationOptions,
    onRemoveUser,
    onUpdateLocations,
    account,
    searchValue,
    options,
  } = props;

  const {
    user: { type },
  } = useContext(AppContext);
  const [checked, setChecked] = useState("");
  const displayMap = new Map([
    [UserTypeName.GATE_CLERK, "ROLE_USERTYPE_GATECLERK"],
    [UserTypeName.GATE_MANAGER, "ROLE_USERTYPE_GATEMANAGER"],
    [UserTypeName.DISPATCHER, "ROLE_USERTYPE_DISPATCHER"],
    [UserTypeName.READ_ONLY_ADMIN, "ROLE_USERTYPE_READ_ONLY_ADMIN"],
    [UserTypeName.ADMIN, "ROLE_USERTYPE_OWNER"],
  ]);
  useEffect(() => {
    if (users && (!checked || checked === "all")) {
      setFilteredData(searchRecordsByKeywordUserName(searchValue, users));
    } else {
      setFilteredData(
        searchRecordsByKeywordUserName(
          searchValue,
          users.filter((ele) => ele?.role === displayMap.get(checked))
        )
      );
    }
  }, [searchValue, users, checked]);

  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + 10;
  const currentItems = filteredData?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(filteredData?.length / 10);

  useEffect(() => {
    setItemOffset(0);
  }, [checked, filteredData]);

  useOnClickOutside(ref, () => setShow(false));

  const handlePageClick = (event) => {
    const newOffset = (event.selected * 10) % filteredData?.length;
    setItemOffset(newOffset);
  };

  const handleOptions = (option) => {
    if (checked === option) {
      setChecked("all");
      setFilteredData(users);
    } else {
      setChecked(option);
      if (users) {
        setFilteredData(
          users.filter((ele) => ele?.role === displayMap.get(option))
        );
      }
    }
  };

  return (
    <div className="report-container">
      <div
        className={classNames("manage-existing-users-container", {
          "manage-existing-users-container-supplier":
            type === AccountType.SUPPLIER,
        })}
      >
        <div className="manage-users-header">
          <div className="manage-users-header-item">USERNAME</div>
          <div className="manage-users-header-item">
            ROLE
            <img
              src={filterIcon}
              alt="filter"
              style={{ height: "30px", cursor: "pointer" }}
              onClick={() => setShow(!show)}
            />
          </div>
          <div className="manage-users-header-item actions-header-item">
            ACTIONS
          </div>
        </div>
        {show && (
          <div
            className={
              show
                ? "ss-select-options-visiblemanage"
                : "ss-select-options-hidden"
            }
            style={{ marginLeft: "21%" }}
            ref={ref}
          >
            <ul
              style={{
                backgroundColor: "white",
                border: "1px solid",
                borderRadius: "4px",
                padding: "9px",
                width: "110%",
                marginTop: "51px",
              }}
            >
              {options.map((option, index) => (
                <p>
                  <input
                    type="checkbox"
                    id={index}
                    onClick={() => handleOptions(option)}
                    checked={checked === option}
                    name={option}
                    value={option}
                    style={{ accentColor: "#f79019" }}
                  />
                  <label for={option} style={{ marginLeft: "10px" }}>
                    {" "}
                    {option}
                  </label>
                </p>
              ))}
            </ul>
          </div>
        )}
        {filteredData && filteredData.length === 0 ? (
          <h4>
            No data found. Use the button to the left to add your first one.
          </h4>
        ) : (
          <>
            <div className="existing-users-container">
              {_.map(currentItems, (user, idx) => {
                return (
                  <ManageUserItem
                    key={idx}
                    user={user}
                    locationOptions={locationOptions}
                    onRemoveUser={onRemoveUser}
                    onUpdateLocations={onUpdateLocations}
                    accountType={type}
                    account={account}
                  />
                );
              })}
            </div>

            <ReactPaginate
              breakLabel="..."
              nextLabel="next >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel="< previous"
              renderOnZeroPageCount={null}
              className="pagination"
            />
          </>
        )}
      </div>
    </div>
  );
};

ManageExistingUsers.propTypes = {
  users: PropTypes.array.isRequired,
  locationOptions: PropTypes.array.isRequired,
  onRemoveUser: PropTypes.func.isRequired,
  onUpdateLocations: PropTypes.func.isRequired,
};

export default ManageExistingUsers;
