import React, { useEffect, useState } from "react";
import { getFineTypes } from "../checkin/request/fine-request";
import Steps from "../steps/Steps";
import FineTypeSelectMenu from "./FineTypeSelectMenu";
import { getErrorMessageForStandardResponse } from "../../util/NetworkErrorUtil";
import { toast } from "react-toastify";

const FineTypeSelect = (props) => {
  const { stepNumber, onFineTypeChange } = props;
  const [fines, setFines] = useState([]);
  const [value, setValue] = useState("");
  const [fineTypes, setFineTypes] = useState(null);

  useEffect(() => {
    getFineTypes()
      .then(
        (promise) => {
          let data = promise.body;
          setFines(data);
          if (data?.length > 0) {
            onFineTypeChange(data[0].fineType.id);
          } else {
            toast.error("No fine types created for this location.");
          }
        },
        (error) => {
          toast.error(getErrorMessageForStandardResponse(error));
        }
      )
      .catch((error) => {
        toast.error(getErrorMessageForStandardResponse(error));
      });
    // eslint-disable-next-line
  }, [setFines]);

  useEffect(() => {
    if (fines.length > 0) {
      let finesArray = [{ id: "", name: "Please select" }];
      for (let fine of fines) {
        finesArray.push(fine.fineType);
      }
      setFineTypes(finesArray);
    }
  }, [fines, setFineTypes]);

  const onChange = (event) => {
    const { value } = event.target;
    setValue(value);
    onFineTypeChange(value);
  };

  return (
    <div>
      <hr />
      <FineTypeSelectMenu
        fineTypes={fineTypes}
        onChange={onChange}
        value={value}
      />
      <hr />
      <Steps
        step={stepNumber}
        type="fineType"
        canContinue={!(fines?.length > 0 && !!value)}
        {...props}
      />
    </div>
  );
};

export default FineTypeSelect;
