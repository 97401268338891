import React from "react";
import _ from "underscore";
import ApprovedDriversListItem from "./ApprovedDriversListItem";
import ReportTitleGroupCounts from "../report/ReportTitleGroupCounts";
import { withGroupAndSortExportAndGroupings } from "../report/hocs/withGroupAndSortExportAndGroupings";

const ApprovedDriversList = (props) => {
  const { drivers, reportTitleCount = "ALL", reportTitle } = props;

  return (
    <>
      <ReportTitleGroupCounts
        dataLength={drivers?.length}
        reportTitleCount={reportTitleCount}
        reportTitle={reportTitle}
      />
      {_.map(drivers, (driver, idx) => {
        return <ApprovedDriversListItem key={idx} driver={driver} />;
      })}
    </>
  );
};

export default ApprovedDriversList;
export const ApprovedDriversListWithGroupAndSort =
  withGroupAndSortExportAndGroupings(ApprovedDriversList, "drivers");
