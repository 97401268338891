import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { requestInterchangeAuditsById } from "./requests/supplier-interchange-requests";
import SupplierInterchangeAuditReportContent from "./SupplierInterchangeAuditReportContent";
import ReportBackgroundContainer from "../../components/report/ReportBackgroundContainer";
import { getErrorMessageForStandardResponse } from "../../util/NetworkErrorUtil";
import ErrorAlert from "../../components/ErrorAlert";

const SupplierInterchangeAuditReport = () => {
  const { id } = useParams();
  const [interchangeAudits, setInterchangeAudits] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (id) {
      requestInterchangeAuditsById(id)
        .then((response) => {
          setInterchangeAudits(response.body);
          setError(null);
        })
        .catch((error) => setError(error));
    }
  }, [id, setInterchangeAudits, setError]);

  return (
    <ReportBackgroundContainer>
      {error && (
        <ErrorAlert>{getErrorMessageForStandardResponse(error)}</ErrorAlert>
      )}
      <SupplierInterchangeAuditReportContent
        interchangeAudits={interchangeAudits}
      />
    </ReportBackgroundContainer>
  );
};

export default SupplierInterchangeAuditReport;
