import React from "react";
import BooleanInput from "../common/BooleanInput";

const PowerAccessible = (props) => {
  const { powerAccessible, onPowerAccessibleChange } = props;

  const powerAccessibleName = "Is Power Accessible";
  const powerAccessibleLabel = "Power Accessible";
  const checkBoxType = "checkbox";
  const className = "form-control orange";

  return (
    <>
      <hr />
      <BooleanInput
        label={powerAccessibleLabel}
        className={className}
        name={powerAccessibleName}
        type={checkBoxType}
        checked={powerAccessible}
        onChange={onPowerAccessibleChange}
      />
    </>
  );
};

export default PowerAccessible;
