import React from "react";
import { MenuItem } from "@material-ui/core";
import {
  deleteWaitListEntryById,
  reserveWaitListEntry,
} from "./WaitListRequests";
import { getErrorMessageForStandardResponse } from "../../util/NetworkErrorUtil";
import { toast } from "react-toastify";
import ReportFlexRightContainer from "../report/ReportFlexRightContainer";
import ReportKebabButton from "../report/ReportKebabButton";

const WaitListMenu = (props) => {
  const {
    waitListEntry,
    setUpdateReport,
    setShouldSelectZone,
    setSelectedWaitList,
  } = props;

  const MyOptions = {
    RESERVE: "Approve WaitList",
    REMOVE: "Decline WaitList",
  };

  const handleMenuOption = (option, waitListEntry) => {
    if (option === MyOptions.RESERVE) {
      reserveWaitListEntry(waitListEntry.id, null)
        .then(
          () => {
            setUpdateReport(waitListEntry.id);
          },
          (error) => {
            if (
              getErrorMessageForStandardResponse(error) &&
              getErrorMessageForStandardResponse(error).includes(
                "does not meet the capacity requirements"
              )
            ) {
              setSelectedWaitList(waitListEntry);
              setShouldSelectZone(true);
              toast.error(getErrorMessageForStandardResponse(error));
              return;
            }
            toast.error(getErrorMessageForStandardResponse(error));
          }
        )
        .catch((error) => {
          toast.error(getErrorMessageForStandardResponse(error));
        });
    }

    if (option === MyOptions.REMOVE) {
      deleteWaitListEntryById(waitListEntry.id)
        .then(
          () => {
            setUpdateReport(waitListEntry.id);
          },
          (error) => {
            toast.error(getErrorMessageForStandardResponse(error));
          }
        )
        .catch((error) => {
          toast.error(getErrorMessageForStandardResponse(error));
        });
    }
  };

  return (
    <ReportFlexRightContainer>
      <ReportKebabButton>
        <MenuItem
          key={MyOptions.RESERVE}
          onClick={() => handleMenuOption(MyOptions.RESERVE, waitListEntry)}
        >
          {MyOptions.RESERVE}
        </MenuItem>
        <MenuItem
          key={MyOptions.REMOVE}
          onClick={() => handleMenuOption(MyOptions.REMOVE, waitListEntry)}
        >
          {MyOptions.REMOVE}
        </MenuItem>
      </ReportKebabButton>
    </ReportFlexRightContainer>
  );
};

export default WaitListMenu;
