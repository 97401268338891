import React, { useEffect, useState } from "react";
import InvoiceCard from "./InvoiceCard";
import ReportRow from "../report/ReportRow";
import InvoiceReportKebabButton from "./InvoiceReportKebabButton";
import AddRefundCreditForm from "../AddRefundCreditForm";
import { Modal, makeStyles } from "@material-ui/core";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  modal: {
    width: "100%",
    height: "100%",
    overflow: "scroll",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  close: {
    color: "black",
    border: "none",
    outline: "none",
    backgroundColor: "transparent",
    marginLeft: "auto",
    fontSize: "3em",
    position: "absolute",
    top: "-0.2em",
    right: "0.2em",
    zIndex: "9999",
    paddingBottom: "1.2em",
    "&:focus": {
      outline: "none",
    },
  },
  modalContent: {
    width: "30em",
    position: "relative",
  },
}));

const InvoiceList = (props) => {
  const [refundModal, setRefundModal] = useState(false);
  const [bookingTransactionId, setBookingTransactionId] = useState(null);
  const classes = useStyles();
  const { invoices } = props;

  function handleClose() {
    setRefundModal(false);
  }

  const addRefundCreditCompleted = () => {
    handleClose();
    toast.success("Successfully added refund credit to invoice!");
  };

  // Sort invoices by checkInDate in descending order
  // const sortedInvoices = [...invoices].sort((a, b) => {
  //   return new Date(b.createdOn) - new Date(a.createdOn);
  // });

  return (
    <>
      <Modal open={refundModal} onClose={handleClose} className={classes.modal}>
        <div className={classes.modalContent}>
          <button
            type="button"
            className={classes.close}
            aria-label="close"
            onClick={handleClose}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <AddRefundCreditForm
            display="popup"
            bookingTransactionId={bookingTransactionId}
            closeSubViewHandler={handleClose}
            addRefundCreditCompleted={addRefundCreditCompleted}
          />
        </div>
      </Modal>
      {invoices &&
        invoices?.map((invoice) => {
          return (
            <div key={invoice.transactionId}>
              <ReportRow>
                <InvoiceReportKebabButton
                  setBookingTransactionId={setBookingTransactionId}
                  setRefundModal={setRefundModal}
                  invoice={invoice}
                />
                <InvoiceCard invoice={invoice} />
              </ReportRow>
            </div>
          );
        })}
      {invoices?.length > 0 && (
        <div style={{ textAlign: "center" }}>
          {" "}
          You have reached the end of the list
        </div>
      )}
      <div className="ss-supplier-active-bookings-endlist">
        <h6>
          {!!invoices && invoices?.length > 0
            ? " "
            : "Match not found. Please try again"}
        </h6>
      </div>
    </>
  );
};

export default InvoiceList;
