import React, { useContext } from "react";
import { Route } from "react-router";
import { AppContext } from "../../context/app-context";
import { withAccessControl } from "../ProtectedRoute";
import AccessControlBuilder from "../AccessControlBuilder";

import SupplierActiveBookings from "../../views/SupplierActiveBookings";
import SupplierInventoryReport from "../../views/SupplierInventoryReport";
import SupplierInterchangeReport from "../../views/supplier-interchange/SupplierInterchangeReport";
import SupplierActivitySummaryReport from "../../views/SupplierActivitySummaryReport";
import SupplierInterchangeAuditReport from "../../views/supplier-interchange/SupplierInterchangeAuditReport";
import AssessFine from "../../components/fines/AssessFine";
import ApprovedDriversContent from "../../views/drivers/ApprovedDriversContent";

const allSupplierGmsAuthorization = new AccessControlBuilder()
  .allowAllGmsUsers()
  .build();
const adminAndAllOwnersSupplierAuthorization = new AccessControlBuilder()
  .allowAdmin()
  .allowAllOwners()
  .build();
const adminReadOnlyAndGateManagerAuth = new AccessControlBuilder()
  .allowGateManager()
  .build();
const allSupplierOwnerAuthorization = new AccessControlBuilder()
  .allowAllSupplierOwner()
  .build();
const gateManagerAuthorization = new AccessControlBuilder()
  .allowGateManager()
  .build();

const ProtectedSupplierActiveBookings = withAccessControl(
  SupplierActiveBookings,
  allSupplierGmsAuthorization
);
const ProtectedSupplierInventoryReport = withAccessControl(
  SupplierInventoryReport,
  allSupplierGmsAuthorization
);
const ProtectedSupplierInterchangeReport = withAccessControl(
  SupplierInterchangeReport,
  allSupplierGmsAuthorization
);
const ProtectedSupplierActivitySummaryReport = withAccessControl(
  SupplierActivitySummaryReport,
  adminReadOnlyAndGateManagerAuth
);
const ProtectedSupplierInterchangeAuditReport = withAccessControl(
  SupplierInterchangeAuditReport,
  adminReadOnlyAndGateManagerAuth
);
const ProtectedSupplierAssessFine = withAccessControl(
  AssessFine,
  adminAndAllOwnersSupplierAuthorization
);
const ProtectedApprovedDriversContent = withAccessControl(
  ApprovedDriversContent,
  allSupplierOwnerAuthorization,
  gateManagerAuthorization
);

const GateManagementContent = () => {
  const appContext = useContext(AppContext);
  const { user, logout } = appContext;

  return [
    <Route path="/check-in" key="/check-in">
      <ProtectedSupplierActiveBookings account={user} handleLogout={logout} />
    </Route>,
    <Route path="/supplier-inventory" key="/supplier-inventory">
      <ProtectedSupplierInventoryReport account={user} handleLogout={logout} />
    </Route>,
    <Route path="/supplier-interchanges" key="/supplier-interchanges">
      <ProtectedSupplierInterchangeReport
        account={user}
        handleLogout={logout}
      />
    </Route>,
    <Route path="/supplier-activity-summary" key="/supplier-activity-summary">
      <ProtectedSupplierActivitySummaryReport
        account={user}
        handleLogout={logout}
      />
    </Route>,
    <Route
      path="/audits/supplier-interchanges/:id"
      key="/audits/supplier-interchanges"
    >
      <ProtectedSupplierInterchangeAuditReport
        account={user}
        handleLogout={logout}
      />
    </Route>,
    <Route path="/assess-fine" key="/assess-fine">
      <ProtectedSupplierAssessFine account={user} handleLogout={logout} />
    </Route>,
    <Route path="/drivers" key="/drivers">
      <ProtectedApprovedDriversContent account={user} handleLogout={logout} />
    </Route>,
  ];
};

export default GateManagementContent;
