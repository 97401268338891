import request from "../../../util/SuperagentUtils";

export const getReservationChargeAmount = (reservationId, paymentMethodId) => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  return request
    .post(
      `${baseUrl}/api/zone/reservation/${reservationId}/calculate-charge-amount/${paymentMethodId}`
    )
    .set("Accept", "application/json")
    .withCredentials();
};
