import React, { Component } from "react";
import ContainerEntryField from "./ContainerEntryField";
import Select from "./Select";
import Error from "./Error";
import "../css/views/booking-common.css";
import "../css/theme/mainContent.css";
import "../css/theme/forms.css";
import "../css/theme/forms-block.css";
import "../css/theme/buttons.css";
import "../views/thirdparty/checkout/checkout.css";
import ReferenceOption from "../controls/ReferenceOption";
import { createLogoutOnFailureHandler } from "../util/LogoutUtil";
import Busy from "./Busy";
import { toast } from "react-toastify";
import DriverSelect from "./checkin/component/DriverSelect";
import { DriverOption } from "../controls/DriverOption";
import { getErrorMessageForStandardResponse } from "../util/NetworkErrorUtil";
import { getApprovedDrivers } from "./checkout/checkoutRequests";
import { ajaxRequest } from "../ajax";
import { EquipmentTypeForm } from "./constants/securspace-constants";

const active_AssetTypes = [
  "CONTAINER_LOADED",
  "CONTAINER_EMPTY",
  "CHASSIS_ONLY",
  "TRUCK_TRAILER_LOADED",
  "TRUCK_TRAILER_EMPTY",
  "TRUCK_ONLY",
  "TRAILER_LOADED",
  "TRAILER_EMPTY",
  "REEFER_LOADED_PLUGIN",
];

export default class EditInventory extends Component {
  constructor(props) {
    super(props);

    this.state = Object.assign({
      errorMessage: "",
      assetTypes: [],
      id: "",
      locationId: "",
      assetType: "",
      containerNumber: "",
      chassisNumber: "",
      chassisLicensePlateNumber: "",
      sealNumber: "",
      driverFirstName: this.props.editItem?.driverFirstName,
      driverLastName: this.props.editItem?.driverLastName,
      driverLicenseNumber: this.props.editItem?.driverLicenseNumber,
      truckLicensePlateNumber: "",
      trailerNumber: "",
      notes: "",
      assetSize: "",
      approvedDrivers: [],
      driverOptions: null,
      selectedDriver: "",
    });
  }

  componentDidMount() {
    this.loadAssetTypes();
    this.loadApprovedDrivers();
  }

  loadApprovedDrivers = () => {
    getApprovedDrivers(this.props.editItem.buyerId)
      .then(
        (response) => {
          const drivers = response.body;
          this.setState({ approvedDrivers: drivers });
          if (drivers?.length > 0) {
            let driverOptions = drivers.map((item) => {
              return new DriverOption(item);
            });
            this.setState({ driverOptions: driverOptions });
          } else if (drivers?.length === 0) {
            toast.error("There are no approved drivers for this booking");
          }
        },
        (error) => {
          toast(getErrorMessageForStandardResponse(error));
        }
      )
      .catch((error) => {
        toast(getErrorMessageForStandardResponse(error));
      });
  };

  handleDriverChange = (event) => {
    const driverId = event.target.value.value;
    if (this.state.approvedDrivers.length > 0) {
      for (const driver of this.state.approvedDrivers) {
        if (driverId === driver.id) {
          this.setState({
            selectedDriver: driver,
            driverLicenseNumber: driver.licenseNumber,
            driverFirstName: driver.firstName,
            driverLastName: driver.lastName,
          });
        }
      }
    }
  };

  loadAssetTypes() {
    Busy.set(true);
    ajaxRequest({
      url: "types/assets",
      type: "GET",
      contentType: "application/json; charset=UTF-8",
      dataType: "json",
      success: (assetTypes) => {
        let temp_assetTypes = assetTypes.filter((type) => {
          return active_AssetTypes.indexOf(type.key) > -1;
        });
        this.loadSelectedAsset(temp_assetTypes);
      },
      statusCode: {
        401: createLogoutOnFailureHandler(this.props.handleLogout),
      },
      error: this.handleFailure,
    });
  }

  loadSelectedAsset = (assetTypes) => {
    ajaxRequest({
      url: `inventories/${this.props.editItem.id}`,
      type: "GET",
      contentType: "application/json; charset=UTF-8",
      dataType: "json",
      success: (data) => {
        Busy.set(false);
        if (data) {
          let selectedAssetType = assetTypes.find(function (assetType) {
            return assetType.key === data.assetType;
          });
          this.setState({
            assetTypes: assetTypes,
            id: data.id ? data.id : "",
            locationId: data.locationId ? data.locationId : "",
            assetType: selectedAssetType
              ? new ReferenceOption(
                  selectedAssetType.key,
                  selectedAssetType.value
                )
              : "",
            containerNumber: data.containerNumber ? data.containerNumber : "",
            chassisNumber: data.chassisNumber ? data.chassisNumber : "",
            chassisLicensePlateNumber: data.chassisLicensePlateNumber
              ? data.chassisLicensePlateNumber
              : "",
            sealNumber: data.sealNumber ? data.sealNumber : "",
            trailerNumber: data?.trailerNumber ? data?.trailerNumber : "",
            selectedDriver: data.driver,
            driverFirstName: data.driver?.firstName
              ? data.driver?.firstName
              : "",
            driverLastName: data.driver?.lastName ? data.driver?.lastName : "",
            driverLicenseNumber: data.driver?.licenseNumber
              ? data.driver?.licenseNumber
              : "",
            truckLicensePlateNumber: data.truckLicensePlateNumber
              ? data.truckLicensePlateNumber
              : "",
            notes: data.notes ? data.notes : "",
            assetSize: data.assetSize ? data.assetSize : "",
          });
        }
      },
      statusCode: {
        401: createLogoutOnFailureHandler(this.props.handleLogout),
      },
      error: this.handleFailure,
    });
  };

  handleFailure(jqXHR, textStatus, errorThrown) {
    Busy.set(false);
    let errorMessage = jqXHR.responseJSON
      ? jqXHR.responseJSON.message
      : "Internal Server Error";
    errorMessage = errorMessage ? errorMessage.trim() : errorMessage;
    toast.error(errorMessage);
  }

  handleFieldChange = (event) => {
    this.setState({ [event.target.name]: event.target.value.toUpperCase() });
  };

  handleContainerIdFieldChange = (containerNumber) => {
    this.setState({ containerNumber: containerNumber });
  };
  handleErrorMessageContainer = (value) => {
    this.setState({
      errorMessage: value,
    });
  };

  saveChanges = () => {
    this.setState({ errorMessage: "" });

    Busy.set(true);

    ajaxRequest({
      url: "inventory",
      data: JSON.stringify({
        id: this.state.id,
        locationId: this.state.locationId,
        containerNumber: this.state.containerNumber,
        chassisNumber: this.state.chassisNumber,
        chassisLicensePlateNumber: this.state.chassisLicensePlateNumber,
        sealNumber: this.state.sealNumber,
        trailerNumber: this.state.trailerNumber,
        driver: this.state.selectedDriver,
        truckLicensePlateNumber: this.state.truckLicensePlateNumber,
        notes: this.state.notes,
        assetSize: this.state.assetSize,
        assetType: this.state.assetType.value,
      }),
      type: "PUT",
      contentType: "application/json; charset=UTF-8",
      dataType: "json",
      success: (data) => {
        Busy.set(false);
        this.props.handlePostSaveEvent();
        toast.success("Successfully saved inventory changes!");
      },
      statusCode: {
        401: createLogoutOnFailureHandler(this.props.handleLogout),
      },
      error: this.handleFailure,
    });
  };

  handleAssetTypeChange = (event) => {
    let assetType = event.target.value;
    this.setState({ assetType: assetType });
  };

  render() {
    const disableSaveButton = (value) => {
      switch (value) {
        case EquipmentTypeForm.CONTAINER_LOADED:
        case EquipmentTypeForm.TRUCK_CONTAINER_LOADED:
          return (
            !!this.state.containerNumber &&
            !!this.state.chassisNumber &&
            !!this.state.chassisLicensePlateNumber &&
            !!this.state.sealNumber &&
            !!this.state.truckLicensePlateNumber
          );
        case EquipmentTypeForm.CONTAINER_EMPTY:
        case EquipmentTypeForm.TRUCK_CONTAINER_EMPTY:
          return (
            !!this.state.containerNumber &&
            !!this.state.chassisNumber &&
            !!this.state.chassisLicensePlateNumber &&
            !!this.state.truckLicensePlateNumber
          );
        case EquipmentTypeForm.CHASSIS_ONLY:
          return (
            !!this.state.chassisNumber &&
            !!this.state.truckLicensePlateNumber &&
            !!this.state.chassisLicensePlateNumber
          );
        case EquipmentTypeForm.TRUCK_ONLY:
          return !!this.state.truckLicensePlateNumber;
        case EquipmentTypeForm.TRUCK_TRAILER_EMPTY:
        case EquipmentTypeForm.TRAILER_EMPTY:
          return (
            !!this.state.trailerNumber && !!this.state.truckLicensePlateNumber
          );
        case EquipmentTypeForm.REEFER_LOADED_PLUGIN:
        case EquipmentTypeForm.TRAILER_LOADED:
        case EquipmentTypeForm.TRUCK_TRAILER_LOADED:
        case EquipmentTypeForm.REEFER_LOADED_NO_PLUGIN:
        case EquipmentTypeForm.TRUCK_REEFER_LOADED_PLUGIN:
        case EquipmentTypeForm.TRUCK_REEFER_LOADED_NO_PLUGIN:
          return (
            !!this.state.sealNumber &&
            !!this.state.truckLicensePlateNumber &&
            !!this.state.driverLicenseNumber
          );
      }
    };
    const getEditForm = (value) => {
      switch (value) {
        case EquipmentTypeForm.CONTAINER_LOADED:
        case EquipmentTypeForm.TRUCK_CONTAINER_LOADED:
          return (
            <div>
              <ContainerEntryField
                compName="editInv"
                className="ss-top"
                valueCallback={this.handleContainerIdFieldChange}
                initState={this.state.containerNumber}
                handleErrorMessageContainer={this.handleErrorMessageContainer}
              />
              <fieldset
                className="ss-middle"
                style={{ marginTop: "-5px", border: "1px solid #E1E1E1" }}
              >
                <label>EQUIPMENT LENGTH</label>
                <Select
                  id="assetSize"
                  name="assetSize"
                  comp="editAssetSize"
                  className="ss-book-space-form-asset-size"
                  handleChange={this.handleFieldChange}
                  selectedOption={this.state.assetSize}
                  placeholder="Choose"
                  options={[
                    "25' MAX (Truck Only)",
                    "45' MAX (Small Container)",
                    "53' MAX (Large Container)",
                    "83' MAX (Truck + Trailer)",
                  ]}
                />
              </fieldset>
              <fieldset
                className="ss-middle"
                style={{ marginTop: "5px", border: "1px solid #E1E1E1" }}
              >
                <label htmlFor="chassisNumber">CHASSIS NUMBER</label>
                <input
                  type="text"
                  id="chassisNumber"
                  name="chassisNumber"
                  value={this.state.chassisNumber}
                  onChange={this.handleFieldChange}
                  placeholder="Enter the chassis number"
                />
              </fieldset>
              <fieldset
                className="ss-middle"
                style={{ marginTop: "5px", border: "1px solid #E1E1E1" }}
              >
                <label htmlFor="chassisLicensePlateNumber">
                  CHASSIS LICENSE PLATE NUMBER
                </label>
                <input
                  type="text"
                  id="chassisLicensePlateNumber"
                  name="chassisLicensePlateNumber"
                  value={this.state.chassisLicensePlateNumber}
                  onChange={this.handleFieldChange}
                  placeholder="Enter the chassis license plate number"
                />
              </fieldset>
              <fieldset
                className="ss-middle"
                style={{ marginTop: "5px", border: "1px solid #E1E1E1" }}
              >
                <label htmlFor="sealNumber">SEAL NUMBER</label>
                <input
                  type="text"
                  id="sealNumber"
                  name="sealNumber"
                  value={this.state.sealNumber}
                  onChange={this.handleFieldChange}
                  placeholder="Enter the seal number"
                />
              </fieldset>
              <fieldset
                className="ss-middle"
                style={{ marginTop: "5px", border: "1px solid #E1E1E1" }}
              >
                <label htmlFor="truckLicensePlateNumber">
                  TRUCK LICENSE PLATE NUMBER
                </label>
                <input
                  type="text"
                  id="truckLicensePlateNumber"
                  name="truckLicensePlateNumber"
                  value={this.state.truckLicensePlateNumber}
                  onChange={this.handleFieldChange}
                  placeholder="Enter the truck's license plate number"
                />
              </fieldset>
              <fieldset
                className="ss-bottom"
                style={{ marginTop: "5px", border: "1px solid #E1E1E1" }}
              >
                <label htmlFor="notes">REMARKS OR NOTES</label>
                <textarea
                  type="text"
                  id="notes"
                  name="notes"
                  value={this.state.notes}
                  onChange={(event) =>
                    this.setState({ [event.target.name]: event.target.value })
                  }
                  placeholder="Enter any notes about the check in."
                />
              </fieldset>
            </div>
          );
        case EquipmentTypeForm.CONTAINER_EMPTY:
        case EquipmentTypeForm.TRUCK_CONTAINER_EMPTY:
          return (
            <div>
              <ContainerEntryField
                compName="editInv"
                className="ss-top"
                valueCallback={this.handleContainerIdFieldChange}
                initState={this.state.containerNumber}
                handleErrorMessageContainer={this.handleErrorMessageContainer}
              />
              <fieldset
                className="ss-middle"
                style={{ marginTop: "-5px", border: "1px solid #E1E1E1" }}
              >
                <label>EQUIPMENT LENGTH</label>
                <Select
                  id="assetSize"
                  name="assetSize"
                  comp="editAssetSize"
                  className="ss-book-space-form-asset-size"
                  handleChange={this.handleFieldChange}
                  selectedOption={this.state.assetSize}
                  placeholder="Choose"
                  options={[
                    "25' MAX (Truck Only)",
                    "45' MAX (Small Container)",
                    "53' MAX (Large Container)",
                    "83' MAX (Truck + Trailer)",
                  ]}
                />
              </fieldset>
              <fieldset
                className="ss-middle"
                style={{ marginTop: "5px", border: "1px solid #E1E1E1" }}
              >
                <label htmlFor="chassisNumber">CHASSIS NUMBER</label>
                <input
                  type="text"
                  id="chassisNumber"
                  name="chassisNumber"
                  value={this.state.chassisNumber}
                  onChange={this.handleFieldChange}
                  placeholder="Enter the chassis number"
                />
              </fieldset>
              <fieldset
                className="ss-middle"
                style={{ marginTop: "5px", border: "1px solid #E1E1E1" }}
              >
                <label htmlFor="chassisLicensePlateNumber">
                  CHASSIS LICENSE PLATE NUMBER
                </label>
                <input
                  type="text"
                  id="chassisLicensePlateNumber"
                  name="chassisLicensePlateNumber"
                  value={this.state.chassisLicensePlateNumber}
                  onChange={this.handleFieldChange}
                  placeholder="Enter the chassis license plate number"
                />
              </fieldset>
              <fieldset
                className="ss-middle"
                style={{ marginTop: "5px", border: "1px solid #E1E1E1" }}
              >
                <label htmlFor="truckLicensePlateNumber">
                  TRUCK LICENSE PLATE NUMBER
                </label>
                <input
                  type="text"
                  id="truckLicensePlateNumber"
                  name="truckLicensePlateNumber"
                  value={this.state.truckLicensePlateNumber}
                  onChange={this.handleFieldChange}
                  placeholder="Enter the truck's license plate number"
                />
              </fieldset>
              <fieldset
                className="ss-bottom"
                style={{ marginTop: "5px", border: "1px solid #E1E1E1" }}
              >
                <label htmlFor="notes">REMARKS OR NOTES</label>
                <textarea
                  type="text"
                  id="notes"
                  name="notes"
                  value={this.state.notes}
                  onChange={(event) =>
                    this.setState({ [event.target.name]: event.target.value })
                  }
                  placeholder="Enter any notes about the check in."
                />
              </fieldset>
            </div>
          );
        case EquipmentTypeForm.CHASSIS_ONLY:
          return (
            <div>
              <fieldset
                className="ss-middle"
                style={{ marginTop: "-10px", border: "1px solid #E1E1E1" }}
              >
                <label>EQUIPMENT LENGTH</label>
                <Select
                  id="assetSize"
                  name="assetSize"
                  comp="editAssetSize"
                  className="ss-book-space-form-asset-size"
                  handleChange={this.handleFieldChange}
                  selectedOption={this.state.assetSize}
                  placeholder="Choose"
                  options={[
                    "25' MAX (Truck Only)",
                    "45' MAX (Small Container)",
                    "53' MAX (Large Container)",
                    "83' MAX (Truck + Trailer)",
                  ]}
                />
              </fieldset>
              <fieldset
                className="ss-middle"
                style={{ marginTop: "5px", border: "1px solid #E1E1E1" }}
              >
                <label htmlFor="chassisNumber">CHASSIS NUMBER</label>
                <input
                  type="text"
                  id="chassisNumber"
                  name="chassisNumber"
                  value={this.state.chassisNumber}
                  onChange={this.handleFieldChange}
                  placeholder="Enter the chassis number"
                />
              </fieldset>
              <fieldset
                className="ss-middle"
                style={{ marginTop: "5px", border: "1px solid #E1E1E1" }}
              >
                <label htmlFor="chassisLicensePlateNumber">
                  CHASSIS LICENSE PLATE NUMBER
                </label>
                <input
                  type="text"
                  id="chassisLicensePlateNumber"
                  name="chassisLicensePlateNumber"
                  value={this.state.chassisLicensePlateNumber}
                  onChange={this.handleFieldChange}
                  placeholder="Enter the chassis license plate number"
                />
              </fieldset>
              <fieldset
                className="ss-middle"
                style={{ marginTop: "5px", border: "1px solid #E1E1E1" }}
              >
                <label htmlFor="truckLicensePlateNumber">
                  TRUCK LICENSE PLATE NUMBER
                </label>
                <input
                  type="text"
                  id="truckLicensePlateNumber"
                  name="truckLicensePlateNumber"
                  value={this.state.truckLicensePlateNumber}
                  onChange={this.handleFieldChange}
                  placeholder="Enter the truck's license plate number"
                />
              </fieldset>
              <fieldset
                className="ss-bottom"
                style={{ marginTop: "5px", border: "1px solid #E1E1E1" }}
              >
                <label htmlFor="notes">REMARKS OR NOTES</label>
                <textarea
                  type="text"
                  id="notes"
                  name="notes"
                  value={this.state.notes}
                  onChange={(event) =>
                    this.setState({ [event.target.name]: event.target.value })
                  }
                  placeholder="Enter any notes about the check in."
                />
              </fieldset>
            </div>
          );
        case EquipmentTypeForm.TRUCK_ONLY:
          return (
            <div>
              <fieldset
                className="ss-middle"
                style={{ marginTop: "-10px", border: "1px solid #E1E1E1" }}
              >
                <label>EQUIPMENT LENGTH</label>
                <Select
                  id="assetSize"
                  name="assetSize"
                  comp="editAssetSize"
                  className="ss-book-space-form-asset-size"
                  handleChange={this.handleFieldChange}
                  selectedOption={this.state.assetSize}
                  placeholder="Choose"
                  options={[
                    "25' MAX (Truck Only)",
                    "45' MAX (Small Container)",
                    "53' MAX (Large Container)",
                    "83' MAX (Truck + Trailer)",
                  ]}
                />
              </fieldset>
              <fieldset
                className="ss-middle"
                style={{ marginTop: "5px", border: "1px solid #E1E1E1" }}
              >
                <label htmlFor="truckLicensePlateNumber">
                  TRUCK LICENSE PLATE NUMBER
                </label>
                <input
                  type="text"
                  id="truckLicensePlateNumber"
                  name="truckLicensePlateNumber"
                  value={this.state.truckLicensePlateNumber}
                  onChange={this.handleFieldChange}
                  placeholder="Enter the truck's license plate number"
                />
              </fieldset>
              <fieldset
                className="ss-bottom"
                style={{ marginTop: "5px", border: "1px solid #E1E1E1" }}
              >
                <label htmlFor="notes">REMARKS OR NOTES</label>
                <textarea
                  type="text"
                  id="notes"
                  name="notes"
                  value={this.state.notes}
                  onChange={(event) =>
                    this.setState({ [event.target.name]: event.target.value })
                  }
                  placeholder="Enter any notes about the check in."
                />
              </fieldset>
            </div>
          );
        case EquipmentTypeForm.TRUCK_TRAILER_EMPTY:
        case EquipmentTypeForm.TRAILER_EMPTY:
          return (
            <div>
              <fieldset
                className="ss-middle"
                style={{ marginTop: "-10px", border: "1px solid #E1E1E1" }}
              >
                <label>EQUIPMENT LENGTH</label>
                <Select
                  id="assetSize"
                  name="assetSize"
                  comp="editAssetSize"
                  className="ss-book-space-form-asset-size"
                  handleChange={this.handleFieldChange}
                  selectedOption={this.state.assetSize}
                  placeholder="Choose"
                  options={[
                    "25' MAX (Truck Only)",
                    "45' MAX (Small Container)",
                    "53' MAX (Large Container)",
                    "83' MAX (Truck + Trailer)",
                  ]}
                />
              </fieldset>
              <fieldset
                className="ss-middle"
                style={{ marginTop: "5px", border: "1px solid #E1E1E1" }}
              >
                <label htmlFor="trailerNumber">TRAILER NUMBER</label>
                <input
                  type="text"
                  id="trailerNumber"
                  name="trailerNumber"
                  value={this.state.trailerNumber}
                  onChange={this.handleFieldChange}
                  placeholder="Enter the trailor number"
                />
              </fieldset>
              <fieldset
                className="ss-middle"
                style={{ marginTop: "5px", border: "1px solid #E1E1E1" }}
              >
                <label htmlFor="truckLicensePlateNumber">
                  TRUCK LICENSE PLATE NUMBER
                </label>
                <input
                  type="text"
                  id="truckLicensePlateNumber"
                  name="truckLicensePlateNumber"
                  value={this.state.truckLicensePlateNumber}
                  onChange={this.handleFieldChange}
                  placeholder="Enter the truck's license plate number"
                />
              </fieldset>
              <fieldset
                className="ss-bottom"
                style={{ marginTop: "5px", border: "1px solid #E1E1E1" }}
              >
                <label htmlFor="notes">REMARKS OR NOTES</label>
                <textarea
                  type="text"
                  id="notes"
                  name="notes"
                  value={this.state.notes}
                  onChange={(event) =>
                    this.setState({ [event.target.name]: event.target.value })
                  }
                  placeholder="Enter any notes about the check in."
                />
              </fieldset>
            </div>
          );
        case EquipmentTypeForm.REEFER_LOADED_PLUGIN:
        case EquipmentTypeForm.TRAILER_LOADED:
        case EquipmentTypeForm.TRUCK_TRAILER_LOADED:
        case EquipmentTypeForm.REEFER_LOADED_NO_PLUGIN:
        case EquipmentTypeForm.TRUCK_REEFER_LOADED_PLUGIN:
        case EquipmentTypeForm.TRUCK_REEFER_LOADED_NO_PLUGIN:
          return (
            <div>
              <fieldset
                className="ss-middle"
                style={{ marginTop: "-10px", border: "1px solid #E1E1E1" }}
              >
                <label>EQUIPMENT LENGTH</label>
                <Select
                  id="assetSize"
                  name="assetSize"
                  comp="editAssetSize"
                  className="ss-book-space-form-asset-size"
                  handleChange={this.handleFieldChange}
                  selectedOption={this.state.assetSize}
                  placeholder="Choose"
                  options={[
                    "25' MAX (Truck Only)",
                    "45' MAX (Small Container)",
                    "53' MAX (Large Container)",
                    "83' MAX (Truck + Trailer)",
                  ]}
                />
              </fieldset>
              <fieldset
                className="ss-middle"
                style={{ marginTop: "5px", border: "1px solid #E1E1E1" }}
              >
                <label htmlFor="sealNumber">SEAL NUMBER</label>
                <input
                  type="text"
                  id="sealNumber"
                  name="sealNumber"
                  value={this.state.sealNumber}
                  onChange={this.handleFieldChange}
                  placeholder="Enter the seal number"
                />
              </fieldset>
              <fieldset
                className="ss-middle"
                style={{ marginTop: "5px", border: "1px solid #E1E1E1" }}
              >
                <label htmlFor="trailerNumber">TRAILER NUMBER</label>
                <input
                  type="text"
                  id="trailerNumber"
                  name="trailerNumber"
                  value={this.state.trailerNumber}
                  onChange={this.handleFieldChange}
                  placeholder="Enter the trailor number"
                />
              </fieldset>
              <fieldset
                className="ss-middle"
                style={{ marginTop: "5px", border: "1px solid #E1E1E1" }}
              >
                <label htmlFor="truckLicensePlateNumber">
                  TRUCK LICENSE PLATE NUMBER
                </label>
                <input
                  type="text"
                  id="truckLicensePlateNumber"
                  name="truckLicensePlateNumber"
                  value={this.state.truckLicensePlateNumber}
                  onChange={this.handleFieldChange}
                  placeholder="Enter the truck's license plate number"
                />
              </fieldset>

              <fieldset
                className="ss-bottom"
                style={{ marginTop: "5px", border: "1px solid #E1E1E1" }}
              >
                <label htmlFor="notes">REMARKS OR NOTES</label>
                <textarea
                  type="text"
                  id="notes"
                  name="notes"
                  value={this.state.notes}
                  onChange={(event) =>
                    this.setState({ [event.target.name]: event.target.value })
                  }
                  placeholder="Enter any notes about the check in."
                />
              </fieldset>
            </div>
          );
        default:
          return (
            <div>
              <ContainerEntryField
                className="ss-top"
                compName="editInv"
                valueCallback={this.handleContainerIdFieldChange}
                initState={this.state.containerNumber}
                handleErrorMessageContainer={this.handleErrorMessageContainer}
              />
              <fieldset
                className="ss-middle"
                style={{ marginTop: "-5px", border: "1px solid #E1E1E1" }}
              >
                <label>EQUIPMENT LENGTH</label>
                <Select
                  id="assetSize"
                  name="assetSize"
                  comp="editAssetSize"
                  className="ss-book-space-form-asset-size"
                  handleChange={this.handleFieldChange}
                  selectedOption={this.state.assetSize}
                  placeholder="Choose"
                  options={[
                    "25' MAX (Truck Only)",
                    "45' MAX (Small Container)",
                    "53' MAX (Large Container)",
                    "83' MAX (Truck + Trailer)",
                  ]}
                />
              </fieldset>
              <fieldset
                className="ss-middle"
                style={{ marginTop: "5px", border: "1px solid #E1E1E1" }}
              >
                <label htmlFor="chassisNumber">CHASSIS NUMBER</label>
                <input
                  type="text"
                  id="chassisNumber"
                  name="chassisNumber"
                  value={this.state.chassisNumber}
                  onChange={this.handleFieldChange}
                  placeholder="Enter the chassis number"
                />
              </fieldset>
              <fieldset
                className="ss-middle"
                style={{ marginTop: "5px", border: "1px solid #E1E1E1" }}
              >
                <label htmlFor="chassisLicensePlateNumber">
                  CHASSIS LICENSE PLATE NUMBER
                </label>
                <input
                  type="text"
                  id="chassisLicensePlateNumber"
                  name="chassisLicensePlateNumber"
                  value={this.state.chassisLicensePlateNumber}
                  onChange={this.handleFieldChange}
                  placeholder="Enter the chassis license plate number"
                />
              </fieldset>
              <fieldset
                className="ss-middle"
                style={{ marginTop: "5px", border: "1px solid #E1E1E1" }}
              >
                <label htmlFor="sealNumber">SEAL NUMBER</label>
                <input
                  type="text"
                  id="sealNumber"
                  name="sealNumber"
                  value={this.state.sealNumber}
                  onChange={this.handleFieldChange}
                  placeholder="Enter the seal number"
                />
              </fieldset>
              <fieldset
                className="ss-middle"
                style={{ marginTop: "5px", border: "1px solid #E1E1E1" }}
              >
                <label htmlFor="truckLicensePlateNumber">
                  TRUCK LICENSE PLATE NUMBER
                </label>
                <input
                  type="text"
                  id="truckLicensePlateNumber"
                  name="truckLicensePlateNumber"
                  value={this.state.truckLicensePlateNumber}
                  onChange={this.handleFieldChange}
                  placeholder="Enter the truck's license plate number"
                />
              </fieldset>
              <fieldset
                className="ss-middle"
                style={{ marginTop: "5px", border: "1px solid #E1E1E1" }}
              >
                <label htmlFor="driverFirstName">DRIVER FIRST NAME</label>
                <input
                  type="text"
                  id="driverFirstName"
                  name="driverFirstName"
                  value={this.state.driverFirstName}
                  disabled={true}
                />
              </fieldset>
              <fieldset
                className="ss-middle"
                style={{ marginTop: "5px", border: "1px solid #E1E1E1" }}
              >
                <label htmlFor="driverLastName">DRIVER LAST NAME</label>
                <input
                  type="text"
                  id="driverLastName"
                  name="driverLastName"
                  value={this.state.driverLastName}
                  disabled={true}
                />
              </fieldset>

              <fieldset
                className="ss-bottom"
                style={{ marginTop: "5px", border: "1px solid #E1E1E1" }}
              >
                <label htmlFor="notes">REMARKS OR NOTES</label>
                <textarea
                  type="text"
                  id="notes"
                  name="notes"
                  value={this.state.notes}
                  onChange={(event) =>
                    this.setState({ [event.target.name]: event.target.value })
                  }
                  placeholder="Enter any notes about the check in."
                />
              </fieldset>
            </div>
          );
      }
    };
    return (
      <form className="ss-form ss-block no-padding">
        <div className="modal-body">
          <DriverSelect
            drivers={this.state.approvedDrivers}
            handleDriverChange={this.handleDriverChange}
            selectedDriver={this.state.selectedDriver}
            driverOptions={this.state.driverOptions}
          />
          <fieldset
            className="ss-middle"
            style={{ marginTop: "-5px", border: "1px solid #E1E1E1" }}
          >
            <label htmlFor="driversLicenseNumber">DRIVER LICENSE NUMBER</label>
            <input
              type="text"
              id="driversLicenseNumber"
              name="driversLicenseNumber"
              value={this.state.driverLicenseNumber}
              disabled={true}
            />
          </fieldset>
          <div>
            <fieldset
              className="ss-stand-alone"
              style={{ marginTop: "5px", border: "1px solid #E1E1E1" }}
            >
              <label>EQUIPMENT TYPE</label>
              <Select
                id="assetType"
                name="assetType"
                comp="editAssetSize"
                optionsWidth="300px"
                className="ss-book-space-form-asset-type"
                handleChange={this.handleAssetTypeChange}
                selectedOption={this.state.assetType}
                placeholder="Select the equipment type being checked in"
                options={this.state.assetTypes.map(
                  (item) => new ReferenceOption(item.key, item.value)
                )}
              />
            </fieldset>
          </div>
          <div>
            {this.state?.assetType
              ? getEditForm(this.state?.assetType?.getValue())
              : ""}
            {this.state.errorMessage ? (
              <Error>{this.state.errorMessage}</Error>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="modal-footer">
          <div className="newbuttonDivSubmitDelete newButtonDiv">
            <button
              type="button"
              className="ss-button-secondary"
              onClick={() => this.props.handlePanelCloseEvent()}
            >
              Cancel
            </button>
            <button
              type="button"
              className="ss-button-primary"
              onClick={() => this.saveChanges()}
              disabled={
                this.state?.assetType
                  ? !disableSaveButton(this.state?.assetType?.getValue())
                  : false
              }
              style={
                this.state?.assetType
                  ? disableSaveButton(this.state?.assetType?.getValue())
                    ? {
                        marginLeft: "10px",
                      }
                    : { opacity: "0.5", marginLeft: "10px" }
                  : {
                      marginLeft: "10px",
                    }
              }
            >
              Save
            </button>
          </div>
        </div>
      </form>
    );
  }
}
