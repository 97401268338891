import React, { useEffect, useState } from "react";
import NumberOfSpaces from "../zonebuilder/zones/NumberOfSpaces";
import PowerAccessible from "../reservationbuiler/PowerAccessible";
import StartEndDate from "../reservationbuiler/StartEndDate";
import EquipmentLengths from "../reservationbuiler/EquipmentLengths";
import Steps from "../steps/Steps";
import FrequencyDuration from "../reservationbuiler/FrequencyDuration";
import { toast } from "react-toastify";
import "../zonebuilder/zones/zones.css";

const ReservationCriteria = (props) => {
  const {
    stepNumber,
    equipmentLengths,
    selectedEquipmentLength,
    onEquipmentLengthChange,
    numberOfSpaces,
    onNumberOfSpacesChange,
    powerAccessible,
    onPowerAccessibleChange,
    startDate,
    onStartDateChange,
    endDate,
    onEndDateChange,
    frequencyTypes,
    durationTypes,
    selectedFrequency,
    selectedDuration,
    onFrequencyChange,
    onDurationChange,
  } = props;

  const [buttonDisabled, setButtonDisabled] = useState(true);

  useEffect(() => {
    // End date is not required for recurring bookings but is for one-time
    if (
      selectedEquipmentLength &&
      numberOfSpaces &&
      startDate &&
      endDate &&
      selectedFrequency === "ONE_TIME"
    ) {
      setButtonDisabled(false);
    } else if (
      selectedEquipmentLength &&
      numberOfSpaces &&
      startDate &&
      selectedFrequency === "RECURRING"
      // && selectedDuration
    ) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
    // else if (selectedFrequency === "ONE_TIME" && endDate === "") {
    //   toast.info(
    //     "Start Date and End Date is required for One Time or Recurring reservations "
    //   );
    // }
  }, [
    selectedEquipmentLength,
    numberOfSpaces,
    startDate,
    selectedFrequency,
    endDate,
    selectedDuration,
  ]);
  useEffect(() => {
    if (Number(numberOfSpaces) > 99) {
      setButtonDisabled(true);
    }
  }, [numberOfSpaces]);

  return (
    <>
      <div style={{ display: "flex" }}>
        <div>
          <FrequencyDuration
            frequencyTypes={frequencyTypes}
            durationTypes={durationTypes}
            selectedFrequency={selectedFrequency}
            selectedDuration={selectedDuration}
            onFrequencyChange={onFrequencyChange}
            onDurationChange={onDurationChange}
          />
        </div>
        <div style={{ marginLeft: "29%" }}>
          <EquipmentLengths
            stepNumber={stepNumber}
            equipmentLengths={equipmentLengths}
            selectedEquipmentLength={selectedEquipmentLength}
            onEquipmentLengthChange={onEquipmentLengthChange}
          />
        </div>
      </div>
      <StartEndDate
        startDate={startDate}
        onStartDateChange={onStartDateChange}
        endDate={endDate}
        onEndDateChange={onEndDateChange}
        selectedFrequency={selectedFrequency}
      />
      <hr />

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ width: "40%" }}>
          <NumberOfSpaces
            numberOfSpaces={numberOfSpaces}
            onNumberOfSpacesChange={onNumberOfSpacesChange}
          />{" "}
        </div>
        <div className="powerAccessibleDiv" style={{ marginRight: "27%" }}>
          <PowerAccessible
            powerAccessible={powerAccessible}
            onPowerAccessibleChange={onPowerAccessibleChange}
          />
        </div>
      </div>
      <hr />

      <Steps step={stepNumber} canContinue={buttonDisabled} {...props} />
    </>
  );
};

export default ReservationCriteria;
