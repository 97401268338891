import React from "react";
import ReportRow from "../report/ReportRow";
import ReportDataRow from "../report/ReportDataRow";
import ReportData from "../report/ReportData";
import DateDisplay from "../date/DateDisplay";
import { DateFormats } from "../constants/securspace-constants";

const ApprovedDriversListItem = (props) => {
  const { driver } = props;

  return (
    <ReportRow>
      <ReportDataRow>
        <ReportData label={"id"}>{driver?.id || "-"}</ReportData>
        <ReportData label={"account Id"}>{driver?.accountId || "-"}</ReportData>
        <ReportData label={"carrier"}>{driver?.carrier || "-"}</ReportData>
        <ReportData label={"first Name"}>{driver?.firstName || "-"}</ReportData>
        <ReportData label={"last Name"}>{driver?.lastName || "-"}</ReportData>
        <ReportData label={"license Number"}>
          {driver?.licenseNumber || "-"}
        </ReportData>
        <ReportData label={"state"}>{driver?.state || "-"}</ReportData>
        <ReportData label={"license expiration Date"}>
          <DateDisplay date={driver?.expirationDate} format={DateFormats.DAY} />
        </ReportData>
        <ReportData label={"status"}>{driver?.status || "-"}</ReportData>
        <ReportData label={"Date of Birth"}>
          <DateDisplay date={driver?.dob} format={DateFormats.DAY} />
        </ReportData>
        <ReportData label={"twic Card Number"}>
          {driver?.twicCardNumber || "-"}
        </ReportData>
        <ReportData label={"twic Expiration Date"}>
          {driver?.twicExpirationDate || "-"}
        </ReportData>
        <ReportData label={"headshot Identifier"}>
          {driver?.headshotIdentifier || "-"}
        </ReportData>
        <ReportData label={"created By"}>{driver?.createdBy || "-"}</ReportData>
        <ReportData label={"creation Date"}>
          <DateDisplay date={driver.creationDate} />
        </ReportData>
        <ReportData label={"last Modified By"}>
          {driver?.lastModifiedBy || "-"}
        </ReportData>
        <ReportData label={"last Modified Date"}>
          <DateDisplay date={driver?.lastModifiedDate} />
        </ReportData>
        <ReportData label={"revision Type"}>
          {driver?.revisionType || "-"}
        </ReportData>
        <ReportData label={"revision Instant"}>
          {driver?.revisionInstant || "-"}
        </ReportData>
        <ReportData label={"created By"}>{driver?.createdBy || "-"}</ReportData>
        <ReportData label={"creation Date"}>
          <DateDisplay date={driver?.creationDate} />
        </ReportData>
        <ReportData label={"last Modified By"}>
          {driver?.lastModifiedBy || "-"}
        </ReportData>
        <ReportData label={"last Modified Date"}>
          <DateDisplay date={driver?.lastModifiedDate} />
        </ReportData>
        <ReportData label={"revision Type"}>
          {driver?.revisionType || "-"}
        </ReportData>

        <ReportData label={"revision Instant"}>
          {driver?.revisionInstant || "-"}
        </ReportData>
      </ReportDataRow>
    </ReportRow>
  );
};

export default ApprovedDriversListItem;
