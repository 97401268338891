import React from "react";
import BooleanInput from "../common/BooleanInput";
import SelectZone from "./SelectZone";
import "../zonebuilder/zones/zones.css";

const OverrideZones = (props) => {
  const { checked, setChecked, zones, selectedZone, onZoneChange } = props;

  return (
    <>
      <div className="powerAccessibleDiv">
        <BooleanInput
          className={"form-control orange"}
          label={"Override Zone Availability"}
          name={"Override Zone Availability"}
          type={"checkbox"}
          checked={checked}
          onChange={() => {
            setChecked((prevChecked) => !prevChecked);
          }}
        />
      </div>
      {checked && (
        <SelectZone
          zones={zones}
          selectedZone={selectedZone}
          onZoneChange={onZoneChange}
        />
      )}
    </>
  );
};

export default OverrideZones;
