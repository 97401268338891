import React, { Component } from 'react';
import { withRouter } from "react-router";
import '../css/theme/mainContent.css';

class LandingPage extends Component {

    render() {
        return (
            <div className="landing-page-container">
            </div>
        )
    }
}

export default withRouter(LandingPage);
