import React from "react";
import BooleanInput from "../../common/BooleanInput";
import PropTypes from "prop-types";
import Steps from "../../steps/Steps";
import "./zones.css";

const AllowBookings = (props) => {
  const {
    stepNumber,
    allowOneTimeBookings,
    allowWeeklyBookings,
    allowMonthlyBookings,
    onAllowOneTimeBookingsChange,
    onAllowWeeklyBookingsChange,
    onAllowMonthlyBookingsChange,
    buttonDisabled,
  } = props;

  const className = "form-control orange";
  const type = "checkbox";
  const allowOneTimeBookingsName = "Allow One Time Bookings";
  const allowOneTimeBookingsLabel = "Allow One Time Bookings ";
  const allowWeeklyBookingsName = "Allow Weekly Bookings";
  const allowWeeklyBookingsLabel = "Allow Weekly Bookings ";
  const allowMonthlyBookingsName = "Allow Recurring Bookings";
  const allowMonthlyBookingsLabel = "Allow Recurring Bookings ";

  return (
    <div>
      <div className="booleanInputAllowBooking">
        <BooleanInput
          className={className}
          name={allowOneTimeBookingsName}
          type={type}
          label={allowOneTimeBookingsLabel}
          checked={allowOneTimeBookings}
          onChange={onAllowOneTimeBookingsChange}
        />

        {/* <BooleanInput
        className={className}
        name={allowWeeklyBookingsName}
        type={type}
        label={allowWeeklyBookingsLabel}
        checked={allowWeeklyBookings}
        onChange={onAllowWeeklyBookingsChange}
      /> */}

        <BooleanInput
          className={className}
          name={allowMonthlyBookingsName}
          type={type}
          label={allowMonthlyBookingsLabel}
          checked={allowMonthlyBookings}
          onChange={onAllowMonthlyBookingsChange}
        />
      </div>
      <Steps
        step={stepNumber}
        canContinue={!(allowOneTimeBookings || allowMonthlyBookings)}
        {...props}
      />
    </div>
  );
};

AllowBookings.propType = {
  allowOneTimeBookings: PropTypes.bool,
  allowWeeklyBookings: PropTypes.bool,
  allowMonthlyBookings: PropTypes.bool,
  onAllowOneTimeBookingsChange: PropTypes.func,
  onAllowWeeklyBookingsChange: PropTypes.func,
  onAllowMonthlyBookingsChange: PropTypes.func,
};

export default AllowBookings;
