import React from "react";
import ReportKebabButton from "../../components/report/ReportKebabButton";
import { MenuItem } from "@material-ui/core";
import ReportFlexRightContainer from "../../components/report/ReportFlexRightContainer";
import { useHistory } from "react-router-dom";
import { isRoleApproved, MANAGER_EDIT_ROLES } from "../../util/user/UserUtil";

const BookingReportKebabButton = (props) => {
  const {
    bookingId,
    userType,
    booking,
    setShow,
    setBookingId,
    setOrderNumber,
  } = props;
  const history = useHistory();

  const handleMoveZone = () => {
    history.push(`/booking/${bookingId}`);
  };

  const handleCancelBooking = () => {
    setShow(true);
    setBookingId(bookingId);
    setOrderNumber(booking?.orderNumber);
    //history.push(`/booking/${bookingId}/cancel`);
  };

  const handleCancelBookingSubscription = () => {
    history.push(`/booking/${bookingId}/cancelSubscription`);
  };

  const menuItems = [];

  if (isRoleApproved(MANAGER_EDIT_ROLES, userType)) {
    menuItems.push(
      <MenuItem key="cancel-booking" onClick={handleCancelBooking}>
        Cancel Booking
      </MenuItem>
    );
    if (booking.frequency === "RECURRING") {
      menuItems.push(
        <MenuItem
          key="cancel-booking-subscription"
          onClick={handleCancelBookingSubscription}
        >
          Cancel Booking Subscription
        </MenuItem>
      );
    }
    if (booking.locationId) {
      menuItems.push(
        <MenuItem key="change-booking-zone" onClick={handleMoveZone}>
          Change Booking's Zone
        </MenuItem>
      );
    }
  }

  return (
    <ReportFlexRightContainer>
      <ReportKebabButton>{menuItems}</ReportKebabButton>
    </ReportFlexRightContainer>
  );
};

export default BookingReportKebabButton;
