import React from 'react';
import * as PropType from "prop-types";

const MoneyDisplay = (props) => {
  const { dollarsAsLong } = props;
  return <span>${(dollarsAsLong/100).toFixed(2)}</span>
}

MoneyDisplay.propTypes = {
  dollarsAsLong: PropType.number.isRequired
}

export default MoneyDisplay;
