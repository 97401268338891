import React from 'react';
import Steps from "../../steps/Steps";
import PropTypes from "prop-types";
import SelectInput from "../../common/SelectInput";

const EquipmentLengths = (props) => {

  const {
    stepNumber,
    equipmentLengths,
    selectedEquipmentLength,
    onEquipmentLengthChange,
  } = props;

  return (
    <div>
      <SelectInput
        label={"Equipment Length: "}
        name={"Equipment Length: "}
        value={selectedEquipmentLength}
        onChange={onEquipmentLengthChange}
        items={equipmentLengths}
      />
      <Steps
        step={stepNumber}
        {...props}
      />
    </div>
  );
}

EquipmentLengths.propType = {
  stepNumber: PropTypes.number,
  equipmentLengths: PropTypes.array,
  selectedEquipmentLength: PropTypes.string,
  onEquipmentLengthChange: PropTypes.func
}

export default EquipmentLengths;