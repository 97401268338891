import React from "react";
import Select from "../../Select";

const DriverSelect = (props) => {
  const { drivers, handleDriverChange, selectedDriver, driverOptions } = props;

  const formatSelectedDriver = () => {
    return selectedDriver
      ? selectedDriver?.firstName +
          " " +
          selectedDriver?.lastName +
          " - " +
          selectedDriver?.carrier
      : "";
  };
  return (
    <div className="checkin-selector">
      <label>SELECT DRIVER</label>
      {drivers && driverOptions ? (
        <Select
          id="driverSelect"
          name="driverSelect"
          className="ss-book-space-form-asset-type"
          optionsWidth="300px"
          handleChange={handleDriverChange}
          selectedOption={formatSelectedDriver()}
          placeholder="Please select driver"
          options={driverOptions}
        />
      ) : null}
    </div>
  );
};

export default DriverSelect;
