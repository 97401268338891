import React from "react";
import Button from "./Button";
import classNames from "classnames";
import _ from "underscore";

export const ButtonStyles = Object.freeze({
  SOLID: "solid",
  OUTLINED: "outlined",
});

const buttonStyles = Object.freeze({
  [ButtonStyles.SOLID]: "ss-button-primary",
  [ButtonStyles.OUTLINED]: "ss-button-secondary",
});

const StyledButton = (props) => {
  const { style = ButtonStyles.SOLID, className, disabled, ...rest } = props;

  const styleClass = buttonStyles[style];

  if (_.isEmpty(styleClass)) {
    throw new Error("Style must be a valid option from ButtonStyles");
  }

  return (
    <Button
      disabled={disabled}
      className={classNames(styleClass, className)}
      {...rest}
    />
  );
};

export default StyledButton;
