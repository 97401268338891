import React from "react";
import SupplierInterchangeListItem from "./SupplierInterchangeListItem";
import ReportTitleGroupCounts from "../report/ReportTitleGroupCounts";
import { withGroupAndSortExportAndGroupings } from "../report/hocs/withGroupAndSortExportAndGroupings";

const SupplierInterchangeList = (props) => {
  const { supplierInterchanges, ...rest } = props;
  return (
    <>
      <ReportTitleGroupCounts
        dataLength={supplierInterchanges?.length}
        {...rest}
      />
      {supplierInterchanges?.map((supplierInterchange) => (
        <SupplierInterchangeListItem
          key={supplierInterchange.id}
          supplierInterchange={supplierInterchange}
        />
      ))}
    </>
  );
};

export default SupplierInterchangeList;
export const SupplierInterchangeListWithGroupAndSort =
  withGroupAndSortExportAndGroupings(
    SupplierInterchangeList,
    "supplierInterchanges"
  );
