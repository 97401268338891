import { useEffect, useState } from "react";
import { getLocations } from "../components/checkin/request/fine-request";
import { toast } from "react-toastify";
import { getErrorMessageForStandardResponse } from "./NetworkErrorUtil";

const useLocation = () => {
  const [locations, setLocations] = useState([]);
  const [value, setValue] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [locationId, setLocationId] = useState("");

  useEffect(() => {
    let mounted = true;
    getLocations()
      .then(
        (promise) => {
          if (mounted) {
            setLocations(promise.body);
          }
        },
        (error) => {
          toast.error(getErrorMessageForStandardResponse(error));
        }
      )
      .catch((error) => {
        toast.error(getErrorMessageForStandardResponse(error));
      });

    return () => (mounted = false);
  }, [setLocations]);

  const onChange = (location) => {
    const locationId = location?.locationName;
    setValue(location?.locationName);
    setLocationId(location?.id);
    if (locationId === "pleaseSelect") setButtonDisabled(true);
    else setButtonDisabled(false);
  };

  return {
    locations,
    value,
    buttonDisabled,
    setButtonDisabled,
    onChange,
    locationId,
    setValue,
  };
};

export default useLocation;
