import React from "react";
import PropTypes from "prop-types";

const FineNotesTextArea = (props) => {
  const { notes, onChange } = props;

  return (
    <div>
      <label>Notes: </label>
      <textarea
        className="form-control"
        name="notes"
        placeholder="Please add notes"
        value={notes}
        onChange={onChange}
        style={{ resize: "none" }}
      />
    </div>
  );
};

FineNotesTextArea.propTypes = {
  notes: PropTypes.string,
  onChange: PropTypes.func,
};

export default FineNotesTextArea;
