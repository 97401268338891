import React, { useState, useEffect } from "react";
import "../css/theme/mainContent.css";
import "../css/theme/forms.css";
import "../css/theme/forms-block.css";
import "../css/components/badge.css";
import "../css/views/accountReport.css";
import "../css/views/search.css";
import { CSVLink } from "react-csv";
import moment from "moment/moment";
import DataListResultsEntryRow from "./DataListResultsEntryRow";
import { formatCurrencyValue } from "../util/PaymentUtils";

const DataListResultsEntry = (props) => {
  const visibleRecordBatchSize = props.visibleRecordBatchSize || 20;

  const [recordsShowing, setRecordsShowing] = useState(
    props.entry[1].length >= visibleRecordBatchSize
      ? props.entry[1].slice(0, visibleRecordBatchSize)
      : props.entry[1]
  );
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    refreshVisibleRecords(props.entry);
  }, [props.entry, props.groupBy]);

  const refreshVisibleRecords = (entry) => {
    setRecordsShowing(
      entry[1].length >= visibleRecordBatchSize
        ? entry[1].slice(0, visibleRecordBatchSize)
        : entry[1]
    );
  };

  const fetchMoreData = () => {
    if (recordsShowing.length >= props.entry[1].length) {
      setHasMore(false);
      return;
    }

    const end = recordsShowing.length + visibleRecordBatchSize;
    setRecordsShowing(
      props.entry[1].length >= end
        ? props.entry[1].slice(0, end)
        : props.entry[1]
    );
  };

  const sum = (items, prop) => {
    return items.reduce((a, b) => a + b[prop], 0);
  };

  const buildReportsTitle = () => {
    if (props.accumulateLocations) {
      return `${props.entry[1].length} records`;
    }
    if (props.groupBy && props.groupBy.name) {
      return `No ${props.fieldLabels[props.groupBy.name]} Value`;
    } else {
      return `${props.entry[1].length} records`;
    }
  };

  const editData = (data) => {
    return data.filter(
      (dataItem) => dataItem.location !== "All Locations Combined"
    );
  };

  // const formatDate = (date) => moment(date, "MM-DD-YYYY HH:mm:ss").format("MM-DD-YYYY hh:mmA");

  const handleExcelData = () => {
    const formatDate = (date) => {
      return moment(date, "MM-DD-YYYY HH:mm:ss").format("MM-DD-YYYY hh:mmA");
    };

    const formatKeyName = (key) => {
      return key
        .replace(/_/g, " ") // Replace underscores with spaces
        .split(" ") // Split by space
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        ) // Capitalize each word
        .join(" "); // Join words back with spaces
    };

    const transformKeys = (obj) => {
      const transformedObj = {};
      for (const [key, value] of Object.entries(obj)) {
        const formattedKey = formatKeyName(key);
        if (formattedKey === "Check In") {
          transformedObj[formattedKey] = formatDate(value);
        } else {
          transformedObj[formattedKey] = value;
        }
      }
      return transformedObj;
    };

    const data = props.accumulateLocations
      ? editData(props.entry[1]).map((listItem) =>
          transformKeys({
            ...listItem.exportData,
          })
        )
      : props.entry[1].map((listItem) =>
          transformKeys({
            ...listItem.exportData,
          })
        );

    return data;
  };

  const createExportFileName = (groupByValue) => {
    const groupByPart = props.groupBy.name ? `${groupByValue}_` : "";
    const datePart = moment(new Date()).format("MM-DD-YYYY_HH_mm");
    return `${groupByPart}${props.dataType}_${datePart}.csv`;
  };

  const formatGroupSummaryFieldValue = (entry) => {
    if (entry.formatter) {
      return entry.formatter(props.entry[1]);
    } else if (entry.type === "NUMBER") {
      return sum(props.entry[1], entry.name);
    } else {
      return formatCurrencyValue(sum(props.entry[1], entry.name), true);
    }
  };

  return (
    <div>
      {props.entry[0] &&
      props.entry[0] !== "false" &&
      props.entry[0] !== "undefined" ? (
        <div className="reports_title">
          <h3>
            {props.entry[0]}
            {recordsShowing.length !== props.entry[1].length ? (
              <span className="ss-record-count">
                {" "}
                (showing {recordsShowing.length} of {props.entry[1].length}{" "}
                records)
              </span>
            ) : (
              <span className="ss-record-count">
                {" "}
                (showing all {props.entry[1].length} records in group)
              </span>
            )}
          </h3>
          {props.groupSummaryFields ? (
            <div style={{ marginTop: "20px", width: "300px" }}>
              {props.groupSummaryFields.map((entry, index) => (
                <div key={entry.id + "-datalistresultsentry-" + index}>
                  <div
                    style={{
                      display: "inline-block",
                      fontSize: "13px",
                      fontWeight: "400",
                      color: "#999999",
                    }}
                  >
                    Total {entry.label}:&nbsp;&nbsp;
                  </div>
                  <div
                    style={{
                      display: "inline-block",
                      fontSize: "15px",
                      padding: "3px",
                      fontWeight: "700",
                    }}
                  >
                    {formatGroupSummaryFieldValue(entry)}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        <div className="reports_title">
          <h3>
            {buildReportsTitle()}
            {recordsShowing.length !== props.entry[1].length ? (
              <span className="ss-record-count">
                {" "}
                (showing {recordsShowing.length} of {props.entry[1].length}{" "}
                records)
              </span>
            ) : (
              <span className="ss-record-count">
                {" "}
                (showing all records in group)
              </span>
            )}
          </h3>
          {props.groupSummaryFields ? (
            <div style={{ marginTop: "20px", width: "300px" }}>
              {props.groupSummaryFields.map((entry, index) => (
                <div key={entry.id + "-datalistresultsentry-" + index}>
                  <div
                    style={{
                      display: "inline-block",
                      fontSize: "13px",
                      fontWeight: "400",
                      color: "#999999",
                    }}
                  >
                    Total {entry.label}:&nbsp;
                  </div>
                  <div
                    style={{
                      display: "inline-block",
                      fontSize: "15px",
                      padding: "3px",
                      fontWeight: "700",
                    }}
                  >
                    {formatGroupSummaryFieldValue(entry)}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            ""
          )}
        </div>
      )}
      <div className="to_excel_container">
        {props.payOutGroupFunction ? (
          <a
            href="/#"
            onClick={(event) => {
              props.payOutGroupFunction(props.entry);
              event.preventDefault();
            }}
          >
            <button
              style={{ height: "35px", width: "135px", fontSize: "14px" }}
              type="button"
              className="ss-button-primary ss-cash-icon"
            >
              PAY OUT GROUP
            </button>
          </a>
        ) : (
          ""
        )}
        <CSVLink
          filename={createExportFileName(props.entry[0])}
          data={handleExcelData()}
          separator={","}
        >
          <button type="button" className="ss-button-primary ss-excel-icon">
            EXPORT TO EXCEL
          </button>
        </CSVLink>
      </div>
      <div className="report-container">
        {recordsShowing.map((item, index) => (
          <DataListResultsEntryRow
            key={item.id + "-dlrerow-" + index}
            item={item}
            reportFields={props.reportFields}
            columnWidth={props.columnWidth}
            actions={props.actions}
            accumulateLocations={props.accumulateLocations}
            searchedValue={props.searchedValue}
          />
        ))}
        {recordsShowing.length !== props.entry[1].length ? (
          <div className="ss-load-more-records">
            <a
              href="/#"
              className="ss-load-more-records-link"
              onClick={(event) => {
                fetchMoreData();
                event.preventDefault();
              }}
            >
              Show next{" "}
              {visibleRecordBatchSize <=
              props.entry[1].length - recordsShowing.length
                ? visibleRecordBatchSize
                : props.entry[1].length - recordsShowing.length}{" "}
              records ({props.entry[1].length - recordsShowing.length} not
              shown)
            </a>
          </div>
        ) : (
          <div className="ss-load-more-records">
            Showing all records in group
          </div>
        )}
      </div>
    </div>
  );
};

export default DataListResultsEntry;
