import request from "../../util/SuperagentUtils";

const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const requestCurrentUsername = (
  onSuccess?: (currentUsername: string) => void,
  onFail?: (err: Object) => void
): void | Promise => {
  const result = request.get(`${baseUrl}/api/current-username`);

  if (onSuccess && onFail) {
    result.then((resp) => onSuccess(resp.body)).catch((err) => onFail(err));
  } else {
    return result;
  }
};

export const requestLoggedInUserDetails = (
  onSuccess?: ({ username: string, userType: string }) => void,
  onFail?: (err) => void
): void | Promise => {
  const result = request.get(`${baseUrl}/api/user-details`).withCredentials();

  if (onSuccess && onFail) {
    result.then((resp) => onSuccess(resp.body)).catch((err) => onFail(err));
  } else {
    return result;
  }
};

export const requestLoggedInAuthoritySourceWithToken = (
  token: string
): void | Promise => {
  return request
    .get(`${baseUrl}/api/authority`)
    .auth(token, { type: "bearer" })
    .withCredentials();
};

export const requestLoggedInAuthoritySource = (
  onSuccess?: (authority: Object) => void,
  onFail?: (err: Object) => void
): void | Promise => {
  const result = request.get(`${baseUrl}/api/authority`).withCredentials();

  if (onSuccess && onFail) {
    result.then((resp) => onSuccess(resp.body)).catch((err) => onFail(err));
  } else {
    return result;
  }
};

export const requestLoggedInAccount = (
  onSuccess?: (account: Object) => void,
  onFail?: (err: Object) => void
): void | Promise => {
  const result = request.get(`${baseUrl}/api/account`).withCredentials();

  if (onSuccess && onFail) {
    result.then((resp) => onSuccess(resp.body)).catch((err) => onFail(err));
  } else {
    return result;
  }
};

export const requestLogout = (
  onSuccess?: (resp: Object) => void,
  onFail?: (err: Object) => void
): void | Promise => {
  const result = request.post(`${baseUrl}/api/logout`).withCredentials();

  if (onSuccess && onFail) {
    result.then((resp) => onSuccess(resp)).catch((err) => onFail(err));
  } else {
    return result;
  }
};
