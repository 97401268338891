import React from 'react';
import SelectInput from "../common/SelectInput";

const FrequencySelect = (props) => {

  const {
    frequencyTypes,
    selectedFrequency,
    onFrequencyChange,
  } = props;

  return (
    <SelectInput
      label={"Select Frequency: "}
      name={"Select Frequency: "}
      value={selectedFrequency}
      onChange={onFrequencyChange}
      items={frequencyTypes}
    />
  );
}

export default FrequencySelect;
