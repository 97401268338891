import React from "react";
import ReportData from "../report/ReportData";
import ReportDataRow from "../report/ReportDataRow";
import { formatBool, formatDate } from "../../util/booking/bookingUtil";
import { formatCurrencyValue } from "../../util/PaymentUtils";

const FineAssessment = (props) => {
  const { fineAssessment } = props;

  return (
    <div>
      <ReportDataRow>
        <ReportData label={"Booking Number"}>
          {fineAssessment?.bookingNumber}
        </ReportData>

        <ReportData label={"Fine Name"}>
          {fineAssessment?.fineType?.name}
        </ReportData>
        {fineAssessment.fineAmount ? (
          <ReportData label={"Fine Amount"}>
            {formatCurrencyValue(fineAssessment.fineAmount, true)}
          </ReportData>
        ) : (
          ""
        )}
        {fineAssessment.notes ? (
          <ReportData label={"Notes"}>{fineAssessment?.notes}</ReportData>
        ) : (
          ""
        )}
        <ReportData label={"Fine Date"}>
          {formatDate(fineAssessment?.createdOn)}
        </ReportData>
        <ReportData label={"Tow Away"}>
          {formatBool(fineAssessment?.towInventory)}
        </ReportData>
      </ReportDataRow>
    </div>
  );
};

export default FineAssessment;
