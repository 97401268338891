import React from "react";
import { useHistory } from "react-router";
import { toastErrorMessage } from "../../util/NetworkErrorUtil";
import Busy from "../../components/Busy";
import { requestCancelBooking } from "./requests/booking-requests";
import useBookingParam from "../../hooks/booking/useBookingParam";
import ConfirmDialogView from "../../components/ConfirmDialogView";
import { toast } from "react-toastify";

const BookingCancel = () => {
  const { booking } = useBookingParam();
  const history = useHistory();

  const handleCancelBooking = () => {
    Busy.set(true);
    requestCancelBooking(booking.id)
      .then(() => {
        Busy.set(false);
        toast.success("Booking cancelled successfully!");
        history.push(`/supplier-bookings`);
      }, toastErrorMessage)
      .catch(toastErrorMessage);
  };

  return (
    <ConfirmDialogView
      handleConfirm={handleCancelBooking}
      headerText={`Cancel Booking ${booking.orderNumber}`}
      backButtonText={"Go Back"}
    >
      Are you sure you want to cancel this booking?
    </ConfirmDialogView>
  );
};

export default BookingCancel;
