import React from "react";
import _ from "underscore";
import LocationSelectItem from "./LocationSelectItem";
import "../../css/components/selectInput.css";
const LocationSelect = (props) => {
  const { value, locations, onChange } = props;

  return (
    <div className="selectDownDiv">
      <label>
        Select A Location:
        <select value={value} onChange={onChange}>
          {_.map(locations, (location, idx) => {
            return <LocationSelectItem key={idx} location={location} />;
          })}
        </select>
      </label>
    </div>
  );
};

export default LocationSelect;
