import React, { useContext } from "react";
import { Route } from "react-router";
import Checkout from "../../views/thirdparty/checkout/Checkout";
import TestCheckout from "../../views/thirdparty/checkout/TestCheckout";
import ManagePayments from "../../views/thirdparty/manage-payments/ManagePayments";
import { AppContext } from "../../context/app-context";

const CheckoutContent = () => {
  const { user, loadingUserDetails } = useContext(AppContext);
  return [
    <Route path="/checkout/:reservationId" key="/checkout">
      <Checkout />
    </Route>,
    <Route path="/test/checkout" key="/test/checkout">
      <TestCheckout />
    </Route>,
    <Route path="/manage-payments" key="/manage-payments">
      <ManagePayments account={user} loading={loadingUserDetails} />
    </Route>,
  ];
};

export default CheckoutContent;
