import React from "react";
import ReservationConfirmationCard from "./ReservationConfirmationCard";
import Steps from "../steps/Steps";
import SolidButton from "../form/SolidButton";

const ReservationConfirmation = (props) => {
  const {
    stepNumber,
    powerAccessible,
    selectedEquipmentLength,
    numberOfSpaces,
    startDate,
    endDate,
    selectedFrequency,
    selectedDuration,
    selectedZone,
    price,
    saveReservationToApi,
    zonePriceOverride,
    zoneOverride,
    selectedZone1,
  } = props;

  return (
    <>
      <ReservationConfirmationCard
        powerAccessible={powerAccessible}
        numberOfSpaces={numberOfSpaces}
        selectedEquipmentLength={selectedEquipmentLength}
        selectedFrequency={selectedFrequency}
        selectedDuration={selectedDuration}
        startDate={startDate}
        endDate={endDate}
        selectedZone={selectedZone}
        price={price}
        zonePriceOverride={zonePriceOverride}
        zoneOverride={zoneOverride}
        selectedZone1={selectedZone1}
      />
      <hr />
      <div style={{ width: "35%", marginLeft: "33%" }}>
        <SolidButton label={"SAVE"} onClick={saveReservationToApi} />
      </div>
      <Steps step={stepNumber} {...props} />
    </>
  );
};

export default ReservationConfirmation;
