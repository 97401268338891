import React, { useEffect, useState } from "react";
import { getFineAssessment } from "../../../checkin/request/fine-request";
import ReportBackgroundContainer from "../../../report/ReportBackgroundContainer";
import "./FineAssessments.css";
import { toast } from "react-toastify";
import { getErrorMessageForStandardResponse } from "../../../../util/NetworkErrorUtil";
import Filter from "../../../../views/bookings/Filter";
import { FineAssessmentsListWithGroupAndSort } from "./FineAssessmentsList";
import ReportEmpty from "../../../report/ReportEmpty";
import Busy from "../../../Busy";

const FineAssessmentsContent = () => {
  const [fineAssessment, setFineAssessment] = useState(null);
  const [updateAssessment, setUpdateAssessment] = useState(null);
  const [searchBox, setSearchBox] = useState("");
  const [filteredData, setFilteredData] = useState();
  const [show, setShow] = useState(false);

  const handleAssessmentUpdate = (assessmentId) => {
    setUpdateAssessment(assessmentId);
  };

  useEffect(() => {
    Busy.set(true);
    getFineAssessment()
      .then(
        (promise) => {
          let data = promise.body;
          setFineAssessment(data);
          setFilteredData(data);
          Busy.set(false);
          setShow(true);
        },
        (error) => {
          Busy.set(false);
          setShow(true);
          toast(getErrorMessageForStandardResponse(error));
        }
      )
      .catch((error) => {
        Busy.set(false);
        setShow(true);
        toast(getErrorMessageForStandardResponse(error));
      });
  }, [updateAssessment, setUpdateAssessment]);

  const searchChangeHandler = (event) => {
    let value = event.target.value;
    setSearchBox(value);
    search(value);
  };

  const search = (value) => {
    let filtered = [];
    for (const item of fineAssessment) {
      value.split(" ").forEach((word) => {
        for (const element of Object.entries(item)) {
          if (
            element.toString().toLowerCase().indexOf(word.toLowerCase()) !== -1
          ) {
            filtered.push(item);
            break;
          }
        }
      });
    }
    setFilteredData(filtered);
  };

  const reportFields = Object.freeze([
    { label: "Location Name", name: "locationName" },
    { label: "Zone Name", name: "zoneName" },
    { label: "Company Name", name: "companyName" },
    { label: "Booking Number", name: "bookingNumber" },
    // { label: "Location Inventory ID", name: "locationInventoryId" },
    // { label: "Fine Amount", name: "fineAmount" },
    // {
    //   label: "Peak Pricing Additional Charge",
    //   name: "peakPricingAdditionalCharge",
    // },
    // { label: "Payment Processor Fee", name: "paymentProcessorFee" },
    { label: "Notes", name: "notes" },
    // { label: "Tow Inventory", name: "towInventory" },
    // { label: "Processed", name: "processed" },
    // { label: "Refunded", name: "refund" },
  ]);

  return (
    <ReportBackgroundContainer>
      <header>
        <ul className="breadcrumb">
          <li>FINES</li>
          <li>Fine Assessments</li>
        </ul>
        <h1 className="content-header-title">Fine Assessments</h1>
      </header>
      {!!filteredData && filteredData?.length > 0 && (
        <Filter
          searchBox={searchBox}
          searchChangeHandler={searchChangeHandler}
          placeHolder="Type any field to filter fine assesments result"
        />
      )}
      {!!filteredData && filteredData?.length > 0 ? (
        <>
          <FineAssessmentsListWithGroupAndSort
            fineAssessments={filteredData}
            onFineAssessmentUpdate={handleAssessmentUpdate}
            reportFields={reportFields}
          />
        </>
      ) : (
        show && <ReportEmpty />
      )}
    </ReportBackgroundContainer>
  );
};

export default FineAssessmentsContent;
