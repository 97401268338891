import React from "react";
import ExportCSVButton from "./ExportCSVButton";
import _ from "underscore";

export const withExportButton =
  (Component, dataProp = "data") =>
  (props) => {
    const {
      [dataProp]: data,
      grouped,
      sortedAndGroupedData,
      reportName,
    } = props;

    const exportData = () => {
      let data2Export = sortedAndGroupedData;
      if (grouped) {
        data2Export = _.flatten(
          sortedAndGroupedData.map((item) => item[dataProp])
        );
      }
      return (data2Export?.length && data2Export) || data;
    };
    function rearrangeObjectByNull(obj) {
      const entries = Object.entries(obj);
      const nonNullElements = entries.filter(([key, value]) => value !== null);
      const nullElements = entries.filter(([key, value]) => value === null);
      return nonNullElements.concat(nullElements);
    }
    const rearrangedObjectArray = exportData().map((ele) =>
      Object.fromEntries(rearrangeObjectByNull(ele))
    );
    return (
      <>
        <ExportCSVButton data={rearrangedObjectArray} reportName={reportName} />
        <Component {...props} />
      </>
    );
  };
