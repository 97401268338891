import React from "react";

const Filter = (props) => {
  const { searchBox, searchChangeHandler, placeHolder } = props;

  return (
    <form className="report-form newFormCol2">
      <div className="data-list-filter-container search-container">
        <fieldset className="trigger-click hs-field">
          <label>FILTER</label>
          <input
            type="text"
            id="searchBox"
            name="searchBox"
            value={searchBox}
            onChange={searchChangeHandler}
            placeholder={placeHolder}
          />
          <i
            className="fa fa-search"
            style={{ float: "right", marginTop: "0.25%", marginRight: "1%" }}
          />
        </fieldset>
      </div>
    </form>
  );
};

export default Filter;
