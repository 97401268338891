import React from "react";
import StepWizard from "react-step-wizard";
import SetFineType from "./SetFineType";
import FineStartEndDateAdd from "./FineStartEndDateAdd";
import FineInfractionAdd from "./FineInfractionAdd";
import PeakPricingWindowAdd from "./PeakPricingWindowAdd";
import StepWizardContainer from "../../stepWizard/StepWizardContainer";

const FineAdd = (props) => {
  const {
    fine,
    setFineType,
    addPeakPricingAdditionalCharge,
    addFineInfractions,
    addPeakPricingWindows,
    cancel,
    saveFineToApi,
    addStartDate,
    addEndDate,
    heading,
  } = props;

  return (
    <div className="container">
      <div>
        <h3>{heading}</h3>
        <StepWizardContainer>
          <StepWizard>
            <SetFineType
              setFineType={setFineType}
              fine={fine}
              cancel={cancel}
              stepNumber={1}
            />
            <FineStartEndDateAdd
              addStartDate={addStartDate}
              addEndDate={addEndDate}
              startDate={fine ? fine.startDate : ""}
              endDate={fine ? fine.endDate : ""}
              cancel={cancel}
              stepNumber={2}
            />
            <FineInfractionAdd
              addFineInfractions={addFineInfractions}
              infractions={fine ? fine.fineInfractions : []}
              cancel={cancel}
              stepNumber={3}
            />
            <PeakPricingWindowAdd
              fine={fine}
              addPeakPricingWindows={addPeakPricingWindows}
              addPeakPricingAdditionalCharge={addPeakPricingAdditionalCharge}
              peakPricingWindows={fine ? fine.peakPricingWindows : []}
              peakPricingAdditionalCharge={
                fine ? fine.peakPricingAdditionalCharge : 0
              }
              saveFineToApi={saveFineToApi}
              cancel={cancel}
              stepNumber={4}
            />
          </StepWizard>
        </StepWizardContainer>
      </div>
    </div>
  );
};

export default FineAdd;
