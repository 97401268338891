import React, { useEffect } from "react";
import Steps from "../../steps/Steps";
import BooleanInput from "../../common/BooleanInput";
import PropTypes from "prop-types";
import "./zones.css";

const AutoApprovedZone = (props) => {
  const { stepNumber, autoApproved, onAutoApprovedChange } = props;

  const className = "form-control orange";
  const type = "checkbox";
  const autoApprovedName = "Auto Approve Zone";
  const autoApprovedLabel = "Auto Approve Zone ";

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      <div className="powerAccessibleDiv">
        <BooleanInput
          className={className}
          name={autoApprovedName}
          type={type}
          label={autoApprovedLabel}
          checked={autoApproved}
          onChange={onAutoApprovedChange}
        />
      </div>
      <Steps step={stepNumber} {...props} />
    </>
  );
};

AutoApprovedZone.propType = {
  autoApproved: PropTypes.bool,
  onAutoApprovedChange: PropTypes.func,
};

export default AutoApprovedZone;
