import request from "../../util/SuperagentUtils";
const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const getRevenueByLocation = (accountId, locationId, requestDates) => {
  return request
    .get(
      `${baseUrl}/api/suppliers/${accountId}/location/${locationId}/transactions`
    )
    .query(requestDates)
    .withCredentials();
};

export const getRevenueForAllLocations = (accountId, requestDates) => {
  return request
    .get(`${baseUrl}/api/suppliers/${accountId}/transactions`)
    .query(requestDates)
    .withCredentials();
};
