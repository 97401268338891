import React from "react";
import PropTypes from "prop-types";
import BooleanInput from "../common/BooleanInput";
import ReportDataRow from "../report/ReportDataRow";
import ReportData from "../report/ReportData";
import { EquipmentTypeForm } from "../constants/securspace-constants";

const ShouldBeTowedCheckBox = (props) => {
  const {
    containerNumber,
    trailerNumber,
    chassisNumber,
    truckLicensePlateNumber,
    towed,
    sealNumber,
    chassisLicensePlateNumber,
    assetSize,
    driverLicenseNumber,
    onTowedChange,
    assetType,
  } = props;

  const getCheckInFormType = (value) => {
    switch (value) {
      case EquipmentTypeForm.CONTAINER_LOADED:
      case EquipmentTypeForm.TRUCK_CONTAINER_LOADED:
        return (
          <ReportDataRow>
            <ReportData label={"Container Number"}>
              {containerNumber ? containerNumber : "N/A"}
            </ReportData>
            <ReportData label={"Equipment Length"}>
              {assetSize ? assetSize : "N/A"}
            </ReportData>
            <ReportData label={"Chassis Number"}>
              {chassisNumber ? chassisNumber : "N/A"}
            </ReportData>
            <ReportData label={"Chassis License Plate Number"}>
              {chassisLicensePlateNumber ? chassisLicensePlateNumber : "N/A"}
            </ReportData>
            <ReportData label={"Seal Number"}>
              {sealNumber ? sealNumber : "N/A"}
            </ReportData>
            <ReportData label={"Truck License Plate Number"}>
              {truckLicensePlateNumber ? truckLicensePlateNumber : "N/A"}
            </ReportData>
          </ReportDataRow>
        );

      case EquipmentTypeForm.CONTAINER_EMPTY:
      case EquipmentTypeForm.TRUCK_CONTAINER_EMPTY:
        return (
          <ReportDataRow>
            <ReportData label={"Container Number"}>
              {containerNumber ? containerNumber : "N/A"}
            </ReportData>
            <ReportData label={"Equipment Length"}>
              {assetSize ? assetSize : "N/A"}
            </ReportData>
            <ReportData label={"Chassis Number"}>
              {chassisNumber ? chassisNumber : "N/A"}
            </ReportData>
            <ReportData label={"Chassis License Plate Number"}>
              {chassisLicensePlateNumber ? chassisLicensePlateNumber : "N/A"}
            </ReportData>
            <ReportData label={"Truck License Plate Number"}>
              {truckLicensePlateNumber ? truckLicensePlateNumber : "N/A"}
            </ReportData>
          </ReportDataRow>
        );
      case EquipmentTypeForm.CHASSIS_ONLY:
        return (
          <ReportDataRow>
            <ReportData label={"Equipment Length"}>
              {assetSize ? assetSize : "N/A"}
            </ReportData>
            <ReportData label={"Chassis Number"}>
              {chassisNumber ? chassisNumber : "N/A"}
            </ReportData>
            <ReportData label={"Chassis License Plate Number"}>
              {chassisLicensePlateNumber ? chassisLicensePlateNumber : "N/A"}
            </ReportData>
            <ReportData label={"Truck License Plate Number"}>
              {truckLicensePlateNumber ? truckLicensePlateNumber : "N/A"}
            </ReportData>
          </ReportDataRow>
        );
      case EquipmentTypeForm.REEFER_LOADED_PLUGIN:
      case EquipmentTypeForm.TRAILER_LOADED:
      case EquipmentTypeForm.TRUCK_TRAILER_LOADED:
      case EquipmentTypeForm.REEFER_LOADED_NO_PLUGIN:
      case EquipmentTypeForm.TRUCK_REEFER_LOADED_PLUGIN:
      case EquipmentTypeForm.TRUCK_REEFER_LOADED_NO_PLUGIN:
        return (
          <ReportDataRow>
            <ReportData label={"Equipment Length"}>
              {assetSize ? assetSize : "N/A"}
            </ReportData>
            <ReportData label={"Trailer Number"}>
              {trailerNumber ? trailerNumber : "N/A"}
            </ReportData>
            <ReportData label={"Seal Number"}>
              {sealNumber ? sealNumber : "N/A"}
            </ReportData>
            <ReportData label={"Driver License Number"}>
              {driverLicenseNumber ? driverLicenseNumber : "N/A"}
            </ReportData>
            <ReportData label={"Truck License Plate Number"}>
              {truckLicensePlateNumber ? truckLicensePlateNumber : "N/A"}
            </ReportData>
          </ReportDataRow>
        );
      case EquipmentTypeForm.TRUCK_TRAILER_EMPTY:
      case EquipmentTypeForm.TRAILER_EMPTY:
        return (
          <ReportDataRow>
            <ReportData label={"Equipment Length"}>
              {assetSize ? assetSize : "N/A"}
            </ReportData>
            <ReportData label={"Trailer Number"}>
              {trailerNumber ? trailerNumber : "N/A"}
            </ReportData>
            <ReportData label={"Truck License Plate Number"}>
              {truckLicensePlateNumber ? truckLicensePlateNumber : "N/A"}
            </ReportData>
          </ReportDataRow>
        );
      case EquipmentTypeForm.TRUCK_ONLY:
        return (
          <ReportDataRow>
            <ReportData label={"Truck License Plate Number"}>
              {truckLicensePlateNumber ? truckLicensePlateNumber : "N/A"}
            </ReportData>
          </ReportDataRow>
        );
      default:
        return (
          <ReportDataRow>
            <ReportData label={"Container Number"}>
              {containerNumber ? containerNumber : "N/A"}
            </ReportData>
            <ReportData label={"Trailer Number"}>
              {trailerNumber ? trailerNumber : "N/A"}
            </ReportData>
            <ReportData label={"Chassis Number"}>
              {chassisNumber ? chassisNumber : "N/A"}
            </ReportData>
            <ReportData label={"Truck License Plate Number"}>
              {truckLicensePlateNumber ? truckLicensePlateNumber : "N/A"}
            </ReportData>
          </ReportDataRow>
        );
    }
  };

  return (
    <div>
      <div style={{ marginLeft: "-36px" }}>{getCheckInFormType(assetType)}</div>
      <hr />
      <BooleanInput
        label={"Should Inventory be Towed ?"}
        className="form-control orange"
        type="checkbox"
        checked={towed}
        compName="fineAssess"
        onChange={onTowedChange}
      />
    </div>
  );
};

ShouldBeTowedCheckBox.propTypes = {
  containerNumber: PropTypes.string,
  trailerNumber: PropTypes.string,
  chassisNumber: PropTypes.string,
  truckLicensePlateNumber: PropTypes.string,
  checked: PropTypes.bool,
  onCheckedChange: PropTypes.func,
};

export default ShouldBeTowedCheckBox;
