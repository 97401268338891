import React from "react";
import ReportDataRow from "../../report/ReportDataRow";
import ReportData from "../../report/ReportData";
import {
  formatBool,
  formatDate,
  formatEquipmentLength,
} from "../../../util/booking/bookingUtil";

const ZoneCard = (props) => {
  const { zone } = props;

  return (
    <div>
      {zone ? (
        <ReportDataRow>
          <ReportData label={"Zone Name"}>{zone?.name || "-"}</ReportData>
          <ReportData label={"Total Spaces"}>
            {zone?.totalSpaces || "-"}
          </ReportData>
          <ReportData label={"Total Booked Spaces"}>
            {zone.numberOfBookedSpaces ? zone.numberOfBookedSpaces : "-"}
          </ReportData>
          <ReportData label={"Total Reserved Spaces"}>
            {zone.numberOfReservedSpaces ? zone.numberOfReservedSpaces : "-"}
          </ReportData>
          <ReportData label={"Current Available Spaces"}>
            {zone.currentAvailableSpaces ? zone.currentAvailableSpaces : "-"}
          </ReportData>
          <ReportData label={"Equipment Length"}>
            {zone.equipmentLength
              ? formatEquipmentLength(zone.equipmentLength)
              : "-"}
          </ReportData>
          <ReportData label={"Power Accessible"}>
            {formatBool(zone.powerAccessible)}
          </ReportData>
          <ReportData label={"Activation Date"}>
            {zone?.activationDate
              ? `${formatDate(zone.activationDate)} 12:00 AM`
              : "-"}
          </ReportData>
          <ReportData label={"Deactivation Date"}>
            {zone?.deactivationDate
              ? `${formatDate(zone.deactivationDate)} 11:59 PM`
              : "-"}
          </ReportData>
          <ReportData label={"Active"}>{formatBool(zone.active)}</ReportData>
          <ReportData label={"Price Per Day/One Time"}>
            {zone.pricePerDayInCents
              ? "$" + `${zone.pricePerDayInCents / 100}`
              : "-"}
          </ReportData>
          {/* <ReportData label={"Price Per Week"}>
            ${zone.pricePerWeekInCents / 100}
          </ReportData> */}
          <ReportData label={"Price Per Month/Recurring"}>
            {zone?.pricePerMonthInCents
              ? "$" + `${zone.pricePerMonthInCents / 100}`
              : "-"}
          </ReportData>
          <ReportData label={"Allow One Time Bookings"}>
            {formatBool(zone.allowOneTimeBookings)}
          </ReportData>
          {/* <ReportData label={"Allow Weekly Bookings"}>
            {formatBool(zone.allowWeeklyBookings)}
          </ReportData> */}
          <ReportData label={"Allow Recurring Bookings"}>
            {formatBool(zone.allowMonthlyBookings)}
          </ReportData>
          <ReportData label={"Auto Approved"}>
            {formatBool(zone.autoApproved)}
          </ReportData>
          <hr />
        </ReportDataRow>
      ) : (
        ""
      )}
    </div>
  );
};

export default ZoneCard;
