import React, {useState} from 'react'

const PeakPricingTimeSelect = (props) => {
    const {label, name, onChangeTime} = props

    const [hour, setHour] = useState('01')
    const [minute, setMinute] = useState('00')
    const [AMPM, setAMPM] = useState('AM')

    const formatDoubleDigits = (value) => {
        const number = Number(value);
        if (number < 10) {
            return `0${number}`
        } else {
            return value
        }
    }

    const mapOptions = ((array) =>  array.map((number) => {
        return (<option key={number} className='ss-add-fine-time-select-option' value={number}>{number}</option>)
    }))

    const hours = [...Array(12).keys()].map(i => formatDoubleDigits(i+1) )
    // minutes are in 15 minute intervals. all 60 minutes seemed excessive. -EL
    const minutes = ['00', '15', '30', '45']

    const formatTime = (incomingHour, incomingMinute, incomingAMPM) => {
        let formattedTime;
        if (incomingAMPM === 'PM') {
            const newHour = Number(incomingHour) + 12;
            formattedTime = `${newHour}:${incomingMinute}:00` 
        } else {
            formattedTime = `${incomingHour}:${incomingMinute}:00`
        }
        return formattedTime;
    }

    const onChangeHour = (event) => {
        const value = event.target.value;
        setHour(value);
        onChangeTime(name, formatTime(value, minute, AMPM))
    }
    const onChangeMinute = (event) => {
        const value = event.target.value;
        setMinute(value)
        onChangeTime(name, formatTime(hour, value, AMPM))
    }
    const onChangeAMPM = (event) => {
        const value = event.target.value;
        setAMPM(value);
        onChangeTime(name, formatTime(hour, minute, value))
    }

    return(
        <div className='ss-add-fine-time-select-block'>
            <label htmlFor='selectTime'>{label}</label>
            <div name='selectTime' className='ss-fine-time-select-parent'>
                <select 
                    className='ss-add-fine-time-select'
                    onChange={onChangeHour}  >
                        {mapOptions(hours)}
                </select>
                <select 
                    className='ss-add-fine-time-select'
                    onChange={onChangeMinute}  >
                        {mapOptions(minutes)}
                </select>
                <select 
                    className='ss-add-fine-time-select'
                    onChange={onChangeAMPM}  >
                        <option value={'AM'}>AM</option>
                        <option value={'PM'}>PM</option>
                </select>
            </div>
        </div>
    )
}

export default PeakPricingTimeSelect;