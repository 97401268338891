import React from "react";
import "../../css/views/login.css";
import "../../css/theme/mainContent.css";
import "../../css/theme/forms.css";
import "../../css/theme/forms-block.css";
import "../../css/theme/buttons.css";
import "../../css/views/loginAsAccount.css";
import Select from "../Select";

const SelectBuyerAccount = (props) => {
  const { buyerAccounts, selectedBuyerAccount, handleBuyerAccountChange } =
    props;

  return (
    <div className="ss-login-as-container">
      <div id="ssLoginFormContainer">
        <h2>Select Buyer Account</h2>
        <form
          className="ss-form ss-block"
          autoComplete="off"
          style={{ width: "190%" }}
        >
          <fieldset className="ss-stand-alone">
            <label>SELECT BUYER ACCOUNT</label>
            {buyerAccounts && buyerAccounts.length > 0 ? (
              <Select
                name="selectBuyer"
                id="selectBuyer"
                handleChange={handleBuyerAccountChange}
                className="ss-account-select"
                selectedOption={selectedBuyerAccount}
                placeholder="Please select"
                options={buyerAccounts?.sort((a, b) =>
                  a.displayValue.localeCompare(b.displayValue)
                )}
                canSearch="1"
              />
            ) : (
              ""
            )}
          </fieldset>
        </form>
      </div>
    </div>
  );
};

export default SelectBuyerAccount;
