import React from 'react';
import useFineAddLogic from "./UseFineAddLogic";
import FineAdd from "./FineAdd";

const FineAddMenu = () => {

  const {
    setFineType,
    addLocationId,
    addPeakPricingAdditionalCharge,
    addFineInfractions,
    addPeakPricingWindows,
    cancel,
    saveFineToApi,
    addStartDate,
    addEndDate,
    toggleEditMode,
    editMode,
    fine,
    setFine,
  } = useFineAddLogic();

  return (
    <div>
      <FineAdd
        heading={"CREATE FINE"}
        setFineType={setFineType}
        addLocationId={addLocationId}
        addPeakPricingAdditionalCharge={addPeakPricingAdditionalCharge}
        addFineInfractions={addFineInfractions}
        addPeakPricingWindows={addPeakPricingWindows}
        cancel={cancel}
        saveFineToApi={saveFineToApi}
        addStartDate={addStartDate}
        addEndDate={addEndDate}
        fine={fine}
        setFine={setFine}
        editMode={editMode}
        toggleEditMode={toggleEditMode}
      />
    </div>
  );
}

export default FineAddMenu;