import React from "react";
import Steps from "../steps/Steps";
import ShouldBeTowedCheckBox from "./ShouldBeTowedCheckBox";
import PropTypes from "prop-types";
import SolidButton from "../form/SolidButton";

const ShouldBeTowed = (props) => {
  const {
    onTowedChange,
    towed,
    containerNumber,
    trailerNumber,
    chassisNumber,
    truckLicensePlateNumber,
    sealNumber,
    chassisLicensePlateNumber,
    stepNumber,
    saveAssessmentToAPI,
    assetType,
    driverLicenseNumber,
    assetSize,
  } = props;

  return (
    <div>
      <ShouldBeTowedCheckBox
        containerNumber={containerNumber}
        trailerNumber={trailerNumber}
        chassisNumber={chassisNumber}
        truckLicensePlateNumber={truckLicensePlateNumber}
        chassisLicensePlateNumber={chassisLicensePlateNumber}
        sealNumber={sealNumber}
        assetSize={assetSize}
        onTowedChange={onTowedChange}
        towed={towed}
        driverLicenseNumber={driverLicenseNumber}
        assetType={assetType}
      />
      <hr />
      <div style={{ display: "flex" }}>
        <Steps step={stepNumber} {...props} type="fineNote" />
        <SolidButton
          label={"SAVE"}
          onClick={saveAssessmentToAPI}
          compType="accessFine"
        />
      </div>
    </div>
  );
};

ShouldBeTowed.propTypes = {
  containerNumber: PropTypes.string,
  stepNumber: PropTypes.number,
  saveAssessmentToAPI: PropTypes.func,
};

export default ShouldBeTowed;
