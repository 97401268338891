import Option from "./Option";

class ZoneRefOption extends Option {
  constructor(zoneRef) {
    super(zoneRef.id);
    this.displayValue =
      !!zoneRef?.locationName && zoneRef.currentAvailableSpaces > 0
        ? `${zoneRef.locationName} 
       - ${zoneRef.name}
       - Available Spaces: ${zoneRef.currentAvailableSpaces} 
       `
        : "";
  }

  getDisplayValue() {
    return this.displayValue;
  }
}

export { ZoneRefOption };
