import React, { useEffect, useState } from "react";
import StepWizard from "react-step-wizard";
import { useHistory } from "react-router-dom";
import useLocation from "../../../util/UseLocation";
import CoordinatesBuilder from "./CoordinatesBuilder";
import LocationIdSelect from "../../fines/LocationIdSelect";
import ZoneNameNumberSpacesPowerStep from "./ZoneNameNumberSpacesPowerStep";
import EquipmentLengths from "./EquipmentLengths";
import ActivationDates from "./ActivationDates";
import Price from "./Price";
import AllowBookings from "./AllowBookings";
import ViewZone from "./ViewZone";
import { zoneCreate } from "../zoneRequest";
import { toast } from "react-toastify";
import { getErrorMessageForStandardResponse } from "../../../util/NetworkErrorUtil";
import { withRouter } from "react-router";
import AutoApprovedZone from "./AutoApprovedZone";
import StepWizardContainer from "../../stepWizard/StepWizardContainer";
import { formatDate } from "../../../util/DateUtils";

const ZoneCreator = (props) => {
  const [zone] = useState(
    props.location.state && props.location.state.zone
      ? props.location.state.zone
      : null
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const { locations, buttonDisabled, onChange, locationId } = useLocation();

  const [zoneName, setZoneName] = useState(zone ? zone.name : "");
  const [totalSpaces, setTotalSpaces] = useState(zone ? zone.totalSpaces : 1);
  const [powerAccessible, setPowerAccessible] = useState(
    zone ? zone.powerAccessible : false
  );
  const [equipmentLengths] = useState([
    { label: "25' MAX (Truck Only)", value: "TWENTY_FIVE_FOOT" },
    { label: "45' MAX (Small Container)", value: "FORTY_FIVE_FOOT" },
    { label: "53' MAX (Large Container)", value: "FIFTY_THREE_FOOT" },
    { label: "83' MAX (Truck + Trailer)", value: "EIGHTY_THREE_FOOT" },
  ]);
  const [selectedEquipmentLength, setSelectedEquipmentLength] = useState(
    zone ? zone.equipmentLength : equipmentLengths[0].value
  );

  const [active, setActive] = useState(zone ? zone.active : false);
  const [activationDate, setActivationDate] = useState(
    zone && zone.activationDate ? zone.activationDate : "MM/dd/yyyy"
  );
  const [deactivationDate, setDeactivationDate] = useState(
    zone && zone.deactivationDate ? zone.deactivationDate : "MM/dd/yyyy"
  );

  const [pricePerDayInCents, setPricePerDayInCents] = useState(
    zone ? zone.pricePerDayInCents : 100
  );
  const [pricePerWeekInCents, setPricePerWeekInCents] = useState(
    zone ? zone.pricePerWeekInCents : 100
  );
  const [pricePerMonthInCents, setPricePerMonthInCents] = useState(
    zone ? zone.pricePerMonthInCents : 100
  );

  const [allowOneTimeBookings, setAllowOneTimeBookings] = useState(
    zone ? zone.allowOneTimeBookings : true
  );
  const [allowWeeklyBookings, setAllowWeeklyBookings] = useState(
    zone ? zone.allowWeeklyBookings : true
  );
  const [allowMonthlyBookings, setAllowMonthlyBookings] = useState(
    zone ? zone.allowMonthlyBookings : true
  );

  const [autoApproved, setAutoApproved] = useState(
    zone ? zone.autoApproved : false
  );

  const [coordinates, setCoordinates] = useState(zone ? zone.coordinates : []);

  let history = useHistory();

  const onZoneNameChange = (event) => {
    setZoneName(event.target.value);
  };

  const onTotalSpacesChange = (event) => {
    const { value } = event.target;
    const numValue = Number.parseInt(value);
    if (value === "") {
      setTotalSpaces(value);
    } else if (numValue > -1) {
      setTotalSpaces(numValue);
    }
  };

  const onPowerAccessibleChange = () => {
    setPowerAccessible(!powerAccessible);
  };

  const onEquipmentLengthChange = (event) => {
    setSelectedEquipmentLength(event.target.value);
  };

  const onActiveChange = () => {
    setActive(!active);
  };

  const onActivationDateChange = (event) => {
    setActivationDate(event.target.value);
  };

  const onDeactivationDateChange = (event) => {
    setDeactivationDate(event.target.value);
  };

  const onPricePerDateInCentsChange = (event) => {
    const { value } = event.target;
    const numValue = Number(value);
    if (value === "" || numValue > 0) {
      setPricePerDayInCents(numValue * 100);
    }
  };

  const onPricePerWeekInCentsChange = (event) => {
    const { value } = event.target;
    const numValue = Number(value);
    if (value === "" || numValue > 0) {
      setPricePerWeekInCents(numValue * 100);
    }
  };

  const onPricePerMonthInCentsChange = (event) => {
    const { value } = event.target;
    const numValue = Number(value);
    if (value === "" || numValue > 0) {
      setPricePerMonthInCents(numValue * 100);
    }
  };

  const onAllowOneTimeBookingsChange = () => {
    setAllowOneTimeBookings(!allowOneTimeBookings);
  };

  const onAllowWeeklyBookingsChange = () => {
    setAllowWeeklyBookings(!allowWeeklyBookings);
  };

  const onAllowMonthlyBookingsChange = () => {
    setAllowMonthlyBookings(!allowMonthlyBookings);
  };

  const onAutoApprovedChange = () => {
    setAutoApproved(!autoApproved);
  };

  const onCoordinatesChange = (coordinates) => {
    let coordinatesArray = [];
    for (let coordinatesKey in coordinates) {
      const ZoneCoordinates = {
        order: Number(coordinatesKey),
        latitude: coordinates[coordinatesKey].lat.toString(),
        longitude: coordinates[coordinatesKey].lng.toString(),
      };
      coordinatesArray.push(ZoneCoordinates);
    }
    setCoordinates(coordinatesArray);
  };

  const cancel = () => {
    history.goBack();
  };

  const buildLocationZone = () => {
    return {
      name: zoneName,
      locationId: locationId,
      totalSpaces: totalSpaces,
      powerAccessible: powerAccessible,
      equipmentLength: selectedEquipmentLength,
      active: active,
      activationDate:
        activationDate === "MM/dd/yyyy" ? null : formatDate(activationDate),
      deactivationDate:
        deactivationDate === "MM/dd/yyyy" ? null : formatDate(deactivationDate),
      pricePerDayInCents: pricePerDayInCents,
      pricePerWeekInCents: pricePerWeekInCents,
      pricePerMonthInCents: pricePerMonthInCents,
      allowOneTimeBookings: allowOneTimeBookings,
      allowWeeklyBookings: allowWeeklyBookings,
      allowMonthlyBookings: allowMonthlyBookings,
      autoApproved: autoApproved,
      coordinates: coordinates,
    };
  };

  const convertZoneCoordinates = () => {
    let zone = buildLocationZone();
    if (zone.coordinates.length > 1) {
      for (let coordinates of zone.coordinates) {
        coordinates.latitude = Number(coordinates.latitude);
        coordinates.longitude = Number(coordinates.longitude);
      }
      return zone;
    }
  };

  const findLocationById = (locationId) => {
    return locations.find((location) => location.id === locationId);
  };

  const getOtherZonesByLocationId = (locationId) => {
    const location = findLocationById(locationId);
    const zones = location?.locationZones ? location.locationZones : [];
    // exclude drawing polygon for edited zone
    if (zone && zone.id) {
      return zones.filter((otherZone) => otherZone.id !== zone.id);
    }
    return zones;
  };

  const saveToApi = () => {
    zoneCreate(buildLocationZone())
      .then(
        () => {
          toast.success("Zone Saved Successfully");
          history.goBack();
        },
        (error) => {
          toast.error(getErrorMessageForStandardResponse(error));
        }
      )
      .catch((error) => {
        toast.error(getErrorMessageForStandardResponse(error));
      });
  };

  return (
    <div className="container">
      <h3>ZONE BUILDER</h3>
      <StepWizardContainer>
        <StepWizard>
          <LocationIdSelect
            locations={locations}
            onChange={onChange}
            stepNumber={1}
            buttonDisabled={buttonDisabled}
            cancel={cancel}
            comp="zoneInputWidth"
          />
          <ZoneNameNumberSpacesPowerStep
            zoneName={zoneName}
            onZoneNameChange={onZoneNameChange}
            totalSpaces={totalSpaces}
            onTotalSpacesChange={onTotalSpacesChange}
            powerAccessible={powerAccessible}
            onPowerAccessibleChange={onPowerAccessibleChange}
            buttonDisabled={buttonDisabled}
            canContinue={!(totalSpaces !== "" && totalSpaces > 0 && !!zoneName)}
            stepNumber={2}
            cancel={cancel}
          />
          <EquipmentLengths
            equipmentLengths={equipmentLengths}
            selectedEquipmentLength={selectedEquipmentLength}
            onEquipmentLengthChange={onEquipmentLengthChange}
            buttonDisabled={buttonDisabled}
            stepNumber={3}
            cancel={cancel}
          />
          <ActivationDates
            active={active}
            onActiveChange={onActiveChange}
            activationDate={activationDate}
            onActivationDateChange={onActivationDateChange}
            deactivationDate={deactivationDate}
            onDeactivationDateChange={onDeactivationDateChange}
            buttonDisabled={buttonDisabled}
            stepNumber={4}
            cancel={cancel}
          />
          <Price
            pricePerDayInCents={pricePerDayInCents}
            pricePerWeekInCents={pricePerWeekInCents}
            pricePerMonthInCents={pricePerMonthInCents}
            onPricePerDateInCentsChange={onPricePerDateInCentsChange}
            onPricePerWeekInCentsChange={onPricePerWeekInCentsChange}
            onPricePerMonthInCentsChange={onPricePerMonthInCentsChange}
            allowOneTimeBookings={allowOneTimeBookings}
            allowWeeklyBookings={allowWeeklyBookings}
            allowMonthlyBookings={allowMonthlyBookings}
            buttonDisabled={buttonDisabled}
            stepNumber={5}
            cancel={cancel}
          />
          <AllowBookings
            allowOneTimeBookings={allowOneTimeBookings}
            allowWeeklyBookings={allowWeeklyBookings}
            allowMonthlyBookings={allowMonthlyBookings}
            onAllowOneTimeBookingsChange={onAllowOneTimeBookingsChange}
            onAllowWeeklyBookingsChange={onAllowWeeklyBookingsChange}
            onAllowMonthlyBookingsChange={onAllowMonthlyBookingsChange}
            pricePerDayInCents={pricePerDayInCents}
            pricePerWeekInCents={pricePerWeekInCents}
            pricePerMonthInCents={pricePerMonthInCents}
            buttonDisabled={buttonDisabled}
            stepNumber={6}
            cancel={cancel}
          />
          <AutoApprovedZone
            autoApproved={autoApproved}
            onAutoApprovedChange={onAutoApprovedChange}
            buttonDisabled={buttonDisabled}
            stepNumber={7}
            cancel={cancel}
          />
          <CoordinatesBuilder
            coordinates={coordinates}
            otherZones={getOtherZonesByLocationId(locationId)}
            location={findLocationById(locationId)}
            onCoordinatesChange={onCoordinatesChange}
            buttonDisabled={buttonDisabled}
            stepNumber={8}
            cancel={cancel}
          />
          <ViewZone
            zone={convertZoneCoordinates()}
            buttonDisabled={buttonDisabled}
            saveToApi={saveToApi}
            stepNumber={9}
            cancel={cancel}
          />
        </StepWizard>
      </StepWizardContainer>
    </div>
  );
};

export default withRouter(ZoneCreator);
