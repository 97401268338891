import React, { useContext, useState } from "react";
import "../css/views/landing.css";
import "../css/theme/common.css";
import { Helmet } from "react-helmet";
import { AppContext } from "../context/app-context";
import { useHistory, Redirect } from "react-router";
import { getLandingRedirectPathForUser } from "../routing/route-utils";
import Login from "./Login";
import launcher from "../img/launcher.png";
import ForgotPassword from "./ForgotPassword";

const $ = window.$;

const Landing = (props) => {
  const { user, updateUser, redirectPath, history } = props;

  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [resetPasswordSuccessful, setResetPasswordSuccessful] = useState(false);

  $(document).delegate(".trigger-click", "click", function () {
    let element = $(this).find("input");
    $(element[0]).focus();
  });

  const ConditionalForm = () => {
    return (
      <div className="ss-login-conditional-form">
        {!showForgotPassword ? (
          <Login
            account={user}
            handleAccountChange={(newUser) => updateUser(newUser, history)}
            setShowForgotPassword={setShowForgotPassword}
          />
        ) : (
          <ForgotPassword
            setShowForgotPassword={setShowForgotPassword}
            resetSuccessful={setResetPasswordSuccessful}
          />
        )}
      </div>
    );
  };

  let content;

  if (user) {
    content = <Redirect to={redirectPath} />;
  } else {
    content = (
      <div id="ssLanding" className="ss-main ss-horizontal">
        <Helmet>
          <title>Port of Oakland | Smart Parking System</title>
          <meta name="keywords" content="truck parking oakland drayage" />
          <meta
            name="description"
            content="Port of Oakland drayage parking and storage"
          />
        </Helmet>

        <header
          style={{
            backgroundColor: "whitesmoke",
            display: "grid",
            gridTemplateColumns: "10% 40% 50%",
          }}
        >
          <div></div>

          <img
            className="login-logo"
            src={launcher}
            alt="oakland portal welcome. reads, 'i love oakland.'"
          />
          <div className={"d-flex flex-column ss-login-form-container"}>
            <ConditionalForm />
          </div>
        </header>
      </div>
    );
  }

  return content;
};

const ContextualLandingWrapper = (props) => {
  const { user, loadingUserDetails, updateUser } = useContext(AppContext);
  const redirectPath = getLandingRedirectPathForUser(user);

  const history = useHistory();

  return (
    <Landing
      {...props}
      user={user}
      updateUser={updateUser}
      loadingUserDetails={loadingUserDetails}
      history={history}
      redirectPath={redirectPath}
    />
  );
};

export default ContextualLandingWrapper;
