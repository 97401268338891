import React, { useState } from "react";
import { MenuItem } from "@material-ui/core";
import ReportFlexRightContainer from "../../report/ReportFlexRightContainer";
import ReportKebabButton from "../../report/ReportKebabButton";
import ConfirmDialog from "../../ConfirmDialog";
import Busy from "../../Busy";
import { toast } from "react-toastify";

const FineMenu = (props) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const { fine, editFine, deleteFine } = props;

  const MyOptions = {
    EDIT: "Edit Fine",
    REMOVE: "Delete Fine",
  };

  const handleMenuOption = (option, fine) => {
    if (option === MyOptions.EDIT) {
      editFine(fine);
    }

    if (option === MyOptions.REMOVE) {
      setShowDeleteConfirmation(true);
    }
  };
  const deleteFinePopUp = async (fine) => {
    Busy.set(false);
    await deleteFine(fine);
    toast.success("Fine deleted successfully!", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  const cancelDeleteProcess = () => {
    Busy.set(false);
    setShowDeleteConfirmation(false);
  };

  return (
    <>
      <ReportFlexRightContainer>
        <ReportKebabButton comp="editFine">
          <MenuItem
            key={MyOptions.EDIT}
            onClick={() => handleMenuOption(MyOptions.EDIT, fine)}
          >
            {MyOptions.EDIT}
          </MenuItem>
          <MenuItem
            key={MyOptions.REMOVE}
            onClick={() => handleMenuOption(MyOptions.REMOVE, fine)}
          >
            {MyOptions.REMOVE}
          </MenuItem>
        </ReportKebabButton>
      </ReportFlexRightContainer>
      <ConfirmDialog
        showAlert={showDeleteConfirmation}
        title="Delete Fine"
        onClose={() => cancelDeleteProcess()}
        proceedEventHandler={() => deleteFinePopUp(fine)}
      >
        Are you sure you want to delete this fine?
      </ConfirmDialog>
    </>
  );
};

export default FineMenu;
