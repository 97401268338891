import React from "react";
import PropTypes from "prop-types";
import { ArrowDropDown } from "@material-ui/icons";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";

const MultiSelectSearch = ({
  label,
  selectedOptionNames = [],
  placeholder,
  onChange,
  onFocus,
  isDisabled,
  dropDown,
  isClosed,
}) => {
  const displayPlaceholder =
    selectedOptionNames.length > 0
      ? selectedOptionNames.join(", ")
      : placeholder;

  const inputClassName =
    selectedOptionNames.length > 0 ? "text" : "placeholder";

  return (
    <div className={`multi-select-search ${isDisabled ? "disabled" : ""}`}>
      <label>{label}</label>
      <div
        className="multi-select-input"
        onClick={!isDisabled ? onFocus : undefined}
      >
        <input
          type="text"
          placeholder={selectedOptionNames.length === 0 ? placeholder : "abc"}
          value={displayPlaceholder}
          readOnly
          disabled={isDisabled}
          onChange={onChange}
          className={`read-only ${inputClassName}`}
        />
        {dropDown === "dropdownIcon" &&
          (!!isClosed ? (
            <ArrowDropDown
              style={{
                position: "absolute",
                right: "8px",
                marginTop: "18px",
                transform: "translateY(-50%)",
                cursor: "pointer",
              }}
            />
          ) : (
            <ArrowDropUpIcon
              style={{
                position: "absolute",
                right: "8px",
                marginTop: "18px",
                transform: "translateY(-50%)",
                cursor: "pointer",
              }}
            />
          ))}
      </div>
    </div>
  );
};

MultiSelectSearch.propTypes = {
  label: PropTypes.string.isRequired,
  selectedOptionNames: PropTypes.array,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  dropDown: PropTypes.string.isRequired,
};

MultiSelectSearch.defaultProps = {
  placeholder: "View all",
  isDisabled: false,
};

export default MultiSelectSearch;
