import { toast } from "react-toastify";
import request from "../../../util/SuperagentUtils";
import { UserType, AuthorityType } from "../../constants/securspace-constants";

const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const requestPaymentMethods = (
  id: String,
  userType: String,
  onSuccess: Function,
  onFail: Function
) => {
  if (userType === UserType.THIRD_PARTY_ADMIN) {
    requestPaymentMethodsForThirdPartyId(id, onSuccess, onFail);
  } else {
    requestPaymentMethodsForAccountId(id, onSuccess, onFail);
  }
};

export const requestPaymentMethodsForAccountId = (
  accountId: String,
  onSuccess: (paymentMethods: Array<Object>) => void,
  onFail: (err: Object) => void
) => {
  if (accountId) {
    request
      .get(`${baseUrl}/api/payment-method`)
      .query({ accountId })
      .withCredentials()
      .then((resp) => onSuccess(resp.body))
      .catch((err) => onFail(err));
  }
};

export const requestPaymentMethodsForThirdPartyId = (
  thirdPartyId: String,
  onSuccess: (paymentMethods: Array<Object>) => void,
  onFail: (err: Object) => void
) => {
  if (thirdPartyId) {
    request
      .get(`${baseUrl}/api/third-party/${thirdPartyId}/payment-method`)
      .withCredentials()
      .then((resp) => onSuccess(resp.body))
      .catch((err) => onFail(err));
  }
};

export const requestDeactivatePaymentMethod = (
  authorityId: String,
  paymentMethodId: String,
  userType: String,
  onSuccess: (resp: Object) => void,
  onFail: (err: Object) => void
) => {
  if (userType === UserType.THIRD_PARTY_ADMIN) {
    requestDeactivateThirdPartyPaymentMethod(
      authorityId,
      paymentMethodId,
      onSuccess,
      onFail
    );
  } else {
    requestDeactivateAccountPaymentMethod(
      authorityId,
      paymentMethodId,
      onSuccess,
      onFail
    );
  }
};

export const requestDeactivateAccountPaymentMethod = (
  accountId: String,
  paymentMethodId: String,
  onSuccess: (resp: Object) => void,
  onFail: (err: Object) => void
) => {
  if (accountId && paymentMethodId) {
    request
      .post(`${baseUrl}/api/payment-method/deactivate`)
      .send({
        id: paymentMethodId,
        accountId,
      })
      .withCredentials()
      .then((resp) => onSuccess(resp))
      .catch((err) => onFail(err));
  }
};

export const requestDeactivateThirdPartyPaymentMethod = (
  thirdPartyId: String,
  paymentMethodId: String,
  onSuccess: (resp: Object) => void,
  onFail: (err: Object) => void
) => {
  if (thirdPartyId && paymentMethodId) {
    request
      .delete(
        `${baseUrl}/api/third-party/${thirdPartyId}/payment-method/${paymentMethodId}`
      )
      .withCredentials()
      .then((resp) => onSuccess(resp))
      .catch((err) => onFail(err));
  }
};

export const requestRenamePaymentMethod = (
  data: { authorityId: String, paymentMethodId: String, nickName: String },
  userType: String,
  onSuccess: (resp: Object) => void,
  onFail: (err: Object) => void
) => {
  if (userType === UserType.THIRD_PARTY_ADMIN) {
    requestRenameThirdPartyPaymentMethod(
      { ...data, thirdPartyId: data.authorityId },
      onSuccess,
      onFail
    );
  } else {
    requestRenameAccountPaymentMethod(
      { ...data, accountId: data.authorityId },
      onSuccess,
      onFail
    );
  }
};

export const requestRenameAccountPaymentMethod = (
  data: { accountId: String, paymentMethodId: String, nickName: String },
  onSuccess: (resp: Object) => void,
  onFail: (err: Object) => void
) => {
  const { accountId, paymentMethodId, nickName } = data || {};

  if (accountId && paymentMethodId) {
    request
      .post(`${baseUrl}/api/payment-method/rename`)
      .withCredentials()
      .send({
        id: paymentMethodId,
        accountId,
        nickName,
      })
      .then((resp) => onSuccess(resp))
      .catch((err) => onFail(err));
  }
};

export const requestRenameThirdPartyPaymentMethod = (
  data: { id: String, nickNameEdit: String },
  onSuccess: (resp: Object) => void,
  onFail: (err: Object) => void
) => {
  const { id, nickNameEdit } = data || {};

  if (id) {
    const result = request
      .post(`${baseUrl}/api/payment-method/rename`)
      .withCredentials()
      .send({
        id: id,
        nickName: nickNameEdit,
      });
    if (onSuccess) {
      result.then(() => onSuccess()).catch((err) => onFail(err));
    } else if (onFail) {
      toast.error("Internal server error");
    } else {
      return result;
    }
  }
};

export const requestToken = (
  authorityId: String,
  userType: String,
  onSuccess: (token: String) => void,
  onFail: (err: Object) => void
) => {
  if (userType === UserType.THIRD_PARTY_ADMIN) {
    requestTokenForThirdParty(authorityId, onSuccess, onFail);
  } else {
    requestTokenForAccount(authorityId, onSuccess, onFail);
  }
};

export const requestTokenForAccount = (
  accountId: String,
  onSuccess: (token: Object) => void,
  onFail: (err: Object) => void
) => {
  request
    .get(`${baseUrl}/api/payment-method/token`)
    .query({
      accountId,
    })
    .withCredentials()
    .then((resp) => onSuccess(resp.body.token))
    .catch((err) => onFail(err));
};

export const requestTokenForThirdParty = (
  thirdPartyId: String,
  onSuccess: (token: Object) => void,
  onFail: (err: Object) => void
) => {
  request
    .get(`${baseUrl}/api/third-party/${thirdPartyId}/payment-method/token`)
    .withCredentials()
    .then((resp) => onSuccess(resp.body.token))
    .catch((err) => onFail(err));
};

export const requestPaymentProcessorEnvironmentDetails = (
  onSuccess?: (env: Object) => void,
  onFail?: (err: Object) => void
): Promise<Object> | void => {
  const result = request
    .get(`${baseUrl}/api/payment-method/environment`)
    .withCredentials();

  if (onSuccess) {
    result.then((resp) => onSuccess(resp.body)).catch((err) => onFail(err));
  } else {
    return result.then((resp) => resp.body);
  }
};

export const requestAddStripePaymentMethod = (
  data: { authorityId: String, userType: String, stripeToken: String },
  onSuccess: Function,
  onFail: Function
) => {
  const { userType } = data;

  if (userType === UserType.THIRD_PARTY_ADMIN) {
    requestAddStripePaymentMethodToThirdParty(data, onSuccess, onFail);
  } else {
    requestAddStripePaymentMethodToAccount(
      { ...data, accountId: data.authorityId },
      onSuccess,
      onFail
    );
  }
};

export const requestAddStripePaymentMethodToAccount = (
  data: { accountId: String, stripeToken: String },
  onSuccess,
  onFail
) => {
  requestAddAccountPaymentMethod(data, onSuccess, onFail);
};

export const requestAddStripePaymentMethodToThirdParty = (
  data: { authorityId: String, stripeToken: String },
  onSuccess: Function,
  onFail: Function
) => {
  requestAddThirdPartyPaymentMethod(data, onSuccess, onFail);
};

export const requestAddPlaidPaymentMethod = (
  data: {
    authorityId: String,
    userType: String,
    plaidPublicToken: String,
    plaidAccountId: String,
  },
  onSuccess: Function,
  onFail: Function
) => {
  const { authorityId, userType } = data;

  if (UserType.THIRD_PARTY_ADMIN === userType) {
    requestAddPlaidPaymentMethodToThirdParty(data, onSuccess, onFail);
  } else {
    requestAddPlaidPaymentMethodToAccount(
      { ...data, accountId: authorityId },
      onSuccess,
      onFail
    );
  }
};

export const requestAddPlaidPaymentMethodToAccount = (
  data: { accountId: String, plaidPublicToken: String, plaidAccountId: String },
  onSuccess: Function,
  onFail: Function
) => {
  requestAddAccountPaymentMethod(data, onSuccess, onFail);
};

export const requestAddPlaidPaymentMethodToThirdParty = (
  data: {
    authorityId: String,
    plaidPublicToken: String,
    plaidAccountName: String,
  },
  onSuccess: Function,
  onFail: Function
) => {
  requestAddThirdPartyPaymentMethod(data, onSuccess, onFail);
};

export const requestAddDwollaPaymentMethod = (
  data: {
    authorityId: String,
    userType: String,
    dwollaFundingSourceId: String,
  },
  onSuccess: Function,
  onFail: Function
) => {
  const { authorityId, userType, dwollaFundingSourceId } = data;

  if (UserType.THIRD_PARTY_ADMIN === userType) {
    requestAddDwollaPaymentMethodToThirdParty(
      authorityId,
      dwollaFundingSourceId,
      onSuccess,
      onFail
    );
  } else {
    requestAddDwollaPaymentMethodToAccount(
      authorityId,
      dwollaFundingSourceId,
      onSuccess,
      onFail
    );
  }
};

export const requestAddDwollaPaymentMethodToAccount = (
  accountId: String,
  dwollaFundingSourceId: String,
  onSuccess: Function,
  onFail: Function
) => {
  requestAddAccountPaymentMethod(
    { accountId, dwollaFundingSourceId },
    onSuccess,
    onFail
  );
};

export const requestAddDwollaPaymentMethodToThirdParty = (
  thirdPartyId: String,
  dwollaFundingSourceId: String,
  onSuccess: Function,
  onFail: Function
) => {
  requestAddThirdPartyPaymentMethod(
    { authorityId: thirdPartyId, dwollaFundingSourceId },
    onSuccess,
    onFail
  );
};

export const requestMakePaymentMethodDefault = (
  paymentMethodId: String,
  onSuccess?: () => void,
  onFail?: (err: Object) => void
): Promise<void> | void => {
  const result = request
    .post(`${baseUrl}/api/payment-method/${paymentMethodId}/default`)
    .withCredentials();

  if (onSuccess) {
    result.then(() => onSuccess()).catch((err) => onFail(err));
  } else {
    return result;
  }
};

export const requestDeletePaymentMethod = (
  paymentMethodId: String,
  onSuccess?: () => void,
  onFail?: (err: Object) => void
): Promise<void> | void => {
  const result = request
    .delete(
      `${baseUrl}/api/payment-method/delete-payment-method/${paymentMethodId}`
    )
    .withCredentials();

  if (onSuccess) {
    result.then(() => onSuccess()).catch((err) => onFail(err));
  } else {
    return result;
  }
};

export const requestVerifyBankAccount = (
  data: {
    paymentMethodId: String,
    microDeposit1: Number,
    microDeposit2: Number,
  },
  onSuccess?: (updatedPaymentMethod: Object) => void,
  onFail?: (err: Object) => void
): Promise<Object> | void => {
  const result = request
    .post(`${baseUrl}/api/payment-method/verify`)
    .withCredentials()
    .send(data);

  if (onSuccess) {
    result.then((resp) => onSuccess(resp.body)).catch((err) => onFail(err));
  } else {
    return result;
  }
};

const requestAddAccountPaymentMethod = (data, onSuccess, onFail) => {
  request
    .post(`${baseUrl}/api/payment-method`)
    .withCredentials()
    .send(data)
    .then((resp) => onSuccess(resp))
    .catch((err) => onFail(err));
};

const requestAddThirdPartyPaymentMethod = (data, onSuccess, onFail) => {
  const { authorityId } = data;
  request
    .post(`${baseUrl}/api/third-party/${authorityId}/payment-method`)
    .withCredentials()
    .send({
      ...data,
      authorityType: AuthorityType.THIRD_PARTY,
    })
    .then((resp) => onSuccess(resp))
    .catch((err) => onFail(err));
};
