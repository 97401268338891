import React from "react";
import Steps from "../../steps/Steps";
import Input from "../../common/Input";
import BooleanInput from "../../common/BooleanInput";
import "./zones.css";
const ZoneNameNumberSpacesPowerStep = (props) => {
  const {
    stepNumber,
    zoneName,
    onZoneNameChange,
    totalSpaces,
    onTotalSpacesChange,
    powerAccessible,
    setButtonDisabled,
    canContinue,
    onPowerAccessibleChange,
  } = props;

  const className = "form-control orange";
  const textType = "text";
  const numberType = "number";
  const checkBoxType = "checkbox";
  const zoneNameName = "Zone Name";
  const zoneNameLabel = "Zone Name: ";
  const numberOfSpacesName = "Total Number Of Spaces";
  const numberOfSpacesLabel = "Total Number of Spaces:";
  const powerAccessibleName = "Is Power Accessible";
  const powerAccessibleLabel = "Power Accessible ";
  const handleChange = (e) => {
    if (!!setButtonDisabled) {
      if (!e.target.value) {
        setButtonDisabled(true);
      } else {
        setButtonDisabled(false);
      }
    }

    onZoneNameChange(e);
  };

  return (
    <div>
      <div className="inputDivBox">
        <div style={{ display: "block" }}>
          <label>{zoneNameLabel}</label>
          <input
            className={className}
            type={textType}
            name={zoneNameName}
            placeholder="Enter the zone name"
            value={zoneName}
            onChange={(e) => handleChange(e)}
          />
        </div>

        <Input
          label={numberOfSpacesLabel}
          className={className}
          name={numberOfSpacesName}
          type={numberType}
          placeHolder={totalSpaces}
          value={totalSpaces}
          onChange={onTotalSpacesChange}
        />
      </div>

      <div className="powerAccessibleDiv">
        <BooleanInput
          label={powerAccessibleLabel}
          className={className}
          name={powerAccessibleName}
          type={checkBoxType}
          checked={powerAccessible}
          onChange={onPowerAccessibleChange}
        />
      </div>
      <Steps step={stepNumber} {...props} />
    </div>
  );
};

export default ZoneNameNumberSpacesPowerStep;
