import useQuery from "../query/useQuery";
import { useMemo } from "react";
import { extractAccount } from "./JwtUtil";

const useJwt = () => {
  const { token } = useQuery();

  return useMemo(() => {
    return extractAccount(token);
  }, [token]);
};

export default useJwt;
