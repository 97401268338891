import React, { useEffect, useState } from "react";
import Steps from "../../steps/Steps";
import MoneyInput from "../../common/MoneyInput";

const Price = (props) => {
  const {
    stepNumber,
    pricePerDayInCents,
    pricePerWeekInCents,
    pricePerMonthInCents,
    onPricePerDateInCentsChange,
    onPricePerWeekInCentsChange,
    onPricePerMonthInCentsChange,
    allowOneTimeBookings,
    allowWeeklyBookings,
    allowMonthlyBookings,
  } = props;

  const [priceIsValid, setPriceIsValid] = useState(false);

  const className = "form-control";
  const type = "number";
  const pricePerDayInCentsLabel = "Price Per Day/One Time";
  const pricePerWeekInCentsLabel = "Price Per Week";
  const pricePerMonthInCentsLabel = "Price Per Month/Recurring";

  useEffect(() => {
    if (allowOneTimeBookings && !pricePerDayInCents) {
      setPriceIsValid(false);
    } else if (allowWeeklyBookings && !pricePerWeekInCents) {
      setPriceIsValid(false);
    } else if (allowMonthlyBookings && !pricePerMonthInCents) {
      setPriceIsValid(false);
    } else {
      setPriceIsValid(true);
    }
  }, [
    pricePerDayInCents,
    pricePerWeekInCents,
    pricePerMonthInCents,
    allowOneTimeBookings,
    allowWeeklyBookings,
    allowMonthlyBookings,
  ]);

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        {allowOneTimeBookings && (
          <MoneyInput
            label={pricePerDayInCentsLabel}
            className={className}
            type={type}
            placeholder={pricePerDayInCents}
            value={pricePerDayInCents}
            onChange={onPricePerDateInCentsChange}
            disabled={!allowOneTimeBookings}
          />
        )}
        {/* {!allowOneTimeBookings && (
          <span>
            One time bookings is disabled for this Zone. Enable one time
            bookings to edit this setting.
          </span>
        )} */}
        {/* <MoneyInput
        label={pricePerWeekInCentsLabel}
        className={className}
        type={type}
        placeholder={pricePerWeekInCents}
        value={pricePerWeekInCents}
        onChange={onPricePerWeekInCentsChange}
        disabled={!allowWeeklyBookings}
      />
      {!allowWeeklyBookings && <span>Weekly bookings is disabled for this Zone. Enable weekly bookings to edit this setting.</span>} */}
        {allowMonthlyBookings && (
          <MoneyInput
            label={pricePerMonthInCentsLabel}
            className={className}
            type={type}
            placeholder={pricePerMonthInCents}
            value={pricePerMonthInCents}
            onChange={onPricePerMonthInCentsChange}
            disabled={!allowMonthlyBookings}
          />
        )}
        {/* {!allowMonthlyBookings && (
          <span>
            Monthly bookings is disabled for this Zone. Enable monthly bookings
            to edit this setting.
          </span>
        )} */}
      </div>

      <Steps step={stepNumber} canContinue={!priceIsValid} {...props} />
    </>
  );
};

export default Price;
