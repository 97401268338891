import { useMemo } from "react";
import { useLocation } from "react-router";

// if you use a '+' in the url you may need to adjust
//  this logic a bit. see https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => {
    let urlSearchParams = new URLSearchParams(search);
    let paramsObject = new Map();
    for (let [key, value] of urlSearchParams) {
      if (paramsObject.has(key)) {
        paramsObject.set(key, urlSearchParams.getAll(key));
      } else {
        paramsObject.set(key, value);
      }
    }
    return Object.fromEntries(paramsObject.entries());
  }, [search]);
};

export default useQuery;
