import React from "react";
import classNames from "classnames";
import commonButtonPropTypes from "./util/button-prop-types";

import "./style/button.css";

const Button = (props) => {
  const {
    label,
    onClick,
    disabled = false,
    className,
    edit,
    type,
    compType,
    ...rest
  } = props;

  const internalOnClick = () => {
    if (!disabled) {
      onClick();
    }
  };

  return (
    <div
      className={classNames("button", className, {
        "disabled-button": disabled,
      })}
      onClick={internalOnClick}
      style={
        edit === "edit"
          ? { width: "20%", marginLeft: "81%", marginTop: "-48px" }
          : type === "buyerAccount"
          ? {
              marginLeft: "31px",
            }
          : compType === "accessFine"
          ? {
              width: "200px",
              marginTop: "29px",
              height: "38px",
            }
          : compType === "driver"
          ? {
              width: "26%",
              margin: "auto",
            }
          : type === "fineNote"
          ? {
              height: "38px",
            }
          : compType === "bookingCancelSubs"
          ? {
              width: "200px",
              height: "38px",
            }
          : {
              height: "38px",
            }
      }
      {...rest}
    >
      {label}
    </div>
  );
};

Button.propTypes = commonButtonPropTypes;

export default Button;
