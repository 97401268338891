import React, { useEffect, useState } from "react";
import ButtonSelector from "../../components/ButtonSelector";
import "../../css/components/groupAndSort/groupAndSort.css";
import Select from "../../components/Select";

const GroupAndSort = (props) => {
  const {
    groupByChangeHandler,
    groupBy,
    sortDirection,
    groupDirectionChangeHandler,
    groupByOptions,
    sortByChangeHandler,
    groupDirection,
    sortDirectionChangeHandler,
    sortBy,
    asc,
    desc,
  } = props;

  let groupByOptionsVal = groupByOptions.map((field) => {
    let option = {
      name: field.name,
      getDisplayValue: () => field.label,
    };

    return option;
  });

  let sortByOptions = groupByOptions.map((field) => {
    let option = {
      name: field.name,
      getDisplayValue: () => field.label,
    };
    return option;
  });

  const [show, setShow] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");

  const handleChange = (option) => {
    // onChange(option);
    setSelectedValue(option.name);
    setShow(false);
  };

  const handleClick = () => {
    setShow(true);
  };

  return (
    <form className="report-form newRightCol2">
      {/* <div className="ss-stand-alone data-list-filter-container search-container">
        <fieldset className="ss-fieldset-row-inner-left">
          <label>GROUP BY:</label>

          <Select
            id="groupBy"
            name="groupBy"
            handleChange={groupByChangeHandler}
            selectedOption={groupBy}
            placeholder="Please select"
            options={groupByOptionsVal}
          />
        </fieldset>
        <div
          className="ss-fieldset-row-inner-middle"
          style={
            !groupBy
              ? { pointerEvents: "none", opacity: "0.5", marginTop: "15px" }
              : { marginTop: "15px" }
          }
        >
          <ButtonSelector
            options={[asc, desc]}
            selectedOption={groupDirection}
            handleOptionSelected={groupDirectionChangeHandler}
          />
        </div>
      </div> */}
      <div className="ss-stand-alone data-list-filter-container search-container">
        <fieldset className="ss-fieldset-row-inner-left">
          <label>SORT BY:</label>

          {/* <select
            className={"form-control ss-select-list ss-group-select"}
            style={{ font: "inherit" }}
            id="groupBy"
            name="groupBy"
            onChange={sortByChangeHandler}
            value={sortBy}
            placeholder="Choose"
          >
            {groupByOptions &&
              groupByOptions.map((option, key) => (
                <option key={key} value={option.name}>
                  {option.label}
                </option>
              ))}
          </select> */}
          <Select
            id="groupBy"
            name="groupBy"
            handleChange={sortByChangeHandler}
            selectedOption={sortBy}
            placeholder="Please select"
            options={sortByOptions}
          />
        </fieldset>
        <div
          className="ss-fieldset-row-inner-middle"
          style={
            !sortBy
              ? { pointerEvents: "none", opacity: "0.5", marginTop: "15px" }
              : { marginTop: "15px" }
          }
        >
          <ButtonSelector
            options={[asc, desc]}
            selectedOption={sortDirection}
            handleOptionSelected={sortDirectionChangeHandler}
          />
        </div>
      </div>
    </form>
  );
};

export default GroupAndSort;
