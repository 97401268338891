import React from "react";
import Steps from "../steps/Steps";
import LocationSelect from "./LocationSelect";
import PropTypes from "prop-types";

const LocationIdSelect = (props) => {
  const {
    locations,
    stepNumber,
    buttonDisabled,
    onChange,
    value,
    addLocationId,
    setButtonDisabled,
    comp,
  } = props;

  return (
    <div>
      <LocationSelect
        addLocationId={addLocationId}
        locations={locations}
        onChange={onChange}
        value={value}
        comp={comp}
        setButtonDisabled={setButtonDisabled}
        {...props}
      />
      <Steps step={stepNumber} canContinue={buttonDisabled} {...props} />
    </div>
  );
};

LocationIdSelect.propTypes = {
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      locationName: PropTypes.string,
    })
  ),
  onChange: PropTypes.func,
  buttonDisabled: PropTypes.bool,
  stepNumber: PropTypes.number,
  value: PropTypes.string,
};

export default LocationIdSelect;
