import React, { useEffect, useState } from "react";
import LocationZone from "./LocationZone";
import Filter from "../../../views/bookings/Filter";
import GroupAndSort from "../../../views/bookings/GroupAndSort";
import {
  SORT_DIRECTION_ASC,
  SORT_DIRECTION_DESC,
} from "../../../util/report/ReportUtils";
import { searchRecordsByKeywordUser } from "../../../util/SearchUtils";
import ReportEmpty from "../../report/ReportEmpty";
import _ from "underscore";
import {
  compareObjectsAscending,
  compareObjectsDescending,
} from "../../constants/securspace-constants";

const Location = (props) => {
  const reportFields = [
    { label: "Current Available Space", name: "currentAvailableSpaces" },
    { label: "Auto Approved", name: "autoApproved" },
    { label: "Equipment Length", name: "equipmentLength" },
    { label: "Price Per Day/One Time", name: "pricePerDayInCents" },
    { label: "Price Per Month/Recurring", name: "pricePerMonthInCents" },
    { label: "Power Accessible", name: "powerAccessible" },
    { label: "Total Spaces", name: "totalSpaces" },
    { label: "Zone Name", name: "name" },
  ];

  const lengthOrder = [
    "",
    "TWENTY_FIVE_FOOT",
    "FORTY_FIVE_FOOT",
    "FIFTY_THREE_FOOT",
    "EIGHTY_THREE_FOOT",
  ];

  const { location, account } = props;

  const [groupBy, setGroupBy] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [sortDirection, setSortDirection] = useState(SORT_DIRECTION_ASC);
  const [groupDirection, setGroupDirection] = useState(SORT_DIRECTION_ASC);
  const [searchBox, setSearchBox] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    if (
      location &&
      location.locationZones &&
      location.locationZones.length > 0 &&
      filteredData.length < 1
    ) {
      setFilteredData(location.locationZones);
    }
  }, [location]);
  //useEffect(() => {}, [filteredData?.length, sortBy?.name]);

  useEffect(() => {
    if (!!sortBy?.name) sortData(sortBy);
  }, [filteredData?.length, sortBy?.name, sortDirection]);

  const searchChangeHandler = (event) => {
    const value = event.target.value;
    setSearchBox(value);
    setFilteredData(searchRecordsByKeywordUser(value, location.locationZones));
  };

  const groupByChangeHandler = (event) => {
    const value = event.target.value;
    setGroupBy(value);
    groupData(value);
  };

  const groupData = (value) => {
    const groupedData = _.groupBy(filteredData, value);
    const toArray = _.toArray(groupedData);
    const flattened = _.flatten(toArray);
    setFilteredData(flattened);
  };

  const sortByChangeHandler = (event) => {
    const value = event.target.value;
    setSortBy(value);
    //sortData(value);
  };

  const sortData = (value) => {
    let tempData = [...filteredData];

    if (tempData) {
      if (value?.name) {
        if (sortDirection === SORT_DIRECTION_ASC) {
          tempData = tempData.sort((a, b) => {
            if (sortBy?.name === "equipmentLength") {
              return (
                lengthOrder.indexOf(a.equipmentLength) -
                lengthOrder.indexOf(b.equipmentLength)
              );
            }
            return compareObjectsAscending(a, b, value);
          });
        }
        if (sortDirection === SORT_DIRECTION_DESC) {
          tempData = tempData.sort((a, b) => {
            if (sortBy?.name === "equipmentLength") {
              return (
                lengthOrder.indexOf(b.equipmentLength) -
                lengthOrder.indexOf(a.equipmentLength)
              );
            }
            return compareObjectsDescending(a, b, sortBy);
          });
        }
      }
    }

    setFilteredData(tempData);
  };
  const sortDirectionChangeHandler = () => {
    sortByDirection();
    setFilteredData([...filteredData.reverse()]);
  };

  const groupDirectionChangeHandler = () => {
    groupByDirection();
    setFilteredData([...filteredData.reverse()]);
  };

  const sortByDirection = () => {
    setSortDirection(
      sortDirection === SORT_DIRECTION_ASC
        ? SORT_DIRECTION_DESC
        : SORT_DIRECTION_ASC
    );
  };

  const groupByDirection = () => {
    setGroupDirection(
      groupDirection === SORT_DIRECTION_ASC
        ? SORT_DIRECTION_DESC
        : SORT_DIRECTION_ASC
    );
  };
  return (
    <div>
      {!!location && location?.locationZones && (
        <div>
          <header>
            <ul className="breadcrumb">
              <li> Account</li>
              <li>Zone</li>
            </ul>
            <h1 className="ss-report-header-title"> {location.locationName}</h1>
          </header>
          {filteredData.length > 0 && (
            <Filter
              searchBox={searchBox}
              searchChangeHandler={searchChangeHandler}
              placeHolder="Type zone name to filter location result"
            />
          )}

          {!!filteredData && filteredData.length > 0 ? (
            <>
              <GroupAndSort
                groupByOptions={reportFields}
                groupByChangeHandler={groupByChangeHandler}
                groupBy={groupBy}
                groupDirection={groupDirection}
                groupDirectionChangeHandler={groupDirectionChangeHandler}
                sortBy={sortBy}
                sortByChangeHandler={sortByChangeHandler}
                sortDirection={sortDirection}
                sortDirectionChangeHandler={sortDirectionChangeHandler}
                asc={SORT_DIRECTION_ASC}
                desc={SORT_DIRECTION_DESC}
              />
              <LocationZone zones={filteredData} account={account} />
            </>
          ) : (
            <div>
              <div className="ss-supplier-active-bookings-endlist">
                <h6>No record found for this particular zone</h6>
              </div>{" "}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Location;
