import React from 'react';
import PropTypes from "prop-types";
import '../../css/components/selectInput.css'

const SelectInput = (props) => {

  const { label, name, value, onChange, onClick, items, placeholder } = props;

  return (
    <div className='selectDownDiv'>
      <label>{label}</label>
      <select
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onClick={onClick}
      >
        {items && items.map((item) =>
          <option
            key={item.label}
            value={item.value}
          >
            {item.label}
          </option>
        )}
      </select>
    </div>
  );
}

SelectInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  items: PropTypes.array
}

export default SelectInput;