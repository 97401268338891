import React from "react";
import "../../css/components/report/report-row.css";

const ReportRow = (props) => {
  const { children, name } = props;

  return (
    <div className="ss-report-row">
      <div
        style={
          name === "interchange"
            ? {}
            : {
                display: "flex",
                padding: "10px",
                width: "100%",
                flexDirection:
                  name === "zoneRevenueReport" ||
                  name === "zonesReport" ||
                  name === "fineInfraction"
                    ? "row"
                    : "row-reverse",
              }
        }
      >
        {children}
      </div>
    </div>
  );
};

export default ReportRow;
