import React from "react";
import _ from "underscore";
import SupplierInterchangeAuditReportContentItem from "./SupplierInterchangeAuditReportContentItem";
import ReportTitle from "../../components/report/ReportTitle";
import ReportHeader from "../../components/report/ReportHeader";

const SupplierInterchangeAuditReportContent = (props) => {
  const { interchangeAudits } = props;

  return (
    <div>
      <ReportHeader parentMenu="Interchanges">Interchange Audits</ReportHeader>
      <ReportTitle subtext="(SHOWING ALL RECORDS IN GROUP)">
        {interchangeAudits.length} record
        {interchangeAudits.length > 1 ? "s" : ""}
      </ReportTitle>
      <div style={{ marginTop: "10px" }}>
        {interchangeAudits &&
          interchangeAudits.length > 0 &&
          _.map(interchangeAudits, (interchangeAudit, idx) => {
            return (
              <SupplierInterchangeAuditReportContentItem
                interchangeAudit={interchangeAudit}
                key={idx}
              />
            );
          })}
        <div class="ss-load-more-records">Showing all records in group</div>
      </div>
    </div>
  );
};

export default SupplierInterchangeAuditReportContent;
