import request from "../../../util/SuperagentUtils";

export const requestLogBackInAsAdmin = (
  onSuccess?: (user: Object) => void,
  onFail: (err: Object) => void
): Promise | void => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  const result = request
    .post(`${baseUrl}/api/login-back-in-as-admin`)
    .withCredentials();

  if (onSuccess && onFail) {
    result.then((resp) => onSuccess(resp.body)).catch((err) => onFail(err));
  } else {
    return result;
  }
};
