import React from 'react'
import commonButtonPropTypes from './util/button-prop-types'
import StyledButton, {ButtonStyles} from "./StyledButton";

const OutlinedButton = (props) => {
  return <StyledButton style={ButtonStyles.OUTLINED} { ...props } />
}

OutlinedButton.propTypes = commonButtonPropTypes

export default OutlinedButton
