import React from "react";
import SolidButton from "../form/SolidButton";
import OutlinedButton from "../form/OutlinedButton";
import "./step.css";
const Steps = ({
  nextStep,
  previousStep,
  handleGoBack,
  totalSteps,
  step,
  toggleEditMode,
  cancel,
  type,
  canContinue,
}) => (
  <div
    style={type === "fineNote" ? { display: "flex", marginBottom: "11px" } : {}}
  >
    {step < totalSteps ? (
      <>
        <div
          className="btnDivBox"
          style={
            type === "fineType"
              ? {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }
              : type === "fineNote"
              ? {
                  display: "flex",
                  alignItems: "center",
                  width: "189px",
                }
              : {}
          }
        >
          {step > 1 ? (
            <OutlinedButton
              label={"GO BACK"}
              onClick={
                step === 3 && type === "zonePicker"
                  ? handleGoBack
                  : previousStep
              }
              type="fineNote"
            />
          ) : (
            <SolidButton
              label={"CONTINUE"}
              disabled={canContinue}
              onClick={nextStep}
              type={type}
            />
          )}
          {cancel ? (
            <OutlinedButton
              label={"CANCEL"}
              onClick={toggleEditMode ? toggleEditMode : cancel}
            />
          ) : (
            ""
          )}
        </div>
        <div
          className="goBackBtn"
          style={
            type === "fineNote" ? { width: "200px", marginTop: "29px" } : {}
          }
        >
          {step > 1 && (
            <SolidButton
              label={"CONTINUE"}
              disabled={canContinue}
              onClick={nextStep}
              type={type}
            />
          )}
        </div>
      </>
    ) : (
      <>
        <div
          className="btnDivBox"
          style={
            type === "fineNote"
              ? {
                  display: "flex",
                  alignItems: "center",
                  width: "189px",
                }
              : {}
          }
        >
          {step > 1 && (
            <OutlinedButton
              label={"GO BACK"}
              onClick={
                step === 3 && type === "zonePicker"
                  ? handleGoBack
                  : previousStep
              }
            />
          )}
          {cancel ? (
            <OutlinedButton
              label={"CANCEL"}
              onClick={toggleEditMode ? toggleEditMode : cancel}
            />
          ) : (
            ""
          )}
        </div>
      </>
    )}
  </div>
);

export default Steps;
