import React from "react";
import ReportData from "../../components/report/ReportData";
import ReportDataRow from "../../components/report/ReportDataRow";
import DateDisplay from "../../components/date/DateDisplay";
import ReportRow from "../../components/report/ReportRow";

const SupplierInterchangeAuditReportContentItem = (props) => {
  const { interchangeAudit } = props;

  const { modifiedFieldNames } = interchangeAudit;

  const checkModified = (field1, field2) => {
    return (
      modifiedFieldNames.includes(field1) || modifiedFieldNames.includes(field2)
    );
  };
  const getEquipmentLength = (tempValue) => {
    if (tempValue === "25") return "25' MAX (Truck Only)";
    if (tempValue === "45") return "45' MAX (Small Container)";
    if (tempValue === "53") return "53' MAX (Large Container)";
    if (tempValue === "83") return "83' MAX (Truck + Trailer)";
  };

  return (
    <ReportRow name="interchange">
      <ReportDataRow>
        {interchangeAudit.revisionType && (
          <ReportData label={"REVISION TYPE:"}>
            {interchangeAudit.revisionType}
          </ReportData>
        )}
        {interchangeAudit.revisionInstant && (
          <ReportData label={"REVISION TIME:"}>
            <DateDisplay date={interchangeAudit.revisionInstant} />
          </ReportData>
        )}
        {interchangeAudit.createdBy && (
          <ReportData label={"CREATED BY:"}>
            {interchangeAudit.createdBy}
          </ReportData>
        )}
        {interchangeAudit.creationDate && (
          <ReportData label={"CREATED ON:"}>
            <DateDisplay date={interchangeAudit.creationDate} />
          </ReportData>
        )}

        {interchangeAudit.activity && (
          <ReportData label={"ACTIVITY:"} modified={checkModified("activity")}>
            {interchangeAudit.activity}
          </ReportData>
        )}
        {interchangeAudit.activityDate && (
          <ReportData label={"DATE:"} modified={checkModified("activityDate")}>
            <DateDisplay date={interchangeAudit.activityDate} />
          </ReportData>
        )}
        {interchangeAudit.containerNumber && (
          <ReportData
            label={"CONTAINER:"}
            modified={checkModified("containerNumber")}
          >
            {interchangeAudit.containerNumber}
          </ReportData>
        )}

        {interchangeAudit.trailerNumber && (
          <ReportData
            label={"TRAILER:"}
            modified={checkModified("trailerNumber")}
          >
            {interchangeAudit.trailerNumber}
          </ReportData>
        )}

        {interchangeAudit.chassisNumber && (
          <ReportData
            label={"CHASSIS NUMBER:"}
            modified={checkModified("chassisNumber")}
          >
            {interchangeAudit.chassisNumber}
          </ReportData>
        )}

        {interchangeAudit.chassisLicensePlateNumber && (
          <ReportData
            label={"CHASSIS LICENSE NUMBER:"}
            modified={checkModified("chassisLicensePlateNumber")}
          >
            {interchangeAudit.chassisLicensePlateNumber}
          </ReportData>
        )}

        {interchangeAudit.assetSize && (
          <ReportData
            label={"EQUIPMENT LENGTH:"}
            modified={checkModified("assetSize")}
          >
            {getEquipmentLength(interchangeAudit.assetSize)}
          </ReportData>
        )}

        {interchangeAudit.driverFirstName && (
          <ReportData
            label={"DRIVER FIRST NAME:"}
            modified={checkModified("driverFirstName")}
          >
            {interchangeAudit.driverFirstName}
          </ReportData>
        )}

        {interchangeAudit.driverLastName && (
          <ReportData
            label={"DRIVER LAST NAME:"}
            modified={checkModified("driverLastName")}
          >
            {interchangeAudit.driverLastName}
          </ReportData>
        )}
        {interchangeAudit.driverLicenseNumber && (
          <ReportData
            label={"LICENSE NUMBER:"}
            modified={checkModified("driverLicenseNumber")}
          >
            {interchangeAudit.driverLicenseNumber}
          </ReportData>
        )}
        {interchangeAudit.truckLicensePlateNumber && (
          <ReportData
            label={"TRUCK LICENSE PLATE NUMBER:"}
            modified={checkModified("truckLicensePlateNumber")}
          >
            {interchangeAudit.truckLicensePlateNumber}
          </ReportData>
        )}
        {interchangeAudit.sealNumber && (
          <ReportData label={"SEAL:"} modified={checkModified("sealNumber")}>
            {interchangeAudit.sealNumber}
          </ReportData>
        )}

        {interchangeAudit.assetType && (
          <ReportData
            label={"EQUIPMENT TYPE:"}
            modified={checkModified("assetType")}
          >
            {interchangeAudit.assetType}
          </ReportData>
        )}
        {interchangeAudit.orderNumber && (
          <ReportData
            label={"BOOKING NUMBER:"}
            modified={checkModified("orderNumber")}
          >
            {interchangeAudit.orderNumber}
          </ReportData>
        )}
        {interchangeAudit.companyName && (
          <ReportData
            label={"CUSTOMER:"}
            modified={checkModified("companyName")}
          >
            {interchangeAudit.companyName}
          </ReportData>
        )}
        {interchangeAudit.locationName && (
          <ReportData
            label={"LOCATION:"}
            modified={checkModified("locationName")}
          >
            {interchangeAudit.locationName}
          </ReportData>
        )}

        {interchangeAudit.occupiedSpaces && (
          <ReportData
            label={"INVENTORY:"}
            modified={checkModified("occupiedSpaces", "bookedSpaces")}
          >
            {interchangeAudit.occupiedSpaces +
              " of " +
              interchangeAudit.bookedSpaces}
          </ReportData>
        )}
        {interchangeAudit.lastModifiedDate && (
          <ReportData
            label={"LAST MODIFIED DATE:"}
            modified={checkModified("lastModifiedDate")}
          >
            <DateDisplay date={interchangeAudit.lastModifiedDate} />
          </ReportData>
        )}
        {interchangeAudit.lastModifiedBy && (
          <ReportData
            label={"LAST MODIFIED BY:"}
            modified={checkModified("lastModifiedBy")}
          >
            {interchangeAudit.lastModifiedBy}
          </ReportData>
        )}
        {interchangeAudit.notes && (
          <ReportData label={"NOTES:"} modified={checkModified("notes")}>
            {interchangeAudit.notes}
          </ReportData>
        )}
      </ReportDataRow>
    </ReportRow>
  );
};

export default SupplierInterchangeAuditReportContentItem;
