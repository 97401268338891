import React from "react";
import PropTypes from "prop-types";
import Input from "../../common/Input";

const FineTypeInput = (props) => {
  const { onChange, fine } = props;

  return (
    <div>
      <Input
        label={"Create Fine Type: "}
        className="form-control"
        type="text"
        name="fineType"
        value={fine && fine.fineType ? fine.fineType.name : ""}
        placeHolder={"Enter fine type"}
        //placeholder={fine && fine.fineType ? fine.fineType.name : "ILLEGAL PARKING"}
        onChange={onChange}
      />
    </div>
  );
};

FineTypeInput.propTypes = {
  fine: PropTypes.shape({
    id: PropTypes.string,
    fineType: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  }),
  onChange: PropTypes.func,
};

export default FineTypeInput;
