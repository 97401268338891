import React from 'react';
import BookingList from "../../../views/bookings/BookingList";

const BookingContainer = (props) => {

  const { bookings, thirtyDayBookings } = props;

  return <>
    {thirtyDayBookings && thirtyDayBookings.length > 0 ?
      <div>
        <h3>ZONE WILL NOT BE EDITABLE BECAUSE IT AFFECTS:</h3>
        <BookingList
          bookings={thirtyDayBookings}
        />
      </div>
      : ''
    }
    {
      bookings && bookings.length > 0 ?
        <div>
          <br/>
          <h3>BOOKINGS MORE THAN 30 DAYS THAT WILL BE CANCELED BY EDIT:</h3>
          <BookingList
            bookings={bookings}
          />
        </div>
        : ''
    }
    {
      bookings?.length === 0 && thirtyDayBookings?.length === 0 ?
        <div>
          <h3>NO BOOKINGS WILL BE EFFECTED BY EDIT</h3>
        </div> : ''
    }
  </>
}

export default BookingContainer;
