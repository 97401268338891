import request from "../../util/SuperagentUtils";
const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const zoneCreate = (zone) => {
  return request.post(`${baseUrl}/api/zone`).withCredentials().send(zone);
};

export const getAllZones = (startDate, endDate) => {
  return request
    .get(`${baseUrl}/api/zone`)
    .query({
      startDate: startDate,
      endDate: endDate,
    })
    .withCredentials();
};

export const getAllZonesByDate = (startDate, endDate) => {
  return request
    .get(`${baseUrl}/api/zone/report`)
    .query({
      startDate: startDate,
      endDate: endDate,
    })
    .withCredentials();
};

export const zoneEdit = (zone) => {
  return request
    .put(`${baseUrl}/api/zone/` + zone.id)
    .send(zone)
    .withCredentials();
};

export const zoneDelete = (zoneId) => {
  return request.delete(`${baseUrl}/api/zone/` + zoneId).withCredentials();
};

export const getRenewingBookings30DaysOut = (
  zoneId,
  equipmentLength,
  powerAccessible,
  numberOfSpaces,
  interval
) => {
  return request
    .get(`${baseUrl}/api/zone/` + zoneId + "/bookings")
    .query({
      equipmentLength: equipmentLength,
      powerAccessible: powerAccessible,
      numberOfSpaces: numberOfSpaces,
      startingMonthInt: interval,
    })
    .withCredentials();
};

export const getZoneAudits = (zoneId) => {
  return request
    .get(`${baseUrl}/api/zone/` + zoneId + "/audit")
    .withCredentials();
};

export const getZoneById = (zoneId) => {
  return request.get(`${baseUrl}/api/zone/${zoneId}`).withCredentials();
};
