import React from "react";
import Input from "../common/Input";
import "../zonebuilder/zones/zones.css";

const StartEndDate = (props) => {
  const {
    startDate,
    onStartDateChange,
    endDate,
    onEndDateChange,
    selectedFrequency,
  } = props;

  const className = "form-control orange";
  const dateType = "date";
  const startDateLabel = "Start Date: ";
  const endDateLabel = "End Date: ";

  return (
    <div className="inputDivBox">
      <Input
        label={startDateLabel}
        className={className}
        type={dateType}
        name={startDate}
        value={startDate}
        placeHolder={"MM/dd/yyyy"}
        onChange={onStartDateChange}
      />
      <hr />
      {selectedFrequency !== "RECURRING" && (
        <Input
          label={endDateLabel}
          className={className}
          type={dateType}
          name={endDate}
          value={endDate}
          id="endDate"
          startDate={startDate}
          endDate={endDate}
          disabled={startDate === "" ? true : false}
          placeHolder={"MM/dd/yyyy"}
          onChange={onEndDateChange}
        />
      )}
    </div>
  );
};

export default StartEndDate;
