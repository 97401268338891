import React, { useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import polylabel from "polylabel";
import ZoneMapItemContent from "./ZoneMapItemContent";
import moment from "moment";
import { ZoneMapColors } from "../constants/securspace-constants";
import { geoJson2GoogleMap, toLinearRing } from "../../util/zone/ZoneMapUtil";

const google = window.google;

const ZoneMapItem = (props) => {
  const { zone, activeZone, setActiveZone } = props;
  const [polygonColor, setPolygonColor] = useState("");
  const [markerPosition, setMarkerPosition] = useState(
    new google.maps.LatLng(0, 0)
  );
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    const isZoneActive = (zone) => {
      return (
        zone.active ||
        moment().isBetween(
          zone.activationDate,
          zone.deactivationDate,
          undefined,
          "[]"
        )
      );
    };

    const getPolygonColor = (zone) => {
      return isZoneActive(zone) ? ZoneMapColors.ACTIVE : ZoneMapColors.INACTIVE;
    };

    const getMarkerPosition = (zone) => {
      return geoJson2GoogleMap(polylabel(toLinearRing(zone.coordinates)));
    };

    if (zone) {
      setPolygonColor(getPolygonColor(zone));
      setMarkerPosition(getMarkerPosition(zone));
      setIsActive(isZoneActive(zone));
    }
  }, [zone, setPolygonColor, setMarkerPosition, setIsActive]);

  const handleToggleInfoWindow = () => {
    if (activeZone && activeZone.name === zone.name) {
      setActiveZone(null);
    } else {
      setActiveZone(zone);
    }
  };

  return (
    <ZoneMapItemContent
      zone={zone}
      polygonColor={polygonColor}
      toggleInfoWindow={activeZone && activeZone.name === zone.name}
      handleToggleInfoWindow={handleToggleInfoWindow}
      markerPosition={markerPosition}
      isActive={isActive}
    />
  );
};

ZoneMapItem.propTypes = {
  zone: PropTypes.shape({
    name: PropTypes.string,
    totalSpaces: PropTypes.number,
    equipmentLength: PropTypes.string,
    activationDate: PropTypes.string,
    deactivationDate: PropTypes.string,
    active: PropTypes.bool,
    coordinates: PropTypes.arrayOf(
      PropTypes.shape({
        latitude: PropTypes.number,
        longitude: PropTypes.number,
      })
    ),
  }).isRequired,
  activeZone: PropTypes.object,
  setActiveZone: PropTypes.func.isRequired,
};

export default ZoneMapItem;
