import React from "react";
import ReportRow from "../../../report/ReportRow";
import FineAssessmentsMenu from "./FineAssessmentsMenu";
import FineAssessmentReportDataRow from "./FineAssessmentReportDataRow";

const FineAssessmentsListItem = (props) => {
  const { fineAssessment, onFineAssessmentUpdate } = props;

  return (
    <ReportRow>
      <FineAssessmentsMenu
        fineAssessment={fineAssessment}
        onFineAssessmentUpdate={onFineAssessmentUpdate}
      />
      {fineAssessment && (
        <FineAssessmentReportDataRow fineAssessment={fineAssessment} />
      )}
    </ReportRow>
  );
};

export default FineAssessmentsListItem;
