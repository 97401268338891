import React from "react";
import ReportRow from "../report/ReportRow";
import TransactionItem from "./TransactionItem";
import { withGroupAndSortExportAndGroupings } from "../report/hocs/withGroupAndSortExportAndGroupings";
import ReportTotal from "../report/ReportTotal";
import { formatCurrencyValue } from "../../util/PaymentUtils";
import { PAYMENT_FAILED } from "../SupplierTransactionStatus";

const LocationRevenueContent = (props) => {
  const { transactions } = props;

  const formatTotals = (value) => formatCurrencyValue(value, true);

  const OVERAGE_CHARGE = Object.freeze(["OVERAGE_CHARGE"]);

  const BOOKING_CHARGE = Object.freeze(["BOOKING_CHARGE"]);

  const FINE_ASSESSMENT_CHARGE = Object.freeze(["FINE_ASSESSMENT_CHARGE"]);

  const REFUNDS = Object.freeze([
    "CANCELLATION_REFUND",
    "BILLING_CORRECTION_REFUND",
    "FINE_ASSESSMENT_REFUND",
    "DATE_ADJUST_REFUND",
    "SPACE_REMOVED_REFUND",
  ]);

  const calculateBookingCharge = (x) => {
    return (
      BOOKING_CHARGE.includes(x.transactionType) &&
      !PAYMENT_FAILED.includes(x.status)
    );
  };

  return (
    <>
      <ReportTotal
        bold={true}
        data={transactions}
        totalFieldLabel={"Revenue"}
        totalFieldName={"grossAmount"}
        totalFieldFormat={formatTotals}
      />
      <ReportTotal
        data={transactions}
        totalFieldLabel={"Booking Revenue"}
        totalFieldName={"grossAmount"}
        totalFieldFormat={formatTotals}
        totalFieldFilter={(x) => calculateBookingCharge(x)}
      />
      <ReportTotal
        data={transactions}
        totalFieldLabel={"Overage Revenue"}
        totalFieldName={"grossAmount"}
        totalFieldFormat={formatTotals}
        totalFieldFilter={(x) => OVERAGE_CHARGE.includes(x.transactionType)}
      />
      <ReportTotal
        data={transactions}
        totalFieldLabel={"Fine Revenue"}
        totalFieldName={"grossAmount"}
        totalFieldFormat={formatTotals}
        totalFieldFilter={(x) =>
          FINE_ASSESSMENT_CHARGE.includes(x.transactionType)
        }
      />
      <ReportTotal
        data={transactions}
        totalFieldLabel={"Failed Payments"}
        totalFieldName={"grossAmount"}
        totalFieldFormat={formatTotals}
        totalFieldFilter={(x) => PAYMENT_FAILED.includes(x.status)}
      />
      <ReportTotal
        data={transactions}
        totalFieldLabel={"Refunds"}
        totalFieldName={"grossAmount"}
        totalFieldFormat={formatTotals}
        totalFieldFilter={(x) => REFUNDS.includes(x.transactionType)}
      />
      {transactions &&
        transactions.map((transaction) => {
          return (
            <div key={transaction.transactionId}>
              <ReportRow>
                <TransactionItem transaction={transaction} />
              </ReportRow>
            </div>
          );
        })}
    </>
  );
};

export default LocationRevenueContent;
export const LocationRevenueContentWithGroupAndSort =
  withGroupAndSortExportAndGroupings(LocationRevenueContent, "transactions");
