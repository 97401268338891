const notApproved = [
    "PAYMENT_AUTHORIZED",
    "PAYMENT_NOT_SUBMITTED"
];

const bookingDeclined = [
    "PAYMENT_CANCELLED"
];

const chargePending = [
    "CHARGE_PENDING"
];

const paymentSucceeded = [
    "PAYMENT_SUCCEEDED"
]

const incomplete = [
    "BOOKING_INCOMPLETE"
];

const paymentPending = [
    "PAYMENT_PENDING"
];

const refundPending = [
    "REFUND_REQUESTED",
];

export const PAYMENT_FAILED = Object.freeze([
    "PAYMENT_FAILED"
]);

export const PAYOUT_SUCCEEDED = Object.freeze([
    "SUPPLIER_PAYOUT_SUCCEEDED",
    "SUPPLIER_PAYOUT_SUCCEEDED_SECURSPACE_FEE_PAYOUT_FAILED"
]);

let getDisplayValue = (value) => {

    if (notApproved.includes(value)) {
        return "Approval Pending"
    } else if (bookingDeclined.includes(value)) {
        return "Booking Declined"
    } else if (chargePending.includes(value)) {
        return "Charge Pending";
    } else if (incomplete.includes(value)) {
        return "Incomplete";
    } else if (paymentPending.includes(value)) {
        return "Payment Pending";
    } else if (paymentSucceeded.includes(value)) {
      return "Payment Succeeded";
    } else if (PAYMENT_FAILED.includes(value)) {
        return "Payment Failed";
    } else if (refundPending.includes(value)) {
      return "Refund Pending";
    } else {
        return value;
    }
};

export default getDisplayValue;
