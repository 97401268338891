import React from "react";
import ZoneSelect from "./ZoneSelect";
import Steps from "../steps/Steps";
import SolidButton from "../form/SolidButton";

const ZoneSelectStep = (props) => {
  const {
    selectedZone,
    zones,
    onChange,
    step,
    totalSteps,
    onCancel,
    onSave,
    disabled,
  } = props;

  return (
    <>
      <ZoneSelect value={selectedZone} zones={zones} onChange={onChange} />
      <Steps step={step} totalSteps={totalSteps} cancel={onCancel} />
      <SolidButton
        label={"SAVE"}
        onClick={onSave}
        edit="edit"
        disabled={disabled}
      />
    </>
  );
};

export default ZoneSelectStep;
