import React from 'react';
import '../../css/components/report/report-title.css';

const ReportTitleContainer = (props) => {
  const {children} = props;
  return <div className={'ss-report-title-container'}>
    {children}
  </div>
}

export default ReportTitleContainer;
