import React, { useEffect, useState } from "react";
import { getWaitListReportData } from "./WaitListRequests";
import { toast } from "react-toastify";
import { getErrorMessageForStandardResponse } from "../../util/NetworkErrorUtil";
import ReportBackgroundContainer from "../report/ReportBackgroundContainer";
import Filter from "../../views/bookings/Filter";
import ReportFormDateInput from "../report/ReportFormDateInput";
import ReportFormContainer from "../report/ReportFormContainer";
import { WaitListWithGroupAndSort } from "./WaitListWithGroupAndSort";
import moment from "moment";
import { DateFormats } from "../constants/securspace-constants";
import { searchRecordsByKeyword } from "../../util/SearchUtils";
import ReportEmpty from "../report/ReportEmpty";
import { extractDay } from "../constants/securspace-constants";
import Busy from "../Busy";

const WaitListReport = () => {
  const dateFormat = (date) =>
    date && moment(new Date(date)).format(DateFormats.DATE);
  const defaultStartDate = moment()
    .subtract(30, "days")
    .format(DateFormats.DAY);
  const defaultEndDate = moment().format(DateFormats.DAY);

  const reportFields = [
    { label: "Company Name", name: "companyName" },
    { label: "Start Date", name: "startDate", groupDisplayFormat: dateFormat },
    { label: "End Date", name: "endDate", groupDisplayFormat: dateFormat },
    { label: "Equipment Length", name: "equipmentLength" },
    { label: "Frequency", name: "frequency" },
    { label: "Number of Spaces", name: "numberOfSpaces" },
  ];

  const [waitListEntries, setWaitListEntries] = useState();
  const [searchBox, setSearchBox] = useState("");
  const [filteredData, setFilteredData] = useState();
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);

  useEffect(() => {
    let requestObject = {
      startDate: startDate,
      endDate: endDate,
    };
    Busy.set(true);
    getWaitListReportData(requestObject)
      .then(
        (promise) => {
          let data = promise.body;
          setWaitListEntries(data);
          setFilteredData(data);
          Busy.set(false);
        },
        (error) => {
          toast(getErrorMessageForStandardResponse(error));
          Busy.set(false);
        }
      )
      .catch((error) => {
        toast(getErrorMessageForStandardResponse(error));
        Busy.set(false);
      });
  }, [setWaitListEntries, startDate, endDate]);

  useEffect(() => {
    if (waitListEntries && searchBox) {
      setFilteredData(searchRecordsByKeyword(searchBox, waitListEntries));
    } else {
      setFilteredData(waitListEntries);
    }
  }, [waitListEntries, searchBox]);

  const searchChangeHandler = (event) => {
    const value = event.target.value;
    setSearchBox(value);
  };

  const handleStartDate = (date) => {
    let day1 = extractDay(date);
    let day2 = extractDay(startDate);

    if (!(day1 === day2)) {
      setStartDate(date);
    }
  };

  const handleEndDate = (date) => {
    let day1 = extractDay(date);
    let day2 = extractDay(endDate);

    if (!(day1 === day2)) {
      setEndDate(date);
    }
  };
  return (
    <ReportBackgroundContainer>
      <header>
        <ul className="breadcrumb">
          <li>Report</li>
          <li>WaitList Report</li>
        </ul>
        <h1 className="ss-report-header-title">WaitList Items</h1>
      </header>
      <div style={{ width: "49%" }}>
        <ReportFormContainer>
          <ReportFormDateInput
            label={"START DATE"}
            date={startDate}
            onDateChange={handleStartDate}
          />
          <ReportFormDateInput
            label={"END DATE"}
            date={endDate}
            onDateChange={handleEndDate}
          />
        </ReportFormContainer>
      </div>
      {waitListEntries?.length > 0 && (
        <Filter
          searchBox={searchBox}
          searchChangeHandler={searchChangeHandler}
          placeHolder="Type any field to filter wait list report result"
        />
      )}
      {!!filteredData && filteredData?.length > 0 ? (
        <>
          <WaitListWithGroupAndSort
            reportFields={reportFields}
            waitListEntries={filteredData}
            reportTitle={"WaitListReport"}
            reportName={"Wait List"}
          />
          {filteredData?.length > 0 && (
            <div style={{ textAlign: "center" }}>
              {" "}
              You have reached end of the list
            </div>
          )}
          <div className="ss-supplier-active-bookings-endlist">
            <h6>{!!filteredData && filteredData.length > 0 ? " " : " "}</h6>
          </div>
        </>
      ) : (
        <ReportEmpty />
      )}
    </ReportBackgroundContainer>
  );
};

export default WaitListReport;
