import React from 'react'
import commonButtonPropTypes from './util/button-prop-types'
import StyledButton, {ButtonStyles} from "./StyledButton";

const SolidButton = (props) => {
  return <StyledButton style={ButtonStyles.SOLID} { ...props} />
}

SolidButton.propTypes = commonButtonPropTypes

export default SolidButton
