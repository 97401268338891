import React from "react";
import moment from "moment";
import * as PropTypes from "prop-types";
import { DateFormats } from "../constants/securspace-constants";

const DateDisplay = (props) => {
  const { date, format = DateFormats.DATE_RESERVATION } = props;

  return <span>{moment(new Date(date)).format(format)}</span>;
};

DateDisplay.propTypes = {
  date: PropTypes.string.isRequired,
  format: PropTypes.string,
};

export default DateDisplay;
