import React from "react";
import ReportData from "../../../report/ReportData";
import ReportDataRow from "../../../report/ReportDataRow";
import ReportRow from "../../../report/ReportRow";
import { displayDaysOfWeek } from "../../../../util/DateUtils";

const FineReportPeakPricingWindowRow = (props) => {
  const { peakPricingWindow } = props;
  const { daysOfWeek, startTime, endTime } = peakPricingWindow;

  return (
    <ReportRow name="fineInfraction">
      <ReportDataRow>
        <ReportData label={"Days Of Week"}>
          {displayDaysOfWeek(daysOfWeek)}
        </ReportData>
        <ReportData label={"Start Time"}>{startTime}</ReportData>
        <ReportData label={"End Time"}>{endTime}</ReportData>
      </ReportDataRow>
    </ReportRow>
  );
};
export default FineReportPeakPricingWindowRow;
