import React, { useEffect, useState } from "react";
import { toastErrorMessage } from "../../util/NetworkErrorUtil";
import moment from "moment";
import Busy from "../Busy";
import useQuery from "../../util/query/useQuery";
import ReportBackgroundContainer from "../report/ReportBackgroundContainer";
import ReportHeader from "../report/ReportHeader";
import ReportFormContainer from "../report/ReportFormContainer";
import ReportFormDateInput from "../report/ReportFormDateInput";
import { DateFormats } from "../constants/securspace-constants";
import { getZoneAudits, getZoneById } from "../zonebuilder/zoneRequest";
import ZoneAuditCard from "../zonebuilder/zones/ZoneAuditCard";
import ZoneCard from "../zonebuilder/zones/ZoneCard";

const ZoneAudit = () => {
  const defaultStartDate = moment()
    .subtract(30, "days")
    .format(DateFormats.DAY);
  const defaultEndDate = moment().format(DateFormats.DAY);

  const { zoneId } = useQuery();
  const [currentZone, setCurrentZone] = useState({});
  const [zoneAudits, setZoneAudits] = useState([]);
  const [dataFilteredByDate, setDataFilteredByDate] = useState([]);
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const getZone = (id) => {
      getZoneById(id)
        .then((res) => {
          setCurrentZone(res.body);
        }, toastErrorMessage)
        .catch(toastErrorMessage);
    };

    const getAudits = (id) => {
      getZoneAudits(id)
        .then((res) => {
          setZoneAudits(res.body);
        }, toastErrorMessage)
        .catch(toastErrorMessage);
    };

    Busy.set(true);

    Promise.all([getZone(zoneId), getAudits(zoneId)]).then(() =>
      Busy.set(false)
    );
  }, [zoneId]);

  useEffect(() => {
    let data = zoneAudits;

    if (startDate !== "") {
      data = data
        ?.filter((ele) => ele?.name === currentZone?.name)
        ?.filter((zone) =>
          moment(zone.lastModifiedDate).add(0, "day").isAfter(startDate)
        );
    }
    if (endDate !== "") {
      data = data
        ?.filter((ele) => ele?.name === currentZone?.name)
        ?.filter((zone) =>
          moment(zone.lastModifiedDate).subtract(0, "day").isBefore(endDate)
        );
    }

    setDataFilteredByDate(data);
  }, [startDate, endDate, zoneAudits]);

  const handleStartDate = (date) => {
    setStartDate(date);
  };

  const handleEndDate = (date) => {
    setEndDate(date);
  };

  return (
    <div>
      <ReportBackgroundContainer>
        <div style={{ marginTop: "10px" }}>
          <ReportFormContainer>
            <ReportFormDateInput
              label={"START DATE"}
              date={startDate}
              onDateChange={handleStartDate}
            />
            <ReportFormDateInput
              label={"END DATE"}
              date={endDate}
              onDateChange={handleEndDate}
            />
          </ReportFormContainer>
        </div>
        <ReportHeader>Current Zone</ReportHeader>
        <ZoneCard zone={currentZone} />
        <ReportHeader>Zone History</ReportHeader>
        <div>Highlighting Indicates Modified Field</div>
        {dataFilteredByDate.length > 0 ? (
          dataFilteredByDate.map((zoneAudit, key) => {
            return <ZoneAuditCard key={key} zone={zoneAudit} />;
          })
        ) : (
          <h3>
            There is no history of changes for selected zone in date range
          </h3>
        )}
      </ReportBackgroundContainer>
      {dataFilteredByDate?.length > 0 ? (
        <div className="ss-load-more-records">
          You have reached end of the list
        </div>
      ) : (
        <div className="ss-supplier-active-bookings-endlist"></div>
      )}
    </div>
  );
};

export default ZoneAudit;
