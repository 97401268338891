import React, {useContext} from 'react'
import {AppContext} from "../../context/app-context";
import {ModalRoute} from 'react-router-modal'
import {useHistory} from "react-router-dom";
import {getLandingRedirectPathForUser} from "../route-utils";

import ContextualSignUp from "../../components/modals/context-wrappers/ContextualSignUp";
import ContextualAccountConfirmation from "../../components/modals/context-wrappers/ContextualAccountConfirmation";
import {DefaultModalClassNames} from "../../components/modals/modals";

const ModalContent = () => {

    const appContext = useContext(AppContext)
    const { user } = appContext
    const history = useHistory()

    const globalCancel = () => history.push(getLandingRedirectPathForUser(user))

    return [
        <ModalRoute path="/signup"
                    parentPath={getLandingRedirectPathForUser(user)}
                    key="modal_/signup"
                    { ...DefaultModalClassNames }>
            <ContextualSignUp
                cancel={globalCancel}
                handleNavigateToSignup={() => history.push("/signup")}
                handleNavigateToLogin={() => history.push("/login")}
            />
        </ModalRoute>,
        <ModalRoute path="/confirm-account"
                    parentPath="/"
                    key="modal_/confirm_account"
                    onBackdropClick={() => {}}
                    { ...DefaultModalClassNames }>
            <ContextualAccountConfirmation />
        </ModalRoute>
    ]
}


export default ModalContent
