import React, { useEffect, useState } from "react";
import ReportBackgroundContainer from "../../../components/report/ReportBackgroundContainer";
import SolidButton from "../../../components/form/SolidButton";

// this component is for testing purposes. we can delete this later on after mobile programming gets their end setup.
const TestCheckout = () => {
  const [reservationId, setReservationId] = useState("");
  const [jwt, setJwt] = useState("");
  const [jsMessage, setJsMessage] = useState([]);
  const [popupRef, setPopupRef] = useState({});

  useEffect(() => {
    window.addEventListener("message", onJsMessage);
  }, []);

  const onReservationIdChange = (event) => {
    setReservationId(event.target.value);
  };

  const onJwtChange = (event) => {
    setJwt(event.target.value);
  };

  const onJsMessage = (event) => {
    if (event.data.source && !event.data.source.startsWith("react"))
      setJsMessage((prevMessage) => [...prevMessage, event.data]);
  };

  const onOpenCheckout = () => {
    const newWindow = window.open(`/checkout/${reservationId}?token=${jwt}`);
    newWindow.Android = {
      onSuccess: (data) => {
        //console.log(`Android: ${data.status}: ${data.data}`)
      },
      onCancel: (data) => {
        //console.log(`Android: ${data.status}: ${data.data}`)
      },
    };
    setPopupRef(newWindow);
  };

  const onCloseCheckout = () => {
    popupRef.closeCheckoutWindow();
  };

  return (
    <ReportBackgroundContainer>
      <label>
        ReservationId:
        <input type="text" onChange={onReservationIdChange} />
      </label>
      <label>
        JWT:
        <input type="text" onChange={onJwtChange} />
      </label>
      <SolidButton label={"Open Checkout"} onClick={onOpenCheckout} />
      <SolidButton label={"Close Checkout"} onClick={onCloseCheckout} />
      <span>{JSON.stringify(jsMessage)}</span>
    </ReportBackgroundContainer>
  );
};

export default TestCheckout;
