import Request from "superagent";
const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const requestLocations = (): Promise => {
  return Request.get(`${baseUrl}/api/location`).withCredentials();
};

export const requestLocation = (id) => {
  return Request.get(`${baseUrl}/api/location/${id}`).withCredentials();
};
