import React, { useEffect, useState } from "react";
import { parseCurrencyValue } from "../../../util/PaymentUtils";
import PropTypes from "prop-types";
import Input from "../../common/Input";
import PeakPricingTimeSelect from "./PeakPricingTimeSelect/PeakPricingTimeSelect";
import OutlinedButton from "../../form/OutlinedButton";
import { checkTimeIsValid } from "../../../util/TimeUtils";
import PeakPricingWeekdayForm from "./PeakPricingTimeSelect/PeakPricingWeekdayForm";
import FineReportPeakPricingWindowRow from "./FineComponents/FineReportPeakPricingWindowRow";
import "../../../css/components/fine.css";

const PeakPricingWindowInputs = (props) => {
  const {
    fine,
    peakPricingWindows,
    addPeakPricingWindows,
    addPeakPricingAdditionalCharge,
    peakPricingAdditionalCharge,
  } = props;

  const [pricingWindow, setPricingWindow] = useState({
    fineId: fine?.id,
    daysOfWeek: [],
    startTime: "01:00:00",
    endTime: "01:00:00",
  });
  const [pricingWindows, setPricingWindows] = useState(
    peakPricingWindows ? peakPricingWindows : []
  );
  const [disableSubmit, setDisableSubmit] = useState(false);

  let peakPricingAdditionalChargeRef = React.createRef();

  const addPricingWindow = () => {
    let newPricingWindowArray = [...pricingWindows];
    newPricingWindowArray.push(pricingWindow);

    setPricingWindows(newPricingWindowArray);

    peakPricingAdditionalChargeRef.current.value = "";

    addPeakPricingWindows(newPricingWindowArray);
  };

  const deletePricingWindow = () => {
    let newPricingWindowArray = [...pricingWindows];
    newPricingWindowArray.pop();

    setPricingWindows(newPricingWindowArray);

    addPeakPricingWindows(newPricingWindowArray);
  };

  const handleChangeWeekday = (event) => {
    event.persist();
    const { daysOfWeek } = pricingWindow;
    if (event.target.checked === true) {
      setPricingWindow((prevState) => ({
        ...prevState,
        daysOfWeek: [...daysOfWeek, event.target.value],
      }));
    } else if (event.target.checked === false) {
      let freshWeekdayArray = daysOfWeek.filter(
        (val) => val !== event.target.value
      );
      setPricingWindow((prevState) => ({
        ...prevState,
        daysOfWeek: [...freshWeekdayArray],
      }));
    }
  };

  const onChangeTime = (startOrEnd, time) => {
    setPricingWindow((prevState) => ({
      ...prevState,
      [startOrEnd]: time,
    }));
  };

  const onPeakPricingAdditionalChargeChange = (event) => {
    const value = event.target.value;
    const parsed = parseCurrencyValue(value);
    addPeakPricingAdditionalCharge(parsed * 100);
  };

  useEffect(() => {
    const { startTime, endTime } = pricingWindow;
    const validTimeRange = checkTimeIsValid(startTime, endTime);

    if (validTimeRange && pricingWindow?.daysOfWeek?.length > 0) {
      setDisableSubmit(false);
    } else {
      setDisableSubmit(true);
    }
  }, [pricingWindow]);

  return (
    <div>
      <Input
        ref={peakPricingAdditionalChargeRef}
        label={"Peak Pricing Additional Charge: "}
        name={"peakPricingAdditionalCharge"}
        value={
          peakPricingAdditionalCharge ? peakPricingAdditionalCharge / 100 : 0
        }
        className="form-control"
        type="text"
        placeholder="$20"
        compName="peakPricingCharge"
        onChange={onPeakPricingAdditionalChargeChange}
      />
      <hr />
      <h3>Peak Pricing Windows: </h3>
      <PeakPricingWeekdayForm handleChangeWeekday={handleChangeWeekday} />
      <div className={"ss-add-fine-time-select-container"}>
        <PeakPricingTimeSelect
          label="Start Time: "
          name="startTime"
          className="form-control"
          type="text"
          onChangeTime={onChangeTime}
        />
        <PeakPricingTimeSelect
          label="End Time: "
          name="endTime"
          className="form-control"
          type="text"
          onChangeTime={onChangeTime}
        />
      </div>
      <hr />
      <div>
        {pricingWindows
          ? pricingWindows.map((peakPricingWindow, key) => {
              return (
                <FineReportPeakPricingWindowRow
                  key={key}
                  peakPricingWindow={peakPricingWindow}
                />
              );
            })
          : ""}
      </div>
      <hr />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <OutlinedButton
          label={"ADD PRICING WINDOW"}
          onClick={addPricingWindow}
          disabled={disableSubmit}
        />
        <br />
        {pricingWindows?.length > 0 && (
          <OutlinedButton
            label={"DELETE PRICING WINDOW"}
            onClick={deletePricingWindow}
          />
        )}
      </div>
    </div>
  );
};

PeakPricingWindowInputs.propTypes = {
  peakPricingWindows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      fineId: PropTypes.string,
      daysOfWeek: PropTypes.arrayOf(PropTypes.string),
      startTime: PropTypes.string,
      endTime: PropTypes.string,
    })
  ),
  addPeakPricingWindows: PropTypes.func,
  addPeakPricingAdditionalCharge: PropTypes.func,
};

export default PeakPricingWindowInputs;
