import React from "react";
import FrequencySelect from "./FrequencySelect";
import DurationSelect from "./DurationSelect";

const FrequencyDuration = (props) => {
  const {
    frequencyTypes,
    durationTypes,
    selectedFrequency,
    selectedDuration,
    onFrequencyChange,
    onDurationChange,
  } = props;

  return (
    <>
      <FrequencySelect
        frequencyTypes={frequencyTypes}
        selectedFrequency={selectedFrequency}
        onFrequencyChange={onFrequencyChange}
      />
      <hr />
      {/* {selectedFrequency === 'RECURRING' ?
      <DurationSelect
        durationTypes={durationTypes}
        selectedDuration={selectedDuration}
        onDurationChange={onDurationChange}
      /> : ''
    } */}
    </>
  );
};

export default FrequencyDuration;
