import React from "react";
import Select from "../../Select";

const AssetTypeSelect = (props) => {
  const { options, assetType, handleAssetTypeChange, selectedDriver } = props;
  return (
    <div className="checkin-selector">
      <label style={!selectedDriver ? { marginBottom: "14px" } : {}}>
        {" "}
        EQUIPMENT TYPE{" "}
      </label>
      {options && !!selectedDriver ? (
        <Select
          id="assetType"
          name="assetType"
          className="ss-book-space-form-asset-type"
          optionsWidth="300px"
          handleChange={handleAssetTypeChange}
          selectedOption={assetType}
          placeholder="Select the equipment type"
          options={options}
        />
      ) : null}
    </div>
  );
};

export default AssetTypeSelect;
