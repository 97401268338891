import React, { useEffect } from "react";
import "../../css/views/login.css";
import "../../css/theme/mainContent.css";
import "../../css/theme/forms.css";
import "../../css/theme/forms-block.css";
import "../../css/theme/buttons.css";
import "../../css/views/loginAsAccount.css";
import Select from "../Select";

const SelectZone = (props) => {
  const { zones, selectedZone, onZoneChange } = props;

  return (
    <div className="ss-zone-as-container">
      {zones && zones.length > 0 ? (
        <div id="ssLoginFormContainer">
          <h2>Select Zone</h2>
          <form className="ss-form ss-block" autoComplete="off">
            <fieldset className="ss-stand-alone">
              <div>
                <label>SELECT ZONE</label>
                <Select
                  id="selectZone1"
                  name="selectZone1"
                  handleChange={onZoneChange}
                  className="ss-account-select"
                  selectedOption={selectedZone}
                  placeholder="Choose"
                  options={zones?.sort((a, b) =>
                    a.displayValue.localeCompare(b.displayValue)
                  )}
                  canSearch="1"
                />
              </div>
            </fieldset>
          </form>
        </div>
      ) : (
        <h3>There are no zones available based on selected criteria</h3>
      )}
    </div>
  );
};

export default SelectZone;
