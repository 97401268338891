import React, { useEffect, useMemo, useState } from "react";
import GroupAndSort from "../../../views/bookings/GroupAndSort";
import {
  SORT_DIRECTION_ASC,
  SORT_DIRECTION_DESC,
} from "../../../util/report/ReportUtils";
import _ from "underscore";
import ReportEmpty from "../ReportEmpty";
import {
  compareObjectsAscending,
  compareObjectsDescending,
  mMdDYYYYFormatAscending,
  mMdDYYYYFormatDescending,
} from "../../constants/securspace-constants";
const validSortNames = ["twicExpirationDate"];

export const withGroupAndSort =
  (Component, dataProp = "data") =>
  (props) => {
    const { [dataProp]: data = [], reportName, reportFields, ...rest } = props;
    const lengthOrder = [
      "",
      "TWENTY_FIVE_FOOT",
      "FORTY_FIVE_FOOT",
      "FIFTY_THREE_FOOT",
      "EIGHTY_THREE_FOOT",
    ];

    const [groupBy, setGroupBy] = useState("");
    const [sortBy, setSortBy] = useState("");
    const [sortDirection, setSortDirection] = useState(SORT_DIRECTION_ASC);
    const [groupDirection, setGroupDirection] = useState(SORT_DIRECTION_ASC);
    const [total, setTotal] = useState(0);
    useEffect(() => {}, [sortBy?.name]);

    const sortedAndGroupedData = useMemo(() => {
      let tempData = data;
      if (tempData) {
        if (sortBy?.name) {
          if (sortDirection === SORT_DIRECTION_ASC) {
            tempData = tempData.sort((a, b) => {
              if (validSortNames?.includes(sortBy?.name)) {
                return mMdDYYYYFormatAscending(a, b, sortBy);
              } else if (sortBy?.name === "equipmentLength") {
                return (
                  lengthOrder.indexOf(a.equipmentLength) -
                  lengthOrder.indexOf(b.equipmentLength)
                );
              } else {
                return compareObjectsAscending(a, b, sortBy);
              }
            });
          }
          if (sortDirection === SORT_DIRECTION_DESC) {
            tempData = tempData.sort((a, b) => {
              if (validSortNames?.includes(sortBy?.name)) {
                return mMdDYYYYFormatDescending(a, b, sortBy);
              } else if (sortBy?.name === "equipmentLength") {
                return (
                  lengthOrder.indexOf(b.equipmentLength) -
                  lengthOrder.indexOf(a.equipmentLength)
                );
              } else {
                return compareObjectsDescending(a, b, sortBy);
              }
            });
          }

          tempData = { [dataProp]: tempData };
        } else {
          tempData = { [dataProp]: tempData };
        }

        return tempData;
      }
    }, [data, sortBy, groupBy, sortDirection, groupDirection]);

    useEffect(() => {
      setTotal(data?.length);
    }, [data.length]);

    const groupByChangeHandler = (event) => {
      const value = event.target.value;
      setGroupBy(value);
    };

    const sortByChangeHandler = (event) => {
      const value = event.target.value;
      setSortBy(value);
    };

    const sortDirectionChangeHandler = () => {
      setSortDirection((prevSortDirection) =>
        prevSortDirection === SORT_DIRECTION_ASC
          ? SORT_DIRECTION_DESC
          : SORT_DIRECTION_ASC
      );
    };

    const groupDirectionChangeHandler = () => {
      setGroupDirection((prevGroupDirection) =>
        prevGroupDirection === SORT_DIRECTION_ASC
          ? SORT_DIRECTION_DESC
          : SORT_DIRECTION_ASC
      );
    };

    return (
      <>
        {total > 0 && (
          <GroupAndSort
            groupByOptions={reportFields}
            groupByChangeHandler={groupByChangeHandler}
            groupBy={groupBy}
            groupDirection={groupDirection}
            groupDirectionChangeHandler={groupDirectionChangeHandler}
            sortBy={sortBy}
            sortByChangeHandler={sortByChangeHandler}
            sortDirection={sortDirection}
            sortDirectionChangeHandler={sortDirectionChangeHandler}
            asc={SORT_DIRECTION_ASC}
            desc={SORT_DIRECTION_DESC}
          />
        )}
        {!total && <ReportEmpty />}
        {!!total && (
          <Component
            {...rest}
            reportName={reportName}
            {...sortedAndGroupedData}
            grouped={groupBy}
            groupBy={groupBy}
            sortedAndGroupedData={sortedAndGroupedData}
            reportFields={reportFields}
          />
        )}
      </>
    );
  };
