import React from "react";
import ReportRow from "../report/ReportRow";
import ReportDataRow from "../report/ReportDataRow";
import ReportData from "../report/ReportData";
import {
  formatBool,
  formatEquipmentLength,
  formatFrequencyByDuration,
} from "../../util/booking/bookingUtil";

const ReservationConfirmationCard = (props) => {
  const {
    selectedZone,
    powerAccessible,
    selectedEquipmentLength,
    numberOfSpaces,
    startDate,
    endDate,
    selectedFrequency,
    selectedDuration,
    price,
    zonePriceOverride,
    zoneOverride,
    selectedZone1,
  } = props;
  function format(inputDate) {
    var date = new Date(inputDate);
    if (!isNaN(date.getTime())) {
      const getMonthVal =
        Number(date.getMonth() + 1) < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      const getDateVal =
        Number(date.getDate()) < 10 ? "0" + date.getDate() : date.getDate();

      return getMonthVal + "/" + getDateVal + "/" + date.getFullYear();
    }
  }

  return (
    <>
      <h2>Reservation Confirmation</h2>
      <hr />
      <ReportRow>
        <ReportDataRow>
          <ReportData label={"Zone"}>
            {!!zoneOverride
              ? selectedZone?.displayValue
              : selectedZone1?.displayValue}
          </ReportData>
          <ReportData label={"Power Accessible"}>
            {formatBool(powerAccessible)}
          </ReportData>
          <ReportData label={"Equipment Length"}>
            {formatEquipmentLength(selectedEquipmentLength)}
          </ReportData>
          <ReportData label={"Number Of Spaces"}>{numberOfSpaces}</ReportData>
          <ReportData label={"Start Date"}>{format(startDate)}</ReportData>
          {selectedFrequency !== "RECURRING" && (
            <ReportData label={"End Date"}>{format(endDate)}</ReportData>
          )}
          <ReportData label={"Frequency"}>
            {formatFrequencyByDuration(selectedFrequency, selectedDuration)}
          </ReportData>
          {/* <ReportData label={"Duration"}>
            {selectedFrequency === "RECURRING" ? "MONTHLY" : "DAILY"}
          </ReportData> */}
          <ReportData label={"Price"}>
            {!!zonePriceOverride ? price : ""}
          </ReportData>
        </ReportDataRow>
      </ReportRow>
    </>
  );
};

export default ReservationConfirmationCard;
