import React from "react";

const PeakPricingWeekdaySelect = (props) => {
  const { day, handleChangeWeekday } = props;
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <input
        type="checkbox"
        name={day}
        value={day}
        onChange={handleChangeWeekday}
        style={{
          height: "20px",
          width: "20px",
          accentColor: "white",
        }}
      />
      <label
        htmlFor={day}
        className="sps-fine-weekday-label"
        style={{ marginTop: "6px" }}
      >
        {" "}
        {day}
      </label>
      <br></br>
    </div>
  );
};

export default PeakPricingWeekdaySelect;
