import React from "react";
import ReportFlexRightContainer from "../report/ReportFlexRightContainer";
import ReportKebabButton from "../report/ReportKebabButton";
import { MenuItem } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const ZoneAvailabilityReportMenu = (props) => {
  const { zone } = props;

  const history = useHistory();

  const MyOptions = {
    HISTORY: "View History",
  };

  const handleViewHistory = (zone) => {
    history.push("/audits/zones", { zoneId: zone.id });
  };

  return (
    <ReportFlexRightContainer>
      <ReportKebabButton>
        <MenuItem onClick={() => handleViewHistory(zone)}>
          {MyOptions.HISTORY}
        </MenuItem>
      </ReportKebabButton>
    </ReportFlexRightContainer>
  );
};

export default ZoneAvailabilityReportMenu;
