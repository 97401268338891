import React, { useEffect, useState } from "react";
import ReportBackgroundContainer from "../../components/report/ReportBackgroundContainer";
import { ApprovedDriversListWithGroupAndSort } from "../../components/drivers/ApprovedDriversList";
import { requestDrivers } from "../../components/drivers/requests/driver-requests";
import SolidButton from "../../components/form/SolidButton";
import Busy from "../../components/Busy";
import Filter from "../bookings/Filter";
import { searchRecordsByKeyword } from "../../util/SearchUtils";
import moment from "moment";
import { DateFormats } from "../../components/constants/securspace-constants";
import ReportEmpty from "../../components/report/ReportEmpty";
import "../../css/views/accountReport.css";
import "../../css/components/report/report-header.css";

const ApprovedDriversContent = () => {
  const dateTimeFormat = (date) =>
    date && moment(new Date(date)).format(DateFormats.DATE);
  const dateFormat = (date) => date && moment(date).format(DateFormats.DAY);
  const reportFields = Object.freeze([
    { label: "Carrier", name: "carrier" },
    { label: "Date Of Birth", name: "dob", groupDisplayFormat: dateFormat },
    {
      label: "License Expiration Date",
      name: "expirationDate",
      groupDisplayFormat: dateTimeFormat,
    },
    { label: "First Name", name: "firstName" },
    { label: "Headshot Identifier", name: "headshotIdentifier" },
    { label: "Last Name", name: "lastName" },
    { label: "License Number", name: "licenseNumber" },
    { label: "State", name: "state" },
    { label: "Status", name: "status" },
    { label: "Twic Card Number", name: "twicCardNumber" },
    {
      label: "Twic Expiration Date",
      name: "twicExpirationDate",
      groupDisplayFormat: dateTimeFormat,
    },
  ]);
  const [drivers, setDrivers] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [page, setPage] = useState(0);
  const [reportTitleSubtext, setReportTitleSubtext] = useState("ALL");
  const [searchBox, setSearchBox] = useState("");
  const [filteredDrivers, setFilteredDrivers] = useState([]);
  const PAGE_SIZE = 50;

  useEffect(() => {
    Busy.set(true);
    requestDrivers(0, PAGE_SIZE).then((request) => {
      setDrivers(request.body.content);
      setPageInfo({
        first: request.body.first,
        last: request.body.last,
        totalElements: request.body.totalElements,
      });
      Busy.set(false);
    });
  }, []);

  useEffect(() => {
    if (drivers.length === pageInfo.totalElements) {
      setReportTitleSubtext("ALL");
    } else {
      setReportTitleSubtext(`${drivers.length} of ${pageInfo.totalElements}`);
    }
  }, [pageInfo, drivers]);

  useEffect(() => {
    if (drivers && searchBox) {
      setFilteredDrivers(searchRecordsByKeyword(searchBox, drivers));
    } else {
      setFilteredDrivers(drivers);
    }
  }, [drivers, searchBox]);

  const searchChangeHandler = (event) => {
    const value = event.target.value;
    setSearchBox(value);
  };

  const handleLoadMore = () => {
    Busy.set(true);
    requestDrivers(page + 1, PAGE_SIZE).then((request) => {
      setDrivers((prevDrivers) => [...prevDrivers, ...request.body.content]);
      setPageInfo({
        first: request.body.first,
        last: request.body.last,
        totalElements: request.body.totalElements,
      });
      setPage((prevPage) => prevPage + 1);
      Busy.set(false);
    });
  };

  const showLoadMore = () => {
    return !pageInfo.last;
  };

  return (
    <ReportBackgroundContainer>
      <header>
        <ul className="breadcrumb">
          <li>GATE MANAGEMENT</li>
          <li>DRIVERS</li>
        </ul>
        <h1 className="ss-report-header-title">Approved Drivers</h1>
      </header>
      {!!drivers && drivers?.length > 0 ? (
        <>
          <Filter
            searchBox={searchBox}
            searchChangeHandler={searchChangeHandler}
            placeHolder="Type any field to filter approved driver result"
          />
          <ApprovedDriversListWithGroupAndSort
            drivers={filteredDrivers}
            reportFields={reportFields}
            reportTitleCount={reportTitleSubtext}
            reportName="Approved Drivers"
          />
          {filteredDrivers?.length > 0 && showLoadMore() && (
            <div style={{ marginBottom: "52px" }}>
              <SolidButton
                label={"Load More"}
                compType="driver"
                onClick={handleLoadMore}
              />
              <br />
            </div>
          )}
          {filteredDrivers?.length > 0 && !showLoadMore() && (
            <div className="ss-load-more-records">
              You have reached end of the list
            </div>
          )}
        </>
      ) : (
        <ReportEmpty />
      )}
    </ReportBackgroundContainer>
  );
};

export default ApprovedDriversContent;
