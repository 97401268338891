import React, { Component } from "react";
import "../css/components/select.css";
import { ArrowDropDown } from "@material-ui/icons";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";

const KEY_CODE_ENTER = 13;
const KEY_CODE_UP_ARROW = 38;
const KEY_CODE_DOWN_ARROW = 40;

class Select extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      id: props.id,
      items: props.options,
      allItems: props.options,
      firstOption: props.selectedOption ? props.selectedOption : {},
      canSearch:
        typeof props.canSearch !== "undefined" && props.canSearch === "1",
    };

    this.selectedOptionIndex = "";

    this.applyFilters = this.applyFilters.bind(this);
    this.searchKeyPress = this.searchKeyPress.bind(this);
  }

  safeSetState = (stateUpdates) => {
    if (this.mounted) {
      this.setState(stateUpdates);
    }
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.options !== this.state.items) {
      this.setState({ items: nextProps.options });
    }
  }

  componentDidMount() {
    this.mounted = true;
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (this.props.options !== newProps.options) {
      this.safeSetState({ allItems: newProps.options });
      this.safeSetState({ firstOption: newProps.options[0] });
      if (this.props.id !== "selectZone1") {
        this.safeSetState({ items: newProps.options });
      }
    }
  }

  // componentWillUnmount() {
  //   this.mounted = false;
  //   document.body.removeEventListener(
  //     "click",
  //     function (event) {
  //       event.preventDefault();
  //     },
  //     this.hideOptions
  //   );
  // }

  showOptions = () => {
    // this.safeSetState({ show: !this.state.show });
    // document.body.addEventListener("click");
    if (!this.state.show) {
      document.addEventListener("click", this.hideOptions, false);
    } else {
      document.removeEventListener("click", this.hideOptions, false);
    }

    this.setState((prevState) => ({
      show: !prevState.show,
    }));
  };

  hideOptions = (e) => {
    if (!this.node?.contains(e?.target)) this.showOptions();

    // this.safeSetState({ show: false });
    // document.body.removeEventListener(
    //   "click",
    //   function (event) {
    //     event.preventDefault();
    //   },
    //   this.hideOptions
    // );
  };

  selectOption = (selectedOption, index, hideOptions, scrollIntoView) => {
    this.selectedOptionIndex = index;
    this.props.handleChange({
      target: {
        name: this.props.name,
        type: "select",
        value: selectedOption,
      },
    });
    if (this.state.canSearch)
      document.getElementById(this.state.id || "searchId").value =
        selectedOption.displayValue;
    if (hideOptions) {
      this.hideOptions();
    }
    let selectedListItem = document.getElementById(selectedOption.value);
    if (scrollIntoView && selectedListItem) {
      selectedListItem.scrollIntoView(false);
    }
  };

  getSelectClass = () => {
    return (
      this.props.className +
      " ss-select " +
      (this.state.canSearch ? "with-search" : "")
    );
  };

  getSelectInputClass = () => {
    return this.props.className
      ? this.props.className +
          " ss-select-text " +
          (this.props.placeholder ? "" : "ss-select-caret")
      : "";
  };

  applyFilters = (event) => {
    this.showOptions();
    let updatedList = this.state.allItems,
      myObj = this,
      searchFilter = event.target.value.toLowerCase();

    if (searchFilter.length > 0) {
      let count = 0;
      updatedList = updatedList.filter(function (item) {
        if (item?.displayValue?.toLowerCase()?.search(searchFilter) !== -1) {
          if (count === 0) {
            myObj.state.firstOption = item;
          }
          count++;
          return true;
        } else return false;
      });
    }
    this.safeSetState({ items: updatedList });
  };

  searchKeyPress = (event) => {
    if (event.keyCode === KEY_CODE_ENTER || event.which === KEY_CODE_ENTER) {
      if (this.state.items && this.state.items.length > 0) {
        let newSelectedOptionIndex = this.isCurrentSelectedIndexValid()
          ? this.selectedOptionIndex
          : 0;
        this.selectOption(
          this.state.items[newSelectedOptionIndex],
          newSelectedOptionIndex,
          true,
          false
        );
      }
    } else if (
      event.keyCode === KEY_CODE_UP_ARROW ||
      event.which === KEY_CODE_UP_ARROW
    ) {
      if (this.state.items && this.state.items.length > 0) {
        let newSelectedOptionIndex = this.shouldDecrementCurrentIndex()
          ? this.selectedOptionIndex - 1
          : 0;
        this.selectOption(
          this.state.items[newSelectedOptionIndex],
          newSelectedOptionIndex,
          false,
          true
        );
      }
    } else if (
      event.keyCode === KEY_CODE_DOWN_ARROW ||
      event.which === KEY_CODE_DOWN_ARROW
    ) {
      if (this.state.items && this.state.items.length > 0) {
        let newSelectedOptionIndex = this.shouldIncrementCurrentIndex()
          ? this.selectedOptionIndex + 1
          : 0;
        this.selectOption(
          this.state.items[newSelectedOptionIndex],
          newSelectedOptionIndex,
          false,
          true
        );
      }
    } else {
      this.props.handleChange({
        target: {
          name: this.props.name,
          type: "select",
          value: { value: null, displayValue: "" },
        },
      });
    }
  };

  isCurrentSelectedIndexValid = () => {
    return (
      typeof this.selectedOptionIndex === "number" &&
      this.selectedOptionIndex >= 0 &&
      this.selectedOptionIndex < this.state.items.length
    );
  };

  shouldIncrementCurrentIndex = () => {
    return (
      typeof this.selectedOptionIndex === "number" &&
      this.selectedOptionIndex + 1 > 0 &&
      this.selectedOptionIndex + 1 < this.state.items.length
    );
  };

  shouldDecrementCurrentIndex = () => {
    return (
      typeof this.selectedOptionIndex === "number" &&
      this.selectedOptionIndex - 1 > 0 &&
      this.selectedOptionIndex - 1 < this.state.items.length
    );
  };

  static getDisplayValue(option) {
    return option && typeof option.getDisplayValue === "function"
      ? option.getDisplayValue()
      : option;
  }

  render() {
    let inputElement;

    if (!this.state.canSearch) {
      //old version
      inputElement = (
        <>
          <input
            type="text"
            id={this.props.id}
            name={this.props.name}
            style={
              this.props?.comp === "location"
                ? this.props.name === "sortBy" || this.props.name === "groupBy"
                  ? {
                      border: "1px solid black",
                      fontWeight: "bold",
                      borderRadius: "7px",
                    }
                  : {}
                : this.props.name === "sortBy" || this.props.name === "groupBy"
                ? {
                    border: "1px solid black",
                    fontWeight: "bold",
                    borderRadius: "7px",
                  }
                : { fontWeight: "bold" }
            }
            value={Select.getDisplayValue(this.props.selectedOption)}
            onClick={this.showOptions}
            className={this.getSelectInputClass()}
            placeholder="Please select"
            readOnly
          />
          {this.props?.comp !== "editAssetType" ? (
            this.props.name === "sortBy" || this.props.name === "groupBy" ? (
              <ArrowDropDown
                style={{
                  position: "absolute",
                  marginLeft: "-28px",
                  marginTop: "2px",
                  cursor: "pointer",
                }}
                onClick={this.showOptions}
              />
            ) : this.props.name === "maxOneTimeDuration" ||
              this.props.name === "maxRecurringDuration" ? (
              <ArrowDropDown
                style={{
                  position: "absolute",
                  marginLeft: "20px",
                  marginTop: "-4px",
                  cursor: "pointer",
                }}
                onClick={this.showOptions}
              />
            ) : this.props.name === "selectedCriteriaFieldValue" ? (
              <ArrowDropDown
                style={{
                  position: "absolute",
                  marginLeft: "27px",
                  marginTop: "7px",
                  cursor: "pointer",
                }}
                onClick={this.showOptions}
              />
            ) : this.props.name === "assetSize" ? (
              this.props?.comp === "editAssetSize" ? (
                <ArrowDropDown
                  style={{
                    position: "absolute",
                    marginLeft: "-25px",
                    marginTop: "7px",
                    cursor: "pointer",
                  }}
                  onClick={this.showOptions}
                />
              ) : (
                <ArrowDropDown
                  style={{
                    position: "absolute",
                    marginLeft: "5px",
                    marginTop: "7px",
                    cursor: "pointer",
                  }}
                  onClick={this.showOptions}
                />
              )
            ) : this.props.comp === "manageUser" ? (
              !this.state.show ? (
                <ArrowDropDown
                  style={{
                    position: "absolute",
                    marginLeft: "-28px",
                    marginTop: "7px",
                    cursor: "pointer",
                  }}
                  onClick={this.showOptions}
                />
              ) : (
                <ArrowDropUpIcon
                  style={{
                    position: "absolute",
                    marginLeft: "-28px",
                    marginTop: "7px",
                    cursor: "pointer",
                  }}
                  onClick={this.showOptions}
                />
              )
            ) : (
              <ArrowDropDown
                style={{
                  position: "absolute",
                  marginLeft: "-28px",
                  marginTop: "7px",
                  cursor: "pointer",
                }}
                onClick={this.showOptions}
              />
            )
          ) : (
            <ArrowDropDown
              style={{
                position: "absolute",
                marginLeft: "-22px",
                marginTop: "7px",
                cursor: "pointer",
              }}
              onClick={this.showOptions}
            />
          )}
        </>
      );
    } else {
      // interactive search
      inputElement = (
        <>
          {this.props.id === "selectZone1" ? (
            <input
              type="text"
              id={this.props.id || "searchId"}
              name={this.props.name || "searchName"}
              style={
                this.props.name === "selectZone"
                  ? { fontWeight: "bold", width: "83%", marginLeft: "12px" }
                  : this.props?.comp === "location"
                  ? {}
                  : { fontWeight: "bold" }
              }
              value={
                !!this.props.selectedOption?.displayValue
                  ? Select.getDisplayValue(this.props.selectedOption)
                  : ""
              }
              //value={Select.getDisplayValue(this.props.selectedOption)}
              onClick={this.showOptions}
              placeholder="Please select"
              onChange={this.applyFilters}
              onKeyDown={this.searchKeyPress}
              autoComplete="off"
            />
          ) : (
            <input
              type="text"
              id={this.props.id || "searchId"}
              name={this.props.name || "searchName"}
              style={
                this.props.name === "selectZone"
                  ? { fontWeight: "bold", width: "83%", marginLeft: "12px" }
                  : { fontWeight: "bold" }
              }
              onClick={this.showOptions}
              placeholder={this.props.placeholder}
              onChange={this.applyFilters}
              onKeyDown={this.searchKeyPress}
              autoComplete="off"
            />
          )}

          {this.props.name === "selectZone" ? (
            <ArrowDropDown
              style={{
                position: "absolute",
                marginLeft: "-24px",
                marginTop: "2px",
                cursor: "pointer",
              }}
              onClick={this.showOptions}
            />
          ) : (
            <ArrowDropDown
              style={{
                position: "absolute",
                marginLeft: "-28px",
                marginTop: "7px",
                cursor: "pointer",
              }}
              onClick={this.showOptions}
            />
          )}
        </>
      );
    }

    return (
      <div
        className={this.getSelectClass()}
        style={
          this.props.name === "selectedCriteriaFieldValue"
            ? {
                marginLeft: "9px",
                marginTop: "-4px",
              }
            : {}
        }
        ref={(node) => {
          this.node = node;
        }}
      >
        {inputElement}
        <div
          className={
            this.state.show
              ? "ss-select-options-visible"
              : "ss-select-options-hidden"
          }
          style={
            this.props.name === "maxOneTimeDuration" ||
            this.props.name === "maxRecurringDuration"
              ? {
                  height: "73px",
                  marginTop: "17px",
                  marginLeft: "-24px",
                  width: "233px",
                }
              : this.props.name === "sortBy" || this.props.name === "groupBy"
              ? {
                  marginLeft: "-26px",
                  marginTop: "20px",
                  width: "calc(100% + 5px)",
                }
              : this.props.name === "selectedCriteriaFieldValue"
              ? {
                  marginTop: "30px",
                  marginLeft: "-36px",
                  height: "106px",
                }
              : this.props.name === "assetSize"
              ? this.props?.comp === "editAssetSize"
                ? {
                    height: "100px",
                    marginLeft: "-22px",
                    width: "100%",
                    marginTop: "32px",
                  }
                : this.props?.comp === "checkIn"
                ? {
                    height: "100px",
                    marginLeft: "-1px",
                    width: "105%",
                  }
                : this.props?.comp === "editAssetType"
                ? {
                    height: "100px",
                    marginLeft: "1px",
                    width: "100%",
                    marginTop: "34px",
                  }
                : this.props.compName === "waitList"
                ? {
                    width: "84%",
                    marginLeft: "54px",
                  }
                : this.props.name === "role"
                ? {
                    marginLeft: "-13px",
                    marginTop: "24px",
                  }
                : {}
              : this.props.comp === "editAssetType" &&
                this.props.name === "assetType"
              ? {
                  height: "100px",
                  marginLeft: "1px",
                  width: "100%",
                  marginTop: "31px",
                }
              : this.props.comp === "editAssetSize" &&
                this.props.name === "assetType"
              ? {
                  width: "calc(100% + 6px)",
                  height: "auto",
                  top: "41px",
                  left: "0px",
                }
              : {}
          }
        >
          <ul
            className="ss-select-list"
            style={{ maxHeight: "114px", overflowY: "visible" }}
          >
            {this.state?.items?.map((option, index) => (
              <li
                id={option ? option.value : index}
                key={index}
                className={
                  option === this.props.selectedOption
                    ? "ss-select-item sel-item"
                    : "ss-select-item"
                }
                onClick={() => this.selectOption(option, index, true, false)}
              >
                {Select.getDisplayValue(option)}
              </li>
            ))}
            {!!this.state.items && this.state.items.length === 0 && (
              <span style={{ marginLeft: "30px" }}>
                {" "}
                Match not found. Please try again
              </span>
            )}
          </ul>
        </div>
      </div>
    );
  }
}

export default Select;
