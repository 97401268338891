import React from "react";
import PropTypes from "prop-types";
import "./zones.css";

const NumberOfSpaces = (props) => {
  const { numberOfSpaces, onNumberOfSpacesChange } = props;

  return (
    <div>
      <label>Number of Spaces: </label>
      <input
        className="form-control"
        type="number"
        min="1"
        name="Number of Spaces"
        max="99"
        placeholder={numberOfSpaces}
        value={numberOfSpaces}
        onChange={onNumberOfSpacesChange}
      />
      <div className="ss-error-numberOfSpace">
        {numberOfSpaces === "-0" && "Invalid number of space!"}
        {Number(numberOfSpaces) < 1 && "Invalid number of space!"}
        {Number(numberOfSpaces) > 99 && "Invalid number of space!"}
      </div>
    </div>
  );
};

NumberOfSpaces.propType = {
  numberOfSpaces: PropTypes.number,
  onNumberOfSpacesChange: PropTypes.func,
};

export default NumberOfSpaces;
