import React from "react";

const BooleanInput = (props, ref) => {
  const { name, label, className, type, checked, onChange, compName } = props;

  return (
    <div style={compName === "fineAssess" ? { display: "flex" } : {}}>
      <label style={compName === "fineAssess" ? { width: "100%" } : {}}>
        {label}
      </label>
      <input
        ref={ref}
        className={className}
        name={name}
        type={type}
        checked={checked}
        onChange={onChange}
        style={
          compName === "fineAssess"
            ? {
                marginTop: "-1px",
                height: "19px",
                marginLeft: "-50%",
                width: "5%",
              }
            : {}
        }
      />
    </div>
  );
};

export default React.forwardRef(BooleanInput);
