import React from "react";
import Steps from "../../steps/Steps";
import ZoneEditMap from "../../zone/ZoneEditMap";

const CoordinatesBuilder = (props) => {
  const { stepNumber, coordinates, onCoordinatesChange, otherZones, location } =
    props;

  return (
    <div>
      <div style={{ height: "40vh", width: "100%" }}>
        <ZoneEditMap
          zones={otherZones}
          location={location}
          coordinates={coordinates}
          onCoordinatesChange={onCoordinatesChange}
          containerElement={<div style={{ height: `100%` }} />}
          mapElement={<div style={{ height: `100%` }} />}
        />
      </div>
      <Steps step={stepNumber} {...props} />
    </div>
  );
};

CoordinatesBuilder.propType = {};

export default CoordinatesBuilder;
