import request from "../../../util/SuperagentUtils";

export const requestCheckInFieldsForAccountId = (accountId: String) => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  if (accountId) {
    return request
      .get(`${baseUrl}/api/account/${accountId}/check-in-fields`)
      .withCredentials();
  }
};

export const uploadFileDataRequest = (
  folder,
  file,
  profileFileName,
  inventoryId
) => {
  let data = new FormData();
  data.append("inputStream", file.dataURL);
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  return request
    .post(
      `${baseUrl}
/api/file/upload/${folder}?name=${profileFileName}&inventoryId=${inventoryId}&contentType=${file.type}`
    )
    .withCredentials()
    .send(data);
};

export const galleryCheckout = (checkout, data) => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  return request
    .post(`${baseUrl}/api/gallery-check-out`)
    .withCredentials()
    .send(data);
};

export const saveCheckInFormRequest = (data) => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  return request
    .post(`${baseUrl}/api/check-in-images`)
    .withCredentials()
    .send(data);
};
