import React, { Component } from "react";
import "../css/views/companyProfile.css";
import "../css/theme/mainContent.css";
import "../css/theme/forms.css";
import "../css/theme/forms-block.css";
import "../css/theme/buttons.css";
import { createLogoutOnFailureHandler } from "../util/LogoutUtil";
import Busy from "../components/Busy";
import { createLocationFromAddress } from "../util/AddressUtil";
import MapStyle from "../components/MapStyle";
import {
  parseCurrencyValue,
  validateCurrencyValue,
} from "../util/PaymentUtils";
import { toast } from "react-toastify";
import LocationMap from "../components/LocationMap";
import { ADMIN_EDIT_ROLES, isRoleApproved } from "../util/user/UserUtil";
import { ajaxRequest } from "../ajax";
import ButtonSelector2 from "../components/ButtonSelector2";
import { UserType } from "../components/constants/securspace-constants";
import CustomFilePicker from "../components/CustomFilePicker";
import pdfIcon from "../app-images/users/PDF_file_icon.svg.png";
const google = window.google;
let autocomplete;

let DURATION_CHARGE_TYPE_24_HOUR = {
  name: "24 Hour",
  value: "TWENTY_FOUR_HOUR",
};
let DURATION_CHARGE_TYPE_CALENDAR_DAY = {
  name: "Calendar Day",
  value: "CALENDAR_DAY",
};

let DURATION_CHARGE_TYPES = [
  DURATION_CHARGE_TYPE_24_HOUR,
  DURATION_CHARGE_TYPE_CALENDAR_DAY,
];

class CompanyDetails extends Component {
  constructor(props) {
    super(props);
    let mapData = {
      center: {
        lat: 37.09024,
        lng: -95.712891,
      },
      zoom: 4,
      markers: [],
    };

    let lookupLocation = createLocationFromAddress(this.props.account);
    let currentDurationChargeType =
      this.props.account && this.props.account.useCalendarDays
        ? DURATION_CHARGE_TYPE_CALENDAR_DAY
        : DURATION_CHARGE_TYPE_24_HOUR;
    this.state = Object.assign(
      this.props.account,
      {
        lookupLocation: lookupLocation,
        selectedDurationChargeType: currentDurationChargeType,
      },
      mapData
    );
    this.fileInputRef = React.createRef();

    this.setAutocompleteFromAddress(lookupLocation);
  }

  componentDidUpdate(nextProps) {
    if (this.props.account !== nextProps.account) {
      let lookupLocation = createLocationFromAddress(nextProps.account);
      let currentDurationChargeType =
        nextProps.account && nextProps.account.useCalendarDays
          ? DURATION_CHARGE_TYPE_CALENDAR_DAY
          : DURATION_CHARGE_TYPE_24_HOUR;
      this.setState(
        Object.assign(this.state, nextProps.account, {
          lookupLocation: lookupLocation,
          selectedDurationChargeType: currentDurationChargeType,
        })
      );
      this.setAutocompleteFromAddress(lookupLocation);
    }
  }

  componentDidMount() {
    let _this = this;
    let lookupLocation = document.getElementById("lookupLocation");
    autocomplete = new google.maps.places.Autocomplete(lookupLocation);
    autocomplete.addListener("place_changed", function () {
      let place = autocomplete.getPlace();
      if (place.geometry) {
        let streetNumber = "";
        let route = "";
        let city = "";
        let state = "";
        let zip = "";

        let location = place.geometry.location;

        for (let i = 0; i < place.address_components.length; i++) {
          let addressType = place.address_components[i].types[0];

          if (addressType === "street_number") {
            streetNumber = place.address_components[i]["short_name"];
          } else if (addressType === "route") {
            route = place.address_components[i]["long_name"];
          } else if (addressType === "locality") {
            city = place.address_components[i]["long_name"];
          } else if (addressType === "administrative_area_level_1") {
            state = place.address_components[i]["long_name"];
          } else if (addressType === "postal_code") {
            zip = place.address_components[i]["short_name"];
          }

          _this.setState({
            lookupLocation: place.formatted_address,
            addressLine1: streetNumber + " " + route,
            addressLine2: "",
            city: city,
            state: state,
            zip: zip,
            addressLatitude: location.lat(),
            addressLongitude: location.lng(),
          });
        }

        _this.setMarkerFromPlace(location);
        _this.centerMapOnGeometry(location, place.geometry.viewport);
      } else {
        //todo not good
        alert("Address Not Found!");
      }
    });
  }

  setAutocompleteFromAddress(address) {
    let _this = this;
    if (address) {
      new google.maps.Geocoder().geocode(
        { address: address },
        function (results, status) {
          if (status === "OK") {
            let location = results[0].geometry.location;
            _this.setMarkerFromPlace(location);
            _this.centerMapOnGeometry(location, results[0].geometry.viewport);
          } else {
            alert(
              "Geocode was not successful for the following reason: " + status
            );
          }
        }
      );
    }
  }

  centerMapOnGeometry(loc, viewport) {
    this.setState({ center: loc });
    if (this.mapContainer && viewport) {
      this.mapContainer.state.map.fitBounds(viewport);
    } else {
      this.setState({ zoom: 10 });
    }
  }

  setMarkerFromPlace = (location) => {
    this.setState({
      markers: [
        {
          position: location,
          showInfo: false,
        },
      ],
    });
  };

  handleChange = (event) => {
    let name = event.target.name;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;

    if ("zip" === name && (!CompanyDetails.isInteger(value) || value > 99999)) {
      return;
    }
    if (
      "phoneNumber" === name &&
      (!CompanyDetails.isInteger(value) || value > 9999999999)
    ) {
      return;
    }
    if (
      ("feePercentage" === name || "billingFeePercentage" === name) &&
      (!CompanyDetails.isInteger(value) || value > 100)
    ) {
      return;
    }
    if ("subscriptionFee" === name) {
      if (!validateCurrencyValue(value)) {
        return;
      }
      value = parseCurrencyValue(value);
    }
    if ("lookupLocation" === name) {
      //Clear this state every time the user types in the lookupLocation field as they invalidate the lat and lng.
      //Once the user selects an address from the lookup, then the lat and lng will get populated again.
      this.setState({
        addressLatitude: null,
        addressLongitude: null,
      });
    }
    this.setState({ [name]: value });
  };

  static isInteger(x) {
    return x.indexOf(".") < 0 && x % 1 === 0;
  }

  handleSubmit = () => {
    this.saveProfile();
  };

  saveProfile = () => {
    toast.dismiss();
    let errorMessage = null;

    let addressLookupFieldValue =
      document.getElementById("lookupLocation").value;
    if (!this.state.companyName) {
      errorMessage = "Please enter your company name.";
    } else if (!this.state.email) {
      errorMessage = "Please enter an email address.";
    } else if (!CompanyDetails.validateEmail(this.state.email)) {
      errorMessage = "Email address is invalid.";
    } else if (!this.state.phoneNumber) {
      errorMessage = "Please enter an Phone number.";
    } else if (!this.state.companyDescription) {
      errorMessage = "Please enter an company description.";
    } else if (!this.state.lookupLocation) {
      errorMessage = "Please enter an Location.";
    } else if (!this.state.firstName) {
      errorMessage = "Please enter your first name.";
    } else if (!this.state.lastName) {
      errorMessage = "Please enter your last name.";
    } else if (
      addressLookupFieldValue &&
      (!this.state.addressLongitude || !this.state.addressLatitude)
    ) {
      errorMessage = "Please select an address from the address lookup.";
    }
    if (errorMessage) {
      Busy.set(false);
      toast.error(errorMessage);
      return;
    }
    ajaxRequest({
      url: "account",
      type: "POST",
      data: JSON.stringify({
        id: this.state.id,
        type: this.state.type,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        companyName: this.state.companyName,
        companyDescription: this.state.companyDescription,
        addressLatitude: addressLookupFieldValue
          ? this.state.addressLatitude
          : null,
        addressLongitude: addressLookupFieldValue
          ? this.state.addressLongitude
          : null,
        addressLine1: addressLookupFieldValue ? this.state.addressLine1 : null,
        addressLine2: addressLookupFieldValue ? this.state.addressLine2 : null,
        city: addressLookupFieldValue ? this.state.city : null,
        state: addressLookupFieldValue ? this.state.state : null,
        zip: addressLookupFieldValue ? this.state.zip : null,
        phoneNumber: this.state.phoneNumber,
        email: this.state.email,
        username: this.state.username,
        listingImageFileName: this.state.listingImageFileName,
        supplierLegalAgreementFileName:
          this.state.supplierLegalAgreementFileName,
        useCalendarDays:
          this.state.selectedDurationChargeType ===
          DURATION_CHARGE_TYPE_CALENDAR_DAY,
        feePercentage: this.state.feePercentage,
        activated: this.state.activated,
        subscriptionType: this.state.subscriptionType,
        subscriptionFee: this.state.subscriptionFee,
        billingFeePercentage: this.state.billingFeePercentage,
        subscriptionEffectiveDate: this.state.subscriptionEffectiveDate,
      }),
      contentType: "application/json; charset=UTF-8",
      dataType: "json",
      success: this.handleSuccess,
      statusCode: {
        401: createLogoutOnFailureHandler(this.props.handleLogout),
      },
      error: this.handleFailure,
    });
  };

  handleSuccess = (data) => {
    Busy.set(false);
    this.setState(data);
    toast.success("Successfully updated account profile!");
    this.props.handleAccountUpdated(data);
  };

  static validateEmail(email) {
    let reg = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
    return reg.test(email);
  }

  handleFailure = (jqXHR, textStatus, errorThrown) => {
    Busy.set(false);
    let errorMessage = jqXHR.responseJSON
      ? jqXHR.responseJSON.message
      : "Internal Server Error";
    toast.error(errorMessage);
  };

  handleDurationChargeTypeSelected = (value) => {
    this.setState({ selectedDurationChargeType: value });
  };

  handleFileChange = (event) => {
    const file = event.target.files[0];
    if (
      file &&
      (file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/png")
    ) {
      const reader = new FileReader();
      reader.onloadend = () => {
        this.setState({
          imagePreviewUrl: reader.result,
          listingImageFileName: file.name,
        });
      };
      reader.readAsDataURL(file);
    } else {
      // this.setState({
      //   imagePreviewUrl: null,
      //   listingImageFileName: "",
      // });
      // event.target.value = null;
      toast.error("You can only upload images in jpg, png, or jpeg format");
    }
  };

  handleAgreementChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === "application/pdf") {
      const reader = new FileReader();
      reader.onloadend = () => {
        this.setState({
          agreementPreviewUrl: reader.result,
          supplierLegalAgreementFileName: file.name,
        });
      };
      reader.readAsDataURL(file);
    } else {
      // this.setState({
      //   agreementPreviewUrl: null,
      //   supplierLegalAgreementFileName: "",
      // });
      // event.target.value = null;
      alert("You can only upload files in PDF format");
    }
  };

  handleRemoveImage = () => {
    this.setState({
      imagePreviewUrl: null,
      listingImageFileName: "",
    });
    document.getElementById("listingImageFilePicker").value = null;
  };

  handleRemoveAgreement = () => {
    this.setState({
      agreementPreviewUrl: null,
      supplierLegalAgreementFileName: "",
    });

    document.getElementById("agreementFilePicker").value = null;
  };

  render() {
    return (
      <form className="ss-form ss-block">
        <div className="row">
          <div className="for-section col-lg-4 col-md-6 col-sm-12">
            <div>
              <fieldset>
                <label htmlFor="companyName">COMPANY NAME</label>
                <input
                  type="text"
                  id="companyName"
                  name="companyName"
                  value={this.state.companyName}
                  onChange={this.handleChange}
                  placeholder="Enter your company name"
                />
              </fieldset>

              <fieldset>
                <label htmlFor="email">EMAIL</label>
                <input
                  type="text"
                  id="email"
                  name="email"
                  value={this.state.email}
                  onChange={this.handleChange}
                  placeholder="Please enter an email address"
                  disabled={this.props.account.userType === "READ_ONLY_ADMIN"}
                />
              </fieldset>

              <fieldset>
                <label htmlFor="phoneNumber">PHONE NUMBER</label>
                <input
                  type="text"
                  id="phoneNumber"
                  name="phoneNumber"
                  value={this.state.phoneNumber}
                  onChange={this.handleChange}
                  placeholder="Please enter your phone number"
                  disabled={this.props.account.userType === "READ_ONLY_ADMIN"}
                />
              </fieldset>
            </div>
            <div>
              <fieldset>
                <label htmlFor="companyDescription">COMPANY DESCRIPTION</label>
                <textarea
                  id="companyDescription"
                  name="companyDescription"
                  value={
                    this.state.companyDescription != null
                      ? this.state.companyDescription
                      : ""
                  }
                  onChange={this.handleChange}
                  placeholder="Describe your company in a few words"
                  disabled={this.props.account.userType === "READ_ONLY_ADMIN"}
                />
              </fieldset>

              <fieldset>
                <label htmlFor="lookupLocation">LOCATION</label>
                <input
                  type="text"
                  id="lookupLocation"
                  name="lookupLocation"
                  value={this.state.lookupLocation}
                  onChange={this.handleChange}
                  placeholder="Enter your company address"
                  disabled={this.props.account.userType === "READ_ONLY_ADMIN"}
                />
                <LocationMap
                  ref={(mapContainer) => {
                    this.mapContainer = mapContainer;
                  }}
                  containerElement={<div style={{ height: `265px` }} />}
                  mapElement={<div style={{ height: `265px` }} />}
                  center={this.state.center}
                  zoom={this.state.zoom}
                  options={{
                    styles: MapStyle,
                    mapTypeControl: false,
                    streetViewControl: false,
                    fullscreenControl: false,
                    zoomControl: false,
                    scrollwheel: false,
                  }}
                  markers={this.state.markers}
                />
              </fieldset>
            </div>
          </div>
          <div className="for-section col-lg-4 col-md-6 col-sm-12">
            <h3 className="group-title company-image">Company Image</h3>
            <h5>
              Select an image file that will display on your Company profile
              page
            </h5>
            <CustomFilePicker
              id="listingImageFilePicker"
              onFileChange={this.handleFileChange}
              account={this.props.account}
            />
            {this.state.listingImageFileName && (
              <p>Selected file: {this.state.listingImageFileName}</p>
            )}
            {this.state.imagePreviewUrl && (
              <div style={{ position: "relative", display: "inline-block" }}>
                <img
                  src={this.state.imagePreviewUrl}
                  alt="Image Preview"
                  style={{ width: "100px", height: "100px" }}
                />
                {this.state.imagePreviewUrl && (
                  <button
                    className="delete-icon"
                    onClick={this.handleRemoveImage}
                  >
                    X
                  </button>
                )}
              </div>
            )}
          </div>
          <div className="for-section col-lg-4 col-md-6 col-sm-12">
            <h3 className="group-title user">Personal information</h3>
            <div>
              <fieldset>
                <label htmlFor="firstName">FIRST NAME</label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={this.state.firstName}
                  onChange={this.handleChange}
                  placeholder="Enter your first name"
                  disabled={this.props.account.userType === "READ_ONLY_ADMIN"}
                />
              </fieldset>
              <fieldset>
                <label htmlFor="lastName">LAST NAME</label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={this.state.lastName}
                  onChange={this.handleChange}
                  placeholder="Enter your last name"
                  disabled={this.props.account.userType === "READ_ONLY_ADMIN"}
                />
              </fieldset>
              <h3 className="group-title agreement-image">Legal Agreement</h3>
              <h5>
                Select the legal agreement file the user will have to accept
                before they can book a space
              </h5>

              <CustomFilePicker
                id="agreementFilePicker"
                onFileChange={this.handleAgreementChange}
                account={this.props.account}
              />
              {this.state.supplierLegalAgreementFileName && (
                <p>
                  Selected file: {this.state.supplierLegalAgreementFileName}
                </p>
              )}
              {this.state.agreementPreviewUrl && (
                <div style={{ position: "relative", display: "inline-block" }}>
                  <img
                    src={pdfIcon}
                    title="Agreement Preview"
                    style={{ width: "100px", height: "100px" }}
                  />
                  <button
                    className="delete-icon"
                    onClick={this.handleRemoveAgreement}
                  >
                    X
                  </button>
                </div>
              )}
            </div>
            <div className="hs-selector">
              <h3 className="group-title charge-image">Charge model</h3>

              <ButtonSelector2
                options={DURATION_CHARGE_TYPES}
                selectedOption={this.state.selectedDurationChargeType}
                handleOptionSelected={this.handleDurationChargeTypeSelected}
                buttonHeight={"31px"}
                buttonWidth={"120px"}
                account={this.props.account}
              />
            </div>
          </div>
        </div>

        {isRoleApproved(ADMIN_EDIT_ROLES, this.props.account.userType) && (
          <div className="ss-button-container border-top text-center">
            <button
              type="button"
              className="ss-button-primary"
              onClick={this.handleSubmit}
            >
              Save Changes
            </button>
          </div>
        )}
      </form>
    );
  }
}

export default CompanyDetails;
