import request from "../../../util/SuperagentUtils";
const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const requestInvoices = (accountId, requestDates) => {
  return request
    .get(`${baseUrl}/api/suppliers/${accountId}/transactions`)
    .query(requestDates)
    .withCredentials();
};
export const requestSecurityDeposit = () => {
  return request.get(`${baseUrl}/api/security-deposit`).withCredentials();
};

export const requestRetryPayment = (bookingTransactionId) => {
  return request
    .post(`${baseUrl}/api/booking/retry-charging-transaction`)
    .withCredentials()
    .send({ bookingTransactionId: bookingTransactionId });
};

export const requestAdminInvoices = (startDate, endDate) => {
  return request
    .get(`${baseUrl}/api/admins/transactions`)
    .withCredentials()
    .query({ startDate: startDate, endDate: endDate });
};
