import React, { useState } from "react";
import checkIcon from "../../../img/check-mark.png";
import "../paymentSuccessful.css";
import { truncateStringPaymentSuccessful } from "./checkoutConstants";
const SecurityDepositSuccessfull = ({
  setsecurityDepositPaymentStatus,
  dateInitiatedSecurityDeposit,
  sourceSecurityDeposit,
  securityDepositnickName,
  securityDepositPaymentType,
  securityDepositAmount,
  securityInvoiceNumber,
}) => {
  const [remainingTime, setRemainingTime] = useState(8); // Initial time

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      if (remainingTime > 0) {
        setRemainingTime((prevTime) => prevTime - 1);
      } else {
        setsecurityDepositPaymentStatus(false);
        clearInterval(intervalId); // Clear interval when time reaches 0
      }
    }, 1000);

    return () => clearInterval(intervalId); // Cleanup function
  });

  const handleClick = () => {
    setsecurityDepositPaymentStatus(false);
  };

  const formattedDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDateNew = `${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}/${date
      .getDate()
      .toString()
      .padStart(2, "0")}/${date.getFullYear()}`;
    return formattedDateNew;
  };

  return (
    <div>
      <div className="grey-bg">
        <div>
          <div className="container paymentContainer">
            <img src={checkIcon} alt="checkMark" className="imageCheckMark" />

            <h1 style={{ fontWeight: "bold" }}>Payment Successful</h1>
            <p style={{ marginTop: "3px" }}>
              Your payment has been completed! Details of transactions are
              included below:
            </p>
          </div>

          <div className="container">
            <hr style={{ borderTop: "1px solid #ccc" }} />
            <table className="tableStyle">
              <tr className="tableRow">
                <th className="tableH">Amount : </th>
                <th className="tableHContent">
                  {" "}
                  $ {Number(securityDepositAmount) / 100}
                </th>
              </tr>
              <tr className="tableRow">
                <td className="tableH">Invoice Number : </td>
                <td className="tableHContent">{securityInvoiceNumber}</td>
              </tr>
              <tr className="tableRow">
                <td className="tableH">Payment Type :</td>
                <td className="tableHContent">Security Deposit</td>
              </tr>
              <tr className="tableRow">
                <td className="tableH">Transfer Type :</td>
                <td className="tableHContent">{securityDepositPaymentType}</td>
              </tr>
              <tr className="tableRow">
                <td className="tableH">Source :</td>
                <td className="tableHContent">
                  {!!securityDepositnickName
                    ? truncateStringPaymentSuccessful(securityDepositnickName)
                    : sourceSecurityDeposit}
                </td>
              </tr>
              <tr className="tableRow">
                <td className="tableH">Recipient :</td>
                <td className="tableHContent">
                  Oakland Portal Smart Parking System on behalf of Port of
                  Oakland
                </td>
              </tr>
              <tr className="tableRow">
                <td className="tableH">Date Initiated :</td>
                <td className="tableHContent">
                  {formattedDate(dateInitiatedSecurityDeposit)}
                </td>
              </tr>
              <tr className="tableRow">
                <b>
                  Please click below if you are not redirected in{" "}
                  {remainingTime} seconds
                </b>
              </tr>
            </table>

            <button
              className="orange-button ss-action-button ss-login-submit securitypaymentSuccessfulButton"
              onClick={() => handleClick()}
            >
              Continue to Book Space
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecurityDepositSuccessfull;
