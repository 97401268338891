import React from 'react';
import styles from "../fines/FineCSS/wizard.less";
import '../fines/FineCSS/fines.css';

const StepWizardContainer = (props) => {
  const { children } = props;
  return <div 
  className={'jumbotron'}
  >
    <div className='row sps-step-wizard-container'>
      <div className={`${styles[ 'rsw-wrapper' ]} sps-step-wizard-child`}>
        {children}
      </div>
    </div>
  </div>
}

export default StepWizardContainer;
