import request from "../../../util/SuperagentUtils";
import { DateFormats } from "../../constants/securspace-constants";
import moment from "moment";

export const requestMonthlyDurationByDates = (
  supplierId,
  startDate = moment().format(DateFormats.DAY),
  endDate = moment().format(DateFormats.DAY)
) => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  return request
    .get(`${baseUrl}/api/monthly-duration-report`)
    .query({ supplierId, startDate, endDate })
    .withCredentials();
};
