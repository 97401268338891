import React, { useState } from "react";
import Select from "../Select";
import DropGallery from "../DropGallery";
import Busy from "../Busy";
import { ajaxRequest } from "../../ajax";
import { toast } from "react-toastify";

const EditSecurityDepositForm = ({
  securityDepositParticularRecordDetails,
  setShowModal,
}) => {
  const GALLERY_BUCKET = "securityDeposit";
  const [dropzone, setDropzone] = useState(null);
  const [refundStatusName, setRefundStatusName] = useState("");
  const [modeOfPayment, setModeOfPayment] = useState("");
  const [notes, setNotes] = useState("");
  const [modeOfPaymentNumber, setModeOfPaymentNumber] = useState(null);

  const updateDropzone = (dropzone) => {
    setDropzone(dropzone);
  };

  const handleRefundStatus = (event) => {
    setRefundStatusName(event.target.value);
  };
  const handleModeOfPayment = (event) => {
    setModeOfPayment(event.target.value);
  };
  const handleSuccessSecurity = () => {
    toast.success("Refund status editted successfully!");
    Busy.set(false);
    setShowModal(false);
  };
  const handleFailed = (err) => {
    console.log("err", err);
    toast.error("Internal server error");
    Busy.set(false);
  };
  const getRefundPaymentType = (val) => {
    let refundType = "";
    switch (val) {
      case "CHEQUE":
        refundType = "CHEQUE_NUMBER";
        break;
      case "DEMAND DRAFT":
        refundType = "DD";
        break;
      case "ONLINE PAYMENT":
        refundType = "ONLINE_PAYMENT";
        break;
    }
    return refundType;
  };
  const getStatusType = (val) => {
    let statusType = "";
    switch (val) {
      case "Requested":
        statusType = "REQUESTED";
        break;
      case "In Progress":
        statusType = "IN_PROGRESS";
        break;
      case "In Dispute":
        statusType = "IN_DISPUTE";
        break;
      case "Completed":
        statusType = "COMPLETED";
        break;
      case "Cancelled":
        statusType = "CANCELLED";
        break;
    }
    return statusType;
  };
  const handleSubmit = () => {
    Busy.set(true);
    const payloadData = {
      id: securityDepositParticularRecordDetails?.id,
      refundPaymentType: getRefundPaymentType(modeOfPayment),
      refundReturnRequestNumber: modeOfPaymentNumber,
      refundStatus: getStatusType(refundStatusName),
      notes: notes,
      imageUrl: "abc",
    };

    ajaxRequest({
      url: "security-deposit",
      data: JSON.stringify(payloadData),
      type: "POST",
      contentType: "application/json; charset=UTF-8",
      dataType: "json",
      success: handleSuccessSecurity,
      error: handleFailed,
    });
  };
  console.log(
    "securityDepositParticularRecordDetails",
    securityDepositParticularRecordDetails
  );

  return (
    <>
      <form className="ss-form ss-block">
        <div style={{ paddingRight: "44px", paddingLeft: "48px" }}>
          <div className="checkin-selector">
            <label>REFUND STATUS</label>

            <Select
              id="refundStatus"
              name="refundStatus"
              className="ss-book-space-form-asset-type"
              optionsWidth="300px"
              handleChange={handleRefundStatus}
              selectedOption={refundStatusName}
              placeholder="Please select"
              options={
                securityDepositParticularRecordDetails?.refundStatus === "NA"
                  ? ["Requested"]
                  : securityDepositParticularRecordDetails?.refundStatus ===
                    "REQUESTED"
                  ? ["In Progress"]
                  : ["Cancelled", "Completed", "In Dispute"]
              }
            />
          </div>
          {(refundStatusName === "Cancelled" ||
            refundStatusName === "In Dispute") && (
            <fieldset
              className="ss-middle"
              style={{ borderRadius: "5px", border: "solid 1px" }}
            >
              <label htmlFor="notes">REMARKS OR NOTES</label>
              <textarea
                id="notes"
                name="notes"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                placeholder="Enter notes regarding the refund status."
              />
            </fieldset>
          )}
          {refundStatusName === "Completed" && !!refundStatusName && (
            <>
              {" "}
              <div className="checkin-selector">
                <label>MODE OF REFUND PAYMENT</label>

                <Select
                  id="modeOfPayment"
                  name="modeOfPayment"
                  className="ss-book-space-form-asset-type"
                  optionsWidth="300px"
                  handleChange={handleModeOfPayment}
                  selectedOption={modeOfPayment}
                  placeholder="Please select"
                  options={["CHEQUE", "DEMAND DRAFT", "ONLINE PAYMENT"]}
                />
              </div>
              {!!modeOfPayment && (
                <>
                  <fieldset
                    className="ss-middle"
                    style={{ borderRadius: "5px", border: "solid 1px #e1e1e1" }}
                  >
                    <label htmlFor="modeOfPaymentNumber">{modeOfPayment}</label>
                    <input
                      name="modeOfPaymentNumber"
                      id="modeOfPaymentNumber"
                      value={modeOfPaymentNumber}
                      onChange={(e) => setModeOfPaymentNumber(e.target.value)}
                      placeholder={`Enter ${modeOfPayment} number`}
                    />
                  </fieldset>
                  <p style={{ marginTop: "10px" }}>
                    Click below to add image of {modeOfPayment}
                  </p>
                  <fieldset
                    className="ss-top ss-dz"
                    style={{ marginBottom: "-24px" }}
                  >
                    <DropGallery
                      bucket={GALLERY_BUCKET}
                      //locationGallery={this.state.locationGallery}
                      locationId={securityDepositParticularRecordDetails?.id}
                      updateDropzone={updateDropzone}
                    />
                  </fieldset>
                  <div style={{ marginBottom: "35px" }}></div>
                </>
              )}
              <fieldset
                className="ss-middle"
                style={{
                  borderRadius: "5px",
                  border: "solid 1px #e1e1e1",
                  marginTop: "10px",
                }}
              >
                <label htmlFor="notes">REMARKS OR NOTES</label>
                <textarea
                  id="notes"
                  name="notes"
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  placeholder="Enter notes regarding the refund status."
                />
              </fieldset>
            </>
          )}
        </div>
        <div className="modal-footer" style={{ border: "none" }}>
          <div
            className="table text-left"
            style={{ display: "flex", justifyContent: "space-around" }}
          >
            <button
              onClick={() => setShowModal(false)}
              type="button"
              className="ss-button-primary-modal-form reverse"
            >
              Cancel
            </button>
            <button
              type="button"
              className="ss-button-primary-modal-form"
              onClick={handleSubmit}
              disabled={
                !(refundStatusName === "Completed"
                  ? !!modeOfPayment && !!modeOfPaymentNumber
                    ? true
                    : false
                  : !refundStatusName
                  ? false
                  : true)
              }
              style={
                !(refundStatusName === "Completed"
                  ? !!modeOfPayment && !!modeOfPaymentNumber
                    ? true
                    : false
                  : !refundStatusName
                  ? false
                  : true)
                  ? { opacity: "0.5" }
                  : {}
              }
            >
              Save
            </button>
          </div>
        </div>
      </form>{" "}
    </>
  );
};

export default EditSecurityDepositForm;
