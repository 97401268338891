import React from 'react';
import '../../css/components/report/report-form.css';

const ReportFormFieldset = (props) => {
  const {children} = props;
  return <fieldset className={"ss-report-form-fieldset"}>
    {children}
  </fieldset>
}

export default ReportFormFieldset;
