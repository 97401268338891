import React from "react";
import ReportDataRow from "../../../report/ReportDataRow";
import ReportData from "../../../report/ReportData";
import { formatBool } from "../../../../util/booking/bookingUtil";
import { formatCurrencyValue } from "../../../../util/PaymentUtils";

const FineAssessmentReportDataRow = (props) => {
  const { fineAssessment } = props;

  return (
    <ReportDataRow>
      <ReportData label={"Location Name"}>
        {fineAssessment?.locationName || "-"}
      </ReportData>
      <ReportData label={"Zone Name"}>
        {fineAssessment?.zoneName || "-"}
      </ReportData>
      <ReportData label={"Company Name"}>
        {fineAssessment?.companyName || "-"}
      </ReportData>

      <ReportData label={"Booking Number"}>
        {fineAssessment?.bookingNumber || "-"}
      </ReportData>

      <ReportData label={"Fine Type"}>
        {fineAssessment?.fineType?.name || "-"}
      </ReportData>

      <ReportData label={"Fine Amount"}>
        {!!fineAssessment?.fineAmount
          ? formatCurrencyValue(fineAssessment?.fineAmount, true)
          : "-"}
      </ReportData>
      <ReportData label={"Peak Pricing Charge"}>
        {!!fineAssessment?.peakPricingAdditionalCharge
          ? formatCurrencyValue(
              fineAssessment.peakPricingAdditionalCharge,
              true
            )
          : "-"}
      </ReportData>

      <ReportData label={"Payment Processor Fee"}>
        {!!fineAssessment?.paymentProcessorFee
          ? formatCurrencyValue(fineAssessment.paymentProcessorFee, true)
          : "-"}
      </ReportData>

      <ReportData label={"Notes"}>{fineAssessment?.notes || "-"}</ReportData>
      <ReportData label={"Inventory Towed"}>
        {fineAssessment?.towInventory
          ? formatBool(fineAssessment.towInventory)
          : "-"}
      </ReportData>

      <ReportData label={"Processed"}>
        {fineAssessment?.processed ? formatBool(fineAssessment.processed) : "-"}
      </ReportData>

      <ReportData label={"Refund"}>
        {fineAssessment?.refund ? formatBool(fineAssessment?.refund) : "-"}
      </ReportData>
      <ReportData label={"Created Date"}>
        {fineAssessment?.createdOn || "-"}
      </ReportData>
    </ReportDataRow>
  );
};

export default FineAssessmentReportDataRow;
