import React from "react";
import ReportDataRow from "../report/ReportDataRow";
import ReportData from "../report/ReportData";
import ReportRow from "../report/ReportRow";
import DateDisplay from "../date/DateDisplay";
import moment from "moment";
import { AssetTypesMap } from "../constants/securspace-constants";

const SupplierInterchangeListItem = (props) => {
  const { supplierInterchange } = props;
  return (
    <ReportRow>
      <ReportDataRow>
        <ReportData label={"ACTIVITY"}>
          {supplierInterchange?.activity || "-"}
        </ReportData>

        <ReportData label={"DATE"}>
          {supplierInterchange?.activityDate || "-"}
        </ReportData>

        <ReportData label={"CONTAINER"}>
          {supplierInterchange?.containerNumber || "-"}
        </ReportData>

        <ReportData label={"CONTAINER VALIDATION"}>
          {supplierInterchange?.containerNumberValidation || "-"}
        </ReportData>

        <ReportData label={"TRAILER"}>
          {supplierInterchange?.trailerNumber || "-"}
        </ReportData>

        <ReportData label={"CHASSIS"}>
          {supplierInterchange?.chassisNumber || "-"}
        </ReportData>

        <ReportData label={"CHASSIS LICENSE PLATE NUMBER"}>
          {supplierInterchange?.chassisLicensePlateNumber || "-"}
        </ReportData>

        <ReportData label={"SIZE"}>
          {supplierInterchange?.assetSize || "-"}
        </ReportData>

        <ReportData label={"FIRST NAME"}>
          {supplierInterchange?.driverFirstName || "-"}
        </ReportData>

        <ReportData label={"LAST NAME"}>
          {supplierInterchange?.driverLastName || "-"}
        </ReportData>

        <ReportData label={"LICENSE"}>
          {supplierInterchange?.driverLicenseNumber || "-"}
        </ReportData>

        <ReportData label={"TRUCK LICENSE PLATE NUMBER"}>
          {supplierInterchange?.truckLicensePlateNumber || "-"}
        </ReportData>

        <ReportData label={"SEAL"}>
          {supplierInterchange?.sealNumber || "-"}
        </ReportData>

        <ReportData label={"TYPE"}>
          {AssetTypesMap[supplierInterchange?.assetType] ||
            supplierInterchange?.assetType}
        </ReportData>

        <ReportData label={"BOOKING"}>
          {supplierInterchange?.orderNumber || "-"}
        </ReportData>

        <ReportData label={"CUSTOMER"}>
          {supplierInterchange?.buyerName || "-"}
        </ReportData>

        <ReportData label={"LOCATION"}>
          {supplierInterchange?.locationName || "-"}
        </ReportData>

        <ReportData label={"INVENTORY"}>
          {supplierInterchange?.currentInventory || "-"}
        </ReportData>

        <ReportData label={"DURATION OF STAY"}>
          {supplierInterchange?.dwellTimeInMillis
            ? moment
                .duration(supplierInterchange.dwellTimeInMillis)
                .humanize(false, { d: 31 })
            : "-"}
        </ReportData>

        <ReportData label={"LAST UPDATE ON"}>
          {supplierInterchange?.lastUpdatedOn ? (
            <DateDisplay date={supplierInterchange?.lastUpdatedOn} />
          ) : (
            "-"
          )}
        </ReportData>
        <ReportData label={"LAST UPDATE BY"}>
          {supplierInterchange?.lastUpdatedBy || "-"}
        </ReportData>

        <ReportData label={"NOTES"}>
          {supplierInterchange?.notes || "-"}
        </ReportData>

        <ReportData label={"ZONE"}>
          {supplierInterchange?.zoneName || "-"}
        </ReportData>

        <ReportData label={"TOW AWAY"}>
          {supplierInterchange?.towAway ? "YES" : "NO"}
        </ReportData>
      </ReportDataRow>
    </ReportRow>
  );
};

export default SupplierInterchangeListItem;
