export const searchRecordsByKeywordUser = (keyword, records) => {
  const filtered = [];
  if (keyword) {
    for (const record of records) {
      for (const element of Object.values(record)) {
        if (
          element
            ?.toString()
            .toLowerCase()
            .includes(keyword?.toLowerCase()?.trim())
        ) {
          filtered.push(record);
          break;
        }
      }
    }
    return filtered;
  }
  return records;
};

export const searchRecordsByKeywordUserName = (keyword, records) => {
  const filtered = [];

  if (keyword) {
    for (const record of records) {
      if (
        record?.username
          ?.toString()
          .toLowerCase()
          .includes(keyword.toLowerCase().trim())
      ) {
        filtered.push(record);
      }
    }
    return filtered;
  }

  return records;
};

export const searchRecordsByKeywordSecurity = (keyword, records) => {
  const filtered = [];
  if (keyword) {
    const lowerCaseKeyword = keyword.toLowerCase().trim();
    for (const record of records) {
      for (const element of Object.values(record)) {
        if (element?.toString().toLowerCase() === lowerCaseKeyword) {
          filtered.push(record);
          break;
        }
      }
    }
    return filtered;
  }
  return records;
};

export const searchRecordsByKeyword = (keyword, records) => {
  const filtered = [];
  if (keyword) {
    const lowerCaseKeyword = keyword.toLowerCase().trim();
    for (const record of records) {
      for (const element of Object.values(record)) {
        if (element?.toString().toLowerCase() === lowerCaseKeyword) {
          filtered.push(record);
          break;
        }
      }
    }
    return filtered;
  }
  return records;
};
