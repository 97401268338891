import React, { useEffect, useState } from "react";
import Steps from "../steps/Steps";
import FineAssessments from "./FineAssessments";
import { getFineAssessments } from "../checkin/request/fine-request";

const ShowPrevFines = (props) => {
  const {
    stepNumber,
    containerNumber,
    trailerNumber,
    chassisNumber,
    truckLicensePlateNumber,
    locationId,
  } = props;
  const [fineAssessments, setFineAssessments] = useState(null);

  useEffect(() => {
    if (
      locationId &&
      (containerNumber ||
        trailerNumber ||
        chassisNumber ||
        truckLicensePlateNumber)
    ) {
      getFineAssessments(
        locationId,
        containerNumber,
        trailerNumber,
        chassisNumber,
        truckLicensePlateNumber
      ).then((response) => {
        let data = response.body;
        setFineAssessments(data);
      });
    }
  }, [
    setFineAssessments,
    containerNumber,
    trailerNumber,
    chassisNumber,
    truckLicensePlateNumber,
    locationId,
  ]);

  return (
    <div>
      <FineAssessments fineAssessments={fineAssessments} />

      <Steps type="fineNote" step={stepNumber} {...props} />
      <div style={{ marginTop: "30px" }}> </div>
    </div>
  );
};

export default ShowPrevFines;
