import React from "react";
import FineAssessment from "./FineAssessment";

const FineAssessments = (props) => {
  const { fineAssessments } = props;

  return (
    <div>
      <h2>
        {fineAssessments?.length === 0
          ? "PREVIOUS FINES FOR EQUIPMENT : No"
          : "PREVIOUS FINES FOR EQUIPMENT : "}
      </h2>
      {fineAssessments?.length > 0 &&
        fineAssessments
          .sort((a, b) => new Date(b?.createdOn) - new Date(a?.createdOn))
          .map((fineAssessment) => {
            return (
              <div key={fineAssessment.id} style={{ marginLeft: "-32px" }}>
                <FineAssessment fineAssessment={fineAssessment} />
                <hr />
              </div>
            );
          })}
    </div>
  );
};

export default FineAssessments;
