import React, { useEffect, useState } from "react";
import ReportBackgroundContainer from "../report/ReportBackgroundContainer";
import ReportHeader from "../report/ReportHeader";
import ReportRow from "../report/ReportRow";
import {
  getAllWaitListEntries,
  reserveWaitListEntry,
} from "./WaitListRequests";
import { getErrorMessageForStandardResponse } from "../../util/NetworkErrorUtil";
import WaitListItems from "./WaitListItems";
import WaitListMenu from "./WaitListMenu";
import Filter from "../../views/bookings/Filter";
import GroupAndSort from "../../views/bookings/GroupAndSort";
import {
  SORT_DIRECTION_ASC,
  SORT_DIRECTION_DESC,
} from "../../util/report/ReportUtils";
import _ from "underscore";
import { toast } from "react-toastify";
import { isRoleApproved, MANAGER_EDIT_ROLES } from "../../util/user/UserUtil";
import { getAllZones } from "../zonebuilder/zoneRequest";
import { ZoneRefOption } from "../../controls/ZoneRefOption";
import Busy from "../Busy";
import ZoneOverrideSelect from "./ZoneOverrideSelect";
import ReportEmpty from "../report/ReportEmpty";

const WaitList = (props) => {
  const { account } = props;

  const reportFields = [
    { label: "Company Name", name: "companyName" },
    { label: "Start Date", name: "startDate" },
    { label: "End Date", name: "endDate" },
    { label: "Equipment Length", name: "equipmentLength" },
    { label: "Frequency", name: "frequency" },
    { label: "Number of Spaces", name: "numberOfSpaces" },
    { label: "Zone Name", name: "zoneName" },
  ];

  const [waitListEntries, setWaitListEntries] = useState();
  const [updateReport, setUpdateReport] = useState("🏃🏻‍♂️");
  const [groupBy, setGroupBy] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [sortDirection, setSortDirection] = useState(SORT_DIRECTION_ASC);
  const [groupDirection, setGroupDirection] = useState(SORT_DIRECTION_ASC);
  const [searchBox, setSearchBox] = useState("");
  const [filteredData, setFilteredData] = useState();
  const [shouldSelectZone, setShouldSelectZone] = useState(false);
  const [selectedWaitList, setSelectedWaitList] = useState(null);
  const [zones, setZones] = useState([]);
  const [selectedZone, setSelectedZone] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [updateReport]);

  useEffect(() => {
    getAllWaitListEntries()
      .then(
        (promise) => {
          let data = promise.body;
          setWaitListEntries(data);
          setFilteredData(data);
        },
        (error) => {
          toast(getErrorMessageForStandardResponse(error));
        }
      )
      .catch((error) => {
        toast(getErrorMessageForStandardResponse(error));
      });
  }, [updateReport, setWaitListEntries, setUpdateReport]);

  useEffect(() => {
    if (shouldSelectZone && selectedWaitList) {
      getAllZones(selectedWaitList?.startDate, selectedWaitList?.endDate)
        .then((resp) => {
          setZones(resp.body.map((zone) => new ZoneRefOption(zone)));
        })
        .catch((error) => {
          toast.error(getErrorMessageForStandardResponse(error));
        });
    }
  }, [shouldSelectZone, selectedWaitList]);

  const handleSubmit = () => {
    if (selectedZone) {
      Busy.set(true);
      reserveWaitListEntry(selectedWaitList.id, selectedZone.value)
        .then(() => {
          setShouldSelectZone(false);
          setSelectedWaitList(null);
          setUpdateReport("🔥");
        })
        .catch((error) => {
          toast.error(getErrorMessageForStandardResponse(error));
        })
        .finally(() => {
          Busy.set(false);
        });
    }
  };

  const searchChangeHandler = (event) => {
    let value = event.target.value;
    setSearchBox(value);
    search(value);
  };

  const search = (value) => {
    let filtered = [];
    for (const item of waitListEntries) {
      value.split(" ").forEach((word) => {
        for (const element of Object.entries(item)) {
          if (
            element.toString().toLowerCase().indexOf(word.toLowerCase()) !== -1
          ) {
            filtered.push(item);
            break;
          }
        }
      });
    }
    setFilteredData(filtered);
  };

  const groupByChangeHandler = (event) => {
    let value = event.target.value;
    setGroupBy(value);
    groupData(value);
  };

  const groupData = (value) => {
    let groupedData = _.groupBy(filteredData, value);
    let toArray = _.toArray(groupedData);
    let flattened = _.flatten(toArray);
    setFilteredData(flattened);
  };

  const sortByChangeHandler = (event) => {
    let value = event.target.value;
    setSortBy(value);
    sortData(value);
  };

  const sortData = (value) => {
    let sortedData = _.sortBy(filteredData, value);
    setFilteredData(sortedData);
  };

  const sortDirectionChangeHandler = () => {
    sortByDirection();
    setFilteredData([...filteredData.reverse()]);
  };

  const groupDirectionChangeHandler = () => {
    groupByDirection();
    setFilteredData([...filteredData.reverse()]);
  };

  const sortByDirection = () => {
    setSortDirection(
      sortDirection === SORT_DIRECTION_ASC
        ? SORT_DIRECTION_DESC
        : SORT_DIRECTION_ASC
    );
  };

  const groupByDirection = () => {
    setGroupDirection(
      groupDirection === SORT_DIRECTION_ASC
        ? SORT_DIRECTION_DESC
        : SORT_DIRECTION_ASC
    );
  };

  const handleClose = () => {
    setShouldSelectZone(false);
  };

  const handleSelectZone = (event) => {
    const zone = event.target.value;
    setSelectedZone(zone);
  };

  return (
    <div>
      <ReportBackgroundContainer>
        <ReportHeader parentMenu="BOOKINGS">Active Waitlist:</ReportHeader>
        {!!waitListEntries && waitListEntries?.length > 0 ? (
          <>
            <Filter
              searchBox={searchBox}
              searchChangeHandler={searchChangeHandler}
              placeHolder="Type any field to filter wait list result"
            />
            {filteredData?.length > 0 && (
              <GroupAndSort
                groupByOptions={reportFields}
                groupByChangeHandler={groupByChangeHandler}
                groupBy={groupBy}
                groupDirection={groupDirection}
                groupDirectionChangeHandler={groupDirectionChangeHandler}
                sortBy={sortBy}
                sortByChangeHandler={sortByChangeHandler}
                sortDirection={sortDirection}
                sortDirectionChangeHandler={sortDirectionChangeHandler}
                asc={SORT_DIRECTION_ASC}
                desc={SORT_DIRECTION_DESC}
              />
            )}
            {shouldSelectZone && zones.length > 0 && (
              <ZoneOverrideSelect
                handleClose={handleClose}
                handleSelectZone={handleSelectZone}
                selectedZone={selectedZone}
                zones={zones}
                handleSubmit={handleSubmit}
              />
            )}
            {filteredData &&
              filteredData.map((waitListEntry) => {
                return (
                  <div key={waitListEntry.id}>
                    <ReportRow>
                      {isRoleApproved(
                        MANAGER_EDIT_ROLES,
                        account?.userType
                      ) && (
                        <WaitListMenu
                          waitListEntry={waitListEntry}
                          setUpdateReport={setUpdateReport}
                          setShouldSelectZone={setShouldSelectZone}
                          setSelectedWaitList={setSelectedWaitList}
                        />
                      )}
                      <WaitListItems waitListEntry={waitListEntry} />
                    </ReportRow>
                  </div>
                );
              })}
            {filteredData?.length > 0 && (
              <div style={{ textAlign: "center" }}>
                {" "}
                You have reached end of the list
              </div>
            )}

            {!!filteredData && filteredData?.length === 0 && <ReportEmpty />}
          </>
        ) : (
          <ReportEmpty />
        )}
      </ReportBackgroundContainer>
    </div>
  );
};

export default WaitList;
