import React, { useEffect, useState } from "react";
import Steps from "../../steps/Steps";
import FineInfractionAddInputs from "./FineInfractionAddInputs";
import PropTypes from "prop-types";

const FineInfractionAdd = (props) => {
  const { stepNumber, addFineInfractions, infractions } = props;
  const [buttonDisabled, setButtonDisabled] = useState(true);
  useEffect(() => {
    if (infractions?.length === 0) setButtonDisabled(true);
  }, [infractions]);

  return (
    <div>
      <FineInfractionAddInputs
        infractions={infractions}
        addFineInfractions={addFineInfractions}
        buttonDisabled={buttonDisabled}
        setButtonDisabled={setButtonDisabled}
      />
      <Steps step={stepNumber} canContinue={buttonDisabled} {...props} />
    </div>
  );
};

FineInfractionAdd.propTypes = {
  stepNumber: PropTypes.number,
  addFineInfractions: PropTypes.func,
  infractions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      fineId: PropTypes.string,
      infractionOffense: PropTypes.number,
      infractionAmount: PropTypes.number,
    })
  ),
};

export default FineInfractionAdd;
