import React from "react";
import "../../css/components/report/report-data-row.css";

const ReportDataRow = (props) => {
  const { children } = props;

  return <div className="ss-report-data-row">{children}</div>;
};

export default ReportDataRow;
