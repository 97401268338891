import React from 'react';
import ReportData from "../../report/ReportData";
import { formatBool, formatDate, formatEquipmentLength } from "../../../util/booking/bookingUtil";
import ZoneCoordinateCard from "./ZoneCoordinateCard";
import ReportDataRow from "../../report/ReportDataRow";
import ReportRow from "../../report/ReportRow";

const ZoneAuditCard = (props) => {

  const { zone } = props;

  const { modifiedFieldNames } = zone;

  const checkModified = (field1, field2) => {
    return modifiedFieldNames.includes(field1) || modifiedFieldNames.includes(field2);
  }

  return <ReportRow>
    <ReportDataRow>
      <ReportData label={"Zone Name"} modified={checkModified('name')}>{zone.name}</ReportData>
      <ReportData label={"Total Spaces"} modified={checkModified('totalSpaces')}>{zone.totalSpaces}</ReportData>
      <ReportData label={"Equipment Length"} modified={checkModified('equipmentLength')}>{formatEquipmentLength(zone.equipmentLength)}</ReportData>
      <ReportData label={"Power Accessible"} modified={checkModified('powerAccessible')}>{formatBool(zone.powerAccessible)}</ReportData>
      <ReportData label={"Activation Date"} modified={checkModified('activationDate')}>{formatDate(zone.activationDate)}</ReportData>
      <ReportData label={"Deactivation Date"} modified={checkModified('deactivationDate')}>{formatDate(zone.deactivationDate)}</ReportData>
      <ReportData label={"Active"} modified={checkModified('active')}>{formatBool(zone.active)}</ReportData>
      <ReportData label={"Inactive Reason"} modified={checkModified('inactiveReason')}>{zone.inactiveReason}</ReportData>
      <ReportData label={"Price Per Day"} modified={checkModified('pricePerDayInCents')}>${zone.pricePerDayInCents / 100}</ReportData>
      <ReportData label={"Price Per Week"} modified={checkModified('pricePerWeekInCents')}>${zone.pricePerWeekInCents / 100}</ReportData>
      <ReportData label={"Price Per Month"} modified={checkModified('pricePerMonthInCents')}>${zone.pricePerMonthInCents / 100}</ReportData>
      <ReportData label={"Allow One Time Bookings"} modified={checkModified('allowOneTimeBookings')}>{formatBool(zone.allowOneTimeBookings)}</ReportData>
      <ReportData label={"Allow Weekly Bookings"} modified={checkModified('allowWeeklyBookings')}>{formatBool(zone.allowWeeklyBookings)}</ReportData>
      <ReportData label={"Allow Monthly Bookings"} modified={checkModified('allowMonthlyBookings')}>{formatBool(zone.allowMonthlyBookings)}</ReportData>
      <ReportData label={"Auto Approved"} modified={checkModified('autoApproved')}>{formatBool(zone.autoApproved)}</ReportData>
      <ReportData label={"Created By"} modified={checkModified('createdBy')}>{zone.createdBy}</ReportData>
      <ReportData label={"Creation Date"} modified={checkModified('creationDate')}>{formatDate(zone.creationDate)}</ReportData>
      <ReportData label={"Last Modified By"} modified={checkModified('lastModifiedBy')}>{zone.lastModifiedBy}</ReportData>
      <ReportData label={"Last Modified Date"} modified={checkModified('lastModifiedDate')}>{formatDate(zone.lastModifiedDate)}</ReportData>
      <ZoneCoordinateCard
        coordinates={zone.coordinates}
      />
    </ReportDataRow>
  </ReportRow>
}

export default ZoneAuditCard;
