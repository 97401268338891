import request from "../../../util/SuperagentUtils";
const baseUrl = process.env.REACT_APP_API_BASE_URL;

const assessmentBaseUrl = `${baseUrl}
/api/fine-assessments`;
const fineBaseUrl = `${baseUrl}
/api/fines`;

export const fineRequest = () => {
  return request.get(fineBaseUrl).withCredentials();
};

export const fineUpdate = (fine) => {
  return request
    .patch(fineBaseUrl + "/" + fine.id)
    .query({ fineType: fine.fineType })
    .withCredentials()
    .send(fine);
};

export const fineDelete = (fine) => {
  return request.delete(fineBaseUrl + "/" + fine.id).withCredentials();
};

export const getFineTypes = () => {
  return request.get(fineBaseUrl + "/active").withCredentials();
};

export const saveFine = (fine) => {
  return request.post(fineBaseUrl).send(fine).withCredentials();
};

export const saveFineAssessment = (
  containerNumber,
  trailerNumber,
  chassisNumber,
  truckLicensePlateNumber,
  fineAssessment
) => {
  return request
    .post(assessmentBaseUrl)
    .query({
      containerNumber: containerNumber,
      trailerNumber: trailerNumber,
      chassisNumber: chassisNumber,
      truckLicensePlateNumber: truckLicensePlateNumber,
    })
    .withCredentials()
    .send(fineAssessment);
};

export const getLocations = () => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  return request.get(`${baseUrl}/api/location`).withCredentials();
};

export const getAllLiveLocations = () => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  return request.get(`${baseUrl}/public/api/location`).withCredentials();
};

export const getFineAssessment = () => {
  return request.get(assessmentBaseUrl).withCredentials();
};

export const deleteFineAssessment = (assessmentId) => {
  return request
    .delete(assessmentBaseUrl + "/" + assessmentId)
    .withCredentials();
};

export const refundFineAssessment = (assessmentId) => {
  return request
    .post(assessmentBaseUrl + "/" + assessmentId + "/refund")
    .withCredentials();
};

export const getFineAssessments = (
  locationId,
  containerNumber,
  trailerNumber,
  chassisNumber,
  truckLicensePlateNumber
) => {
  return request
    .get(assessmentBaseUrl + "/" + locationId)
    .query({
      containerNumber: containerNumber,
      trailerNumber: trailerNumber,
      chassisNumber: chassisNumber,
      truckLicensePlateNumber: truckLicensePlateNumber,
    })
    .withCredentials();
};
