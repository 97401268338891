import React, { Component } from "react";
import CompanyDetails from "./CompanyDetails";
import "../css/views/companyProfile.css";
import URLUtils from "../util/URLUtils";

class CompanyProfile extends Component {
  constructor(props) {
    super(props);

    let managePaymentMethods = URLUtils.getQueryVariable(
      "managePaymentMethods"
    );
    let initialViewIndex = managePaymentMethods === "true" ? 1 : 0;

    this.state = {
      account: this.props.account ? this.props.account : {},
      initialViewIndex: initialViewIndex,
    };
  }

  componentDidUpdate(nextProps) {
    if (this.state.account !== nextProps.account) {
      this.setState({ account: nextProps.account });
    }
  }

  handleAccountUpdated = (updatedAccount) => {
    this.setState({ account: updatedAccount });
    this.props.handleAccountUpdated(updatedAccount);
  };

  render() {
    let companyDetails = (
      <CompanyDetails
        account={this.state.account}
        handleLogout={this.props.handleLogout}
        handleAccountUpdated={this.handleAccountUpdated}
      />
    );

    return (
      <div
        id="ssCompanyProfile"
        className="company grey-bg hs-bookings-container h-100"
      >
        <div>
          <header>
            <ul className="breadcrumb">
              <li>Account</li>
              <li>Company</li>
            </ul>
            <h1 className="content-header-title">Company Profile</h1>
          </header>

          <div className="white-container">
            {/* <div className="row-no-gutters">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 sm-overflow">
                <ul className="user-menu dtable center-block">
                  {
                                        DEFAULT_ACCOUNT_VIEW_OPTIONS.map((item, index) =>
                                            <li
                                                key={'li'+index}
                                                className={'pointer users-'+item+(index===this.state.initialViewIndex ? ' active':'')}
                                                onClick={ ()=> this.setState({'initialViewIndex': index}) }>
                                                <span>{item}</span>
                                            </li>
                                        )
                                    }
                </ul>
              </div>
            </div> */}
            <div>{companyDetails}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default CompanyProfile;
