import React, { Component } from "react";
import "../css/views/manageUsers.css";
import "../css/theme/mainContent.css";
import "../css/theme/forms.css";
import "../css/theme/forms-block.css";
import "../css/theme/buttons.css";
import "../css/components/badge.css";
import "../css/views/accountReport.css";
import "../css/views/search.css";
import { createLogoutOnFailureHandler } from "../util/LogoutUtil";
import Busy from "../components/Busy";
import userImage from "../app-images/users/user@2x.png";
import Error from "../components/Error";
import Select from "../components/Select";
import {
  AccountType,
  SubscriptionType,
  UserTypeName,
} from "../components/constants/securspace-constants";
import MultiSelect from "../components/multiSelect/MultiSelect";
import { requestLocations } from "../components/location/requests/location-requests";
import _ from "underscore";
import { getErrorMessageForStandardResponse } from "../util/NetworkErrorUtil";
import ManageExistingUsers from "../components/user/ManageExistingUsers";
import { requestUpdateUserAccessibleLocations } from "../components/user/request/user-requests";
import { toast } from "react-toastify";
import { ADMIN_EDIT_ROLES, isRoleApproved } from "../util/user/UserUtil";
import { ajaxRequest } from "../ajax";
import { withRouter } from "react-router-dom";

const supplierRoleMap = new Map([
  [UserTypeName.GATE_CLERK, "ROLE_USERTYPE_GATECLERK"],
  [UserTypeName.GATE_MANAGER, "ROLE_USERTYPE_GATEMANAGER"],
  [UserTypeName.READ_ONLY_ADMIN, "ROLE_USERTYPE_READ_ONLY_ADMIN"],
  [UserTypeName.ADMIN, "ROLE_USERTYPE_OWNER"],
]);

class ManageUsers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      users: [],
      addNewUser: false,
      addUserErrorMessage: "",
      username: "",
      role: "",
      userToDelete: "",
      locations: [],
      locationOptions: [],
      selectedLocationIds: [],
      searchBox: "",
      isLocationVisible: false,
      currentUserName: "",
    };
  }

  componentDidMount() {
    Busy.set(true);
    const promises = [
      this.loadCurrentUser(),
      this.loadAccountUsers(this.props.account ? this.props.account.id : null),
      requestLocations()
        .then((resp) => {
          const processedLocations = _.map(resp.body, (location) => {
            return {
              value: location.id,
              displayName: location.locationName,
            };
          });

          this.setState({ locations: resp.body });
          this.setState({ locationOptions: processedLocations });
        })
        .catch((err) => {
          this.locationsFailedToLoad(getErrorMessageForStandardResponse(err));
        }),
    ];
    Promise.allSettled(promises).finally(() => Busy.set(false));
  }

  loadCurrentUser = () => {
    return ajaxRequest({
      url: "get-current-username",
      type: "GET",
      success: this.currentUser,
      statusCode: {
        401: createLogoutOnFailureHandler(this.props.handleLogout),
      },
      error: this.usersFailedToLoad,
    });
  };

  currentUser = (res) => {
    this.setState({
      currentUserName: res?.username,
    });
  };

  loadAccountUsers = (accountId) => {
    if (accountId) {
      return ajaxRequest({
        url: "account-users/" + accountId,
        type: "GET",
        success: this.usersLoaded,
        statusCode: {
          401: createLogoutOnFailureHandler(this.props.handleLogout),
        },
        error: this.usersFailedToLoad,
      });
    }
  };

  usersFailedToLoad = () => {
    this.setState({ errorMessage: "Failed to load users." });
  };

  locationsFailedToLoad = (errorMessage) => {
    this.setState({ errorMessage: errorMessage });
  };

  usersLoaded = (data) => {
    this.setState({
      users: data,
    });
  };

  addUser = () => {
    if (this.state.username) {
      if (!this.validateEmail(this.state.username)) {
        this.setState({
          addUserErrorMessage: "Invalid email address.",
        });
        return;
      }
    } else {
      this.setState({
        addUserErrorMessage: "Please enter the email address!",
      });
      return;
    }

    if (!this.state.role) {
      this.setState({
        addUserErrorMessage: "Please select a role for the user.",
      });
      return;
    }

    Busy.set(true);
    ajaxRequest({
      url: "account-users/",
      type: "POST",
      contentType: "application/json; charset=UTF-8",
      dataType: "json",
      data: JSON.stringify({
        accountId: this.props.account.id,
        username: this.state.username,
        role: supplierRoleMap.get(this.state.role),
        locationIds: this.state.isLocationVisible
          ? this.state.selectedLocationIds
          : [],
      }),
      success: () => {
        toast.success(
          `Your account has been created. A password setup link has been sent to your email address: ${this.state?.username}`,
          { position: toast.POSITION.TOP_RIGHT }
        );
        this.setState({
          showAddNewUser: "",
          username: "",
          role: "",
          selectedLocationIds: [],
          isLocationVisible: false,
        });

        this.loadAccountUsers(this.props.account.id).always(() =>
          Busy.set(false)
        );
      },
      statusCode: {
        401: createLogoutOnFailureHandler(this.props.handleLogout),
      },
      error: (res) => {
        this.setState({
          addUserErrorMessage: res?.responseJSON?.message,
        });
        Busy.set(false);
      },
    });
  };

  handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    const UPDATE_VISIBILITY_NAME = "role";
    if ("username2" === name || "username" === name) {
      this.setState({
        username: value,
        username2: value,
      });
    } else {
      this.setState({ [name]: value });
      if (
        name === UPDATE_VISIBILITY_NAME &&
        this.props.account.type === AccountType.SUPPLIER
      ) {
        if (value === UserTypeName.ADMIN) {
          this.setState({ isLocationVisible: false });
        } else {
          this.setState({ isLocationVisible: true });
        }
      }
    }
  };

  locationSelectionChange = (locations) => {
    this.setState({ selectedLocationIds: locations });
  };

  getRoleBasedOptions = (props) => {
    if (props.account.type === AccountType.SUPPLIER) {
      if (
        props.account.subscriptionType === SubscriptionType.MARKETPLACE_ONLY
      ) {
        return [UserTypeName.ADMIN];
      } else {
        return [
          UserTypeName.ADMIN,
          UserTypeName.READ_ONLY_ADMIN,
          UserTypeName.GATE_CLERK,
          UserTypeName.GATE_MANAGER,
        ];
      }
    }
  };

  validateEmail = (email) => {
    let reg = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
    return reg.test(email);
  };

  removeUserConfirm = (user) => {
    this.setState({ userToDelete: user });
  };

  removeUser = (user) => {
    Busy.set(true);

    ajaxRequest({
      url: "account-users/",
      type: "DELETE",
      contentType: "application/json; charset=UTF-8",
      dataType: "json",
      data: JSON.stringify({
        accountId: this.props.account.id,
        username: user.username,
        role: this.props.account.userType,
      }),
      success: () => {
        this.setState({ userToDelete: "" });
        this.loadAccountUsers(this.props.account.id).always(() =>
          Busy.set(false)
        );
        toast.success("User removed successfully!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        if (user?.username === this.state.currentUserName) {
          const { history } = this.props;
          history.push("/logout");
        }
      },
      statusCode: {
        401: createLogoutOnFailureHandler(this.props.handleLogout),
      },
      error: () => {
        Busy.set(false);
      },
    });
  };

  redirectPage = (page) => {
    window.location.href = page;
  };

  updateUserLocations = (user, locationIds) => {
    Busy.set(true);
    requestUpdateUserAccessibleLocations(
      user.accountId,
      user.username,
      locationIds
    )
      .then((resp) => {
        toast.success("Changes saved successfully!");
        this.loadAccountUsers(user.accountId).always(() => Busy.set(false));
      })
      .catch((err) => {
        Busy.set(false);
        toast.error("Unable to save user location access changes");
      });
  };
  searchChangeHandler = (event) => {
    this.setState({ searchBox: event.target.value });
  };

  isAddButtonDisabled = () => this.state.selectedLocationIds.length > 0;

  render() {
    return (
      <div
        id="ssCompanyProfile"
        className="grey-bg hs-bookings-container h-100"
      >
        <div>
          <header>
            <ul className="breadcrumb">
              <li>Account</li>
              <li>users </li>
            </ul>
            <h1 className="content-header-title">User Management</h1>
          </header>
          <div className="white-container">
            <div className="row-no-gutters ss-users-container">
              <div>
                <div className="add-new-user ss-users-item">
                  <div className="pointer col-sm-6 col-xs-6 flex">
                    {isRoleApproved(
                      ADMIN_EDIT_ROLES,
                      this.props.account.userType
                    ) ? (
                      <button
                        className="ss-button-primary reverse"
                        style={{ marginLeft: "10px" }}
                        onClick={() =>
                          this.setState({
                            showAddNewUser: !this.state.showAddNewUser,
                          })
                        }
                      >
                        <img alt="" src={userImage} />
                        <span style={{ whiteSpace: "nowrap" }}>
                          Add New User
                        </span>
                        <span className="large-plus">+</span>
                      </button>
                    ) : (
                      <button
                        className="ss-button-primary reverse"
                        onClick={() =>
                          this.setState({
                            showAddNewUser: !this.state.showAddNewUser,
                          })
                        }
                        style={{
                          cursor: "not-allowed",
                          opacity: "0.5",
                        }}
                        disabled={true}
                      >
                        <img alt="" src={userImage} />
                        <span style={{ whiteSpace: "nowrap" }}>
                          Add New User
                        </span>
                        <span className="large-plus">+</span>
                      </button>
                    )}
                  </div>
                </div>
                <div
                  className={
                    "transition-height " +
                    (this.state.showAddNewUser ? "fadeInUp animated" : "")
                  }
                >
                  {this.state.showAddNewUser ? (
                    <form className="ss-form ss-block ss-users-item">
                      <div>
                        <div style={{ width: "123%" }}>
                          <fieldset className="ss-stand-alone">
                            <label htmlFor="username">EMAIL ADDRESS</label>
                            <input
                              type="text"
                              id="username"
                              name="username"
                              placeholder="Enter the email address"
                              value={this.state.username}
                              onChange={this.handleChange}
                              style={{ fontWeight: "bold" }}
                            />
                          </fieldset>
                          <fieldset className="ss-stand-alone">
                            <label>ROLE</label>
                            <Select
                              id="role"
                              name="role"
                              comp="manageUser"
                              className="ss-bank-account-type"
                              handleChange={this.handleChange}
                              selectedOption={this.state.role}
                              placeholder="Please select"
                              options={this.getRoleBasedOptions(this.props)}
                            />
                          </fieldset>
                          {this.state.isLocationVisible && (
                            <MultiSelect
                              value={this.state.selectedLocationIds}
                              options={this.state.locationOptions}
                              onChange={this.locationSelectionChange}
                              label={"LOCATIONS"}
                              dropDown="dropdownIcon"
                            />
                          )}

                          {this.state.addUserErrorMessage ? (
                            <Error>{this.state.addUserErrorMessage}</Error>
                          ) : (
                            ""
                          )}

                          <div className="table text-center">
                            <button
                              type="button"
                              onClick={this.addUser}
                              className={`ss-button-primary ${
                                this.state.selectedLocationIds.length === 0 &&
                                this.state.role !== "Administrator"
                                  ? "disabled-button"
                                  : ""
                              }`}
                              disabled={
                                this.state.role === "Administrator"
                                  ? false
                                  : this.state.selectedLocationIds.length === 0
                              }
                            >
                              Add User
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  ) : null}
                </div>
              </div>
              <div className="users-item">
                <div className="data-list-filter-container search-container">
                  <fieldset className="trigger-click hs-field">
                    <label>Search</label>
                    <input
                      type="text"
                      id="searchBox"
                      name="searchBox"
                      value={this.state.searchBox}
                      onChange={this.searchChangeHandler}
                      placeholder="Search by username"
                    />
                    <i
                      className="fa fa-search"
                      style={{ float: "right", marginRight: "10px" }}
                    />
                  </fieldset>
                </div>
                <ManageExistingUsers
                  users={this.state.users}
                  searchValue={this.state.searchBox}
                  locationOptions={this.state.locationOptions}
                  onRemoveUser={this.removeUserConfirm}
                  onUpdateLocations={this.updateUserLocations}
                  account={this.props.account}
                  options={this.getRoleBasedOptions(this.props)}
                />

                {this.state.userToDelete ? (
                  <div className="unselectable">
                    <div
                      className="modal-dialog"
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "56%",
                        transform: "translate(-50%, -50%)",
                      }}
                    >
                      <div className="modal-content" style={{ width: "70%" }}>
                        <div className="popup-header-remove">
                          <h1>Remove this user</h1>
                          <button
                            type="button"
                            className="close"
                            aria-label="Close"
                            onClick={() =>
                              this.setState({
                                userToDelete: "",
                              })
                            }
                          >
                            <img alt="" src="../app-images/close.png" />
                          </button>
                        </div>
                        <div
                          style={{
                            marginLeft: "10px",
                            marginTop: "50px",
                            marginBottom: "50px",
                          }}
                        >
                          <span>
                            Are you sure you want to remove this User?
                          </span>
                        </div>
                        <div style={{ marginBottom: "18px" }}>
                          <button
                            className="ss-button-secondary"
                            onClick={() => this.setState({ userToDelete: "" })}
                            style={{ marginLeft: "18%", width: "30%" }}
                          >
                            Cancel
                          </button>
                          <button
                            className="ss-button-primary"
                            onClick={() =>
                              this.removeUser(this.state.userToDelete)
                            }
                            style={{ marginLeft: "5%", width: "30%" }}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ManageUsers);
