import React, { Component } from "react";
import { GlobalModalContext } from "../../context/global-modal-context";
import { ModalContainer } from "react-router-modal";

class GlobalModalManager extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contextValue: {},
    };
  }

  render() {
    return (
      <GlobalModalContext.Provider value={this.state.contextValue}>
        {this.props.children}

        <ModalContainer />
      </GlobalModalContext.Provider>
    );
  }
}

export default GlobalModalManager;
