import React, { useEffect, useState } from "react";
import ReportTitle from "../../../components/report/ReportTitle";
import { requestMakePaymentMethodDefault } from "../../../components/paymentMethods/request/payment-method-requests";
import Busy from "../../../components/Busy";
import DeletePopUp from "./deletePopUp";
import EditPopUp from "./EditPopUp";
import { toast } from "react-toastify";
import DeleteImage from "../../../img/trash-can.png";
import EditImage from "../../../img/pencil.png";
import { truncateString } from "./checkoutConstants";
function findLastIndexByPaymentType(array, paymentType) {
  for (let i = array.length - 1; i >= 0; i--) {
    if (array[i].type === paymentType) {
      return i;
    }
  }
  return -1; // Not found
}
function DisplayAddedPaymentMethod({
  checkedButtonPayment,
  paymentMethods,
  cvv,
  setLoadPaymentMethod,
  loadPaymentMethod,
  setSelectingCreditCardIndex,
  setSelectingAchIndex,
  achPayment,
  setSelectedIndex1,
  setSelectedIndex2,
  selectedIndex2,
  selectedIndex1,
  setBankName,
  setAchPayment,
}) {
  const [showModal, setShowModal] = useState(false);
  const [tag, setTag] = useState("");
  const [id, setId] = useState("");
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [editId, setEditId] = useState("");
  const [nickName, setNickName] = useState("");
  // console.log("paymentMethods", paymentMethods);
  // console.log("selectedIndex1", selectedIndex1);
  // console.log("selectedIndex2", selectedIndex2);

  // console.log("cvv", cvv);
  // console.log("achPayment", achPayment);

  useEffect(() => {
    if (!!cvv) {
      setSelectedIndex1(findLastIndexByPaymentType(paymentMethods, "CARD"));
      setSelectingCreditCardIndex(
        findLastIndexByPaymentType(paymentMethods, "CARD")
      );
      setSelectingAchIndex(null);
      setSelectedIndex2(null);
    } else if (achPayment) {
      setSelectedIndex2(
        paymentMethods?.findIndex((ele) => ele?.type === "ACH")
      );
      setSelectingAchIndex(
        paymentMethods?.findIndex((ele) => ele?.type === "ACH")
      );
      setSelectingCreditCardIndex(null);
      setSelectedIndex1(null);
    } else {
      setSelectedIndex1(
        paymentMethods?.findIndex(
          (ele) =>
            ele.defaultPaymentMethodForProfile === true && ele?.type === "CARD"
        )
      );
      setSelectedIndex2(
        paymentMethods?.findIndex(
          (ele) =>
            ele.defaultPaymentMethodForProfile === true && ele?.type === "ACH"
        )
      );
    }
  }, [paymentMethods]);

  const handleChange = (event, index, arrayIndex) => {
    // Deselect if clicking the currently selected checkbox
    if (index === (arrayIndex === 0 ? selectedIndex1 : selectedIndex2)) {
      if (arrayIndex === 0) {
        setSelectedIndex1(null);
      } else {
        setSelectedIndex2(null);
      }
      return; // Early exit to prevent unnecessary updates
    }

    // Update the appropriate state based on arrayIndex
    if (arrayIndex === 0) {
      setSelectedIndex1(index);
      setSelectingCreditCardIndex(index);
      setSelectedIndex2(null); // Deselect from second array
    } else {
      setSelectedIndex1(null); // Deselect from first array
      setSelectedIndex2(index);
      setSelectingAchIndex(index);
    }
  };
  const handleClick = async (ele) => {
    Busy.set(true);
    await requestMakePaymentMethodDefault(ele?.id)
      .then(() => toast.success("Payment method set as default!"))
      .catch((err) => toast.error("Internal server error"));
    setSelectingCreditCardIndex(null);
    setSelectingAchIndex(null);
    Busy.set(false);
    setLoadPaymentMethod(!loadPaymentMethod);
  };

  const handleClickIconDelete = (ele) => {
    setTag(ele?.type);
    setId(ele?.id);
    setShowModal(true);
  };

  const handleClickIconEdit = (ele) => {
    setNickName(ele?.nickName);
    setEditId(ele?.id);
    setShowModalEdit(true);
  };

  return (
    <>
      {paymentMethods?.length > 0 && (
        <div style={{ marginBottom: "10px" }}>
          <ReportTitle>Select Payment Method</ReportTitle>
        </div>
      )}
      <div>
        {paymentMethods?.map((ele, index) => {
          return ele?.type !== "ACH" ? (
            <>
              <div className="payment-method-container">
                <div className="d-flex align-items-center payment-checkbox">
                  <input
                    type="radio"
                    checked={index === selectedIndex1}
                    onChange={(event) => handleChange(event, index, 0)}
                  />
                  <b
                    className="MT-5"
                    style={{ marginTop: "4px" }}
                    title={ele?.nickName}
                  >
                    {ele?.nickName ? (
                      <span title={ele?.nickName}>
                        {truncateString(ele?.nickName)}
                      </span>
                    ) : (
                      `Credit Card ending in ****${ele?.lastFour}`
                    )}
                  </b>
                </div>
                <div className="payment-edit-options">
                  <div className="delete-button">
                    <img
                      className="deleteImage"
                      src={EditImage}
                      alt="edit image"
                      onClick={() => handleClickIconEdit(ele)}
                    />
                  </div>

                  <div className="delete-button">
                    {!ele.defaultPaymentMethodForProfile && (
                      <img
                        className="deleteImage"
                        src={DeleteImage}
                        alt="delete image"
                        onClick={() => handleClickIconDelete(ele)}
                      />
                    )}
                  </div>
                  <div className="default-button">
                    {!ele.defaultPaymentMethodForProfile &&
                      index === selectedIndex1 && (
                        <button
                          className="setAsDefaultButton"
                          onClick={() => handleClick(ele)}
                        >
                          Set as Default
                        </button>
                      )}
                    {!!ele.defaultPaymentMethodForProfile &&
                      index === selectedIndex1 && (
                        <button
                          className="defaultButton"
                          disabled={true}
                          style={{ cursor: "not-allowed" }}
                        >
                          Default
                        </button>
                      )}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="payment-method-container">
                <div className="d-flex align-items-center payment-checkbox">
                  <input
                    type="radio"
                    checked={index === selectedIndex2}
                    onChange={(event) => handleChange(event, index, 1)}
                  />
                  <b
                    className="MT-5"
                    style={{ marginTop: "4px" }}
                    title={ele?.nickName}
                  >
                    {!!ele?.nickName ? (
                      <span title={ele?.nickName}>
                        {truncateString(ele?.nickName)}
                      </span>
                    ) : (
                      `Bank Name : ${ele?.bankName}`
                    )}
                  </b>
                </div>
                <div className="payment-edit-options">
                  <div className="delete-button">
                    {/* <EditIcon
                     className="delete-button"
                     style={{
                       marginLeft: "18px",
                       cursor: "pointer",
                       color: "#f79019",
                     }}
                     onClick={() => handleClickIconEdit(ele)}
                   /> */}
                    <img
                      className="deleteImage"
                      src={EditImage}
                      alt="edit image"
                      onClick={() => handleClickIconEdit(ele)}
                    />
                  </div>

                  <div className="delete-button">
                    {!ele.defaultPaymentMethodForProfile && (
                      <img
                        className="deleteImage"
                        src={DeleteImage}
                        alt="delete image"
                        onClick={() => handleClickIconDelete(ele)}
                      />
                    )}
                  </div>
                  <div className="default-button">
                    {!ele.defaultPaymentMethodForProfile &&
                      index === selectedIndex2 && (
                        <button
                          className="setAsDefaultButton"
                          onClick={() => handleClick(ele)}
                        >
                          Set as Default
                        </button>
                      )}
                    {!!ele.defaultPaymentMethodForProfile &&
                      index === selectedIndex2 && (
                        <button
                          className="defaultButton"
                          disabled={true}
                          style={{ cursor: "not-allowed" }}
                        >
                          Default
                        </button>
                      )}
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>

      {showModalEdit && (
        <EditPopUp
          nickName={nickName}
          id={editId}
          setShowModalEdit={setShowModalEdit}
          setSelectingCreditCardIndex={setSelectingCreditCardIndex}
          setSelectingAchIndex={setSelectingAchIndex}
          setLoadPaymentMethod={setLoadPaymentMethod}
          loadPaymentMethod={loadPaymentMethod}
        />
      )}
      {showModal && (
        <DeletePopUp
          tag={tag}
          id={id}
          setShowModal={setShowModal}
          setSelectingCreditCardIndex={setSelectingCreditCardIndex}
          setSelectingAchIndex={setSelectingAchIndex}
          setLoadPaymentMethod={setLoadPaymentMethod}
          loadPaymentMethod={loadPaymentMethod}
          setBankName={setBankName}
          setAchPayment={setAchPayment}
        />
      )}
    </>
  );
}
export default DisplayAddedPaymentMethod;
