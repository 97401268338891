import request from "../../util/SuperagentUtils";

const baseUrl = `${process.env.REACT_APP_API_BASE_URL}/api/reservation/`;

export const getReservations = () => {
  return request.get(baseUrl).withCredentials();
};

export const approveReservation = (reservationId) => {
  return request
    .post(baseUrl + reservationId)
    .query({ isApproved: true })
    .withCredentials();
};

export const deleteReservation = (reservationId) => {
  return request.delete(baseUrl + reservationId).withCredentials();
};

export const resendCheckoutEmail = (reservationId) => {
  return request
    .post(baseUrl + reservationId + "/send-checkout-email")
    .withCredentials();
};
