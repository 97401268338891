import React from "react";
import ReportRow from "../report/ReportRow";
import WaitListItems from "./WaitListItems";
import { withGroupAndSortExportAndGroupings } from "../report/hocs/withGroupAndSortExportAndGroupings";

const WaitListContent = (props) => {
  const { waitListEntries } = props;

  return (
    <>
      <br />
      {waitListEntries &&
        waitListEntries.map((waitListEntry) => {
          return (
            <ReportRow key={waitListEntry.id}>
              <WaitListItems waitListEntry={waitListEntry} />
            </ReportRow>
          );
        })}
    </>
  );
};

export default WaitListContent;
export const WaitListWithGroupAndSort = withGroupAndSortExportAndGroupings(
  WaitListContent,
  "waitListEntries"
);
