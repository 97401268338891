import React from "react";
import ReportRow from "../report/ReportRow";
import ReportDataRow from "../report/ReportDataRow";
import ReportData from "../report/ReportData";
import ReservationMenu from "./ReservationMenu";
import DateDisplay from "../date/DateDisplay";
import {
  formatBool,
  formatDate,
  formatEquipmentLength,
} from "../../util/booking/bookingUtil";
import { ALL_EDIT_ROLES, isRoleApproved } from "../../util/user/UserUtil";

const ReservationData = (props) => {
  const { reservations, setUpdateReservation, account } = props;

  return (
    <>
      {reservations &&
        reservations.map((reservation) => {
          return (
            <ReportRow key={reservation.id}>
              {isRoleApproved(ALL_EDIT_ROLES, account?.userType) && (
                <ReservationMenu
                  reservation={reservation}
                  setUpdateReservation={setUpdateReservation}
                />
              )}
              <ReportDataRow>
                <ReportData label={"Approved"}>
                  {reservation?.approved
                    ? formatBool(reservation.approved)
                    : "-"}
                </ReportData>
                <ReportData label={"Zone Name"}>
                  {reservation?.zoneName || "-"}
                </ReportData>
                <ReportData label={"Company Name"}>
                  {reservation?.companyName || "-"}
                </ReportData>
                <ReportData label={"Location Name"}>
                  {reservation?.locationName || "-"}
                </ReportData>
                <ReportData label={"Number of Spaces"}>
                  {reservation?.numberOfSpaces || "-"}
                </ReportData>
                <ReportData label={"Frequency"}>
                  {reservation.frequency === "ONE_TIME"
                    ? "ONE TIME"
                    : reservation?.frequency || "-"}
                </ReportData>
                <ReportData label={"Equipment Length"}>
                  {reservation?.equipmentLength
                    ? formatEquipmentLength(reservation.equipmentLength)
                    : "-"}
                </ReportData>
                <ReportData label={"Start Date"}>
                  <DateDisplay date={reservation.startDate}>
                    {reservation?.startDate || "-"}
                  </DateDisplay>
                </ReportData>
                <ReportData label={"End Date"}>
                  {reservation?.endDate &&
                  reservation.frequency === "ONE_TIME" ? (
                    <DateDisplay date={reservation.endDate}>
                      {reservation.endDate}
                    </DateDisplay>
                  ) : (
                    "-"
                  )}
                </ReportData>
                <ReportData label={"Created On"}>
                  {reservation?.creationDate
                    ? formatDate(reservation.creationDate)
                    : "-"}
                </ReportData>
              </ReportDataRow>
            </ReportRow>
          );
        })}
    </>
  );
};

export default ReservationData;
