import request from "../../../util/SuperagentUtils";

export const plateRecognizerRequest = (image: File, correlationId: String) => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  if (image) {
    return request
      .post(
        `${baseUrl}
            /api/plate-recognizer`
      )
      .attach("file", image)
      .field("correlationId", correlationId)
      .withCredentials();
  }
};
