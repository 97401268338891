import request from "../../../util/SuperagentUtils";

const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const requestCognitoTokenForUser = (
  accountId: String,
  username: String
): Promise<Object> => {
  if (accountId && username) {
    return request
      .get(`${baseUrl}/api/account/${accountId}/cognito/token`)
      .query({ email: username })
      .withCredentials();
  } else {
    //console.error("Attempting request for cognito token with invalid account id or username");
  }
};

export const requestUpdateUserAccessibleLocations = (
  accountId: String,
  username: String,
  locationIds: Array<String>
): Promise => {
  return request
    .put(`${baseUrl}/api/account-users/${accountId}`)
    .send({ username, locationIds })
    .withCredentials();
};
