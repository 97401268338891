import React, { useEffect, useState } from "react";
import "../../css/components/report/report-form-date.css";
import ReportFormInputLabel from "./ReportFormInputLabel";
import { DateFormats } from "../constants/securspace-constants";
import moment from "moment";

const ReportFormDateInput = (props) => {
  const { date, onDateChange, label, styles } = props;
  const DATE_INPUT_FORMAT = "yyyy-MM-DD";
  // const DATE_INPUT_FORMAT = "MM-DD-yyyy";

  const [formattedDate, setFormattedDate] = useState(
    moment(date).format(DATE_INPUT_FORMAT)
  );

  useEffect(() => {
    if (date && moment(date).isValid()) {
      setFormattedDate(moment(date).format(DATE_INPUT_FORMAT));
    } else {
      onDateChange(moment().format(DateFormats.DAY));
    }
  }, [date, onDateChange]);

  const handleDateChange = (event) => {
    const dateValue = event.target.value;
    onDateChange(moment(dateValue).format(DateFormats.DAY));
  };

  return (
    <fieldset className="ss-report-form-date-fieldset">
      <ReportFormInputLabel label={label} />
      <input
        type="date"
        className={`ss-report-form-date ${styles}`}
        name="formDate"
        placeholder="MM/DD/YYYY"
        value={formattedDate}
        onChange={handleDateChange}
        onKeyDown={(e) => e.preventDefault()}
      />
    </fieldset>
  );
};

export default ReportFormDateInput;
