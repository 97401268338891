import React from "react";
import BooleanInput from "../common/BooleanInput";
import MoneyInput from "../common/MoneyInput";
import "../zonebuilder/zones/zones.css";

const OverridePricing = (props) => {
  const {
    checked,
    setChecked,
    price,
    onPriceChange,
    selectedZone1,
    selectedZone,
  } = props;

  const handleChange = (val) => {
    onPriceChange(val);
  };

  return (
    <>
      <div className="powerAccessibleDiv">
        <BooleanInput
          className={"form-control orange"}
          label={"Override Zone Pricing"}
          name={"Override Zone Pricing"}
          type={"checkbox"}
          checked={checked}
          onChange={() => {
            setChecked((prevChecked) => !prevChecked);
          }}
        />
      </div>
      <hr />
      {checked && (
        <MoneyInput
          label={"Booking Charge"}
          className={"form-control"}
          type={"number"}
          placeholder={price}
          value={price}
          onChangePrice={(val) => handleChange(val)}
        />
      )}
    </>
  );
};

export default OverridePricing;
