import React from "react";
import ReportDataRow from "../report/ReportDataRow";
import ReportData from "../report/ReportData";
import { EquipmentLengthsConstant } from "../constants/securspace-constants";
import MoneyDisplay from "../money/MoneyDisplay";

const ZoneCheckout = (props) => {
  const { zone } = props;

  return (
    <ReportDataRow>
      <ReportData label={"Zone Name"}>{zone.name}</ReportData>
      <ReportData label={"Equipment Length"}>
        {EquipmentLengthsConstant[zone.equipmentLength]}
      </ReportData>
      <ReportData label={"Power Accessible "}>
        {zone.powerAccessible ? "Yes" : "No"}
      </ReportData>
      <ReportData label={"Price Per Day/One Time"}>
        {zone.pricePerDayInCents && (
          <MoneyDisplay dollarsAsLong={zone.pricePerDayInCents} />
        )}
      </ReportData>
      {/* <ReportData label={"Price Per Week"}>
        {zone.pricePerWeekInCents && (
          <MoneyDisplay dollarsAsLong={zone.pricePerWeekInCents} />
        )}
      </ReportData> */}
      <ReportData label={"Price Per Month/Recurring"}>
        {zone.pricePerMonthInCents && (
          <MoneyDisplay dollarsAsLong={zone.pricePerMonthInCents} />
        )}
      </ReportData>
    </ReportDataRow>
  );
};

export default ZoneCheckout;
