import React from "react";
import "../css/theme/buttons.css";
import "../css/components/buttonSelector.css";
import { ADMIN_EDIT_ROLES, isRoleApproved } from "../util/user/UserUtil";
import { UserType } from "./constants/securspace-constants";

const ButtonSelector2 = ({
  options,
  selectedOption,
  handleOptionSelected,
  account,
}) => {
  const buttonStyle = () => {
    return {
      marginTop: "14px",
      width: buttonWidth,
      height: buttonHeight,
      paddingTop: "1.2px",
      fontSize: "14px",
    };
  };

  const getOptionName = (option) => {
    return option && option.hasOwnProperty("name") ? option.name : option;
  };

  const optionsEqual = (option1, option2) => {
    return getOptionName(option1) === getOptionName(option2);
  };

  const optionClassName = (option, optionIndex) => {
    let firstOption = optionIndex === 0;
    let lastOption = optionIndex === options.length - 1;

    let positionClassName = firstOption
      ? "ss-button-primary-l"
      : lastOption
      ? "ss-button-primary-r"
      : "ss-button-primary-middle";
    let selectedClassName = optionsEqual(option, selectedOption)
      ? " ss-button-primary-selected"
      : " ss-button-primary-deselected";

    return positionClassName + " " + selectedClassName;
  };

  const getDisplayValue = (option) => {
    return option && option.hasOwnProperty("name") ? option.name : option;
  };

  return (
    <div className="button-selector">
      {options.map((option, index) => (
        <button
          key={index}
          type="button"
          className={optionClassName(option, index)}
          onClick={() => handleOptionSelected(option)}
          disabled={account.userType === "READ_ONLY_ADMIN"}
        >
          {getDisplayValue(option)}
        </button>
      ))}
    </div>
  );
};

ButtonSelector2.defaultProps = {
  options: [],
  selectedOption: {},
  handleOptionSelected: null,
};

export default ButtonSelector2;
