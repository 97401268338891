import React, { useEffect, useState } from "react";
import Steps from "../steps/Steps";
import SelectBuyerAccount from "./SelectBuyerAccount";
import { getBuyerAccounts } from "./requests/resevation-requests";
import { AccountRefOption } from "../../controls/AccountRefOption";

const CustomerSelect = (props) => {
  const { stepNumber, handleBuyerAccountChange, selectedBuyerAccountId } =
    props;

  const [buyerAccounts, setBuyerAccounts] = useState();
  const [buttonDisabled, setButtonDisabled] = useState(true);

  useEffect(() => {
    getBuyerAccounts().then((response) => {
      setBuyerAccounts(
        response.body.map(
          (buyerAccount) => new AccountRefOption(buyerAccount, true)
        )
      );
    });

    if (selectedBuyerAccountId) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [buttonDisabled, setButtonDisabled, selectedBuyerAccountId]);

  return (
    <>
      <SelectBuyerAccount
        buyerAccounts={buyerAccounts}
        selectedBuyerAccount={selectedBuyerAccountId}
        handleBuyerAccountChange={handleBuyerAccountChange}
      />
      <Steps
        step={stepNumber}
        type="buyerAccount"
        canContinue={buttonDisabled}
        {...props}
      />
    </>
  );
};

export default CustomerSelect;
