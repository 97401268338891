import React, { useEffect, useState } from "react";
import { getRenewingBookings30DaysOut } from "../zoneRequest";
import { toast } from "react-toastify";
import { getErrorMessageForStandardResponse } from "../../../util/NetworkErrorUtil";
import ReportBackgroundContainer from "../../report/ReportBackgroundContainer";
import Steps from "../../steps/Steps";
import BookingContainer from "./BookingContainer";

const ValidateBookings = (props) => {
  const {
    zone,
    stepNumber,
    equipmentLength,
    powerAccessible,
    numberOfSpaces,
    active,
    buttonDisabled,
    setButtonDisabled,
  } = props;

  const [bookings, setBookings] = useState(null);
  const [thirtyDayBookings, setThirtyDayBookings] = useState(null);

  const getActiveBookings = async (
    zoneId,
    equipmentLength,
    powerAccessible,
    numberOfSpaces,
    interval
  ) => {
    try {
      if (equipmentLength || powerAccessible || numberOfSpaces) {
        const response = await getRenewingBookings30DaysOut(
          zoneId,
          equipmentLength,
          powerAccessible,
          numberOfSpaces,
          interval
        );
        return response.body;
      } else {
        const response = await getRenewingBookings30DaysOut(
          zoneId,
          "",
          "",
          "",
          interval
        );
        return response.body;
      }
    } catch (error) {
      toast.error(getErrorMessageForStandardResponse(error));
    }
  };

  useEffect(() => {
    // Gets all the bookings that are 30 days out that the zone edit could cause to be cancelled
    if (zone.active && !active) {
      getActiveBookings(zone.id, "", "", "", 0).then((data) => {
        setBookings(data);
      });
    } else {
      getActiveBookings(
        zone.id,
        equipmentLength,
        powerAccessible,
        numberOfSpaces,
        1
      )
        .then((data) => {
          setBookings(data);
          if (data && data.length > 0) {
            let totalBookedSpaces = 0;
            for (const booking of data) {
              totalBookedSpaces += booking.numberOfSpaces;
            }
            if (totalBookedSpaces > numberOfSpaces) {
              toast.error(
                `Number of booked spaces: ${totalBookedSpaces} exceeds zone number of spaces!!`
              );
            }
          }
        })
        // Gets all the bookings that are within the next 30 days that could cause the zone to not be editable
        .then(
          getActiveBookings(
            zone.id,
            equipmentLength,
            powerAccessible,
            numberOfSpaces,
            0
          ).then((data) => {
            setThirtyDayBookings(data);
          })
        );
    }
  }, [zone, equipmentLength, powerAccessible, numberOfSpaces, active]);

  useEffect(() => {
    // if (bookings?.length === 0 && thirtyDayBookings?.length === 0) {
    //   setButtonDisabled(false);
    // }
    if (thirtyDayBookings?.length > 0) {
      setButtonDisabled(true);
    }
  }, [
    bookings,
    thirtyDayBookings,
    equipmentLength,
    powerAccessible,
    numberOfSpaces,
    setButtonDisabled,
  ]);

  return (
    <ReportBackgroundContainer>
      <BookingContainer
        bookings={bookings}
        thirtyDayBookings={thirtyDayBookings}
      />
      <Steps step={stepNumber} canContinue={buttonDisabled} {...props} />
    </ReportBackgroundContainer>
  );
};

export default ValidateBookings;
