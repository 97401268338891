import React from "react";
import CheckoutField from "./CheckoutField";

const CheckoutTable = (props) => {
  const {
    containerNumber,
    trailerNumber,
    chassisNumber,
    chassisLicensePlateNumber,
    sealNumber,
    assetType,
    assetSize,
    checkInDate,
  } = props;
  const equipMentVal = (assetType) => {
    return assetType === "CONTAINER_LOADED"
      ? "Container / Chassis (Loaded)"
      : assetType === "CONTAINER_EMPTY"
      ? "Container / Chassis (Empty)"
      : assetType === "CHASSIS_ONLY"
      ? "Chassis Only"
      : assetType === "TRUCK_CONTAINER_LOADED"
      ? "Truck + Container / Chassis (Loaded)"
      : assetType === "TRUCK_CONTAINER_EMPTY"
      ? "Truck + Container / Chassis (Empty)"
      : assetType === "TRUCK_TRAILER_LOADED"
      ? "Truck + Trailer (Loaded)"
      : assetType === "TRUCK_TRAILER_EMPTY"
      ? "Truck + Trailer (Empty)"
      : assetType === "TRUCK_ONLY"
      ? "Truck Only"
      : assetType === "TRAILER_LOADED"
      ? "Trailer (Loaded)"
      : assetType === "TRAILER_EMPTY"
      ? "Trailer (Empty)"
      : assetType === "REEFER_LOADED_PLUGIN"
      ? "Refrigerated (Loaded) (Plug In)"
      : assetType === "REEFER_LOADED_NO_PLUGIN"
      ? "Reefer (Loaded) (No Plug In)"
      : assetType === "TRUCK_REEFER_LOADED_PLUGIN"
      ? "Truck + Reefer (Loaded) (Plug In)"
      : assetType === "TRUCK_REEFER_LOADED_NO_PLUGIN"
      ? "Truck + Reefer (Loaded) (No Plug In)"
      : assetType;
  };

  return (
    <table className="table">
      <tbody>
        {containerNumber && (
          <CheckoutField text={"Container Number:"} prop={containerNumber} />
        )}
        {trailerNumber && (
          <CheckoutField text={"Trailer Number:"} prop={trailerNumber} />
        )}
        {chassisNumber && (
          <CheckoutField text={"Chassis Number:"} prop={chassisNumber} />
        )}
        {chassisLicensePlateNumber && (
          <CheckoutField
            text={"Chassis License Plate Number:"}
            prop={chassisLicensePlateNumber}
          />
        )}
        {sealNumber && (
          <CheckoutField text={"Seal Number:"} prop={sealNumber} />
        )}
        {assetType && (
          <CheckoutField
            text={"Equipment Type:"}
            prop={equipMentVal(assetType)}
          />
        )}
        {assetSize && (
          <CheckoutField text={"Equipment Length:"} prop={assetSize} />
        )}
        {checkInDate && (
          <CheckoutField text={"Check In Date:"} prop={checkInDate} />
        )}
      </tbody>
    </table>
  );
};

export default CheckoutTable;
