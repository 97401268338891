import React from 'react';

const CheckoutField = (props) => {

  const { text, prop } = props;

  return <tr>
    <td><strong>{text}</strong></td>
    <td>{prop}</td>
  </tr>
}

export default CheckoutField;
