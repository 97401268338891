import React, { useContext, useState } from "react";
import StepWizard from "react-step-wizard";
import FineTypeSelect from "./FineTypeSelect";
import ShouldBeTowed from "./ShouldBeTowed";
import FineNotes from "./FineNotes";
import { AppContext } from "../../context/app-context";
import { saveFineAssessment } from "../checkin/request/fine-request";
import { toast } from "react-toastify";
import { getErrorMessageForStandardResponse } from "../../util/NetworkErrorUtil";
import ShowPrevFines from "./ShowPrevFines";
import ReportBackgroundContainer from "../report/ReportBackgroundContainer";
import Busy from "../Busy";

const AssessFine = (props) => {
  const {
    containerNumber,
    trailerNumber,
    chassisNumber,
    truckLicensePlateNumber,
    locationId,
    handleSubPanelCloseEvent,
    assetType,
    assetSize,
    driverLicenseNumber,
    chassisLicensePlateNumber,
    sealNumber,
    towAway,
  } = props;
  const [fineType, setFineType] = useState(null);
  const [towed, setTowed] = useState(towAway);
  const [notes, setNotes] = useState("");
  const { user } = useContext(AppContext);

  const onFineTypeChange = (fineType) => {
    setFineType(fineType);
  };

  const onNotesChange = (notes) => {
    setNotes(notes);
  };

  const onTowedChange = () => {
    setTowed(!towed);
  };

  const saveAssessmentToAPI = () => {
    Busy.set(true);
    const fineAssessment = {
      fineType: {
        id: fineType,
      },
      towInventory: towed,
      gateClerkAccountId: user.id,
      locationId: locationId,
      notes: notes,
    };

    saveFineAssessment(
      containerNumber,
      trailerNumber,
      chassisNumber,
      truckLicensePlateNumber,
      fineAssessment
    )
      .then(
        () => {
          toast.success("Fine Assessed Successfully!", {
            position: toast.POSITION.TOP_RIGHT,
          });
          handleSubPanelCloseEvent();
        },
        (error) => {
          // console.error(getErrorMessageForStandardResponse(error));
        }
      )
      .catch((error) => {
        // console.error(getErrorMessageForStandardResponse(error));
      })
      .finally(() => Busy.set(false));
  };

  return (
    <div>
      <ReportBackgroundContainer>
        <StepWizard>
          <FineTypeSelect onFineTypeChange={onFineTypeChange} stepNumber={1} />
          <FineNotes
            notes={notes}
            onNotesChange={onNotesChange}
            stepNumber={2}
          />
          <ShowPrevFines
            locationId={locationId}
            containerNumber={containerNumber}
            trailerNumber={trailerNumber}
            chassisNumber={chassisNumber}
            truckLicensePlateNumber={truckLicensePlateNumber}
            stepNumber={3}
          />
          <ShouldBeTowed
            containerNumber={containerNumber}
            trailerNumber={trailerNumber}
            chassisNumber={chassisNumber}
            truckLicensePlateNumber={truckLicensePlateNumber}
            chassisLicensePlateNumber={chassisLicensePlateNumber}
            sealNumber={sealNumber}
            assetSize={assetSize}
            driverLicenseNumber={driverLicenseNumber}
            saveAssessmentToAPI={saveAssessmentToAPI}
            towed={towed}
            onTowedChange={onTowedChange}
            assetType={assetType}
            stepNumber={4}
          />
        </StepWizard>
      </ReportBackgroundContainer>
    </div>
  );
};

export default AssessFine;
