import React from "react";
import PropTypes from "prop-types";
import Steps from "../../steps/Steps";
import Input from "../../common/Input";
import BooleanInput from "../../common/BooleanInput";
import "./zones.css";

const ActivationDates = (props) => {
  const {
    stepNumber,
    active,
    onActiveChange,
    activationDate,
    onActivationDateChange,
    deactivationDate,
    onDeactivationDateChange,
    inactiveReason,
    onInactiveReasonChange,
    showInactiveReason,
    setButtonDisabled,
  } = props;

  const className = "form-control orange";
  const dateType = "date";
  const checkBoxType = "checkbox";
  const activeName = "Active";
  const activeLabel = "Active: ";
  const activationDateName = "Activation Date";
  const deactivationDateName = "Deactivation Date";
  const activationDateLabel = "Activation Date: ";
  const deactivationDateLabel = "Deactivation Date: ";
  const inactiveReasonName = "Inactive Reason";
  const inactiveReasonLabel = "Inactive Reason: ";

  return (
    <div>
      <div className="powerAccessibleDiv">
        <BooleanInput
          label={activeLabel}
          className={className}
          type={checkBoxType}
          name={activeName}
          checked={active}
          onChange={onActiveChange}
        />
      </div>
      <hr />
      {showInactiveReason ? (
        <Input
          label={inactiveReasonLabel}
          className={className}
          hide={!showInactiveReason}
          name={inactiveReasonName}
          value={inactiveReason}
          onChange={onInactiveReasonChange}
        />
      ) : (
        <div className="inputDivBox">
          <Input
            label={activationDateLabel}
            className={className}
            type={dateType}
            name={activationDateName}
            value={activationDate}
            onChange={onActivationDateChange}
          />
          <Input
            label={deactivationDateLabel}
            className={className}
            type={dateType}
            name={deactivationDateName}
            activationDate={activationDate}
            deactivationDate={deactivationDate}
            value={deactivationDate}
            setButtonDisabled={setButtonDisabled}
            disabled={activationDate === "MM/dd/yyyy" ? true : false}
            onChange={onDeactivationDateChange}
          />
        </div>
      )}

      <Steps
        canContinue={
          !(
            (showInactiveReason && inactiveReason === "") ||
            active ||
            activationDate != "MM/dd/yyyy"
          )
        }
        step={stepNumber}
        {...props}
      />
    </div>
  );
};

ActivationDates.propType = {
  activationDate: PropTypes.string,
  onActivationDateChange: PropTypes.func,
  deactivationDate: PropTypes.string,
  onDeactivationDateChange: PropTypes.func,
  inactiveReason: PropTypes.string,
  onInactiveReasonChange: PropTypes.func,
};

export default ActivationDates;
