import React from 'react';
import '../../css/components/report/report-title.css';
import * as PropTypes from 'prop-types';

const ReportTitle = (props) => {
  const { children, subtext } = props;

  return (
    <h3 className="ss-report-title-h3">
      {children}
      {
        subtext && <span className="ss-report-title-subtext">{subtext}</span>
      }
    </h3>
  )
}

ReportTitle.propTypes = {
  subtext: PropTypes.any
}

export default ReportTitle;
