import React from "react";
import ReportDataRow from "../report/ReportDataRow";
import ReportData from "../report/ReportData";
import DateDisplay from "../date/DateDisplay";

const ReservationCheckout = (props) => {
  const { reservation } = props;

  return (
    <ReportDataRow>
      <ReportData label={"Number of Spaces"}>
        {reservation.numberOfSpaces}
      </ReportData>
      <ReportData label={"Frequency"}>
        {reservation.frequency === "ONE_TIME"
          ? "ONE TIME"
          : reservation.frequency}
      </ReportData>
      {/* <ReportData label={"Duration"}>{reservation.duration}</ReportData> */}
      <ReportData label={"Start Date"}>
        {reservation && reservation.startDate && (
          <DateDisplay date={reservation.startDate} format={"MM/DD/YYYY"} />
        )}
      </ReportData>
      {reservation?.frequency === "ONE_TIME" && (
        <ReportData label={"End Date"}>
          {reservation && reservation.endDate && (
            <DateDisplay date={reservation.endDate} format={"MM/DD/YYYY"} />
          )}
        </ReportData>
      )}
    </ReportDataRow>
  );
};

export default ReservationCheckout;
