import React from "react";
import _ from "underscore";

export const withGroupings =
  (Component, dataProp = "data") =>
  (props) => {
    const {
      grouped,
      groupBy,
      sortedAndGroupedData,
      reportFields,
      [dataProp]: data,
      ...rest
    } = props;

    const reportTitleFormat = (filteredDataList, groupBy) => {
      const reportField = reportFields.find(
        (reportField) =>
          reportField &&
          reportField.groupDisplayFormat &&
          reportField.name === groupBy
      );
      const groupByFormatFunction =
        reportField && reportField.groupDisplayFormat;
      const dataToFormat = filteredDataList[dataProp][0][groupBy];
      return (
        (groupByFormatFunction && groupByFormatFunction(dataToFormat)) ||
        dataToFormat ||
        "No Value"
      );
    };

    return (
      <>
        {!grouped && <Component {...rest} {...sortedAndGroupedData} />}
        {grouped &&
          _.map(sortedAndGroupedData, (filteredDataList) => (
            <Component
              {...rest}
              reportTitleCount={`ALL ${filteredDataList[dataProp]?.length}`}
              reportTitle={reportTitleFormat(filteredDataList, groupBy)}
              key={filteredDataList[dataProp][0][groupBy]}
              {...filteredDataList}
            />
          ))}
      </>
    );
  };
