import request from "../../util/SuperagentUtils";

export const checkoutImages = (checkoutRequest) => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  return request
    .post(
      `${baseUrl}
    /api/check-out-images`
    )
    .withCredentials()
    .send(checkoutRequest);
};

export const getApprovedDrivers = (accountId) => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  return request
    .get(`${baseUrl}/api/driver/${accountId}/approved-drivers`)
    .withCredentials();
};
