import React from 'react';

const LocationSelectItem = (props) => {
  const { location } = props;

  return <option value={location.id}>
    {location.locationName}
  </option>
}

export default LocationSelectItem;