import React from 'react';

const ReportTotalContainer = (props) => {
  const {children} = props;
  return <div className={'ss-report-total-container'}>
    {children}
  </div>
}

export default ReportTotalContainer;
