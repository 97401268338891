import React, { useEffect, useState } from "react";
import ThirdPartyPaymentManagement from "../../../components/thirdPartyManagement/ThirdPartyPaymentManagement";
import useJwt from "../../../util/jwt/useJwt";
import OutlinedButton from "../../../components/form/OutlinedButton";
import { useHistory } from "react-router-dom";
import Busy from "../../../components/Busy";
import useQuery from "../../../util/query/useQuery";
import moment from "moment";
import { ErrorRounded } from "@material-ui/icons";

const ManagePayments = (props) => {
  const { account, loading } = props;
  const { showBack = false } = useQuery();
  const { username, exp } = useJwt();
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState("");
  const TOKEN_EXPIRED = "Sorry, your token has expired try again later.";

  useEffect(() => {
    if (moment().isAfter(moment.unix(exp))) {
      setErrorMessage(TOKEN_EXPIRED);
    }
  }, [exp, setErrorMessage]);

  useEffect(() => {
    if (loading) {
      Busy.set(true);
    } else {
      Busy.set(false);
      if (!account || !account.userType) {
        setErrorMessage(TOKEN_EXPIRED);
      }
    }
  }, [loading, account]);

  const handleBack = () => {
    history.goBack();
  };

  return (
    <div className={"container"}>
      <h3>Manage Payment Methods</h3>
      <br />
      {!errorMessage && !loading && (
        <ThirdPartyPaymentManagement
          authority={account}
          userType={account?.userType}
          contactEmail={username}
        />
      )}
      <br />
      {showBack && (
        <OutlinedButton label={"Back to Checkout"} onClick={handleBack} />
      )}
      {
        <div>
          {!!errorMessage && (
            <span>
              <ErrorRounded />
              {errorMessage}
            </span>
          )}
        </div>
      }
    </div>
  );
};

export default ManagePayments;
