import React from "react";
import ReportRow from "../report/ReportRow";
import ZoneRevenueItem from "./ZoneRevenueItem";
import { withGroupAndSortExportAndGroupings } from "../report/hocs/withGroupAndSortExportAndGroupings";
import ReportTotal from "../report/ReportTotal";
import { formatCurrencyValue } from "../../util/PaymentUtils";

const ZoneRevenueContent = (props) => {
  const { zones } = props;

  const formatTotals = (value) => formatCurrencyValue(value, true);

  return (
    <>
      <ReportTotal
        data={zones}
        totalFieldLabel={"Revenue"}
        totalFieldName={"totalRevenue"}
        totalFieldFormat={formatTotals}
      />
      <br />
      {zones &&
        zones.map((zone) => {
          return (
            <ReportRow key={zone.zoneId} name="zoneRevenueReport">
              <ZoneRevenueItem zone={zone} />
            </ReportRow>
          );
        })}
    </>
  );
};

export default ZoneRevenueContent;
export const ZoneItemsWithGroupAndSort = withGroupAndSortExportAndGroupings(
  ZoneRevenueContent,
  "zones"
);
