import { LogoType } from "../../components/constants/securspace-constants";

const defaultNavConfig = {
  highlighted: false,
  logoType: LogoType.LOGO_TRANSPARENT,
  exact: false,
};

const makeNavConfig = (config: { path: String, label: String }) => {
  return {
    ...defaultNavConfig,
    ...config,
  };
};

const makeNav = (path: String, label: String, config?: {}) => {
  let newConfig = { path, label };
  if (config) {
    newConfig = { ...newConfig, ...config };
  }
  return makeNavConfig(newConfig);
};

export const LandingNav = Object.freeze({
  LOG_IN: makeNav("login", "LOG IN"),
  SIGN_UP: makeNav("signup", "GET STARTED", {
    highlighted: true,
    className: "pointer",
    linkClassName: "no-link-hover",
  }),
});

export const AccountNav = Object.freeze({
  COMPANY_PROFILE: makeNav("company-profile", "COMPANY"),
  LOCATIONS_PROFILE: makeNav("locations-profile", "LOCATIONS"),
  ZONES_PROFILE: makeNav("zones-profile", "ZONES"),
  USER_MANAGEMENT: makeNav("user-management", "USERS"),
  NOTIFICATIONS_SETTINGS: makeNav("notification-settings", "NOTIFICATIONS"),
  SIGN_OUT: makeNav("logout", "LOG OUT"),
});

export const AdminNav = Object.freeze({
  ADMIN_REPORTS: makeNav("admin-reports", "CSV REPORTS"),
  ADMIN_ACCOUNTS: makeNav("admin-accounts", "ACCOUNTS"),
  ADMIN_BOOKINGS: makeNav("admin-bookings", "BOOKINGS"),
  ADMIN_LOCATIONS: makeNav("admin-locations", "LOCATIONS"),
  ADMIN_INVOICES: makeNav("admin-invoices", "INVOICES"),
  ADMIN_PENDING_CHARGES: makeNav("admin-pending-charges", "PENDING_CHARGES"),
  ADMIN_BOOKED_SPACE_CALENDAR: makeNav(
    "admin-booked-space-calendar",
    "BOOKED SPACE CALENDAR"
  ),
  LOGIN_AS_ACCOUNT: makeNav("login-as-account", "LOG IN AS ACCOUNT"),
});

export const BuyerNav = Object.freeze({
  MY_BOOKINGS: makeNav("my-bookings", "MY BOOKINGS"),
  BUYER_INVENTORY: makeNav("buyer-inventory", "CURRENT INVENTORY"),
  BUYER_INTERCHANGES: makeNav("buyer-interchanges", "INTERCHANGES"),
  BUYER_INVOICES: makeNav("buyer-invoices", "INVOICES"),
  BUYER_BOOKED_SPACE_CALENDAR: makeNav(
    "buyer-booked-space-calendar",
    "BOOKED SPACE CALENDAR"
  ),
});

export const GateManagementNav = Object.freeze({
  CHECK_IN: makeNav("check-in", "CHECK IN"),
  SUPPLIER_INVENTORY: makeNav("supplier-inventory", "CURRENT INVENTORY"),
  SUPPLIER_INTERCHANGES: makeNav("supplier-interchanges", "INTERCHANGES"),
  RESERVATIONS: makeNav("reservations", "RESERVATIONS"),
  SUPPLIER_BOOKINGS: makeNav("supplier-bookings", "ALL BOOKINGS"),
  ZONES_PROFILE: makeNav("zones-profile", "ZONES"),
  DRIVERS: makeNav("drivers", "DRIVERS"),
});

export const SupplierNav = Object.freeze({
  GMS_LANDING: makeNav("gms-landing", "GATE MANAGEMENT"),
  RESERVATIONS: makeNav("reservations", "RESERVATIONS"),
  SUPPLIER_BOOKINGS: makeNav("supplier-bookings", "ALL BOOKINGS"),
  SUPPLIER_BOOKED_SPACE_CALENDAR: makeNav(
    "supplier-booked-space-calendar",
    "BOOKED SPACE CALENDAR"
  ),
  ACTIVE_WAITLIST: makeNav("active-waitlist", "ACTIVE WAITLIST"),
  VIEW_EDIT_FINES: makeNav("fines", "VIEW/EDIT FINES"),
  ADD_FINE: makeNav("add-fine", "ADD FINE"),
  FINES_ASSESSMENTS: makeNav("fine-assessments", "FINE ASSESSMENTS"),
  ZONES_PROFILE: makeNav("zones-profile", "ZONES"),
  ZONES_REPORT: makeNav("zones-report", "ZONES REPORT"),
});

export const ReportsNav = Object.freeze({
  SUPPLIER_ACTIVITY_SUMMARY: makeNav(
    "supplier-activity-summary",
    "ACTIVITY SUMMARY"
  ),
  WAITLIST_REPORT: makeNav("waitlist-report", "WAITLIST REPORT"),
  MONTHLY_DURATION_REPORT: makeNav(
    "monthly-duration-report",
    "MONTHLY DURATION"
  ),
  ZONE_REVENUE_REPORT: makeNav("zone-revenue/report", "ZONE REVENUE REPORT"),
  REVENUE_REPORT: makeNav("revenue/report", "REVENUE REPORT"),
  REVENUE_RECONCILIATION_REPORT: makeNav(
    "revenue-reconciliation-report",
    "REVENUE RECONCILIATION"
  ),
  SUPPLIER_INVOICES: makeNav("supplier-invoices", "INVOICES"),
  SECURITY_DEPOSIT: makeNav("security-deposit", "SECURITY DEPOSITS"),
});

export const ThirdPartyNav = Object.freeze({
  PARTNER_DETAILS: makeNav("partner-details", "PARTNER DETAILS"),
});

export const MarketplaceOnlyGmsUserNav = Object.freeze({
  GMS_UPGRADE: makeNav("gms-upgrade", "UPGRADE REQUIRED"),
});
