import React, { useContext } from "react";
import { Route } from "react-router";
import { withAccessControl } from "../ProtectedRoute";
import AccessControlBuilder from "../AccessControlBuilder";

import SupplierInvoicesReport from "../../components/invoice/SupplierInvoicesReport";
import SecurityDepositReport from "../../components/securityDeposit/securityDepositReport";
import SupplierReadyForPayoutReport from "../../views/SupplierReadyForPayoutReport";
import SupplierPayoutsReport from "../../views/SupplierPayoutsReport";
import SupplierBookedSpaceCalendarReport from "../../views/SupplierBookedSpaceCalendarReport";
import { AppContext } from "../../context/app-context";
import WaitList from "../../components/waitlist/WaitList";
import Reservations from "../../components/reservation/Reservations";
import ViewAllZones from "../../components/zonebuilder/zones/ViewAllZones";
import FineAssessmentsContent from "../../components/admin/fine/assessments/FineAssessmentsContent";
import FineAddMenu from "../../components/admin/fine/FineAddMenu";
import FineViewEdit from "../../components/admin/fine/FineViewEdit";
import BookingsReport from "../../views/bookings/BookingsReport";
import EditBooking from "../../views/bookings/EditBooking";
import BookingCancel from "../../views/bookings/BookingCancel";
import BookingCancelSubscription from "../../views/bookings/BookingCancelSubscription";
import ReservationBuilder from "../../components/reservationbuiler/ReservationBuilder";
import ZoneAudit from "../../components/zone/ZoneAudit";
import ZoneEditor from "../../components/zonebuilder/zones/ZoneEditor";
import ZoneCreator from "../../components/zonebuilder/zones/ZoneCreator";
import ZoneAvailabilityReport from "../../components/zone/ZoneAvailabilityReport";
import WaitListReport from "../../components/waitlist/WaitListReport";
import MonthlyDurationReportContent from "../../views/MonthlyDurationReportContent";
import ZoneRevenueReport from "../../components/zoneReport/ZoneRevenueReport";
import LocationRevenueReport from "../../components/locationReport/LocationRevenueReport";
import RevenueReconciliationReport from "../../views/RevenueReconciliationReport";

const allSupplierOwnerAuthorization = new AccessControlBuilder()
  .allowAllSupplierOwner()
  .build();
const allSupplierGmsAuthorization = new AccessControlBuilder()
  .allowAllGmsUsers()
  .build();
const allSupplierGmsManagerAuthorization = new AccessControlBuilder()
  .allowGateManager()
  .build();

const ProtectedApprovals = withAccessControl(
  Reservations,
  allSupplierGmsAuthorization
);
const ProtectedSupplierBookingsReport = withAccessControl(
  BookingsReport,
  allSupplierGmsAuthorization
);
const ProtectedSupplierInvoicesReport = withAccessControl(
  SupplierInvoicesReport,
  allSupplierGmsAuthorization
);
const ProtectedSecurityDepositReport = withAccessControl(
  SecurityDepositReport,
  allSupplierGmsAuthorization
);
const ProtectedSupplierReadyForPayoutReport = withAccessControl(
  SupplierReadyForPayoutReport,
  allSupplierGmsAuthorization
);
const ProtectedSupplierPayoutsReport = withAccessControl(
  SupplierPayoutsReport,
  allSupplierOwnerAuthorization
);
const ProtectedSupplierBookedSpaceCalendarReport = withAccessControl(
  SupplierBookedSpaceCalendarReport,
  allSupplierGmsAuthorization
);
const ProtectedFineViewEdit = withAccessControl(
  FineViewEdit,
  allSupplierOwnerAuthorization
);
const ProtectedFineAdd = withAccessControl(
  FineAddMenu,
  allSupplierOwnerAuthorization
);
const ProtectedWaitList = withAccessControl(
  WaitList,
  allSupplierGmsManagerAuthorization
);
const ProtectedWaitListReport = withAccessControl(
  WaitListReport,
  allSupplierGmsManagerAuthorization
);
const ProtectedFinesAssessments = withAccessControl(
  FineAssessmentsContent,
  allSupplierOwnerAuthorization
);
const ProtectedViewAllZones = withAccessControl(
  ViewAllZones,
  allSupplierGmsAuthorization
);
const ProtectedZoneBuilder = withAccessControl(
  ZoneCreator,
  allSupplierGmsAuthorization
);
const ProtectedZoneEditor = withAccessControl(
  ZoneEditor,
  allSupplierGmsAuthorization
);
const ProtectedEditBooking = withAccessControl(
  EditBooking,
  allSupplierGmsAuthorization
);
const ProtectedCancelBooking = withAccessControl(
  BookingCancel,
  allSupplierGmsAuthorization
);
const ProtectedCancelBookingSubscription = withAccessControl(
  BookingCancelSubscription,
  allSupplierOwnerAuthorization
);
const ProtectedReservationBuilder = withAccessControl(
  ReservationBuilder,
  allSupplierGmsAuthorization
);
const ProtectedZoneAudit = withAccessControl(
  ZoneAudit,
  allSupplierGmsAuthorization
);
const ProtectedZoneAvailabilityReport = withAccessControl(
  ZoneAvailabilityReport,
  allSupplierGmsAuthorization
);
const ProtectedMonthlyDurationReport = withAccessControl(
  MonthlyDurationReportContent,
  allSupplierGmsManagerAuthorization
);
const ProtectedZoneRevenueReport = withAccessControl(
  ZoneRevenueReport,
  allSupplierOwnerAuthorization
);
const ProtectedLocationRevenueReport = withAccessControl(
  LocationRevenueReport,
  allSupplierOwnerAuthorization
);
const ProtectedRevenueReconciliationReport = withAccessControl(
  RevenueReconciliationReport,
  allSupplierOwnerAuthorization
);

const SupplierContent = () => {
  const appContext = useContext(AppContext);
  const { user, logout } = appContext;

  return [
    <Route path="/reservations" key="/reservations">
      <ProtectedApprovals account={user} handleLogout={logout} />
    </Route>,
    <Route path="/supplier-bookings" key="/supplier-bookings">
      <ProtectedSupplierBookingsReport account={user} handleLogout={logout} />
    </Route>,
    <Route path="/supplier-invoices" key="/supplier-invoices">
      <ProtectedSupplierInvoicesReport account={user} handleLogout={logout} />
    </Route>,
    <Route path="/security-deposit" key="/security-deposit">
      <ProtectedSecurityDepositReport account={user} handleLogout={logout} />
    </Route>,
    <Route path="/monthly-duration-report" key="/monthly-duration-report">
      <ProtectedMonthlyDurationReport account={user} handleLogout={logout} />
    </Route>,
    <Route path="/ready-for-payout" key="/ready-for-payout">
      <ProtectedSupplierReadyForPayoutReport
        account={user}
        handleLogout={logout}
      />
    </Route>,
    <Route path="/supplier-payouts" key="/supplier-payouts">
      <ProtectedSupplierPayoutsReport account={user} handleLogout={logout} />
    </Route>,
    <Route
      path="/supplier-booked-space-calendar"
      key="/supplier-booked-space-calendar"
    >
      <ProtectedSupplierBookedSpaceCalendarReport
        account={user}
        handleLogout={logout}
      />
    </Route>,
    <Route path="/active-waitlist" key="/active-waitlist">
      <ProtectedWaitList account={user} handleLogout={logout} />
    </Route>,
    <Route path="/waitlist-report" key="/waitlist-report">
      <ProtectedWaitListReport account={user} handleLogout={logout} />
    </Route>,
    <Route path="/fines" key="/fines">
      <ProtectedFineViewEdit account={user} handleLogout={logout} />
    </Route>,
    <Route path="/add-fine" key="/add-fine">
      <ProtectedFineAdd account={user} handleLogout={logout} />
    </Route>,
    <Route path="/fines-report" key="/fines-report">
      <div>Fines Report</div>
    </Route>,
    <Route path="/fine-assessments" key="/fine-assessments">
      <ProtectedFinesAssessments account={user} handleLogout={logout} />
    </Route>,
    <Route exact path="/zones-profile" key="/zones-profile">
      <ProtectedViewAllZones account={user} handleLogout={logout} />
    </Route>,
    <Route path="/zones-profile/:locationId" key="/zones-profile/locationId">
      <ProtectedViewAllZones account={user} handleLogout={logout} />
    </Route>,
    <Route path="/zone-builder" key="/zone-builder">
      <ProtectedZoneBuilder account={user} handleLogout={logout} />
    </Route>,
    <Route path="/zone-editor" key="/zone-editor">
      <ProtectedZoneEditor account={user} handleLogout={logout} />
    </Route>,
    <Route path="/zones-report" key="/zones-report">
      <ProtectedZoneAvailabilityReport account={user} handleLogout={logout} />
    </Route>,
    <Route path="/booking/:bookingId/cancel" key="/booking/bookingId/cancel">
      <ProtectedCancelBooking account={user} handleLogout={logout} />
    </Route>,
    <Route
      path="/booking/:bookingId/cancelSubscription"
      key="/booking/bookingId/cancelSubscription"
    >
      <ProtectedCancelBookingSubscription
        account={user}
        handleLogout={logout}
      />
    </Route>,
    <Route path="/booking/:bookingId" key="/booking/bookingId">
      <ProtectedEditBooking account={user} handleLogout={logout} />
    </Route>,
    <Route path="/reservation-builder" key="/reservation-builder">
      <ProtectedReservationBuilder account={user} handleLogout={logout} />
    </Route>,
    <Route path="/audits/zones" key="/audits/zones">
      <ProtectedZoneAudit account={user} handleLogout={logout} />
    </Route>,
    <Route path="/zone-revenue/report" key="/zone-revenue/report">
      <ProtectedZoneRevenueReport account={user} handleLogout={logout} />
    </Route>,
    <Route path="/revenue/report" key="/revenue/report">
      <ProtectedLocationRevenueReport account={user} handleLogout={logout} />
    </Route>,
    <Route
      path="/revenue-reconciliation-report"
      key="/revenue-reconciliation-report"
    >
      <ProtectedRevenueReconciliationReport
        account={user}
        handleLogout={logout}
      />
    </Route>,
  ];
};

export default SupplierContent;
