import React from "react";
import * as PropTypes from "prop-types";
import { InfoWindow, Marker, Polygon } from "react-google-maps";
import { useHistory } from "react-router-dom";
import { EquipmentLengthsConstant } from "../constants/securspace-constants";

const ZoneMapItemContent = (props) => {
  const {
    zone,
    polygonColor,
    toggleInfoWindow,
    handleToggleInfoWindow,
    markerPosition,
    isActive,
  } = props;
  let history = useHistory();

  const handleClick = () => {
    localStorage.setItem("activeMenuItem", "bookings");
    localStorage.setItem("activeSubmenu", "supplier-bookings");
    history.push(`/supplier-bookings?zoneName=${zone.name}`);
    window.location.reload();
  };

  return (
    <>
      <Polygon
        visible={true}
        options={{
          paths: zone.coordinates,
          fillColor: polygonColor,
        }}
      />
      <Marker
        position={{
          lat: Number(markerPosition.lat()),
          lng: Number(markerPosition.lng()),
        }}
        onClick={handleToggleInfoWindow}
      >
        {toggleInfoWindow && (
          <InfoWindow onCloseClick={handleToggleInfoWindow}>
            <div>
              <p>
                Zone Name: <b>{zone.name}</b>
              </p>
              <p>
                Total Spaces: <b>{zone.totalSpaces}</b>
              </p>
              <p>
                Equipment Length:{" "}
                <b>{EquipmentLengthsConstant[zone.equipmentLength]}</b>
              </p>
              <p>
                Active: <b>{isActive ? "Yes" : "No"}</b>
              </p>
              <a
                style={{ color: "blue", marginLeft: "90px" }}
                onClick={handleClick}
              >
                View Bookings
              </a>
            </div>
          </InfoWindow>
        )}
      </Marker>
    </>
  );
};

ZoneMapItemContent.propTypes = {
  zone: PropTypes.shape({
    name: PropTypes.string,
    totalSpaces: PropTypes.number,
    equipmentLength: PropTypes.string,
    activationDate: PropTypes.string,
    deactivationDate: PropTypes.string,
    active: PropTypes.bool,
    coordinates: PropTypes.arrayOf(
      PropTypes.shape({
        lat: PropTypes.func,
        lng: PropTypes.func,
      })
    ),
  }).isRequired,
  polygonColor: PropTypes.string,
  toggleInfoWindow: PropTypes.bool,
  handleToggleInfoWindow: PropTypes.func,
  markerPosition: PropTypes.shape({
    lat: PropTypes.func,
    lng: PropTypes.func,
  }),
  isActive: PropTypes.bool,
};

export default ZoneMapItemContent;
