import React from "react";
import Busy from "../../../components/Busy";
import { toast } from "react-toastify";
import { Modal } from "react-router-modal";
import { requestDeletePaymentMethod } from "../../../components/paymentMethods/request/payment-method-requests";
function DeletePopUp({
  tag,
  id,
  setSelectingCreditCardIndex,
  setSelectingAchIndex,
  setLoadPaymentMethod,
  loadPaymentMethod,
  setShowModal,
  setBankName,
  setAchPayment,
}) {
  const handleClickDelete = async () => {
    Busy.set(true);

    await requestDeletePaymentMethod(id)
      .then((res) => toast.success(res?.text))
      .catch((err) => {
        const errorMessage = err?.message || "An error occurred.";
        const errMessageObj = JSON.parse(errorMessage);
        toast.error(errMessageObj?.message);
      });
    setSelectingCreditCardIndex(null);
    setSelectingAchIndex(null);
    setShowModal(false);
    if (tag === "ACH") {
      setBankName("");
      setAchPayment(false);
    }

    Busy.set(false);
    setLoadPaymentMethod(!loadPaymentMethod);
  };

  const handleCancel = () => {
    setShowModal(false);
  };
  return (
    <>
      <>
        <Modal className="app-modal app-modal-in modalPopup">
          <div className="modal-content popUp">
            <div className="headline">
              <h1 className="ss-report-header-title fontSizePopup">
                {tag !== "ACH" ? "Delete Credit Card" : "Delete ACH account"}
              </h1>
              <button
                type="button"
                className="close"
                style={{ marginTop: "-2%" }}
                onClick={handleCancel}
              >
                <img alt="" src="../../../app-images/close.png" />
              </button>
            </div>

            <div style={{ marginTop: "23px" }}>
              {tag !== "ACH"
                ? "Are you sure you want to delete this credit card?"
                : "Are you sure you want to delete this ACH account?"}
            </div>
            <div
              className="newbuttonDivSubmitDelete newButtonDiv"
              style={{ marginTop: "40px" }}
            >
              <button
                className="ss-button-secondary"
                style={{ width: "200px" }}
                onClick={handleCancel}
              >
                Cancel
              </button>
              <button
                className="ss-button-primary"
                onClick={() => handleClickDelete()}
              >
                Yes
              </button>
            </div>
          </div>
        </Modal>
      </>
    </>
  );
}

export default DeletePopUp;
