import React, { Component } from "react";
import Busy from "../components/Busy";
import { createLogoutOnFailureHandler } from "../util/LogoutUtil";

import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import {
  AccountType,
  UserType,
  UserTypeName,
} from "../components/constants/securspace-constants";
import { ADMIN_EDIT_ROLES, isRoleApproved } from "../util/user/UserUtil";
import { ajaxRequest } from "../ajax";
import "../css/components/checkBox.css";
const $ = window.$;
const NOTIFICATIONS_TYPES_SUPPLIER = [
  "Administrative",
  "Partner_Yard_Management",
];
const NOTIFICATIONS_TOOLTIPS_SUPPLIER = {
  Administrative:
    "Receive relevant emails for Fines, Payments, Bookings and Overages information.",
  Partner_Yard_Management:
    "Receive emails when Customer bookings are created, updated, requested, or cancelled.",
};

const OWNER_ROLE = "ACCOUNTOWNER";

class NotificationsSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      account: this.props.account,
      rolesLabels: [],
      roles: [],
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.account !== nextProps.account) {
      this.setState({
        account: nextProps.account,
      });
    }
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.account !== prevState.account) {
      this.getData();
    }
  }

  createEmptyNotificationsSettings() {
    let temp = {};

    for (let i = 0; i < this.state.notifications_type.length; i++) {
      if (typeof temp[this.state.notifications_type[i]] == "undefined") {
        temp[this.state.notifications_type[i]] = [];
      }
      temp[this.state.notifications_type[i]].push(OWNER_ROLE);
    }
    return temp;
  }

  handleChange = (event) => {
    let element = event.target;
    let type = $(element).data("type");
    let role = $(element).data("role");
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    let data = this.state.data;
    if (typeof data[type] == "undefined") {
      data[type] = [];
    }
    if (value && !data[type].includes(role)) {
      //add role
      data[type].push(role);
    }
    if (!value) {
      //exclude role
      var index = data[type].indexOf(role);
      if (index !== -1) data[type].splice(index, 1);
    }
    this.setState({ data: data });
  };

  handleSubmit = () => {
    let _this = this;
    let apiMethod = "save-notification-settings";

    Busy.set(true);
    ajaxRequest({
      url: apiMethod,
      type: "POST",
      contentType: "application/json; charset=UTF-8",
      data: JSON.stringify({
        accountId: _this.state.account.id,
        values: JSON.stringify(_this.state.data),
      }),
      statusCode: {
        401: createLogoutOnFailureHandler(this.props.handleLogout),
      },
      success: (data) => {
        Busy.set(false);
        toast.success("Notifications Settings saved!");
      },
      error: (err) => {
        Busy.set(false);
        err.customMessage =
          err.responseJSON && err.responseJSON.message
            ? err.responseJSON.message
            : "";
        _this.setState({
          error: err,
          data: null,
        });
      },
    });
  };

  getData() {
    if (!(this.state.account && this.state.account.id)) return;
    let _this = this;
    let apiMethod =
      "get-notification-settings?accountId=" + this.state.account.id;

    this.setState({
      rolesLabels:
        this.props.account.type === AccountType.SUPPLIER
          ? [
              UserTypeName.ADMIN,
              UserTypeName.READ_ONLY_ADMIN,
              UserTypeName.GATE_CLERK,
              UserTypeName.GATE_MANAGER,
            ]
          : [
              UserTypeName.ACCOUNT_OWNER,
              UserTypeName.DISPATCHER,
              UserTypeName.ADMIN,
            ],
    });
    this.setState({
      roles:
        this.props.account.type === AccountType.SUPPLIER
          ? ["OWNER", "READONLYADMIN", "GATECLERK", "GATEMANAGER"]
          : ["ACCOUNTOWNER", "DISPATCHER", "OWNER"],
    });
    this.setState({
      notifications_type: NOTIFICATIONS_TYPES_SUPPLIER,
    });

    Busy.set(true);
    ajaxRequest({
      url: apiMethod,
      type: "GET",
      success: (data) => {
        Busy.set(false);
        if (!data.values) {
          this.setState({
            data: _this.createEmptyNotificationsSettings(),
            error: null,
          });
        } else {
          let userNotifications = JSON.parse(data.values);

          Object.keys(userNotifications).forEach((key) => {
            if (this.state.notifications_type.indexOf(key) === -1) {
              delete userNotifications[key];
            }
          });

          this.setState({ data: userNotifications, error: null });
        }
      },
      error: (err) => {
        Busy.set(false);
        err.customMessage =
          err.responseJSON && err.responseJSON.message
            ? err.responseJSON.message
            : "";
        _this.setState({
          error: err,
          data: null,
        });
      },
    });
  }

  render() {
    return (
      <div className="ss-main h-100">
        <div className="page-content">
          <h1 className="page-title" style={{ marginTop: "40px" }}>
            Notifications Settings
          </h1>
          <div className="container" style={{ marginTop: "40px" }}>
            {this.state.error ? (
              <h4 className="text-danger">
                {this.state.error.customMessage
                  ? this.state.error.customMessage
                  : "ERROR!"}
                ‌
              </h4>
            ) : (
              ""
            )}
            <table className="table  fixed table-bordered">
              <thead>
                <tr>
                  <th>Notification Category</th>
                  {this.state.rolesLabels.map((field, index) => (
                    <th key={index.toString()}>{field}</th>
                  ))}
                </tr>
              </thead>
              {this.state.data ? (
                <tbody>
                  {this.state.notifications_type.map((fieldN, indexN) => (
                    <tr key={indexN.toString()}>
                      <td>
                        <label htmlFor={"ID" + indexN.toString()}>
                          {fieldN.replace(/_/g, " ")}
                          <span
                            data-tip
                            data-for={"ID" + indexN.toString()}
                            className="location-name-tip"
                          >
                            &#x24d8;
                          </span>
                        </label>
                        <ReactTooltip
                          id={"ID" + indexN.toString()}
                          type="success"
                          effect="solid"
                          className="location-name-tip-hover"
                        >
                          <span>{NOTIFICATIONS_TOOLTIPS_SUPPLIER[fieldN]}</span>
                        </ReactTooltip>
                      </td>
                      {this.state.roles.map((fieldR, indexR) => (
                        <td key={indexR.toString()}>
                          <label
                            className={"ss-checkbox"}
                            key={indexN.toString() + indexR.toString()}
                          >
                            <input
                              type="checkbox"
                              checked={
                                this.state.data[fieldN.toString()] &&
                                this.state.data[fieldN.toString()].includes(
                                  fieldR.toString()
                                )
                              }
                              style={{
                                height: "20px",
                                width: "20px",
                                accentColor: "white",
                                outline: "0.5px solid black",
                              }}
                              data-role={fieldR.toString()}
                              data-type={fieldN.toString()}
                              name={fieldN.toString() + "_" + fieldR.toString()}
                              onChange={this.handleChange}
                              disabled={
                                this.props.account.userType ===
                                UserType.READ_ONLY_ADMIN
                              }
                            />
                          </label>
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody>
                  <tr></tr>
                </tbody>
              )}
            </table>
            <br />
            {isRoleApproved(ADMIN_EDIT_ROLES, this.props.account.userType) && (
              <div
                className="ss-button-container"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <button
                  type="button"
                  className="ss-button-primary"
                  onClick={this.handleSubmit}
                  style={{ width: "200px" }}
                >
                  Save
                </button>
              </div>
            )}
            <br />
          </div>
        </div>

        <div></div>
      </div>
    );
  }
}

export default NotificationsSettings;
