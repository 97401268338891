import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import StepWizard from "react-step-wizard";
import ZoneSelectStep from "../../components/zone/ZoneSelectStep";
import {
  requestBookingById,
  requestUpdateLocationZone,
  requestZonesByBookingId,
} from "./requests/edit-booking-requests";
import { useHistory } from "react-router";
import StepWizardContainer from "../../components/stepWizard/StepWizardContainer";
import Busy from "../../components/Busy";
import { toastErrorMessage } from "../../util/NetworkErrorUtil";
import { toast } from "react-toastify";

const EditBooking = () => {
  const { bookingId } = useParams();
  const [selectedZone, setSelectedZone] = useState("");
  const [zones, setZones] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const history = useHistory();

  useEffect(() => {
    Busy.set(true);
    requestBookingById(bookingId)
      .then((bookingResponse) => {
        requestZonesByBookingId(bookingId)
          .then((response) => {
            setZones(response.body);
            if (!!bookingResponse.body.locationZone.id) {
              setSelectedZone(bookingResponse.body.locationZone.id);
            } else {
              setDisabled(true);
              toast.error("No zones available to change this booking's zone!");
            }
            Busy.set(false);
          }, toastErrorMessage)
          .catch(toastErrorMessage);
      }, toastErrorMessage)
      .catch(toastErrorMessage);
  }, [bookingId]);

  const handleZoneChange = (event) => {
    setSelectedZone(event.target.value);
  };

  const handleCancel = () => {
    history.goBack();
  };

  const handleSave = () => {
    Busy.set(true);
    requestUpdateLocationZone(bookingId, selectedZone)
      .then(() => {
        Busy.set(false);
        history.goBack();
      }, toastErrorMessage)
      .catch(toastErrorMessage);
  };

  return (
    <div className="container">
      <h3>Edit Booking</h3>
      <StepWizardContainer>
        <StepWizard>
          <ZoneSelectStep
            selectedZone={selectedZone}
            zones={zones}
            onChange={handleZoneChange}
            step={1}
            totalSteps={1}
            disabled={disabled}
            onCancel={handleCancel}
            onSave={handleSave}
          />
        </StepWizard>
      </StepWizardContainer>
    </div>
  );
};

export default EditBooking;
