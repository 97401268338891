import request from "../../../util/SuperagentUtils";
const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const requestBookings = (accountId) => {
  return request
    .get(`${baseUrl}/api/booking/all-supplier-bookings/${accountId}`)
    .withCredentials();
};

export const requestCancelBooking = (bookingId) => {
  return request
    .post(`${baseUrl}/api/booking/cancel`)
    .withCredentials()
    .send({ id: bookingId });
};

export const requestCancelBookingSubscription = (bookingId) => {
  return request
    .post(`${baseUrl}/api/booking/cancel-subscription`)
    .withCredentials()
    .send({ id: bookingId });
};
