import _ from "underscore";

// convert back to google map LatLng to use for display
export const geoJson2GoogleMap = (latLngArray : Array<number>) : google.maps.LatLng => {
  return new google.maps.LatLng(latLngArray[0], latLngArray[1]);
}

// format required to use polylabel
export const toLinearRing = (coordinates) : Array<Array<Array<number>>> => {
  return [
    _.map(coordinates, (coordinate) => {
      return [
        coordinate.lat(),
        coordinate.lng()
      ]
    })
  ]
}
