import React from "react";
import { Route } from "react-router";
import LandingPage from "../../views/LandingPage";
import ChangePassword from "../../views/ChangePassword";
import ForgotPassword from "../../views/ForgotPassword";
import Landing from "../../views/Landing";
import BookingApproval from "../../views/BookingApproval";
import ExternalLanding from "../../views/ExternalLanding";
import UserBasedLandingRedirect from "../UserBasedLandingRedirect";
import Logout from "../../components/Logout";
import PaymentSuccessful from "../../views/thirdparty/paymentSuccessful";
const LandingContent = () => {
  return [
    <Route path="/gms-landing" key="/gms-landing">
      <ExternalLanding
        url="https://info.secur.space/the-gate-management-system-by-sec%C5%ABrspace?hs_preview=uulHOjyR-23988570436"
        height="3000px"
      />
    </Route>,
    <Route path="/forgot-password" key="/forgot-password">
      <ForgotPassword />
    </Route>,
    <Route path="/change-password" key="/change-password">
      <ChangePassword isNewUser={false} />
    </Route>,
    <Route path="/payment-successful" key="/payment successful">
      <PaymentSuccessful />
    </Route>,
    <Route path="/set-password" key="/set-password">
      <ChangePassword isNewUser={true} />
    </Route>,
    <Route path="/landing/:id" key="/landing/:id">
      <LandingPage />
    </Route>,
    <Route path="/booking-approval" key="/booking-approval">
      <BookingApproval />
    </Route>,
    <Route path="/logout" key="/logout">
      <Logout />
    </Route>,
    <Route path="/" key="/">
      <Landing />
    </Route>,
    <Route path="*" key="/">
      <UserBasedLandingRedirect />
    </Route>,
  ];
};

export default LandingContent;
