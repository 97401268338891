import React from "react";
import ReportDataRow from "../report/ReportDataRow";
import ReportData from "../report/ReportData";
import { formatCurrencyValue } from "../../util/PaymentUtils";
import getDisplayValue from "../SupplierTransactionStatus";
import { formatBookingTransaction } from "../../util/InvoiceUtils";
import { formatEquipmentLength } from "../../util/booking/bookingUtil";

const InvoiceCard = (props) => {
  const { invoice } = props;

  return (
    <>
      {invoice && (
        <ReportDataRow>
          <ReportData label={"CUSTOMER"}>
            {invoice?.buyerCompanyName || "-"}
          </ReportData>
          <ReportData label={"TYPE"}>
            {invoice.transactionType
              ? formatBookingTransaction(invoice.transactionType)
              : "-"}
          </ReportData>
          <ReportData label={"SPACES"}>
            {invoice?.numberOfSpaces || "-"}
          </ReportData>
          <ReportData label={"AMOUNT"}>
            {invoice?.supplierAmount
              ? formatCurrencyValue(invoice.supplierAmount)
              : "-"}
          </ReportData>
          <ReportData label={"STATUS"}>{invoice?.status || "-"}</ReportData>
          <ReportData label={"SERVICE DATES"}>
            {invoice?.serviceDates || "-"}
          </ReportData>
          <ReportData label={"INVOICE CREATED"}>
            {invoice?.createdOn || "-"}
          </ReportData>
          <ReportData label={"PAYMENT INITIATED"}>
            {invoice?.paymentCreatedOn || "-"}
          </ReportData>
          <ReportData label={"PAYMENT COMPLETED"}>
            {invoice?.paymentCompletedOn || "-"}
          </ReportData>
          <ReportData label={"INVOICE NUMBER"}>
            {invoice?.transactionNumber || "-"}
          </ReportData>
          <ReportData label={"PAYMENT TYPE"}>
            {invoice?.paymentType || "-"}
          </ReportData>

          <ReportData label={"CHARGED DATE"}>
            {invoice?.createdOn || "-"}
          </ReportData>

          <ReportData label={"BOOKING NUMBER"}>
            {invoice?.bookingNumber || "-"}
          </ReportData>
          <ReportData label={"Equipment Length"}>
            {invoice?.equipmentLength
              ? formatEquipmentLength(invoice.equipmentLength)
              : "-"}
          </ReportData>
          <ReportData label={"LOCATION NAME"}>
            {invoice?.locationName || "-"}
          </ReportData>
        </ReportDataRow>
      )}
    </>
  );
};

export default InvoiceCard;
