import React, { useEffect, useState } from "react";
import { Modal } from "react-router-modal";
import Busy from "../../../components/Busy";
import { ajaxRequest } from "../../../ajax";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

function CrediCardDisplayPopUp({
  paymentMethods,
  setBookSpaceForm,
  selectingCreditCardIndex,
  setSelectingAchIndex,
  setSelectingCreditCardIndex,
  reservationId,
  id,
  securityDepositAmountState,
  securityDepositRequiredState,
  setSecurityDepositRequiredState,
  setsecurityDepositPaymentStatus,
  setdateInitiatedSecurityDeposit,
  setsourceSecurityDeposit,
  setsecurityDepositnickName,
  setSecurityInvoiceNumber,
  setSecurityDepositPaymentType,
}) {
  const [lastFourDigit, setLastFourDigit] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [cardBrand, setCardBrand] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [tsepToken, setTsepToken] = useState("");
  const [cvvEntered, setCvvEntered] = useState("");
  const [zip, setZip] = useState("");
  const [paymentId, setPaymentId] = useState("");
  const [cvvModified, setCvvModified] = useState("");
  const [fullName, setFullName] = useState("");

  const history = useHistory();

  useEffect(() => {
    if (selectingCreditCardIndex !== null && selectingCreditCardIndex !== -1) {
      setLastFourDigit(
        "XXXX XXXX XXXX " + paymentMethods[selectingCreditCardIndex]?.lastFour
      );
      setExpiryDate(paymentMethods[selectingCreditCardIndex]?.expiryDate);
      setCardBrand(paymentMethods[selectingCreditCardIndex]?.cardBrand);
      setFirstName(paymentMethods[selectingCreditCardIndex]?.firstName);
      setTsepToken(paymentMethods[selectingCreditCardIndex]?.tsepToken);
      setLastName(paymentMethods[selectingCreditCardIndex]?.lastName);
      const fullName =
        paymentMethods[selectingCreditCardIndex]?.firstName +
        " " +
        (!!paymentMethods[selectingCreditCardIndex]?.lastName
          ? paymentMethods[selectingCreditCardIndex]?.lastName
          : "");

      setFullName(fullName);
      setZip(paymentMethods[selectingCreditCardIndex]?.zip);
      setPaymentId(paymentMethods[selectingCreditCardIndex]?.id);
    } else {
      paymentMethods?.map((ele) => {
        if (
          ele.defaultPaymentMethodForProfile === true &&
          ele?.type === "CARD"
        ) {
          setLastFourDigit("XXXX XXXX XXXX " + ele?.lastFour);
          setExpiryDate(ele?.expiryDate);
          setCardBrand(ele?.cardBrand);
          setFirstName(ele?.firstname);
          setLastName(ele?.lastname);
          const fullName = ele?.firstName + " " + ele?.lastName;
          setFullName(fullName);
          setTsepToken(ele?.tsepToken);
          setZip(ele?.zip);
          setPaymentId(ele?.id);
        }
      });
    }
  }, []);

  const handleCancel = () => {
    setBookSpaceForm(false);
    setSelectingCreditCardIndex(null);
    setSelectingAchIndex(null);
  };

  const handleSubmitCreditCardPayment = () => {
    Busy.set(true);
    ajaxRequest({
      url: securityDepositRequiredState
        ? `zone/reservation/tsys/credit-card/${paymentId}/complete?reservationId=${reservationId}&buyerAccountId=${id}`
        : `zone/reservation/tsys/credit-card/${paymentId}/complete?reservationId=${reservationId}&buyerAccountId=${id}`,
      data: JSON.stringify({
        cardNumber: tsepToken,
        firstName: firstName,
        lastName: lastName,
        cardBrand: cardBrand,
        expirationDate: expiryDate,
        securityDepositPayment: securityDepositRequiredState
          ? securityDepositRequiredState
          : false,
        transactionAmount: securityDepositAmountState,
        cvv2: cvvEntered,
        zip: zip,
      }),
      contentType: "application/json",
      method: "POST",
      success: (res) => {
        Busy.set(false);
        setBookSpaceForm(false);
        setSelectingCreditCardIndex(null);
        setSelectingAchIndex(null);
        //  postMessageAndClose(PostMessageStatus.SUCCESS);
        if (securityDepositRequiredState) {
          toast.success("Payment done successfully!");
          setSecurityDepositRequiredState(res?.securityDepositRequired);
          setsecurityDepositPaymentStatus(true);
          setdateInitiatedSecurityDeposit(res?.dateOfDepositProcessed);
          setsourceSecurityDeposit(`Card number ending with ${lastFourDigit}`);
          setsecurityDepositnickName(res?.nickName);
          setSecurityInvoiceNumber(res?.invoiceNumber);
          setSecurityDepositPaymentType("Credit Card Transfer");
        } else {
          toast.success("Space Booked Successfully!");

          history.push({
            pathname: "/payment-successful",
            state: {
              amount: res?.initialPaymentAmount,
              invoiceNumber: res?.invoiceNumber,
              transferType: "Credit Card Transfer",
              dateInitiated: res?.approvedOn,
              nickName: res?.nickName,
              source: `Card number ending with ${lastFourDigit}`,
            },
          });
        }
      },
      error: (jqXHR) => {
        Busy.set(false);
        let errorMessage = jqXHR.responseJSON
          ? jqXHR.responseJSON.message
            ? jqXHR.responseJSON.message.trim()
            : ""
          : "";
        toast.error(errorMessage);
      },
    });
  };
  const handleChange = (e) => {
    let inputValue = e.target.value.replace(/\D/g, "").slice(0, 4);

    setCvvEntered(inputValue?.trim());
    // const newValue = e.target.value.trim();
    // if (!!newValue) {
    //   toast.error("Cvv can't be blank");
    //   return;
    // }
    // setCvvEntered(newValue);
    // const maskedValue = newValue.replace(/\d/g, "*");
    // setCvvModified(maskedValue);
  };

  return (
    <>
      <Modal className="app-modal app-modal-in  ">
        <div className="modal-content " style={{ padding: "20px 30px" }}>
          <h1
            className="ss-report-header-title"
            style={{ textAlign: "center" }}
          >
            Credit Card Details
          </h1>
          <div className="formCreditCard">
            <div
              className="p-0 CardNumber"
              style={{ backgroundColor: "#eeeeee" }}
            >
              <label>CARD NUMBER</label>
              <input
                type="text"
                value={lastFourDigit}
                disabled
                style={{
                  cursor: "not-allowed",
                  opacity: "inherit",
                  fontWeight: "500",
                }}
              ></input>
            </div>
            <div
              className="p-0 CardNumber"
              style={{ backgroundColor: "#eeeeee" }}
            >
              <label>CARDHOLDER NAME</label>
              <input
                type="text"
                value={fullName}
                disabled
                style={{
                  cursor: "not-allowed",
                  opacity: "inherit",
                  fontWeight: "500",
                }}
              ></input>
            </div>
            <div
              className="inputDiv p-0 CardNumber"
              style={{ backgroundColor: "#eeeeee" }}
            >
              <label>EXPIRATION DATE</label>
              <input
                type="text"
                value={expiryDate}
                disabled
                style={{ opacity: "inherit", fontWeight: "500" }}
              ></input>
            </div>
            <div className="inputDiv p-0  CardNumber">
              <label>CVV NUMBER</label>
              <input
                type="number"
                inputmode="numeric"
                min="99"
                max="9999"
                value={cvvEntered}
                onChange={handleChange}
                placeholder="CVV"
              ></input>
            </div>
          </div>

          <div className="newbuttonDivSubmit newButtonDiv">
            <button
              className="ss-button-secondary"
              style={{ width: "200px" }}
              onClick={handleCancel}
            >
              Cancel
            </button>
            <button
              // onClick={handleSubmit}
              className="ss-button-primary processPayment"
              disabled={
                !(
                  cvvEntered?.length >= 3 &&
                  !!expiryDate &&
                  !!lastFourDigit &&
                  !!fullName
                )
              }
              style={
                cvvEntered?.length >= 3 &&
                !!expiryDate &&
                !!lastFourDigit &&
                !!fullName
                  ? {}
                  : { pointerEvents: "none", opacity: "0.5" }
              }
              onClick={handleSubmitCreditCardPayment}
            >
              Process Payment
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}
export default CrediCardDisplayPopUp;
