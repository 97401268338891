export const formatDate = (date) => {
  if (date !== '') {
    const [ year, month, day ] = date.split('-');
    return [ month, day, year ].join('/');
  } else {
    return date;
  }
}

export const formatDateYYMMDD = (date) => {
  if (date !== '') {
    const [ month, day, year ] = date.split('/');
    return [ year, month, day ].join('-');
  } else {
    return date;
  }
}

export const shortenDayOfWeek = (day) => {
  let abbreviatedDay;

  switch(day) {
    case "MONDAY" : 
      abbreviatedDay = "MON"
      break
    case "TUESDAY" :
      abbreviatedDay = "TUE"
      break
    case "WEDNESDAY" :
      abbreviatedDay = "WED"
      break
    case "THURSDAY" :
      abbreviatedDay = "THUR"
      break
    case "FRIDAY" : 
    abbreviatedDay = "FRI"
      break
    case "SATURDAY" : 
      abbreviatedDay = "SAT"
      break
    case "SUNDAY" : 
      abbreviatedDay = "SUN"
      break

    default : return null
  }

  return abbreviatedDay;
}

export const displayDaysOfWeek = (weekdayArray) => {
  const {length} = weekdayArray;

  return weekdayArray.map(( day, index ) => {
    const dayAbbreviation = shortenDayOfWeek(day);
    if (index < length-1) {
      return `${dayAbbreviation}, `
    } else {
      return dayAbbreviation
    }
  })
}
