import React, { useEffect, useState } from "react";
import PeakPricingWindowInputs from "./PeakPricingWindowInputs";
import PropTypes from "prop-types";
import Steps from "../../steps/Steps";
import SolidButton from "../../form/SolidButton";

const PeakPricingWindowAdd = (props) => {
  const [disabled, setDisabled] = useState(true);

  const {
    fine,
    peakPricingWindows,
    addPeakPricingWindows,
    addPeakPricingAdditionalCharge,
    peakPricingAdditionalCharge,
    saveFineToApi,
    stepNumber,
  } = props;

  useEffect(() => {
    if (peakPricingWindows?.length === 0) {
      setDisabled(true);
    } else if (peakPricingWindows?.length > 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [peakPricingAdditionalCharge, peakPricingWindows]);

  return (
    <div>
      <PeakPricingWindowInputs
        fine={fine}
        peakPricingAdditionalCharge={peakPricingAdditionalCharge}
        peakPricingWindows={peakPricingWindows}
        addPeakPricingWindows={addPeakPricingWindows}
        addPeakPricingAdditionalCharge={addPeakPricingAdditionalCharge}
      />
      <Steps step={stepNumber} {...props} />
      <div style={{ width: "35%", marginLeft: "33%" }}>
        <SolidButton
          label={"SAVE"}
          onClick={saveFineToApi}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

PeakPricingWindowAdd.propTypes = {
  peakPricingWindows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      fineId: PropTypes.string,
      daysOfWeek: PropTypes.arrayOf(PropTypes.string),
      startTime: PropTypes.string,
      endTime: PropTypes.string,
    })
  ),
  addPeakPricingWindows: PropTypes.func,
  addPeakPricingAdditionalCharge: PropTypes.func,
  saveFineToApi: PropTypes.func,
  stepNumber: PropTypes.number,
};

export default PeakPricingWindowAdd;
