import React, { useState } from "react";
import { IconButton, Menu } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";

const ReportKebabButton = (props) => {
  const { children, comp } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        className="kebab"
        aria-label="more"
        onClick={handleClick}
        aria-haspopup="true"
        aria-controls="long-menu"
        style={{ height: "50px", marginRight: "-15px" }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        onClose={handleClose}
        onClick={handleClose}
        open={open}
        style={
          comp === "editFine"
            ? { marginLeft: "-75px", marginTop: "26px" }
            : { marginLeft: "-100px", marginTop: "26px" }
        }
      >
        {children}
      </Menu>
    </>
  );
};

export default ReportKebabButton;
