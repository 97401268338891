import React, {Component} from 'react';
import '../css/components/dateArrow.css';

class DateArrow extends Component {

    constructor (props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="ss-date-arrow">
                <div className="arrow-top-half"/>
                <div className="arrow-bottom-half"/>
            </div>
        )
    }
}

export default DateArrow;