import React from "react";
import _ from "underscore";
import MultiSelectContentItem from "./MultiSelectContentItem";
import PropTypes from "prop-types";
import "../../css/components/multiSelectContent.css";

const MultiSelectContent = (props) => {
  const { value, options, onChange, isDisabled, editWidth } = props;

  return (
    <div className="multi-select-content-wrapper">
      <div
        // className={
        //   editWidth === "editWidth"
        //     ? `multi-select-dropdown-content-editWidth`
        //     : `multi-select-dropdown-content`
        // }
        className="multi-select-dropdown-content"
      >
        <MultiSelectContentItem
          value={[]}
          checked={value.length === options?.length}
          name="view-all"
          displayName="View All"
          onChange={onChange}
          isDisabled={isDisabled}
        />
        {options &&
          options.length > 0 &&
          _.map(options, (option, idx) => (
            <MultiSelectContentItem
              key={idx}
              value={option.value}
              checked={_.contains(value, option.value)}
              name={idx}
              displayName={option.displayName}
              onChange={onChange}
              isDisabled={isDisabled}
            />
          ))}
      </div>
    </div>
  );
};

MultiSelectContent.propTypes = {
  value: PropTypes.array.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.exact({
      value: PropTypes.any,
      displayName: PropTypes.string,
    })
  ).isRequired,
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  editWidth: PropTypes.string,
};

export default MultiSelectContent;
