import React, { useState } from "react";
import StepWizardContainer from "../stepWizard/StepWizardContainer";
import StepWizard from "react-step-wizard";
import CustomerSelect from "./CustomerSelect";
import ReservationCriteria from "../reservation/ResevationCriteria";
import ZonePicker from "./ZonePicker";
import { useHistory } from "react-router";
import ReservationConfirmation from "./ReservationConfirmation";
import {
  approveReservation,
  reserveSpace,
} from "./requests/resevation-requests";
import Busy from "../Busy";
import { toast } from "react-toastify";
import { getErrorMessageForStandardResponse } from "../../util/NetworkErrorUtil";
import { ZoneRefOption } from "../../controls/ZoneRefOption";

const ReservationBuilder = (props) => {
  const { account } = props;
  const history = useHistory();

  const equipmentLengths = [
    { label: "Please select", value: "" },
    { label: "25' MAX (Truck Only)", value: "TWENTY_FIVE_FOOT" },
    { label: "45' MAX (Small Container)", value: "FORTY_FIVE_FOOT" },
    { label: "53' MAX (Large Container)", value: "FIFTY_THREE_FOOT" },
    { label: "83' MAX (Truck + Trailer)", value: "EIGHTY_THREE_FOOT" },
  ];
  const frequencyTypes = [
    { label: "Please select", value: "" },
    { label: "One Time", value: "ONE_TIME" },
    { label: "Recurring", value: "RECURRING" },
  ];
  const durationTypes = [
    { label: "Please select", value: "" },
    { label: "Daily", value: "DAILY" },
    { label: "Weekly", value: "WEEKLY" },
    { label: "Monthly", value: "MONTHLY" },
  ];

  const [selectedBuyerAccountId, setSelectedBuyerAccountId] = useState();
  const [powerAccessible, setPowerAccessible] = useState(false);
  const [numberOfSpaces, setNumberOfSpaces] = useState(1);
  const [selectedEquipmentLength, setSelectedEquipmentLength] = useState();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedFrequency, setSelectedFrequency] = useState();
  const [selectedDuration, setSelectedDuration] = useState("");
  const [selectedZone, setSelectedZone] = useState();
  const [selectedZone1, setSelectedZone1] = useState();

  const [price, setPrice] = useState();
  const [zonePriceOverride, setZonePriceOverride] = useState(false);
  const [zoneOverride, setZoneOverride] = useState(false);
  const [emailIdDisplay, setEmailIdDisplay] = useState("");

  const onPriceChange = (val) => {
    setPrice(val);
  };

  const onZoneChange = (event) => {
    if (!event?.target?.value?.value) {
      const selectZone = new ZoneRefOption({ value: null, displayValue: "" });
      setSelectedZone(selectZone);
    } else {
      setSelectedZone(event.target.value);
    }
  };
  const onZoneChange1 = (event) => {
    if (!event?.target?.value?.value) {
      const selectZone = new ZoneRefOption({ value: null, displayValue: "" });
      setSelectedZone1(selectZone);
    } else {
      setSelectedZone1(event.target.value);
    }
  };

  const onFrequencyChange = (event) => {
    setSelectedFrequency(event.target.value);
  };

  const onDurationChange = (event) => {
    setSelectedDuration(event.target.value);
  };

  const onStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const onEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const onEquipmentLengthChange = (event) => {
    setSelectedEquipmentLength(event.target.value);
  };

  const onNumberOfSpacesChange = (event) => {
    setNumberOfSpaces(event.target.value);
  };

  const onPowerAccessibleChange = () => {
    setPowerAccessible(!powerAccessible);
  };

  const handleBuyerAccountChange = (event) => {
    setSelectedBuyerAccountId(event.target.value.value);
    setEmailIdDisplay(event?.target?.value?.displayValue);
  };

  const saveReservationToApi = () => {
    if (selectedZone || selectedZone1) {
      Busy.set(true);
      const reservationRequest = {
        zoneId: !!zoneOverride ? selectedZone.value : selectedZone1?.value,
        accountId: selectedBuyerAccountId,
        numberOfSpaces: numberOfSpaces,
        frequency: selectedFrequency,
        duration: selectedFrequency === "ONE_TIME" ? "DAILY" : "MONTHLY",
        powerAccessible: powerAccessible,
        equipmentLength: selectedEquipmentLength,
        approved: true,
        originType: "BROKERED",
      };

      if (zonePriceOverride) {
        reservationRequest.price = price;
      }

      reserveSpace(reservationRequest, zoneOverride, startDate, endDate)
        .then(
          (response) => {
            approveReservation(response.body.id)
              .then(
                () => {
                  toast.info(
                    `We've successfully secured your space.Please use the payment link that was sent to your registered email address, ${emailIdDisplay}, to finish the payment procedure.`,
                    {
                      position: toast.POSITION.TOP_RIGHT,
                    }
                  );
                  Busy.set(false);
                  history.push("/reservations");
                },
                (error) => {
                  toast.error(getErrorMessageForStandardResponse(error));
                }
              )
              .catch((error) => {
                toast.error(getErrorMessageForStandardResponse(error));
              });
          },
          (error) => {
            toast.error(getErrorMessageForStandardResponse(error));
            Busy.set(false);
            history.goBack();
          }
        )
        .catch((error) => {
          toast.error(getErrorMessageForStandardResponse(error));
          Busy.set(false);
          history.goBack();
        });
    }
  };

  const cancel = () => {
    history.push("/reservations");
  };

  return (
    <div className="container">
      <h3>CREATE RESERVATION</h3>
      <StepWizardContainer>
        <StepWizard>
          <CustomerSelect
            handleBuyerAccountChange={handleBuyerAccountChange}
            selectedBuyerAccountId={selectedBuyerAccountId}
            stepNumber={1}
            cancel={cancel}
          />
          <ReservationCriteria
            account={account}
            startDate={startDate}
            onStartDateChange={onStartDateChange}
            endDate={endDate}
            onEndDateChange={onEndDateChange}
            equipmentLengths={equipmentLengths}
            selectedEquipmentLength={selectedEquipmentLength}
            onEquipmentLengthChange={onEquipmentLengthChange}
            numberOfSpaces={numberOfSpaces}
            onNumberOfSpacesChange={onNumberOfSpacesChange}
            powerAccessible={powerAccessible}
            onPowerAccessibleChange={onPowerAccessibleChange}
            frequencyTypes={frequencyTypes}
            durationTypes={durationTypes}
            selectedFrequency={selectedFrequency}
            selectedDuration={selectedDuration}
            onFrequencyChange={onFrequencyChange}
            onDurationChange={onDurationChange}
            stepNumber={2}
            cancel={cancel}
          />
          <ZonePicker
            account={account}
            powerAccessible={powerAccessible}
            numberOfSpaces={numberOfSpaces}
            selectedEquipmentLength={selectedEquipmentLength}
            selectedFrequency={selectedFrequency}
            selectedDuration={selectedDuration}
            startDate={startDate}
            endDate={endDate}
            selectedZone={selectedZone}
            selectedZone1={selectedZone1}
            onZoneChange={onZoneChange}
            onZoneChange1={onZoneChange1}
            setSelectedZone1={setSelectedZone1}
            setSelectedZone={setSelectedZone}
            zoneOverride={zoneOverride}
            onPriceChange={(val) => onPriceChange(val)}
            setZoneOverride={setZoneOverride}
            zonePriceOverride={zonePriceOverride}
            setZonePriceOverride={setZonePriceOverride}
            price={price}
            stepNumber={3}
            cancel={cancel}
          />
          <ReservationConfirmation
            powerAccessible={powerAccessible}
            numberOfSpaces={numberOfSpaces}
            selectedEquipmentLength={selectedEquipmentLength}
            selectedFrequency={selectedFrequency}
            selectedDuration={selectedDuration}
            startDate={startDate}
            endDate={endDate}
            selectedZone={selectedZone}
            saveReservationToApi={saveReservationToApi}
            stepNumber={4}
            price={price}
            zoneOverride={zoneOverride}
            zonePriceOverride={zonePriceOverride}
            cancel={cancel}
            selectedZone1={selectedZone1}
          />
        </StepWizard>
      </StepWizardContainer>
    </div>
  );
};

export default ReservationBuilder;
