export const checkTimeIsValid = (start, end) => {
  if (start !== '' && end !== '') {
    const compareGreater = (time1, time2) => {
      return (time1 > time2)
    }
    const [startHour, startMinute] = start.split(':');
    const [endHour, endMinute] = end.split(':');

    if (compareGreater(startHour, endHour)) {
      return false
    } else if (startHour === endHour && startMinute === endMinute) {
      return false
    } else {
      return !(startHour === endHour && compareGreater(startMinute, endMinute));
    }
  } else {
    return false;
  }
}
