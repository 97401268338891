import React from 'react';

const ZoneSelectItem = (props) => {
  const { zone } = props;
  return <option value={zone.id}>
    {zone.name}
  </option>
}

export default ZoneSelectItem;
