import React from "react";
import ReportDataRow from "../report/ReportDataRow";
import ReportData from "../report/ReportData";
import ReportRow from "../report/ReportRow";
import { isRoleApproved, MANAGER_EDIT_ROLES } from "../../util/user/UserUtil";
import ZoneAvailabilityReportMenu from "./ZoneAvailabilityReportMenu";

const ZoneAvailabilityListItem = (props) => {
  const { zone, account } = props;

  return (
    <ReportRow name="zonesReport">
      {isRoleApproved(MANAGER_EDIT_ROLES, account?.userType) && (
        <ZoneAvailabilityReportMenu zone={zone} />
      )}
      <ReportDataRow>
        {!!zone?.name && <ReportData label="Zone Name">{zone.name}</ReportData>}
        {zone?.currentAvailableSpaces && (
          <ReportData label="Available Spaces">
            {zone.currentAvailableSpaces}
          </ReportData>
        )}
        {zone.pricePerDayInCents != null ? (
          <ReportData label="One Time Rate">
            ${zone.pricePerDayInCents / 100}
          </ReportData>
        ) : null}
        {/* {zone.pricePerWeekInCents != null ? (
          <ReportData label="Weekly Rate">
            ${zone.pricePerWeekInCents / 100}
          </ReportData>
        ) : null} */}
        {zone.pricePerMonthInCents != null ? (
          <ReportData label="Recurring Rate">
            ${zone.pricePerMonthInCents / 100}
          </ReportData>
        ) : null}
      </ReportDataRow>
    </ReportRow>
  );
};

export default ZoneAvailabilityListItem;
