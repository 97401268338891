import React from 'react';
import ReportRow from "../../report/ReportRow";
import ReportDataRow from "../../report/ReportDataRow";
import ReportData from "../../report/ReportData";

const ZoneCoordinateCard = (props) => {

  const { coordinates } = props;

  return (
    <div>
      {coordinates && coordinates.map((coordinates) => {
        return (
          <ReportRow key={coordinates.id}>
            <ReportDataRow>
              <ReportData label={"Order"}>{coordinates.order + 1}</ReportData>
              <ReportData label={"Latitude"}>{coordinates.latitude}</ReportData>
              <ReportData label={"Longitude"}>{coordinates.longitude}</ReportData>
            </ReportDataRow>
          </ReportRow>
        );
      })}
    </div>
  );
}

export default ZoneCoordinateCard;