import React from "react";
import FineAssessmentsListItem from "./FineAssessmentsListItem";
import { withGroupAndSortExportAndGroupings } from "../../../report/hocs/withGroupAndSortExportAndGroupings";

const FineAssessmentsList = (props) => {
  const { fineAssessments, onFineAssessmentUpdate } = props;

  return (
    <>
      <br />
      {fineAssessments?.length > 0 && (
        <>
          {fineAssessments?.map((fineAssessment) => (
            <FineAssessmentsListItem
              key={fineAssessment.id}
              fineAssessment={fineAssessment}
              onFineAssessmentUpdate={onFineAssessmentUpdate}
            />
          ))}
          <div className="ss-load-more-records">
            You have reached end of the list
          </div>
        </>
      )}
    </>
  );
};

export default FineAssessmentsList;
export const FineAssessmentsListWithGroupAndSort =
  withGroupAndSortExportAndGroupings(FineAssessmentsList, "fineAssessments");
