import React from "react";
import { useHistory } from "react-router";
import { toastErrorMessage } from "../../util/NetworkErrorUtil";
import Busy from "../../components/Busy";
import { requestCancelBookingSubscription } from "./requests/booking-requests";
import useBookingParam from "../../hooks/booking/useBookingParam";
import ConfirmDialogView from "../../components/ConfirmDialogView";

const BookingCancelSubscription = () => {
  const { booking } = useBookingParam();
  const history = useHistory();

  const handleCancelBookingSubscription = () => {
    Busy.set(true);
    requestCancelBookingSubscription(booking.id)
      .then(() => {
        Busy.set(false);
        history.push(`/supplier-bookings`);
      }, toastErrorMessage)
      .catch(toastErrorMessage);
  };

  return (
    <ConfirmDialogView
      handleConfirm={handleCancelBookingSubscription}
      headerText={`Cancel Booking ${booking.orderNumber}'s Subscription`}
      backButtonText={"Go Back"}
      comp="bookingCancelSubs"
    >
      Are you sure you want to end this monthly booking?
    </ConfirmDialogView>
  );
};

export default BookingCancelSubscription;
