import React from 'react';
import PropTypes from "prop-types";
import SelectInput from "../common/SelectInput";

const EquipmentLengths = (props) => {

  const {
    equipmentLengths,
    selectedEquipmentLength,
    onEquipmentLengthChange,
  } = props;

  return (
    <SelectInput
      label={"Equipment Length: "}
      name={"Equipment Length: "}
      value={selectedEquipmentLength}
      onChange={onEquipmentLengthChange}
      items={equipmentLengths}
    />
  );
}

EquipmentLengths.propType = {
  stepNumber: PropTypes.number,
  equipmentLengths: PropTypes.array,
  selectedEquipmentLength: PropTypes.string,
  onEquipmentLengthChange: PropTypes.func
}

export default EquipmentLengths;
