export const ajaxRequest = (options) => {
  const { url, ...otherOptions } = options;
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  return window.$.ajax({
    url: `${baseUrl}/api/` + url,
    xhrFields: {
      withCredentials: true,
    },
    ...otherOptions,
  });
};
