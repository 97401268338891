import moment from "moment/moment";
import {
  BookingSubscriptionStatus,
  EquipmentLengthsConstant,
} from "../../components/constants/securspace-constants";
import { formatCurrencyValue } from "../PaymentUtils";

const dateFormat = "MM/DD/YYYY";

export const formatDate = (date) => {
  return date ? moment(new Date(date)).format(dateFormat) : "N/A";
};

export const formatFrequency = (booking) => {
  return booking.frequency === "RECURRING" ? "Recurring" : "One-Time";
};

export const formatFrequencyByDuration = (frequency, duration) => {
  return frequency === "RECURRING" ? "Recurring" : "One Time";
};

export const formatEquipmentLength = (equipmentLength) => {
  switch (equipmentLength) {
    case Object.keys(EquipmentLengthsConstant)[0]:
      return EquipmentLengthsConstant.TWENTY_FIVE_FOOT;
    case Object.keys(EquipmentLengthsConstant)[1]:
      return EquipmentLengthsConstant.FORTY_FIVE_FOOT;
    case Object.keys(EquipmentLengthsConstant)[2]:
      return EquipmentLengthsConstant.FIFTY_THREE_FOOT;
    case Object.keys(EquipmentLengthsConstant)[3]:
      return EquipmentLengthsConstant.EIGHTY_THREE_FOOT;
    default:
      return "";
  }
};
export const reverseFormatEquipmentLength = (equipmentLength) => {
  switch (equipmentLength) {
    case Object.values(EquipmentLengthsConstant)[0]:
      return Object.keys(EquipmentLengthsConstant)[0];
    case Object.values(EquipmentLengthsConstant)[1]:
      return Object.keys(EquipmentLengthsConstant)[1];
    case Object.values(EquipmentLengthsConstant)[2]:
      return Object.keys(EquipmentLengthsConstant)[2];
    case Object.values(EquipmentLengthsConstant)[3]:
      return Object.keys(EquipmentLengthsConstant)[3];
    default:
      return "";
  }
};

export const formatSubscriptionStatus = (bookingSubscriptionStatus) => {
  switch (bookingSubscriptionStatus) {
    case Object.keys(BookingSubscriptionStatus)[0]:
      return BookingSubscriptionStatus.ACTIVE;
    case Object.keys(BookingSubscriptionStatus)[1]:
      return BookingSubscriptionStatus.CANCELLED;
    case Object.keys(BookingSubscriptionStatus)[2]:
      return BookingSubscriptionStatus.DECLINED;
    case Object.keys(BookingSubscriptionStatus)[3]:
      return BookingSubscriptionStatus.NONE;
    default:
      return "";
  }
};

export const formatRecurringPayoutAmount = (booking) => {
  return booking.frequency === "RECURRING"
    ? formatCurrencyValue(booking.recurringSupplierPayoutAmount, true)
    : "$0";
};

export const formatBookingRate = (booking) => {
  switch (booking.durationType) {
    case "WEEKLY":
      return `${formatCurrencyValue(booking.rate, true)}/week`;
    case "MONTHLY":
      return `${formatCurrencyValue(booking.rate, true)}/month`;
    case "DAILY":
      return `${formatCurrencyValue(booking.rate, true)}/day`;
    default:
      return `${formatCurrencyValue(booking.rate, true)}/day`;
  }
};

export const formatActive = (value) => {
  return value ? "Active" : "Not Active";
};

export const formatBool = (bool) => {
  return bool ? "YES" : "NO";
};
