import React, { useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import _ from "underscore";
import { GoogleMap, Marker, withGoogleMap } from "react-google-maps";
import ZoneMapItem from "./ZoneMapItem";

const ZoneEditMap = (props) => {
  const { coordinates, onCoordinatesChange, location, zones } = props;

  const [displayZones, setDisplayZones] = useState([]);
  const [markerPositions, setMarkerPositions] = useState([]);
  const [position, setPosition] = useState({
    lat: 37.79793679162797,
    lng: -122.29519371288225,
  });

  useEffect(() => {
    const toGoogleMapLatLng = (coordinates): google.maps.LatLng => {
      return _.map(coordinates, (coordinate) => {
        return new google.maps.LatLng(
          coordinate.latitude,
          coordinate.longitude
        );
      });
    };

    if (zones && zones.length) {
      const reformattedZones = _.map(zones, (zone) => {
        const coordinates = _.sortBy(zone.coordinates, "order");
        return {
          ...zone,
          coordinates: toGoogleMapLatLng(coordinates),
        };
      });
      setDisplayZones(reformattedZones);
    }
  }, [zones, setDisplayZones, location]);

  useEffect(() => {
    // load only once to set the map position to the location coordinates
    if (location) {
      setPosition({
        lat: +location?.addressLatitude || 37.79793679162797,
        lng: +location?.addressLongitude || -122.29519371288225,
      });
    }
  }, [location]);

  // This is used to preload markers onto the map when editing a location zone
  // with existing coordinates and only needs to be run once on initial render
  useEffect(() => {
    if (coordinates) {
      let newPositions = [];
      for (let coordinate of coordinates) {
        newPositions.push({
          lat: Number(coordinate.latitude),
          lng: Number(coordinate.longitude),
        });
      }
      setMarkerPositions(newPositions);
    }
  }, [coordinates]);

  const defaultMapOptions = {
    mapTypeId: "satellite", // Set default to satellite view
  };

  const onMarkerDragEnd = (e, key) => {
    let newPositions = [...markerPositions];
    newPositions[key].lat = e.latLng.lat();
    newPositions[key].lng = e.latLng.lng();
    setMarkerPositions(newPositions);
    onCoordinatesChange(newPositions);
  };

  const onSelect = (position) => {
    let newPositions = [...markerPositions];
    let index = newPositions.indexOf(position);
    if (index >= 0) {
      newPositions.splice(index, 1);
    }
    setMarkerPositions(newPositions);
    onCoordinatesChange(newPositions);
  };

  const addMarker = (event) => {
    let newPositions = [...markerPositions];
    let newLat = event.latLng.lat();
    let newLng = event.latLng.lng();
    newPositions.push({ lat: newLat, lng: newLng });
    setMarkerPositions(newPositions);
    onCoordinatesChange(newPositions);
  };

  return (
    <GoogleMap
      center={position}
      onClick={addMarker}
      defaultZoom={15}
      options={defaultMapOptions}
    >
      {_.map(displayZones, (zone, idx) => {
        return zone.coordinates.length > 0 ? (
          <ZoneMapItem key={idx} zone={zone} />
        ) : (
          ""
        );
      })}

      {markerPositions.map((position, key) => {
        return (
          <div key={key}>
            <Marker
              position={position}
              label={`${key + 1}`}
              onDragEnd={(e) => onMarkerDragEnd(e, key)}
              draggable={true}
              onClick={() => onSelect(position)}
            />
          </div>
        );
      })}
    </GoogleMap>
  );
};

ZoneEditMap.propTypes = {
  zones: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      totalSpaces: PropTypes.number,
      equipmentLength: PropTypes.string,
      activationDate: PropTypes.string,
      deactivationDate: PropTypes.string,
      active: PropTypes.bool,
      coordinates: PropTypes.arrayOf(
        PropTypes.shape({
          latitude: PropTypes.any,
          longitude: PropTypes.any,
          order: PropTypes.number,
        })
      ),
    })
  ),
};

export default withGoogleMap(ZoneEditMap);
