import React from "react";
import ReportFlexRightContainer from "../../../report/ReportFlexRightContainer";
import ReportKebabButton from "../../../report/ReportKebabButton";
import { MenuItem } from "@material-ui/core";
import {
  deleteFineAssessment,
  refundFineAssessment,
} from "../../../checkin/request/fine-request";
import { toast } from "react-toastify";
import { getErrorMessageForStandardResponse } from "../../../../util/NetworkErrorUtil";

const FineAssessmentsMenu = (props) => {
  const { fineAssessment, onFineAssessmentUpdate } = props;

  const handleCancelFineAssessment = () => {
    deleteFineAssessment(fineAssessment.id)
      .then(
        () => {
          onFineAssessmentUpdate(fineAssessment.id);
          toast.success("Fine has been cancelled successfully!", {
            position: toast.POSITION.TOP_RIGHT,
          });
        },
        (error) => {
          toast(getErrorMessageForStandardResponse(error));
        }
      )
      .catch((error) => {
        toast(getErrorMessageForStandardResponse(error));
      });
  };

  const handleRefundFineAssessment = () => {
    if (!fineAssessment.refund) {
      refundFineAssessment(fineAssessment.id).then(
        onFineAssessmentUpdate(fineAssessment.id)
      );
    } else {
      toast("Assessment is already marked to process for refund");
    }
  };

  return (
    <ReportFlexRightContainer>
      <ReportKebabButton>
        {fineAssessment?.processed ? (
          <MenuItem onClick={handleRefundFineAssessment}>
            Refund Fine Assessment
          </MenuItem>
        ) : (
          <MenuItem onClick={handleCancelFineAssessment}>
            Cancel Fine Assessment
          </MenuItem>
        )}
      </ReportKebabButton>
    </ReportFlexRightContainer>
  );
};

export default FineAssessmentsMenu;
