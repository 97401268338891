import React, { useEffect, useState } from "react";
import ZoneMap from "../../zone/ZoneMap";
import ZoneList from "./ZoneList";

const LocationZone = (props) => {
  const { zones, account } = props;
  const [convertedZones, setConvertedZones] = useState([]);
  const [updateZones, setUpdateZones] = useState("🌯");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [updateZones]);

  useEffect(() => {
    for (let zone of zones) {
      for (let coordinates of zone.coordinates) {
        coordinates.latitude = Number(coordinates.latitude);
        coordinates.longitude = Number(coordinates.longitude);
      }
    }
    setConvertedZones([...zones]);
  }, [zones]);

  return (
    <div>
      <div style={{ height: "50vh", width: "100%" }}>
        <ZoneMap
          zones={convertedZones}
          containerElement={<div style={{ height: `100%` }} />}
          mapElement={<div style={{ height: `100%` }} />}
        />
      </div>
      <hr />
      <ZoneList
        convertedZones={convertedZones}
        setUpdateZones={setUpdateZones}
        account={account}
      />
    </div>
  );
};

export default LocationZone;
