import React from "react";
import "./currency.css";

const MoneyInput = (props, ref) => {
  const {
    className,
    name,
    label,
    placeHolder,
    value,
    onChange,
    onChangePrice,
    type,
    hide,
    disabled,
  } = props;

  const convertValue = (value) => {
    if (value && typeof value == "number") {
      return value / 100;
    } else {
      return value;
    }
  };
  const handleChange = (e) => {
    if (!!onChangePrice) onChangePrice(e.target.value);
    if (!!onChange) onChange(e);
  };
  var classNameStyle = "";
  if (label === "Booking Charge") classNameStyle = "currencyinput";
  else if (label === "Price Per Day") classNameStyle = "currencyinput_per_day";
  else if (label === "Price Per Day/One Time")
    classNameStyle = "currencyinput_per_day_one_time";
  else if (label === "Price Per Week")
    classNameStyle = "currencyinput_per_week";
  else if (label === "Price Per Month")
    classNameStyle = "currencyinput_per_month";
  else if (label === "Price Per Month/Recurring")
    classNameStyle = "currencyinput_per_month_recurring";

  const blockInvalidChar = (e) =>
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

  return (
    <div style={{ display: hide ? "none" : "block" }}>
      <label>{label}</label>
      <span className={classNameStyle}>$</span>
      <input
        ref={ref}
        className={className}
        type={type}
        name={name}
        placeholder={placeHolder}
        value={convertValue(value)}
        onKeyDown={blockInvalidChar}
        onChange={(e) => handleChange(e)}
        disabled={disabled}
      />
    </div>
  );
};

export default React.forwardRef(MoneyInput);
