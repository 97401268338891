import request from "../../../util/SuperagentUtils";
import { formatDate } from "../../../util/DateUtils";
const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const getBuyerAccounts = () => {
  return request.get(`${baseUrl}/api/account/buyer`).withCredentials();
};

export const searchAvailableZones = (reservationRequest) => {
  return request
    .get(`${baseUrl}/api/zone/search`)
    .query(reservationRequest)
    .withCredentials();
};

export const reserveSpace = (
  reservationRequest,
  zoneOverride,
  startDate,
  endDate
) => {
  return request
    .post(`${baseUrl}/api/reservation/`)
    .query({
      zoneOverride: zoneOverride,
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
    })
    .withCredentials()
    .send(reservationRequest);
};

export const approveReservation = (reservationId) => {
  return request
    .post(`${baseUrl}/api/reservation/` + reservationId)
    .query({ isApproved: true })
    .withCredentials();
};
