import React from 'react';
import _ from "underscore";
import ZoneSelectItem from "./ZoneSelectItem";

const ZoneSelect = (props) => {
  const { value, zones, onChange } = props;
  return <label>
    Select Zone:
    <select value={value}
            onChange={onChange}
    >
      {
        _.map(zones, (zone, idx) => {
          return <ZoneSelectItem key={idx} zone={zone}/>
        })
      }
    </select>
  </label>
}

export default ZoneSelect;
